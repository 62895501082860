import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "mt-10",
  style: {"color":"red"}
}

import { defineComponent, onMounted, onUnmounted, ref, watch, computed } from "vue";
import {
    getFactoryName,
    getGroupName,
    transformDate2,
    getGroupListByFactory,
} from "../../common/tools";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import { langList } from "../../common/lang";
import { toFixed } from "ant-design-vue/lib/input-number/src/utils/MiniDecimal";
import { Item } from "ant-design-vue/lib/menu";



export default /*@__PURE__*/_defineComponent({
  __name: 'downLoad',
  props: {

    dataList: null,
},
  emits: ['downLoadCancel'],
  setup(__props, { emit: __emit }) {

const props: any = __props;
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang()

let type = ref(1)   //1、智能诊断分数下载
let dataList = ref([])
let data = ref([])

let searchModel = ref({
    skip: 1,
    take: 20,
    total: 0,
})

// let pagination = computed(() => ({
//     total: searchModel.value.total,
//     current: searchModel.value.skip,
//     pageSize: searchModel.value.take,
// }));

let columns1:any = ref(
    [
        {
            title: langObj.value['工厂'],
            dataIndex: "factoryName",
            customCell: (_: any, index: any) => ({ rowspan: _.span, })

        },
        {
            title: langObj.value['区域'],
            dataIndex: "groupName",
            // align: "center"
            customCell: (_: any, index: any) => ({ rowspan: _.span, })
        },
        {
            title: langObj.value['设备名称'],
            dataIndex: "machineName",
            customCell: (_: any, index: any) => ({ rowspan: _.span, })
            // align: "center"
        },
        {
            title: langObj.value['设备位号'],
            dataIndex: "bitNumber",
            customCell: (_: any, index: any) => ({ rowspan: _.span, })

        },
        {
            title: langObj.value['设备编号'],
            dataIndex: "machineCode",
            customCell: (_: any, index: any) => ({ rowspan: _.span, })

        },
        {
            title: langObj.value['巡检序号'],
            dataIndex: "sort",
            customCell: (_: any, index: any) => ({ rowspan: _.span, }),

        },
        {
            title: langObj.value['执行人'],
            dataIndex: "userNick",
            customCell: (_: any, index: any) => ({ rowspan: _.span, }),

        },
        {
            title: langObj.value['健康状态'],
            dataIndex: "conditionName",
            customCell: (_: any, index: any) => ({ rowspan: _.span, }),

        },
        {
            title: langObj.value['测点名称'],
            dataIndex: "position",


        },
        {
            title: langObj.value['FX轴承因子'],
            dataIndex: "zc",
        },
        {
            title: langObj.value['FX冲击因子'],
            dataIndex: "cj",
        },
        {
            title: langObj.value['速度均方根'] + "(mm/s)",
            dataIndex: "sz",
        },
        {
            title: langObj.value['加速度均方根'] + "(m/s²)",
            dataIndex: "hz",
        },
        {
            title: langObj.value['温度'] + "(℃)",
            dataIndex: "temp",
        },
        // {
        //     title: langObj.value['健康指标'],
        //     dataIndex: "helth-zb",
        //     slots: { customRender: 'helth' },
        // },
        {
            title: '0-300HZ均方根',
            dataIndex: "Trms_300",
        },
        {
            title: '优化俏度',
            dataIndex: "TLinKurt",
        },
        {
            title: '全频段均方根',
            dataIndex: "Trms",
        },
        {
            title: '峰峰值',
            dataIndex: "Tff",
        },

        // {
        //     title: langObj.value['备注'],
        //     dataIndex: "remark",
        // },
        // {
        //     title: langObj.value['巡检日期'],
        //     dataIndex: "date",
        // },
    ]
)






watch(
    () => props.dataList,
    (newValue, oldValue) => {
        dataList.value = props.dataList
        init();
    }
);



onMounted(() => {
    getLang()
    type.value = props.type
    dataList.value = props.dataList
    init()
})

const init = () => {
    if (!dataList.value || !dataList.value.length) return
    // dataList.value = dataList.value.splice(0, 20)
    searchModel.value.skip = 1
    searchModel.value.take = 20
    searchModel.value.total = dataList.value.length;
    dealWithData1()
    dealWithData2()

}
let conditionList = [
    { label: "健康", value: 0, color: "#61c08f" },
    { label: "可用", value: 1, color: "#d2de49" },
    { label: "可用", value: 2, color: "#d2de49" },
    { label: "警戒", value: 3, color: "#cd5f3b" },
    { label: "警戒", value: 4, color: "#cd5f3b" },
    { label: "警戒", value: 5, color: "#cd5f3b" },
    { label: "故障", value: 6, color: "#921e37" },
    { label: "故障", value: 7, color: "#921e37" },
];
const exportToExcel = (data: any, fileName: any) => {
  

    // const header = ['factoryName', 'groupName', 'machineName','bitNumber','machineCode','sort','userNick','conditionName','position','zc','cj','sz','hz','temp','helth-zb','remark','date'];
    

    let merges:any = []


    for(var index=0;index<data.length;index++){
        const item = data[index]
        let listTStr = ''
        if(item.listT&&item.listT.length){
            for(let i=0;i<item.listT.length;i++){
                listTStr+= item.listT[i].text+':'+(item.listT[i].score||0)+'\n'
            }
        }
        
        item.listT = listTStr
        if(item.span&&item.span>1){
            for(let i=0;i<7;i++){
                merges?.push({ s: { r: index+1, c: i }, e: { r: index+item.span, c: i } })
            }
        }
    }
    data.map((t:any)=>{
        delete t.span
    })
    // const dataM = data.map((obj:any) => header.map((key:any) => obj[key]));
    // columns1.value
    data = data.map((t:any)=>{
        let rr:any = {}
        columns1.value.map((c:any) => {
            let title = c.title
            rr[title] = t[c.dataIndex] 
        })
        return rr
        
        // return {'工厂':t.factoryName,
        // '区域':t.groupName,
        // '设备名称':t.machineName,
        // '设备位号':t.bitNumber,
        // '设备编号':t.equipmentCode,
        // '巡检序号':t.sort,
        // '执行人':t.userNick,
        // '健康状态':t.conditionName,
        // '测点':t.position,
        // 'FX轴承因子':t.zc,
        // 'FX冲击因子':t.cj,
        // '速度均方根':t.sz,
        // '加速度均方根':t.hz,
        // '温度':t.temp,
        // '健康指标':t.listT,
        // '备注':t.remark,
        // '日期':t.date,}

    })

    // console.log('data', data)
   
    const worksheet = XLSX.utils.json_to_sheet(data);

    worksheet['!merges'] =merges
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(dataBlob, fileName + ".xlsx");
}



// 获取报告健康状态
const getReportCondition = (ev: any) => {
    if (ev && ev.condition) {
        return conditionList.find(
            (p: any) => p.value === ev.condition
        );
    } else {
        return conditionList[0];
    }
};
// 获取报告健康状态名称
const getReportConditionL = (ev: any) => {
    if (ev && ev.condition) {
        return conditionList.find(
            (p: any) => p.value === ev.condition
        );
    } else {
        return conditionList[0];
    }
};

const setSersorObj = (r: any) => {

    let obj = {

        position: r.position,
        // 加速度xyz 112 101
        hz: Number(r?.wkstatus?.find((p: any) => p.code == 112 && p.coordinate == 'Z')?.value)?.toFixed(4) || 0,
        lx: Number(r?.wkstatus?.find((p: any) => p.code == 101 && p.coordinate == 'X')?.value)?.toFixed(4) || 0,
        ly: r?.wkstatus?.find((p: any) => p.code == 101 && p.coordinate == 'Y')?.value || 0,
        // 速度xyz 107
        sz: Number(r?.wkstatus?.find((p: any) => p.code == 107 && p.coordinate == 'Z')?.value)?.toFixed(4) || 0,
        sx: r?.wkstatus?.find((p: any) => p.code == 107 && p.coordinate == 'X')?.value || 0,
        sy: r?.wkstatus?.find((p: any) => p.code == 107 && p.coordinate == 'Y')?.value || 0,
        // 温度 100
        temp: Number(r?.wkstatus?.find((p: any) => p.code == 100 && p.coordinate == 'Z')?.value)?.toFixed(2) || 0,
        // 轴承因子 301  冲击因子302
        zc: Number(r?.wkstatus?.find((p: any) => p.code == 301 && p.coordinate == 'Z')?.value)?.toFixed(2) || 0,
        cj: Number(r?.wkstatus?.find((p: any) => p.code == 302 && p.coordinate == 'Z')?.value)?.toFixed(2) || 0,
        remark: r.remark,
        date: r.date,
        listT: r.listT
    }
    return obj

}
let dealList: any = ref([])
let dealListExcel: any = ref([])
const dealWithData1 = () => {
    let list: any = []
    let classIndex = 0
    let listHealth:any = []
    dataList.value.map((r: any) => {

        let obj: any = {
            factoryName: getFactoryName(r.factoryId),
            groupName: getGroupName(r.machine?.groupId),
            machineName: r.machineName,
            bitNumber: r.machine?.bitNumber,
            equipmentCode: r.machine?.equipmentCode,
            sort: r.machine?.sort,
            userNick: r.userNick,
            conditionName: getReportConditionL(r)?.label,
            // sensorList: []
        }
        if(r.listHealth?.length > 0) {
            r.listHealth.map((h:any) => {
                obj[h.quota] = h.score
                let hInfo = listHealth.find((p:any) => p.dataIndex === h.quota)
                if(!hInfo) {
                    listHealth.push({
                        title:  h.text,
                        dataIndex: h.quota
                    })
                }
            }) 
        }
        if(r.listCommon?.length > 0) {
            r.listCommon.map((h:any) => {
                obj[h.quota] = h.score 
            }) 
        }

        if (r.inspectionInfoList) {
            r.inspectionInfoList.map((s: any, i: any) => {
                // obj.sensorList.push(setSersorObj(s))
                if (i == 0) {
                    obj.span = r.inspectionInfoList.length
                } else {
                    obj.span = 0
                }

                list.push({
                    ...obj,
                    ...setSersorObj(s),
                    classIndex,
                })
            })
            classIndex++
        } else {
            obj.span = 1
            list.push({
                ...obj,
                ...setSersorObj(r),
                classIndex
            })
            classIndex++
            // obj.sensorList.push(setSersorObj(r))
        }
        // list.push(obj)


    })
    dealList.value = JSON.parse(JSON.stringify(list))

    let a = [
        {
            title: langObj.value['备注'],
            dataIndex: "remark",
        },
        {
            title: langObj.value['巡检日期'],
            dataIndex: "date",
        },
    ]
    columns1.value = columns1.value.concat(listHealth).concat(a)
}


const dealWithData2 = () => {
    let list: any = []
    dataList.value.map((r: any) => {
        let obj: any = {
            factoryName: getFactoryName(r.factoryId),
            groupName: getGroupName(r.machine?.groupId),
            machineName: r.machineName,
            bitNumber: r.machine?.bitNumber,
            equipmentCode: r.machine?.equipmentCode,
            sort: r.machine?.sort,
            userNick: r.userNick,
            conditionName: getReportConditionL(r)?.label,
            sensorList: []
        }

        if (r.inspectionInfoList) {
            r.inspectionInfoList.map((s: any, i: any) => {
                 obj.sensorList.push(setSersorObj(s))
               
            })
           
        } else {
            obj.sensorList.push(setSersorObj(r))
        }
         list.push(obj)


    })
    dealListExcel.value = JSON.parse(JSON.stringify(list))



}



const getRowClassName = (record: any, index: any) => {
    // 根据索引设置不同的类名
    return record.classIndex % 2 === 0 ? "even-row" : "odd-row";
};



const downLoad2 = () => {
    const data: any =JSON.parse(JSON.stringify(dealList.value))  ;

    exportToExcel(data, "exported_data");
}


const cancel = () => {
    emits('downLoadCancel', true)
}

const emits = __emit

return (_ctx: any,_cache: any) => {
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_table, {
      scroll: {x: '2000px'},
      bordered: "",
      columns: _unref(columns1),
      dataSource: _unref(dealList),
      "row-class-name": getRowClassName
    }, {
      helth: _withCtx(({ text, record }) => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.listT, (item, index) => {
          return (_openBlock(), _createElementBlock("div", null, [
            _createElementVNode("span", null, _toDisplayString(item.text) + ": ", 1),
            _createElementVNode("span", null, _toDisplayString(item.score), 1)
          ]))
        }), 256))
      ]),
      _: 1
    }, 8, ["columns", "dataSource"]),
    _createElementVNode("div", _hoisted_1, _toDisplayString(_unref(langObj)['请耐心等待，完成加载后，点击下载即可下载文件，请勿反复点击下载或关闭本网站']) + "。 ", 1),
    _createElementVNode("div", {
      onClick: downLoad2,
      class: "mt-4 px-5 py-1 fff cursor-pointer",
      style: {"border":"1px solid #0d53b7cc","display":"inline-block","background":"#07249933","color":"#fff"}
    }, _toDisplayString(_unref(langObj)['下载']), 1)
  ]))
}
}

})