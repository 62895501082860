import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "bule_list_page theme-common" }
const _hoisted_2 = { class: "main" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { style: {"margin-top":"8px"} }
const _hoisted_5 = ["src"]

import request from "../../common/request";
import { useRouter } from "vue-router";
import { computed, onMounted } from "vue";
import { ref, reactive, toRaw } from "vue";
import type { UploadProps } from "ant-design-vue";
import { langList } from "../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { operateLogs } from "@/common/logs";
import { getLanguage } from "@/common/translate";

export default /*@__PURE__*/_defineComponent({
  __name: 'model',
  setup(__props) {

const router = useRouter();
const loading = ref(false);
const formRef = ref();
let factoryId = "";
/**操作日志记录 */
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang()
operateLogs("访问设备管理");
/**操作日志记录 */
const formState = reactive({
  keyword: "",
  groupId: "",
  skip: 1,
  take: 10,
});
let total = ref(0);
let pagination = computed(() => ({
  total: total.value,
  current: formState.skip,
  pageSize: formState.take,
}));

const handleTableChange = (pagination: any, filters: any, sorter: any) => {
  formState.skip = pagination.current;
  formState.take = pagination.pageSize
  search();
};
let formMachine:any = ref({
  FirstCategory: '',
  SecondCategory: '',
  ThirdCategory: '',
  ThirdCategoryAbbreviation: '',
});

const modalState = reactive({
  visible: false,
  title:langObj.value["新增"],
  confirmLoading: false,
  roleName: "",
  meun: [],
});

const formStateRef = ref();
const clearValidate = (name: any) => {
  if (formStateRef.value) {
    formStateRef.value.clearValidate(name);
  }
};
const dataSource = ref([]);
const columns = [
  {
    title: langObj.value["一级分类"],
    dataIndex: "FirstCategory",
    align: "center",
  },
  {
    title: langObj.value["二级分类"],
    dataIndex: "SecondCategory",
    align: "center",
  },
  {
    title: langObj.value["三级分类"],
    dataIndex: "ThirdCategory",
    align: "center",
  },
  {
    title: getLanguage("3D模型图"),
    dataIndex: "pictures",
    align: "center",
  },
  // {
  //   title: langObj.value["操作"],
  //   dataIndex: "act",
  //   with: 120,
  //   align: "center",
  // },
];
// 新增
const add = () => {
  formStateRef.value?.clearValidate();
  modalState.title = langObj.value["新增"];
  formMachine.value = {
    FirstCategory: '',
    SecondCategory: '',
    ThirdCategory: '',
    ThirdCategoryAbbreviation: '',
  };
  fileList.value = [];
  modalState.visible = true;
};


const editThis = (val: any) => {
  formStateRef.value?.clearValidate();
  modalState.title = langObj.value["编辑"];
  formMachine.value = JSON.parse(JSON.stringify(val));
  fileList.value = [];
  if (formMachine.value.pictures?.length > 0) {
    formMachine.value.pictures.map((p: any) => {
      p.type = "";
    });
    fileList.value = formMachine.value.pictures;
  } else {
  }
  modalState.visible = true;
};
let fileList = ref<any>([]);

// 保存
const handleOk = () => {
  formStateRef.value?.clearValidate();
  formStateRef.value
    .validate()
    .then((result: any) => {
      if (fileList.value && fileList.value.length) {
        formMachine.value.pictures = fileList.value.map((p: any) => ({
          url: p.url,
          type: 15,
        }));
      }

      console.log('formMachine.value', formMachine.value)

      if (formMachine.value.id) {
        request
          .put("/api/machine-type", formMachine.value)
          .then((res) => {
            if (res) {
              modalState.visible = false;
              message.success("操作成功");
              search();
            }
          });
      } else {
        request
          .post("/api/machine-type", formMachine.value)
          .then((res) => {
            if (res) {
              modalState.visible = false;
              message.success("操作成功");
              search();
            }
          });
      }
    })
    .catch((err: any) => {
      console.log(err); //失败后执行
    });
};

// 删除
const deleteThis = (ev:any) => {
  console.log('ev', ev.id);
  const config = {
    params: {
      id: ev.id
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  request
   .delete("/api/machine-type", config )
   .then((res) => {
      if (res) {
        message.success("操作成功");
        formState.skip = 1;
        search();
      } else {
        message.warning("操作失败");
      }
    });
}

// 查询
const search = () => {
  const config = {
    params: formState,
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/machine-type/list", config).then((res: any) => {
    if (res) {
      dataSource.value = res.data.list;
      total.value = res.data.count;
    }
  });
};

const selectedFactory = (value: any, selectedOptions: any) => {
  console.log(value);
  console.log(selectedOptions);
  formMachine.value.groupId = value[value.length - 1];
  formMachine.value.factoryId = value[value.length - 2];

  if (value.length > 2) {
    formMachine.value.parentFactoryId = value[value.length - 3];
  }
};

let isShowDel:any = ref(false)
const getMemberInfo = async() => {
  let result = await request.get("/api/members/info")
  if (result?.data?.admin) {
    isShowDel.value = true
  }
}
let isEditable:any = ref(false)
// 在组件加载完成后执行一些操作
onMounted(async () => {
  isEditable.value = true
  getMemberInfo()
  search();
});

let groupList = ref<any>();

//**图片上传* */
const previewVisible = ref(false);
const previewImage = ref("");
const previewTitle = ref("");

const handleCancel = () => {
  previewVisible.value = false;
  previewTitle.value = "";
};

const handlePreview = async (file: any) => {
  previewImage.value = file.url;
  previewVisible.value = true;
  previewTitle.value = "";
};

let customRequest = async (options: any) => {
  const { onSuccess, onError, file } = options;
  let formData = new FormData();
  formData.append("file", file); // file为要上传的文件
  const config = {
    headers: {
      requestId: uuidv4(),
      "Content-Type": "multipart/form-data",
    },
  };
  let res = await request.post("/api/upload", formData, config);
  if (res) {
    file.url = res.data;
    onSuccess({ url: file.url, status: "done" });
  }
};

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _unref(enUS) : _unref(zhCN)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_layout_content, { style: {"padding":"30px 30px"} }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, { layout: "inline" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['关键词']
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: formState.keyword,
                      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((formState.keyword) = $event)),
                      placeholder: ""
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (formState.skip = 1, search()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(langObj)['搜索']), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_a_table, {
                bordered: "",
                emptyText: _unref(langObj)['暂无数据'],
                columns: columns,
                "data-source": dataSource.value,
                pagination: _unref(pagination),
                onChange: handleTableChange,
                loading: loading.value,
                size: "small"
              }, {
                bodyCell: _withCtx(({ column, text, record }) => [
                  (column.dataIndex === 'pictures')
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(text, (item) => {
                        return (_openBlock(), _createElementBlock("span", null, [
                          _createVNode(_component_a_image, {
                            src: item.url,
                            style: {"max-width":"100px"}
                          }, null, 8, ["src"])
                        ]))
                      }), 256))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'act')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        (_unref(isEditable))
                          ? (_openBlock(), _createBlock(_component_a_button, {
                              key: 0,
                              type: "primary",
                              class: "plft",
                              onClick: ($event: any) => (editThis(record))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_edit_outlined),
                                _createTextVNode(_toDisplayString(_unref(langObj)['编辑']), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_a_popconfirm, {
                          title: _unref(langObj)['确定删除此条数据么']+'?',
                          "ok-text": _unref(langObj)['确定'],
                          "cancel-text": _unref(langObj)['取消'],
                          onConfirm: ($event: any) => (deleteThis(record))
                        }, {
                          default: _withCtx(() => [
                            (_unref(isShowDel))
                              ? (_openBlock(), _createBlock(_component_a_button, {
                                  key: 0,
                                  style: {"margin-left":"10px"},
                                  type: "primary",
                                  danger: ""
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_delete_outlined),
                                    _createTextVNode(_toDisplayString(_unref(langObj)['删除']), 1)
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["title", "ok-text", "cancel-text", "onConfirm"])
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["emptyText", "data-source", "pagination", "loading"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_a_modal, {
          width: 850,
          maskClosable: false,
          visible: modalState.visible,
          "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((modalState.visible) = $event)),
          title: modalState.title,
          "confirm-loading": modalState.confirmLoading,
          cancelText: _unref(langObj)['取消'],
          okText: _unref(langObj)['确认'],
          onOk: handleOk
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, {
              ref_key: "formStateRef",
              ref: formStateRef,
              model: _unref(formMachine),
              name: "basic",
              autocomplete: "off",
              "label-col": { style: { width: '180px' } }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['一级分类'],
                  name: "FirstCategory"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(formMachine).FirstCategory,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(formMachine).FirstCategory) = $event)),
                      placeholder: _unref(langObj)['请输入一级分类']
                    }, null, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['二级分类'],
                  name: "SecondCategory"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(formMachine).SecondCategory,
                      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_unref(formMachine).SecondCategory) = $event)),
                      placeholder: _unref(langObj)['请输入二级分类']
                    }, null, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['三级分类'],
                  name: "ThirdCategory"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(formMachine).ThirdCategory,
                      "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(formMachine).ThirdCategory) = $event)),
                      placeholder: _unref(langObj)['请输入三级分类']
                    }, null, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['缩写'],
                  name: "ThirdCategoryAbbreviation"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(formMachine).ThirdCategoryAbbreviation,
                      "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_unref(formMachine).ThirdCategoryAbbreviation) = $event)),
                      placeholder: _unref(langObj)['请输入缩写']
                    }, null, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['模型图'],
                  name: "pic"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_upload, {
                      "file-list": _unref(fileList),
                      "onUpdate:fileList": _cache[6] || (_cache[6] = ($event: any) => (_isRef(fileList) ? (fileList).value = $event : fileList = $event)),
                      "list-type": "picture-card",
                      onPreview: handlePreview,
                      "custom-request": _unref(customRequest),
                      "auto-upload": false,
                      accept: "image",
                      "max-count": 6
                    }, {
                      default: _withCtx(() => [
                        (!_unref(fileList) || _unref(fileList).length < 1)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                              _createVNode(_component_plus_outlined),
                              _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(langObj)['上传']), 1)
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["file-list", "custom-request"]),
                    _createVNode(_component_a_modal, {
                      visible: previewVisible.value,
                      title: previewTitle.value,
                      footer: null,
                      onCancel: handleCancel
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          alt: "example",
                          style: {"width":"100%"},
                          src: previewImage.value
                        }, null, 8, _hoisted_5)
                      ]),
                      _: 1
                    }, 8, ["visible", "title"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _: 1
        }, 8, ["visible", "title", "confirm-loading", "cancelText", "okText"])
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})