<template>
  <div class="w-full flex" style="min-height: 80vh">
    <!-- 左侧设备列表 -->
    <machineListLeftComponent @selectMachine="selectMachine" :type="1">
    </machineListLeftComponent>

    <!-- 右侧信息 -->
    <div class="flex-1 ml-4">
      <div class="w-full">
        <div class="w-full flex items-center">
          <div style="font-size: 24px">{{ machineInfo?.machineName }}</div>
          <poweroff-outlined class="ml-2" :style="{ 'background-color': machineInfo?.status?.color }"
            style="border-radius: 50%; padding: 2px; font-size: 14px" />
          <div :style="{ 'background-color': machineInfo?.status?.color }" style="
              font-size: 12px;
              height: 13px;
              padding: 0 2px;
              transform: translateX(-2.5px);
              border-radius: 2px;
            ">
            <div style="transform: translateY(-3px)">
              {{ langObj[machineInfo?.status?.label] }}
            </div>
          </div>

          <smile-outlined class="ml-2" :style="{ 'background-color': machineInfo?.condition?.color }"
            style="border-radius: 50%; padding: 2px; font-size: 14px" />
          <div :style="{ 'background-color': machineInfo?.condition?.color }" style="
              font-size: 12px;
              height: 13px;
              padding: 0 2px;
              transform: translateX(-2.5px);
              border-radius: 2px;
            ">
            <div style="transform: translateY(-3px)">
              {{ langObj[machineInfo?.condition?.label] }}
            </div>
          </div>

          <div class="flex-1"></div>
        </div>
      </div>

      <div class="box mt-4 flex">
        <!-- <div style="width: 350px">
          <div class="w-full mt-2">
            <img class="w-full" style="height: 200px" src="" alt="" />
          </div>
          <div class="mt-1 w-full flex items-center">
            <div class="cursor-pointer">
              <left-outlined class="opt8 size20" />
            </div>
            <div class="flex-1 flex items-center">
              <img class="flex-1" style="height: 48px" src="" alt="" />
              <img class="flex-1" style="height: 48px" src="" alt="" />
              <img class="flex-1" style="height: 48px" src="" alt="" />
              <img class="flex-1" style="height: 48px" src="" alt="" />
            </div>
            <div class="cursor-pointer">
              <right-outlined class="opt8 size20" />
            </div>
          </div>
        </div> -->
        <pictureListComponent :type="1" :picList="picList">
        </pictureListComponent>

        <div class="flex-1 ml-4">
          <a-descriptions layout="vertical" :column="4" :contentStyle="{ color: '#fff' }"
            :labelStyle="{ color: '#7E8BA9' }">
            <a-descriptions-item :label="langObj['设备名称']">{{
              machineInfo.machineName
            }}</a-descriptions-item>
            <a-descriptions-item :label="langObj['所属工厂']">{{
              getFactoryName(machineInfo.factoryId)
            }}</a-descriptions-item>
            <a-descriptions-item :label="langObj['设备编号']">{{
              machineInfo.machineCode
            }}</a-descriptions-item>
            <a-descriptions-item :label="langObj['设备位置']">{{
              machineInfo.position
            }}</a-descriptions-item>
            <a-descriptions-item :label="langObj['设备厂家']">{{
              machineInfo.supplier
            }}</a-descriptions-item>
            <a-descriptions-item :label="langObj['设备型号']">{{
              machineInfo.model
            }}</a-descriptions-item>
            <a-descriptions-item :label="langObj['设备类型']">{{
              langObj[machineInfo.typeName] || machineInfo.typeName
            }}</a-descriptions-item>
            <a-descriptions-item :label="langObj['设备类型缩写']">{{
              machineInfo.machineType
            }}</a-descriptions-item>
            <a-descriptions-item :label="langObj['设备SN号']">{{
              machineInfo.sn
            }}</a-descriptions-item>
            <a-descriptions-item :label="langObj['巡检序号']">{{
              machineInfo.sort
            }}</a-descriptions-item>
            <!-- <a-descriptions-item :label="langObj['是否试验台']">{{
              machineInfo.experiment ? langObj["是" ]: langObj["否"]
            }}</a-descriptions-item> -->
            <a-descriptions-item :label="langObj['设备描述']">{{
              machineInfo.remark
            }}</a-descriptions-item>
          </a-descriptions>
        </div>

        <div v-if="isEditable" @click="editMachine" class="mt-4 px-5 py-1 fff cursor-pointer btn-submit" style="
            border: 1px solid #0d53b7cc;
            background: #072499;
            height: 33px;
            color: #fff;
            display: inline-block;
          ">
          {{ langObj['编辑设备'] }}
        </div>
      </div>

      <div class="box mt-4" style="padding: 0">
        <div class="w-full" style="border-bottom: 1px solid #495985">
          <div class="flex items-center px-5" style="font-size: 18px">
            <div class="py-3 cursor-pointer" :class="{ 'txt-select': menuSelect === 1 }" @click="selectMenu(1)">
              {{ langObj['总貌图'] }}
            </div>
            <div class="py-3 cursor-pointer ml-24" :class="{ 'txt-select': menuSelect === 2 }" @click="selectMenu(2)">
              {{ langObj['测点列表'] }}
            </div>
            <div class="py-3 cursor-pointer ml-24" :class="{ 'txt-select': menuSelect === 3 }" @click="selectMenu(3)">
              {{ langObj['报警记录'] }}
            </div>
          </div>
        </div>

        <div class="p-5">
          <!-- 总貌图 -->
          <div v-if="menuSelect === 1" class="zmt">
            <div>
              <a-button v-if="isEditable" type="primary" class="fr btn-submit" @click="markShow">
                <template #icon>
                  <EditOutlined />
                </template>
                {{ langObj['编辑'] }}
              </a-button>
            </div>
            <div id="yulan" v-if="config_3d && config_3d.img" style="
                margin: auto;
                padding: 20px;
                width: 800px;
                height: 600px;
                position: relative;
              ">
              <img :src="config_3d.picture" style="position: absolute" :style="{
                width: config_3d.img.width + 'px',
                height: config_3d.img.height + 'px',
                top: config_3d.img.y + 'px',
                left: config_3d.img.x + 'px',
                transform: 'rotate(' + config_3d.img.rotate + 'deg)',
              }" />
              <!-- 加载标点 -->
              <div v-for="(item, index) in config_3d.marks" :key="index">
                <div class="d3_point" v-if="item.bind.indexOf('a-') > -1"
                  :style="{ top: item.y + 'px', left: item.x + 'px' }">
                </div>
              </div>
              <!-- 加载标签 -->
              <div v-for="(item, index) in config_3d.marks" :key="index">
                <div class="d3_bq" v-if="item.bind.indexOf('b-') > -1" :style="{
                  top: item.y - 50 + 'px',
                  left: item.x - 100 + 'px',
                  'border-color': getColor(item.condition),
                  'box-shadow': getShadow(item.condition),
                }" style="width: 225px; height: 100px">
                  <div class="w-full" style="padding: 5px 10px">
                    <div class="w-full flex">
                      <div>{{ item.sensorPlace }}</div>
                      <div class="flex-1"></div>
                      <div :style="{
                        'background-color': getColor2(item.condition),
                      }" style="width: 15px; height: 15px; border-radius: 50%"></div>
                    </div>
                    <div class="w-full flex items-center">
                      <div style="width: 35%">
                        <div>{{ item.hz }}m/s²</div>
                        <div style="font-size: 12px; opacity: 0.6">
                          {{ langObj['振动加速度'] }}
                        </div>
                      </div>
                      <div style="width: 35%">
                        <div>{{ item.sz }}mm/s</div>
                        <div style="font-size: 12px; opacity: 0.6">
                          {{ langObj['振动速度'] }}
                        </div>
                      </div>
                      <div style="width: 30%; word-wrap:break-word; ">
                        <div>{{ item.temp }}℃</div>
                        <div style="font-size: 12px; opacity: 0.6">{{ langObj['温度'] }}</div>
                      </div>
                    </div>
                    <div class="w-full flex">
                      <div class="flex-1"></div>
                      <div class="mr-2" style="font-size: 12px; opacity: 0.6">
                        {{ item.date }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 划线 -->
              <div v-for="(item, index) in line" :key="index">
                <div class="d3_line" :style="{
                  top: item.y + 5 + 'px',
                  left: item.x + 5 + 'px',
                  width: item.distance + 'px',
                  transform: 'rotate(' + item.angle + 'deg)',
                }"></div>
              </div>
            </div>
          </div>
          <!-- 测点列表 -->
          <div v-if="menuSelect === 2">
            <div>
              <div v-if="isEditable" @click="addSensor" class="px-5 py-1 fff cursor-pointer ml-4 btn-submit"
                style="background-color: #072499" :style="{ width: language === 'Chinese' ? '100px' : '150px' }">
                {{ langObj['新建测点'] }}
              </div>
            </div>
            <div class="box1 mt-4" style="width: 100%">
              <a-table style="width: 100%" size="small" :pagination="false" :row-class-name="getRowClassName"
                :columns="sensorColumns" :data-source="data">
                <template #bodyCell="{ column, text, record }">
                  <template v-if="column.dataIndex === 'groupId'">
                    <span>{{ getGroupName(record.machine.groupId) }}</span>
                  </template>
                  <template v-if="column.dataIndex === 'machineName'">
                    <span>{{ record.machine.machineName }}</span>
                  </template>
                  <template v-if="column.dataIndex === 'hardware'">
                    <span>{{
                      (record?.hardware?.vol || record?.hardware?.vol == 0) ? record.hardware.vol + ' %' : ''
                      }}</span>
                  </template>
                  <template v-if="column.dataIndex === 'validity'">
                    <span>{{ getValidity(record.validityEnd) }}</span>
                  </template>
                  <template v-if="column.dataIndex === 'inspectionSubs'">
                    <span>{{ getInspectionSubs(record.inspectionSubs) }}</span>
                  </template>
                  <template v-if="column.dataIndex === 'parameter'">
                    <div class="flex items-center">
                      <div v-for="(item, index) in record.sensorGroup" :key="index">
                        <a-tooltip placement="topLeft">
                          <template #title>
                            <div style="background: #fff">
                              <div>{{ langObj['传感器编号'] }}: {{ item.sonId }}</div>
                              <div>{{ langObj['安装坐标'] }}: {{ item.coordinate }}</div>
                              <div>{{ langObj['轴名称'] }}: {{ item.sensorGroupName }}</div>
                            </div>
                          </template>
                          <a-tag color="#2db7f5">{{ item.coordinate }}</a-tag>
                        </a-tooltip>
                      </div>
                    </div>
                  </template>
                  <template v-if="column.dataIndex === 'act'">
                    <a v-if="isEditable" @click="editSensor(record)" class="theme-table-link">{{ langObj['编辑'] }}</a>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
          <!-- 报警记录 -->
          <div v-if="menuSelect === 3">
            <div class="w-full">
              <a-form :model="searchModel" name="horizontal_login" layout="inline" autocomplete="off">
                <a-form-item :label="langObj['报警时间']" name="status">
                  <a-range-picker v-model:value="warnSearchModel.date" :disabled-date="disabledDate"
                    @calendarChange="onCalendarChange" />
                </a-form-item>

                <a-form-item :label="langObj['报警类型']" name="status">
                  <a-select v-model:value="warnSearchModel.type" style="width: 220px" allowClear
                    :placeholder="langObj['请选择报警类型']">
                    <a-select-option v-for="(item, index) in warnTypeList" :key="index" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>

                <a-form-item :label="langObj['严重程度']" name="status">
                  <a-select v-model:value="warnSearchModel.condition" style="width: 220px" allowClear
                    :placeholder="langObj['请选择严重程度']">
                    <a-select-option v-for="(item, index) in conditionList" :key="index" :value="item.valueR">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>

                <a-form-item>
                  <div class="flex items-center">
                    <div @click="reset" class="btn px-5 py-1 fff cursor-pointer ml-4 btn-reset">
                      {{ langObj['重置'] }}
                    </div>

                    <div class="btn px-5 py-1 fff cursor-pointer ml-4 btn-submit" style="background-color: #072499"
                      @click="search">
                      {{ langObj['查询'] }}
                    </div>
                    <div class="btn px-5 py-1 fff cursor-pointer ml-4 btn-submit" style="background-color: #072499"
                      @click="downLoad">
                      {{ langObj['下载报警'] }}
                    </div>
                    <!-- <div
                      class="btn px-5 py-1 fff cursor-pointer ml-4"
                      style="background-color: #072499"
                    >
                      新建检修
                    </div> -->
                    <div v-if="isEditable" class="btn px-5 py-1 fff cursor-pointer ml-4 btn-submit"
                      style="background-color: #072499" @click="closeWarning">
                      {{ langObj['关闭报警'] }}
                    </div>
                  </div>
                </a-form-item>
              </a-form>
            </div>

            <div class="box1 mt-4" style="width: 100%">
              <a-table style="width: 100%" size="small" :row-class-name="getRowClassName" :columns="warningColumns"
                :data-source="warningList" :pagination="warmPagination" @change="changeWarnTable" :row-selection="{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectChange,
                }" rowKey="id">
                <template #bodyCell="{ column, text, record }">
                  <template v-if="column.dataIndex === 'type'">
                    <div class="flex items-center">
                      <div v-if="text === 1">
                        <img style="width: 20px" src="@/assets/zhenduan.png" alt="" />
                      </div>
                      <div v-if="text === 2">
                        <img style="width: 20px" src="@/assets/weixiu.png" alt="" />
                      </div>
                      <div v-if="text === 3">
                        <img style="width: 20px" src="@/assets/jiancha.png" alt="" />
                      </div>
                      <div v-if="text === 4">
                        <img style="width: 20px" src="@/assets/menxian.png" alt="" />
                      </div>
                      <div class="ml-2">
                        {{ langObj[warnTypeList.find((p) => p.value === text)?.label] }}
                      </div>
                    </div>
                  </template>

                  <template v-if="column.dataIndex === 'condition'">
                    <div class="flex items-center">
                      <div style="width: 12px; height: 12px; border-radius: 50%" :style="{
                        background: conditionList.find((p) =>
                          p.value.includes(text)
                        )?.color,
                      }"></div>
                      <div class="ml-2">
                        {{
                          langObj[conditionList.find((p) => p.value.includes(text))
                            ?.label]
                        }}
                      </div>
                    </div>
                  </template>

                  <template v-if="column.dataIndex === 'handleStatus'">
                    <div @click="viewClose(record)" v-if="text === 3">
                      <a>{{ langObj['已关闭'] }}</a>
                    </div>
                    <div v-if="text !== 3">
                      {{
                        handleStatusList.find((p) => p.value === text)
                          ? langObj[handleStatusList.find((p) => p.value === text)
                            ?.label]
                          : langObj['未处理']
                      }}
                    </div>
                  </template>

                  <template v-if="column.dataIndex === 'act'">
                    <a @click="viewReport(record)" v-if="
                      record.type === 1 &&
                      record.reportIds &&
                      record.reportIds.length
                    " class="theme-table-link">{{ langObj['诊断报告'] }}</a>
                    <!-- <a
                      @click="viewReport(record)"
                      v-if="record.type === 2 && record.reportIds.length"
                      class="theme-table-link"
                      >检修报告</a
                    > -->
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 总貌图编辑弹框 -->
    <a-modal class="whiteBg" v-model:visible="visibleEdit" :closable="false" :footer="null"
      :bodyStyle="{ background: '#020F46' }" style="top: 18%; height: 600px" width="1200px" height="600px"
      :maskClosable="false">
      <div style="color: #fff">
        <div class="w-full flex">
          <div class="flex-1"></div>
          <div class="cursor-pointer" @click="visibleEdit = false">
            <close-outlined style="font-size: 20px" />
          </div>
        </div>
        <div class="text-center w-full" style="font-size: 24px">{{ langObj['总貌图'] }}</div>
        <div class="tips">{{ langObj['左键按住可拖动，右键添加标记'] }}</div>
        <div class="main">
          <div id="container" class="rotateThemeBg" v-if="visibleEdit && visibleReEdit">
            <img :src="config_3d.picture" />
            <button id="rotate" class="lipButton">
              <div>
                <SyncOutlined style="font-size: 22px" />
              </div>
              {{ langObj['旋转'] }}
            </button>
            <!-- <button id="reset" class="lipButton">
              <div><SyncOutlined style="font-size: 22px" /></div>
              旋转
            </button> -->
            <span class="gh3d">
              <a-upload @preview="handlePreview" :custom-request="customRequest3D" :auto-upload="false" accept="image"
                :max-count="1">
                <button id="repart" class="lipButton">
                  <div>
                    <SwapOutlined style="font-size: 22px" />
                  </div>
                  {{ langObj['更换3D图'] }}
                </button>
              </a-upload>
            </span>
          </div>
          <div class="m_r">
            <div class="list">

              <div class="item flex items-center mb-4">
                <a-select v-model:value="machineId_copy" style="width: 200px">
                  <a-select-option v-for="(item, index) in machineList_copy" :key="index" :value="item.id">{{
                    item.machineName
                    }}</a-select-option>
                </a-select>
                <div @click="selectMachineCopy" class="btn-default py-1 px-3 mrs " style="width: 83px">{{ '选择覆盖' }}
                </div>
              </div>


              <div class="item" v-for="(item, index) in markList" :key="index">
                <div class="lleft">{{ item.id }}</div>
                <div class="lright">
                  <a-select v-model:value="item.bind" style="width: 200px" :options="options"></a-select>
                </div>
                <div class="licon" @click="deleteTag(item, index)">
                  <DeleteOutlined />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full flex justify-center mt-5">
          <div @click="visibleEdit = false" class="btn-default py-1 px-6">
            {{ langObj['关闭'] }}
          </div>
          <!-- <div @click="view3D" class="btn-default py-1 px-6 mrs">预览</div> -->
          <div @click="save3D" class="btn-default py-1 px-6 mrs">{{ langObj['保存'] }}</div>
        </div>
      </div>
    </a-modal>

    <!-- 关闭报警 -->
    <a-modal v-model:visible="visibledel" :closable="false" :footer="null" :bodyStyle="{ background: '#020F46' }"
      style="top: 30%" width="1200px" :maskClosable="false">
      <div style="color: #fff">
        <div class="w-full flex">
          <div class="flex-1"></div>
          <div class="cursor-pointer" @click="visibledel = false">
            <close-outlined style="font-size: 20px" />
          </div>
        </div>

        <div class="text-center w-full" style="font-size: 24px"> {{ langObj['关闭报警'] }} </div>

        <a-form ref="formRef" name="advanced_search" class="ant-advanced-search-form" style="margin-top: 20px"
          :model="formClose">
          <a-row :gutter="24">
            <a-col :span="24">
              <a-form-item name="closeCause" :label="langObj['关闭原因']" required>
                <a-textarea v-model:value="formClose.closeCause" placeholder="" :rows="4" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>

        <div class="w-full flex justify-center">
          <div @click="submitClose" style="
              background-color: #072499;
              padding: 5px 10px;
              width: 100px;
              font-size: 20px;
              text-align: center;
              cursor: pointer;
            " class="btn-submit">
            {{ langObj['提交'] }}
          </div>
        </div>
      </div>
    </a-modal>

    <a-modal v-model:visible="visibledel2" :closable="false" :footer="null" :bodyStyle="{ background: '#020F46' }"
      style="top: 30%" width="1200px" :maskClosable="false">
      <div style="color: #fff">
        <div class="w-full flex">
          <div class="flex-1"></div>
          <div class="cursor-pointer" @click="visibledel2 = false">
            <close-outlined style="font-size: 20px; color: #fff !important" />
          </div>
        </div>
        <div class="text-center w-full" style="font-size: 24px">关闭报警</div>

        <a-form ref="formRef" name="advanced_search" class="ant-advanced-search-form" style="margin-top: 20px"
          :model="formClose">
          <a-row :gutter="24">
            <a-col :span="24">
              <a-form-item name="closeCause" label="关闭原因" required>
                <a-textarea v-model:value="formClose.closeCause" placeholder="" :rows="4" readonly />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </a-modal>

    <a-drawer v-model:visible="visibleReport" class="custom-class" size="large" :closable="false" placement="right">
      <template #title> </template>
      <DiagnosticReportDetails ref="mrDref" :reportId="reportId" @cancel="cancel"></DiagnosticReportDetails>
    </a-drawer>

    <a-modal v-model:visible="visibleAddMachine" :closable="false" :footer="null" :bodyStyle="{ background: '#020F46' }"
      style="top: 30%" width="1300px" :maskClosable="false">
      <div style="color: #fff">
        <div class="w-full flex">
          <div class="flex-1"></div>
          <div class="cursor-pointer" @click="visibleAddMachine = false">
            <close-outlined style="font-size: 20px" />
          </div>
        </div>
        <div class="text-center w-full" style="font-size: 24px">
          {{ machineForm.id ? langObj["编辑设备"] : langObj["新建设备"] }}
        </div>

        <div class="w-full mt-4">
          <a-form ref="macForm" :model="machineForm" layout="inline" autocomplete="off" class="w-full"
            :label-col="{ style: { width: '180px', wordWrap: 'break-word' } }">
            <a-row class="w-full" :gutter="24">
              <a-col :span="12">
                <a-form-item :label="langObj['设备区域']" name="groupId" required>
                  <a-select v-model:value="machineForm.groupId" :placeholder="langObj['请选择设备区域']"
                    @change="selectGroupM">
                    <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备编号']" name="machineCode">
                  <a-input v-model:value="machineForm.machineCode" :placeholder="langObj['请输入设备编号']"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备名称']" name="machineName" required>
                  <a-input v-model:value="machineForm.machineName" :placeholder="langObj['请输入设备名称']"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备位置']" name="position">
                  <a-input v-model:value="machineForm.position" :placeholder="langObj['请输入设备位置']"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备厂家']" name="supplier">
                  <a-input v-model:value="machineForm.supplier" :placeholder="langObj['请输入设备厂家']"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备型号']" name="model">
                  <a-input v-model:value="machineForm.model" :placeholder="langObj['请输入设备型号']"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备类型']" name="type">
                  <a-cascader style="max-width:236px" v-model:value="machineForm.type" :options="machineTypeList"
                    :placeholder="langObj['请选择设备类型']" />
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备类型缩写']" name="machineType">
                  <a-input v-model:value="machineForm.machineType" :placeholder="langObj['请输入设备类型缩写']"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备SN号']" name="sn">
                  <a-input v-model:value="machineForm.sn" :placeholder="langObj['请输入设备SN号']"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['巡检序号']" name="sort" required>
                  <a-input-number :controls="false" style="width: 100%" v-model:value="machineForm.sort"
                    placeholder="请输入" />
                  <!-- <a-input v-model:value="machineForm.sort" placeholder="请输入设备序号" ></a-input> -->
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备位号']" name="sn">
                  <!-- <a-input-number
                    :controls="false"
                    style="width: 100%"
                    v-model:value="machineForm.bitNumber"
                    placeholder="请输入设备位号"
                  /> -->
                  <a-input v-model:value="machineForm.bitNumber" :placeholder="langObj['请输入设备位号']"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备功率']" name="sn">
                  <a-input v-model:value="machineForm.machinePower" :placeholder="langObj['请输入设备功率']"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备ID']" name="sn">
                  <a-input v-model:value="machineForm.params.ysshId" :placeholder="langObj['请输入设备ID']"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备执行人']" name="sn">
                  <a-input v-model:value="machineForm.params.ysshExecutor" :placeholder="langObj['请输入设备执行人']"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['检测类别']" name="sn">
                  <a-checkbox-group v-model:value="machineForm.mode">
                    <a-checkbox :value="1">{{ langObj['在线监测'] }}</a-checkbox>
                    <a-checkbox :value="2">{{ langObj['巡检'] }}</a-checkbox>
                  </a-checkbox-group>
                </a-form-item>
              </a-col>

              <!-- <a-col :span="12">
                <a-form-item :label="langObj['是否实验台']" name="experiment">
                  <a-select
                    v-model:value="machineForm.experiment"
                    :placeholder="langObj['请选择是否为试验台']"
                  >
                    <a-select-option
                      v-for="(item, index) in whetherList"
                      :key="index"
                      :value="item.value"
                    >
                      {{ langObj[item.label] }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col> -->

              <a-col :span="24">
                <a-form-item :label="langObj['设备描述']" name="remark">
                  <a-textarea v-model:value="machineForm.remark" placeholder="请输入" :rows="4" />
                </a-form-item>
              </a-col>

              <a-col :span="24">
                <a-form-item :label="langObj['设备照片']">
                  <a-upload v-model:file-list="machineFileList" list-type="picture-card" @preview="handlePreview"
                    :custom-request="customRequest" :auto-upload="false" accept="image" :max-count="6">
                    <div v-if="!machineFileList || machineFileList.length < 6">
                      <plus-outlined />
                      <div style="margin-top: 8px; color: #fff">{{ langObj['上传'] }}</div>
                    </div>
                  </a-upload>
                </a-form-item>
              </a-col>
            </a-row>

            <!-- <a-form-item class="w-full">
              <div class="w-full flex justify-center mt-5">
                <div @click="submitMachine" class="btn-default py-1 px-6 cursor-pointer btn-submit">
                  {{ langObj['提交'] }}
                </div>
              </div>
            </a-form-item> -->
            <a-form-item class="w-full">
              <div class="w-full flex justify-center mt-5">
                <div @click="submitMachine" class="btn-default py-1 px-6 cursor-pointer btn-submit">
                  {{ langObj['提交'] }}
                </div>
                <a-spin :spinning="addLoading">
                  <!-- <div v-if="isShowNext && !machineForm.id" @click="addMachineNew"
                    class="ml-8 btn-default py-1 px-6 cursor-pointer btn-submit">
                    {{ getLanguage('下一步') }}
                  </div> -->
                  <div v-if="isShowNext && machineForm.id" @click="addMachineNewNewtEdit"
                    class="ml-8 btn-default py-1 px-6 cursor-pointer btn-submit">
                    {{ getLanguage('下一步') }}
                  </div>
                </a-spin>
              </div>
              <div v-if="isShowNext" class="w-full text-center mt-2" style="color: #fff">{{
                getLanguage("请点击'下一步'为设备配置参数，配置完成后系统才能生成对应的健康分数！若为收集到设备提资信息，请先点击'保存'!") }}</div>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </a-modal>
    <!-- 提资信息 -->
    <a-modal v-model:visible="visibleAddMachineNew" :closable="false" :footer="null"
      :bodyStyle="{ background: '#020F46' }" style="top: 30%" width="1300px" :maskClosable="false"
      :zIndex="editRindex + 1">
      <div style="color: #fff">
        <div class="w-full flex">
          <div class="flex-1"></div>
          <div class="cursor-pointer" @click="visibleAddMachineNew = false">
            <close-outlined style="font-size: 20px" />
          </div>
        </div>
        <div class="text-center w-full" style="font-size: 24px">
          {{ langObj["新建设备"] }}
        </div>

        <a-form :model="addMachineForm">
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-item name="machineTypeId" :label="getLanguage('设备类型')" required>
                <a-cascader style="max-width:236px" v-model:value="addMachineForm.type" :options="machineTypeList"
                  :placeholder="langObj['请选择设备类型']" @change="selectMT" :dropdownClassName="dropdownClassName" />
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item :label="langObj['设备类型缩写']" name="machineType">
                <a-input v-model:value="addMachineForm.machineType" :placeholder="langObj['请输入设备类型缩写']"></a-input>
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item :label="getLanguage('设备是否频繁启停')" required>
                <a-radio-group v-model:value="addMachineForm.machineTypeDetails.isFrequentStart">
                  <a-radio :value="true">{{ getLanguage('是') }}</a-radio>
                  <a-radio :value="false">{{ getLanguage('否') }}</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item :label="getLanguage('设备3D图')">
                <a-select v-model:value="addMachineForm.machineTypeDetails.pictureUrl"
                  :dropdownClassName="dropdownClassName">
                  <a-select-option v-for="(item, index) in pic3Ds" :key="index" :value="item.url">
                    <div class="flex items-center">
                      <a-image height="30px" :src="item.url" />
                      <div ml-2>{{ item.remark }}</div>
                    </div>
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item :label="getLanguage('选择测点')" required>
                <div class="flex">
                  <a-tree-select v-model:value="selectSensorList" style="width: 100%" tree-checkable
                    :tree-data="sensorTreeData" :dropdownClassName="dropdownClassName" />
                  <a-button @click="selectSensor">{{ getLanguage('确认选择') }}</a-button>

                </div>
              </a-form-item>
            </a-col>

          </a-row>
        </a-form>

        <div class="mt-4 part" v-for="(item, index) in addMachineForm.machineTypeDetails.parts" :key="index">
          <div class="w-full flex items-center">
            <div>{{ getLanguage('部件') }}:</div>
            <div>{{ item?.partName }}</div>
            <div class="ml-2">({{ getLanguage('请完善部件信息') }})</div>
          </div>

          <div class="mt-2 ml-8" style="border-left: 2px solid gray">
            <div class="w-full flex items-center flex-wrap table1 ml-8">
              <div style="width: 200px">
                <div class="w-full text-center mb-2">{{ getLanguage('额定转速') }}(rpm)<span style="color: red">*</span>
                </div>
                <div class="mt-2">
                  <a-input-number style="width: 200px" v-model:value="item.params.Rated_speed" :controls="false" />
                </div>
              </div>

              <!-- <div style="width: 200px" class="ml-4">
                <div class="w-full text-center mb-2">{{ getLanguage('额定功率') }}(kw)<span style="color: red">*</span></div>
                <div class="mt-2" >
                  <a-input-number style="width: 200px" v-model:value="item.params.PowerFre" :controls="false" />
                </div>
              </div> -->

              <div style="width: 200px" class="ml-4">
                <div class="w-full text-center mb-2">{{ getLanguage('转速范围') }}<span style="color: red">*</span></div>
                <div class="mt-2 flex">
                  <a-input-number style="width: 90px" v-model:value="item.params.startFre" :controls="false" />
                  <div class="mx-1">——</div>
                  <a-input-number style="width: 90px" v-model:value="item.params.stopFre" :controls="false" />
                </div>
              </div>
              <template v-if="!item.disable">
                <div v-for="(item2, index2) in item.segments.columns" :key="index2" style="width: 200px" class="ml-4">
                  <div class="w-full text-center mb-2">{{ getLanguage(item2) }}<span style="color: red">*</span></div>
                  <div class="mt-2 flex">
                    <a-select v-model:value="item.segments['data' + index2]" style="width: 200px"
                      @change="selectPartParmar(item.segments, index2)">
                      <a-select-option v-for="(s, sIndex) in item.segments['columnsData' + index2]" :key="sIndex"
                        :value="s">{{ s }}</a-select-option>
                    </a-select>
                  </div>
                </div>
              </template>
            </div>

            <div class="mt-4" v-for="(structure, index2) in item.structures" :key="index2">
              <div class="flex items-center">
                <div>—</div>
                <div class="ml-2">{{ getLanguage('结构') }}: </div>
                <div class="ml-1">{{ getLanguage(structure.machineStructureName) }}</div>
                <div v-if="structure.sensorPlace">——</div>
                <div v-if="structure.sensorPlace">{{ getLanguage(structure.sensorPlace) }}</div>
                <div class="ml-2">({{ getLanguage('请完善结构信息，若该结构信息不全，将不会显示该测点的健康指标') }})</div>
              </div>

              <div class="w-full flex items-center flex-wrap table1 ml-8 mt-2">
                <div v-for="(parmar, index3) in structure.parmarList" :key="index3" style="width: 200px"
                  :class="{ 'ml-4': index3 !== 0 }">
                  <div class="w-full text-center mb-2">{{ getLanguage(parmar.label) }}</div>
                  <div class="mt-2">
                    <div v-if="parmar.type === 'select'">
                      <a-select v-model:value="parmar.value" style="width: 200px" :options="parmar.option"
                        :showSearch="true"></a-select>
                    </div>
                    <div v-else-if="parmar.type === 'string'">
                      <a-input style="width: 200px" v-model:value="parmar.value" />
                    </div>
                    <div v-else>
                      <a-input-number style="width: 200px" v-model:value="parmar.value" :controls="false" />
                    </div>
                  </div>
                </div>
              </div>
            </div>




          </div>



        </div>

        <a-form-item class="w-full">
          <div class="w-full flex justify-center mt-5">
            <div @click="isSubmitV = false, submitMachineNew()" class="btn-default py-1 px-6 cursor-pointer btn-submit">
              {{ langObj['提交'] }}
            </div>
          </div>
        </a-form-item>
      </div>

    </a-modal>
    <a-modal :width="1400" v-model:visible="downLoadVisible" title="下载" :maskClosable="false" :footer="false">
      <DownLoadComponent @downLoadCancel="downLoadCancel" :type="2" :dataList="downLoadList"></DownLoadComponent>
    </a-modal>

    <!-- 编辑测点 -->
    <a-modal class="whiteBg" v-model:visible="visibleAddSensor" :closable="false" :footer="null"
      :bodyStyle="{ background: '#020F46' }" style="top: 30%" width="1200px" :maskClosable="false">
      <div style="color: #fff">
        <div class="w-full flex">
          <div class="flex-1"></div>
          <div class="cursor-pointer" @click="visibleAddSensor = false">
            <close-outlined style="font-size: 20px" />
          </div>
        </div>
        <div class="text-center w-full " style="font-size: 24px">
          {{ sensorForm.id ? langObj["编辑测点"] : langObj["新建测点"] }}
        </div>

        <div class="w-full mt-4">
          <a-form ref="sensorForm2" :model="sensorForm" name="horizontal_login" layout="inline" autocomplete="off"
            class="w-full" :label-col="{ style: { width: '150px' } }">
            <a-row class="w-full" :gutter="24">
              <a-col :span="8">
                <a-form-item :label="langObj['传感器SN']" name="sensorSn" required>
                  <a-input v-model:value="sensorForm.sensorSn" :placeholder="langObj['请输入传感器SN']"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item :label="langObj['安装位置']" name="sensorPlace" required>
                  <a-input v-model:value="sensorForm.sensorPlace" :placeholder="langObj['请输入安装位置']"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item :label="langObj['所属设备']" name="machineName" required>
                  <div class="flex items-center">
                    <a-input v-model:value="sensorForm.machineName" :placeholder="langObj['请选择所属设备']"
                      readonly></a-input>
                    <div @click="selectMachineS" class="cursor-pointer btnTheme">
                      {{ langObj['选择'] }}
                    </div>
                  </div>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row class="w-full" :gutter="24">
              <a-col :span="8">
                <a-form-item :label="langObj['转频提取']" name="fcSensorPlace">
                  <div class="flex items-center">
                    <a-input v-model:value="sensorForm.fcSensorPlace" :placeholder="langObj['请选择转频提取测点']"
                      readonly></a-input>
                    <div @click="selectSensorS" class="cursor-pointer btnTheme">
                      {{ langObj['选择'] }}
                    </div>
                  </div>
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item :label="langObj['设备类型']" name="type">
                  <a-cascader style="max-width:236px" @change="changeThirdTypeId" v-model:value="sensorForm.type"
                    :options="machineTypeList" :placeholder="langObj['请选择设备类型']" />
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item :label="langObj['功率']" name="national">
                  <a-select style="width: 244px" v-model:value="sensorForm.national" :placeholder="langObj['请选择功率']">
                    <a-select-option v-for="(item, index) in powerEtcList" :key="index" :value="item.powerEtc">{{
                      item.powerEtc }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <!-- <a-col :span="8">
                <a-form-item label="主测点" name="onOff" required>
                  <a-switch v-model:checked="sensorForm.onOff" checked-children="开启" un-checked-children="关闭" />
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item label="指标间隔" name="quotaInterval" required>
                  <a-input-number style="width: 244px" id="inputNumber" v-model:value="sensorForm.quotaInterval" />
                </a-form-item>
              </a-col> -->

              <a-col :span="8">
                <a-form-item :label="langObj['是否启用']" name="disable">
                  <a-switch v-model:checked="sensorForm.disable" :checked-children="langObj['禁用']"
                    :un-checked-children="langObj['启用']" />
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item :label="getLanguage('智能诊断巡检配置', language)" name="inspectionSubId">
                  <!-- <a-switch v-model:checked="sensorForm.inspectionSubId" :checked-children="langObj['禁用']" :un-checked-children="langObj['启用']" /> -->
                  <a-cascader @change="changeISub" v-model:value="sensorForm.inspectionSubs" :options="options2"
                    placeholder="" />
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item :label="getLanguage('转速范围', language)" name="defaultSpeed">
                  <a-select style="width: 244px" v-model:value="sensorForm.defaultSpeed" :placeholder="langObj['请选择']">
                    <a-select-option v-for="(item, index) in speedList" :key="index" :value="item._id">{{
                      item.speedRange
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :span="24">
                <a-form-item :label="langObj['数据类型']">
                  <a-select v-model:value="sensorForm.dataType" mode="multiple" :placeholder="langObj['请选择数据类型']"
                    :options="dataTypeList"></a-select>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row class="w-full" :gutter="24">
              <a-col :span="24">
                <a-form-item :label="langObj['备注']" name="remark" class="w-full">
                  <a-textarea v-model:value="sensorForm.remark" show-count :maxlength="200"
                    :placeholder="langObj['请输入备注']" :rows="4" />
                </a-form-item>
              </a-col>
            </a-row>


            <a-row class="w-full" :gutter="24">
              <a-col :span="24">
                <a-form-item :label="langObj['三轴参数']" name="sn" class="w-full">
                  <div class="w-full" style="border: 1px solid #0d53b7cc">
                    <a-table style="width: 100%" size="small" :pagination="false" :locale="{ emptyText: ' ' }"
                      :columns="sensorAddColumns" :data-source="sensorForm.sensorGroup">
                      <template #bodyCell="{ column, text, index, record }">
                        <template v-if="column.dataIndex === 'act'">
                          <a @click="deleteSensorParmar(index)" class="theme-table-link">{{ langObj['删除'] }}</a>
                        </template>
                        <template v-if="column.dataIndex === 'sonId'">
                          <a-input v-model:value="record.sonId" :placeholder="langObj['请输入编号']"></a-input>
                        </template>
                        <template v-if="column.dataIndex === 'coordinate'">
                          <a-input v-model:value="record.coordinate" :placeholder="langObj['请输入安装坐标']"></a-input>
                        </template>
                        <template v-if="column.dataIndex === 'sensorGroupName'">
                          <a-input v-model:value="langObj[record.sensorGroupName]"
                            :placeholder="langObj['请输入轴名称']"></a-input>
                        </template>
                      </template>
                    </a-table>

                    <div @click="addSensorParmar" class="mx-4 my-2 cursor-pointer btnTheme" style="width: 48px;">
                      {{ langObj['添加'] }}
                    </div>
                  </div>
                </a-form-item>
              </a-col>
            </a-row>

            <a-col :span="24">
              <a-form-item :label="langObj['测点照片']">
                <a-upload v-model:file-list="machineFileList" list-type="picture-card" @preview="handlePreview"
                  :custom-request="customRequest" :auto-upload="false" accept="image" :max-count="6">
                  <div v-if="!machineFileList || machineFileList.length < 6">
                    <plus-outlined />
                    <div style="margin-top: 8px; color: #fff">{{ langObj['上传'] }}</div>
                  </div>
                </a-upload>
              </a-form-item>
            </a-col>
          </a-form>

          <div class="w-full flex justify-center mt-4">
            <div @click="submitSensor" class="btn-default py-1 px-6 cursor-pointer btn-submit">
              {{ langObj['提交'] }}
            </div>
          </div>
        </div>

        <SelectMachineComponent @selectMachineSen="selectMachineSen" :visible="selectMachineVisible"
          v-if="selectMachineVisible"></SelectMachineComponent>
        <SelectSensorComponent @selectSensorSen="selectSensorSen" :visible="selectSensorVisible"
          v-if="selectSensorVisible">
        </SelectSensorComponent>
      </div>
    </a-modal>


















    <a-modal :visible="previewVisible2" title="图片" :footer="null" @cancel="handleCancel2" :zIndex="1001">
      <img alt="example" style="width: 100%" :src="previewImage2" />
    </a-modal>


  </div>
</template>

<script lang="ts" setup>
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
  createVNode
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { langList } from "../../common/lang";
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import {
  getFactoryName,
  getGroupName,
  transformDate2,
} from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import machineListLeftComponent from "../components/machine-list-left.vue";
import pictureListComponent from "../components/picture-list.vue";
import DiagnosticReportDetails from "../report4/common/DiagnosticReportDetails2.vue";
import { Modal } from 'ant-design-vue';
import {
  getGroupListByFactory,
  getRealFactoryIdList,
} from "../../common/tools";
import DownLoadComponent from "../components/downLoad.vue";
import SelectMachineComponent from "./select-machine.vue";
import SelectSensorComponent from "./select-sensor.vue";
import { Dayjs } from 'dayjs';
import { getLanguage } from "../../common/translate";
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}
getLang()

// #region 总貌图弹框
const handlePreview = async (file: any) => {
  previewImage.value = file.url;
  previewVisible.value = true;
  previewTitle.value = file.url;
};
function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

let customRequest = async (options: any) => {
  const { onSuccess, onError, file } = options;
  let formData = new FormData();
  formData.append("file", file); // file为要上传的文件
  const config = {
    headers: {
      requestId: uuidv4(),
      "Content-Type": "multipart/form-data",
    },
  };
  let res = await request.post("/api/upload", formData, config);
  if (res) {
    file.url = res.data;
    onSuccess({ url: file.url, status: "done" });
  }
};
const visibleReEdit = ref(true);
let customRequest3D = async (options: any) => {
  const { onSuccess, onError, file } = options;
  let formData = new FormData();
  formData.append("file", file); // file为要上传的文件
  const config = {
    headers: {
      requestId: uuidv4(),
      "Content-Type": "multipart/form-data",
    },
  };
  let res = await request.post("/api/upload", formData, config);
  if (res) {
    file.url = res.data;
    onSuccess({ url: file.url, status: "done" });
    visibleReEdit.value = false;
    // config_3d.value.picture = res.data.url;
    config_3d.value = {
      id: config_3d.value?.id,
      picture: res.data,
      marks: [],
    };
    markList.value = [];
    setTimeout(() => {
      visibleReEdit.value = true;
      setTimeout(() => {
        $("#container").ZoomMark({
          markColor: "red",
          afterMark: addRowToTabel,
          showMarkNumber: false,
          markList: [],
        });
      }, 800);
    }, 200);
  }
};

let visibleEdit = ref(false);

let markList = ref([]);

let config_3d: any = ref({
  _id: "",
  marks: [],
});

let line = ref([]);

const load3D = async (type: any) => {
  config_3d.value = { marks: [] };
  let config: any = {
    params: {
      machineId: machine_id.value,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machine-3D", config);

  if (result) {
    if (
      (!result.data || !result.data.picture) &&
      machineInfo.value.machineTypeId
    ) {
      config = {
        params: {
          _id: machineInfo.value.machineTypeId,
        },
        headers: {
          requestId: uuidv4(),
        },
      };
      // 查询3d模型库
      let res = await request.get("/api/machine-type", config);
      if (res.data && res.data.picture) {
        result.data = {
          picture: res.data.picture,
          marks: [],
        };
      }
    }

    config_3d.value = result.data || {
      marks: [],
    };
    markList.value = config_3d.value.marks;
  }

  if (type == 2) {
    setTimeout(() => {
      $("#container").ZoomMark({
        markColor: "red",
        afterMark: addRowToTabel,
        showMarkNumber: false,
        markList: [],
      });
      setTimeout(() => {
        if (config_3d.value.img) {
          $("#container").ZoomMark("config", config_3d.value.img);
        }

        setTimeout(() => {
          if (config_3d.value.marks.length) {
            $("#container").ZoomMark("addMarks", config_3d.value.marks);
          }
        }, 100);
      }, 100);

      $("#reset").click(function () {
        $("#container").ZoomMark("reset");
      });
      $("#rotate").click(function () {
        angle = angle + 90 >= 360 ? 0 : angle + 90;

        $("#container").ZoomMark("rotate", angle);
      });
    }, 100);
  } else {
    line.value = [];
    for (var i = 0; i < config_3d.value.marks.length; i++) {
      var item = config_3d.value.marks[i];
      if (item.bind.indexOf("a-") > -1) {
        var find = config_3d.value.marks.find(
          (t) =>
            t.bind.substring(2, t.bind.length) ==
            item.bind.substring(2, item.bind.length) &&
            t.bind.indexOf("b-") > -1
        );
        if (find) {
          var distance = Math.sqrt(
            Math.pow(find.x - item.x, 2) + Math.pow(find.y - item.y, 2)
          );
          var angle_d =
            Math.atan2(find.y - item.y, find.x - item.x) * (180 / Math.PI);
          line.value.push({
            ...item,
            distance: distance,
            angle: angle_d,
          });
        }
      }
    }
  }

  get3DData();
};

// 获取3D图测点数据
const get3DData = async () => {
  let config: any = {
    params: {
      id: machine_id.value,
    },

    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get(
    "/api/external/machines/workstatus/info",
    config
  );
  if (result && result.data) {
    config_3d.value.marks.map((d: any) => {
      if (d.bind.includes("b-")) {
        let list = d.bind.split("-");
        if (list && list.length === 2) {
          let sensorId = list[1];
          let sensorInfo = result.data.sensors.find(
            (p: any) => p.id === sensorId
          );
          if (sensorInfo) {
            d.sensorPlace = sensorInfo.sensorPlace;
          } else {
            d.sensorPlace = "";
          }

          let info = result.data.quotas.find(
            (p: any) => p.sensorId === sensorId
          );
          if (info) {
            if (info.values.length) {
              let sz = info.values.find((p: any) => p.type === "速度Z");
              d.sz = sz ? sz.value.toFixed(2) : "0";
              let hz = info.values.find((p: any) => p.type === "高频加速度");
              d.hz = hz ? hz.value.toFixed(2) : "0";
              let temp = info.values.find((p: any) => p.type === "温度");
              d.temp = temp ? temp.value.toFixed(2) : "0";
            }
            d.date = info.date
          }
          let stableInfo = result.data.stableSensors && result.data.stableSensors.find(
            (p: any) => p.id === sensorId
          );
          if (stableInfo) {
            delete stableInfo.id;
            let list = Object.values(stableInfo).sort();
            let score: any = 100;
            if (list && list.length) {
              score = list[0];
            }

            if (score >= 80 && score <= 100) {
              d.condition = 0;
            }
            if (score >= 60 && score < 80) {
              d.condition = 1;
            }
            if (score >= 30 && score < 60) {
              d.condition = 3;
            }
            if (score >= 0 && score < 30) {
              d.condition = 6;
            }
          }
        }
      }
    });
  }
};

let conditionList = [
  { label: "健康", value: [0], color: "#61c08f", color2: "#0D53B7", valueR: 1 },
  {
    label: "可用",
    value: [1, 2],
    color: "#d2de49",
    color2: "#d2de49",
    valueR: 2,
  },
  {
    label: "警戒",
    value: [3, 4, 5],
    color: "#cd5f3b",
    color2: "#cd5f3b",
    valueR: 3,
  },
  {
    label: "故障",
    value: [6, 7],
    color: "#921e37",
    color2: "#921e37",
    valueR: 4,
  },
];

const getColor = (ev: any) => {
  let a = conditionList.find((p: any) => p.value.includes(ev));
  return a ? a.color2 : "#072499";
};

const getColor2 = (ev: any) => {
  let a: any = conditionList.find((p: any) => p.value.includes(ev));
  return a?.color;
};
const getShadow = (ev: any) => {
  // box-shadow: inset 0 0 34px 0 #00249b;
  let a = conditionList.find((p: any) => p.value.includes(ev));
  let c = a ? a.color2 : "#072499";

  return "inset 0 0 34px 0 " + c;
};

let options = ref([]);

const save3D = async () => {
  var setting = $("#container").ZoomMark("getSetting");
  // if (markList.value.length == 0) {
  //   message.warning("请选择3D标点");
  //   return;
  // }
  if (markList.value.filter((t) => !t.bind).length > 1) {
    message.warning("请设置每个标点内容");
    return;
  }

  markList.value.forEach((t, index) => {
    var find = setting.marks.find((t2) => t2.id == t.id);
    t.x = find.x;
    t.y = find.y;
  });
  var postData = {
    id: config_3d.value?.id,
    machineId: machine_id.value,
    picture: config_3d.value?.picture,
    marks: markList.value,
    img: setting.img,
  };
  // 保存
  if (postData.id) {
    var result = await request.put("/api/machine-3D", postData);
    if (result && result.status == 200) {
      message.success("保存成功");
      visibleEdit.value = false;
      load3D(1);
    }
  } else {
    var result = await request.post("/api/machine-3D", postData);
    if (result && result.status == 200) {
      message.success("保存成功");
      visibleEdit.value = false;
      config_3d.value._id = result.data;
      load3D(1);
    }
  }
};

const markShow = () => {
  if (!machineInfo.value.machineTypeId) {
    message.warning("请选择设备类型后编辑");
    return;
  }

  visibleEdit.value = true;
  load3D(2);
};

// $('#container').ZoomMark('getSetting');
const deleteTag = (item: any, index: any) => {
  markList.value.splice(index, 1);
  $("#container").ZoomMark("deleteMark", index);
  markList.value.map((d: any, i: any) => {
    d.id = i + 1
  })
  var setting = $("#container").ZoomMark("getSetting");

};

var angle = 0;

function addRowToTabel(marks) {
  for (var i = 0; i < marks.length; i++) {
    if (!markList.value.filter((t) => t.id == marks[i].id).length) {
      markList.value.push({ ...marks[i], bind: "" });
    }
  }
  // $('#marksTable').find('tbody').append('<tr id="row_'+marks[marks.length-1].id+'"><td><span class="colorSpan"style="background-color:'+marks[marks.length-1].color+'">'+($('#marksTable').find('tbody').find('tr').length+1)+'</span></td><td >'+marks[marks.length-1].color+'</td><td><textarea name="a" style="width:200px;height:60px;"></textarea></td><td><img src="example/assets/delete.png" onclick="deleteMark('+marks[marks.length-1].id+')"></td></tr>');
}

function deleteMark(id) {
  $("#container").ZoomMark("deleteMark", id);

  $("#row_" + id).remove();
  updateTableId();
}

function updateTableId() {
  $("#marksTable")
    .find("tbody")
    .find(".colorSpan")
    .each(function (index, value) {
      $(this).html(index + 1);
    });
}
//新用户引导新建设备按钮回调
let editRindex:any = ref(1000);


// #endregion

let value = ref("");
let menuSelect = ref(1);
let machine_id = ref("");
let machineInfo: any = ref({});

const sensorColumns = [
  {
    title: langObj.value["传感器SN"],
    dataIndex: "sensorSn",
  },
  {
    title: langObj.value["测点位置"],
    dataIndex: "sensorPlace",
  },
  {
    title: langObj.value["设备区域"],
    dataIndex: "groupId",
  },
  {
    title: langObj.value["所属设备"],
    dataIndex: "machineName",
  },
  {
    title: langObj.value["测点ID"],
    dataIndex: "id",
  },
  {
    title: langObj.value["转频提取测点"],
    dataIndex: "fcSensorPlace",
  },
  {
    title: langObj.value["轴参数"],
    dataIndex: "parameter",
  },
  // {
  //   title: langObj.value["开机指标"],
  //   dataIndex: "threshold",
  // },
  {
    title: langObj.value["传感器电量"],
    dataIndex: "hardware",
  },
  {
    title: getLanguage('智能采集有效期', language.value),
    dataIndex: "validity",
  },
  {
    title: getLanguage('智能诊断巡检配置', language.value),
    dataIndex: "inspectionSubs",
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];
let warnTypeList = [
  { label: "智能诊断", value: 1 },
  { label: "门限报警", value: 4 },
];
let warningColumns = [
  {
    title: langObj.value["报警时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["报警类型"],
    dataIndex: "type",
  },
  {
    title: langObj.value["严重程度"],
    dataIndex: "condition",
  },
  {
    title: langObj.value["测点"],
    dataIndex: "sensorPlace",
  },
  {
    title: langObj.value["报警内容"],
    dataIndex: "diagnosticNotes",
  },
  {
    title: langObj.value["联系人"],
    dataIndex: "contactName",
  },
  {
    title: langObj.value["处理状态"],
    dataIndex: "status",
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];
let handleStatusList = [
  { label: "未处理 ", value: 1 },
  { label: "已处理 ", value: 2 },
  { label: "已关闭 ", value: 3 },
];
let warnSearchModel: any = ref({
  machineId: "",
  date: null,
  type: null,
  condition: null,
  skip: 1,
  take: 20,
  total: 0,
});

const warmPagination = computed(() => ({
  total: warnSearchModel.value.total,
  current: warnSearchModel.value.skip,
  pageSize: warnSearchModel.value.take,
}));

let data: any = ref([]);
let warningList: any = ref([]);
let searchModel: any = ref({});

let selectedRowKeys: any = ref();

// let conditionList = [
//   { label: "健康", value: [0], color: "#61c08f" },
//   { label: "可用", value: [1, 2], color: "#d2de49" },
//   { label: "警戒", value: [3, 4, 5], color: "#cd5f3b" },
//   { label: "故障", value: [6, 7], color: "#921e37" },
// ];

let statusList = [
  { label: "停机", value: 0, color: "#ADADAD" },
  { label: "运行", value: 1, color: "#4EFAEE" },
];
let picList: any = ref([]);
let picListAll: any = ref([]);
let isEditable: any = ref(false);

let options2: any = ref([])
let categoryAll: any = ref([])
onMounted(() => {
  isEditable.value = true
  getMachineAll()
  getSearch3()
  // getBearingParameter()
})

const getSearch3 = async () => {
  let config: any = {
    params: {

    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get('/api/external/inspection/threshold', config)
  if (result?.data) {
    categoryAll.value = JSON.parse(JSON.stringify(result.data))
  }

  options.value = []
  let first = categoryAll.value.map((p: any) => p.firstCategory)
  first = [... new Set(first)]
  let list: any = []
  first.map((f: any) => {
    let firstList: any = {
      label: f,
      value: f,
      children: []
    }
    let second = categoryAll.value.filter((p: any) => p.firstCategory === f).map((p: any) => p.secondCategory)
    second = [... new Set(second)]
    second.map((s: any) => {
      let secondList: any = {
        label: s,
        value: s,
        children: []
      }
      let third = categoryAll.value.filter((p: any) => p.firstCategory === f && p.secondCategory === s).map((p: any) => p.thirdCategory)
      third = [... new Set(third)]
      third.map((t: any) => {
        let thirdList: any = {
          label: t,
          value: t,
        }
        secondList.children.push(thirdList)
      })
      firstList.children.push(secondList)
    })

    list.push(firstList)
  })

  options2.value = list
}

// 获取左侧列表设备id
const selectMachine = (ev: any) => {
  machine_id.value = ev;
  init();
};

const init = async () => {
  getMachinrTypeAll();
  picList.value = [];
  search();
  load3D(1);
  loadDatatype();
  getMachineType();
};

const getMachinrTypeAll = async () => {
  let result = await request("/api/sensors/machine-types");
  if (result && result.data) {
    machineTypeListAll.value = result.data;
  }

  getMachineInfo();
};

const getMachineInfo = async () => {
  let config: any = {
    params: {
      machineId: machine_id.value,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machines/info", config);

  if (result) {
    machineInfo.value = result.data;
    if (machineInfo.value?.machineTypeId) {
      let info: any = machineTypeListAll.value.find(
        (p: any) => p.id === machineInfo.value.machineTypeId
      );
      if (info) {
        machineInfo.value.typeName = `${info.FirstCategory}/${info.SecondCategory}/${info.ThirdCategory}`;
      } else {
        machineInfo.value.typeName = "";
      }
    }

    if (
      machineInfo.value &&
      machineInfo.value.workStatus &&
      machineInfo.value.workStatus.stableCondition
    ) {
      machineInfo.value.condition = conditionList.find((p: any) =>
        p.value.includes(machineInfo.value.workStatus.stableCondition)
      );
    } else {
      machineInfo.value.condition = conditionList[0];
    }

    if (
      machineInfo.value &&
      machineInfo.value.workStatus &&
      machineInfo.value.workStatus.scoreWkStatus
    ) {
      machineInfo.value.status = statusList.find(
        (p: any) => p.value === machineInfo.value.workStatus.scoreWkStatus
      );
    } else {
      machineInfo.value.status = statusList[0];
    }
  }

  if (machineInfo.value.pictures?.length > 0) {
    picList.value = machineInfo.value.pictures;
  }
  getSensorList();
};

const getSensorList = async () => {
  options.value = []
  let config: any = {
    params: {
      machineId: machine_id.value,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/sensors", config);

  if (result && result.status === 200) {
    result.data.map((d: any) => {
      if (d.pictures?.length > 0) {
        picList.value = picList.value.concat(d.pictures);
      }
    });

    data.value = result.data;
    // 总貌图选项
    for (var i = 0; i < data.value.length; i++) {
      options.value.push({
        label: data.value[i].sensorPlace + "-标点",
        value: "a-" + data.value[i].id,
      });
      options.value.push({
        label: data.value[i].sensorPlace + "-标签",
        value: "b-" + data.value[i].id,
      });
    }
  }

  picListAll.value = picList.value;
};

// 报警

const changeWarnTable = (pagination: any) => {
  warnSearchModel.value.skip = pagination.current;
  warnSearchModel.value.take = pagination.pageSize;
  getWarningList();
};

const search = () => {
  warnSearchModel.value.skip = 1;
  getWarningList();
};

const reset = () => {
  warnSearchModel.value = {
    ...warnSearchModel.value,
    date: null,
    type: null,
    condition: null,
  };
};

const getWarningList = async () => {
  let config: any = {
    params: {
      skip: warnSearchModel.value.skip,
      take: warnSearchModel.value.take,
      machineId: machine_id.value,
      typeList: [1, 4],
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  if (warnSearchModel.value.type) {
    delete config.params.typeList;
    config.params.type = warnSearchModel.value.type;
  }

  if (warnSearchModel.value.date && warnSearchModel.value.date.length === 2) {
    config.params.begin = transformDate2(
      new Date(warnSearchModel.value.date[0]),
      1
    );
    config.params.end = transformDate2(
      new Date(warnSearchModel.value.date[1]),
      2
    );
  }

  if (warnSearchModel.value.condition) {
    config.params.conditionList = conditionList.find(
      (p: any) => p.valueR === warnSearchModel.value.condition
    )?.value;
  }

  let result = await request.get("/api/workflows", config);
  if (result) {
    warningList.value = result.data.list;
    warnSearchModel.value.total = result.data.total;
  }
};

// 关闭报警
let formClose: any = ref({
  closeCause: "",
});
let visibledel = ref(false);

const onSelectChange = (ev: any) => {
  selectedRowKeys.value = ev;
};

// 关闭警报
const closeWarning = () => {
  if (!selectedRowKeys.value) {
    message.warning(langObj.value["请选择报警"]);
    return;
  }

  let list2 = warningList.value.filter((p: any) =>
    selectedRowKeys.value.includes(p.id)
  );

  for (let d of list2) {
    if (d.handleStatus && (d.handleStatus === 2 || d.handleStatus === 3)) {
      message.warning(langObj.value["请选择未处理的报警"]);
      return;
    }
  }
  formClose.value.closeCause = "";
  visibledel.value = true;
};

const submitClose = async () => {
  if (!formClose.value.closeCause) {
    message.warning("请填写关闭原因");
    return;
  }

  let form = {
    closeCause: formClose.value.closeCause,
    list: [],
  };

  let list = warningList.value.filter((p: any) =>
    selectedRowKeys.value.includes(p.id)
  );

  if (list && list.length) {
    form.list = list.map((d: any) => ({
      date: d.date,
      condition: d.condition,
      diagnosticNotes: d.diagnosticNotes,
      id: d.id,
    }));
  }

  let result = await request.post("/api/workflows/close", form);
  if (result && result.status == 200) {
    message.success(langObj.value["关闭成功"] + "!");
    visibledel.value = false;
    getWarningList();
  } else {
    message.warning(langObj.value["操作失败"]);
  }
};

// 查看已关闭
let visibledel2 = ref(false);
const viewClose = (ev: any) => {
  formClose.value.closeCause = ev.closeCause;
  visibledel2.value = true;
};

let visibleReport = ref(false);
let reportId = ref("");
let mrDref = ref();
const viewReport = (ev: any) => {
  // console.log("ev", ev);
  // if(ev.type === 2 && ev.reportIds.length) {
  //     getRepairInfo(ev.reportIds[0])
  //     visibleRepair.value = true
  // }

  visibleReport.value = true;
  reportId.value = ev.reportIds[0];
  setTimeout(() => {
    mrDref.value.show(ev.reportIds[0]);
  }, 500);
};
const cancel = (ev: any) => {
  if (ev) {
    visibleReport.value = false;
    reportId.value = "";
  }
};

// 选择菜单
const selectMenu = (ev: any) => {
  menuSelect.value = ev;
};

//编辑设备
let machineFileList: any = ref([]);
let machineForm: any = ref({
  machineCode: null,
  groupId: null,
  type: null,
  machineName: "",
  position: null,
  supplier: null,
  model: null,
  machineType: null,
  machineTypeId: null,
  sn: null,
  sort: null,
  experiment: false,
  remark: null,
  picture: "",
  factoryId: "",
  parentFactoryId: "",
  params: {},
  bitNumber: null,
  machinePower: null,
  mode: [],
});
//新建设备

let machineTypeListAll: any = ref([]);
let visibleAddMachine = ref(false);
let macForm = ref();

let machineTypeList = ref([]);
let groupList: any = ref([]);

let whetherList = [
  { label: "是", value: true },
  { label: "否", value: false },
];


let machine_select_id: any = ref('')
let visibleAddMachineNew = ref(false);
let addMachineForm: any = ref({})
let pic3Ds: any = ref([])
let sensorTreeData: any = ref([]);
let selectSensorList: any = ref([]);
let structureList: any = ref([]) //结构列表
let isShowNext = computed(() => {
  return machineForm.value.mode.includes(1)
})
let addLoading: any = ref(false);



const getStructureList = async () => {
  let config: any = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machine-structures/all", config);
  if (result?.data) {
    structureList.value = result.data;
  }
}
getStructureList()
// 编辑的下一步
let parts_old: any = ref(null)
const addMachineNewNewtEdit = () => {

macForm.value?.clearValidate();
macForm.value
  .validate()
  .then(async (res: any) => {
    let form = JSON.parse(JSON.stringify(machineForm.value));
    if (machineFileList.value && machineFileList.value.length) {
      form.pictures = machineFileList.value.map((p: any) => ({
        url: p.url,
        type: 1,
      }));
    }

    if (form.type && form.type.length === 3) {
      let info = machineTypeListAll.value.find(
        (p: any) =>
          p.FirstCategory === form.type[0] &&
          p.SecondCategory === form.type[1] &&
          p.ThirdCategory === form.type[2]
      );
      form.machineTypeId = info.id;
    } else {
      form.machineTypeId = null;
    }
    delete form.type;

    let result = form.id
      ? await request.put("/api/machines/info", form)
      : await request.post("/api/machines", form);
    if (result && result.status === 200) {
      init()

      parts_old.value = null
      let { id, type } = machineForm.value
      machine_select_id.value = id
      let machineTypeDetails = JSON.parse(JSON.stringify(machineForm.value.machineTypeDetails) || 'null')
      if (machineTypeDetails) {
        addMachineForm.value = {
          type: null,
          machineType: null,
          machineTypeId: null,
          machineTypeDetails: {
            isFrequentStart: machineTypeDetails.isFrequentStart,
            pictureUrl: machineTypeDetails.pictureUrl,
            sensorList: [],
            parts: []
          }
        }

        if (type?.length === 3) {
          addMachineForm.value.type = type
          selectMT(type)
        }
        if (machineTypeDetails.sensorList?.length) {
          selectSensorList.value = machineTypeDetails.sensorList.map((p: any) => p.partUniqueId + '-' + p.sensorPlace)
          parts_old.value = machineTypeDetails.parts
          selectSensor()
        }

      } else {
        addMachineForm.value = {
          type: null,
          machineType: null,
          machineTypeId: null,
          machineTypeDetails: {
            isFrequentStart: false,
            pictureUrl: null,
            sensorList: [],
            parts: []
          }
        }
      }

      visibleAddMachineNew.value = true


    } else {
      message.warning("操作失败,请联系管理员!");
    }
  })
  .catch((err: any) => {
    console.log(err); //失败后执行
  });

}
// 选择设备类型
const selectMT = async (ev: any) => {
  if (ev?.length === 3) {
    sensorTreeData.value = []
    let machineTypeInfo = machineTypeListAll.value.find((p: any) => p.FirstCategory === ev[0] && p.SecondCategory === ev[1] && p.ThirdCategory === ev[2])
    if (machineTypeInfo) {
      addMachineForm.value.machineType = machineTypeInfo.ThirdCategoryAbbreviation
      addMachineForm.value.machineTypeId = machineTypeInfo.id
      pic3Ds.value = machineTypeInfo.pictures || []
      // 获取测点列表
      let sensorList1 = machineTypeInfo?.sensorTemplate?.sensorList || []
      sensorList1.map((d: any) => {
        let first: any = {
          label: d.name || machineTypeInfo.ThirdCategory,
          value: d.uniqueId,
          selectable: false,
          children: []
        }
        if (d.sensors?.length > 0) {
          d.sensors.map((s: any) => {
            let second = {
              label: s.sensorPlace,
              value: d.uniqueId + '-' + s.sensorPlace,
              selectable: true
            }
            first.children.push(second)
          })
        }
        sensorTreeData.value.push(first)
      })
    }
  }
}
// 选择测点
const selectSensor = async() => {
  if(!addMachineForm.value.machineTypeId) return message.warning('请先选择设备类型！')
  if(!selectSensorList.value?.length) return message.warning('请先选择测点！')

  let sensorList_select = selectSensorList.value.map((d:any) => {
    return {
      partUniqueId: d.split('-')[0],
      sensorPlace: d.split('-')[1]
    }
  })
  addMachineForm.value.machineTypeDetails.sensorList = sensorList_select

  let uniqueIds = [... new Set(sensorList_select.map((d:any) => d.partUniqueId))]


  let machineTypeInfo = machineTypeListAll.value.find((p:any) => p.id === addMachineForm.value.machineTypeId)
  let sensorList_template = machineTypeInfo?.sensorTemplate?.sensorList || []

  let parts:any = []
  

  for (let i in uniqueIds) {
    let d = uniqueIds[i]
    let structures:any = []
    let partInfo = sensorList_template.find((p:any) => p.uniqueId === d)
    if(partInfo?.sensors?.length > 0) {
      // 获取细分列表
      let config: any = {
        params: {
            machineTypeId: machineTypeInfo.id,
            partUniqueId: machineTypeInfo.isPart ? '' : d,
            take: 999
        },
        headers: {
            requestId: uuidv4(),
        },
      };
      let result = await request.get('/api/machine-type/segments/list', config)
      let resultList = result?.data?.list || []
      let tableTitles:any = []
      resultList.map((r:any) => {
        tableTitles = tableTitles.concat(Object.keys(r?.columns || {})) 
      })
      let firstColumnsData:any = []
      if(tableTitles?.length > 0) {
        resultList.map((t:any) => {
          firstColumnsData.push(t?.columns[tableTitles[0]]) 
        })
      }
      let segments = {
        columns: [... new Set(tableTitles)],
        dataList: resultList,
        columnsData0: [... new Set(firstColumnsData)],
      }

      let sensors_part = partInfo.sensors.filter((p:any) => sensorList_select.find((s:any) => s.sensorPlace === p.sensorPlace))

      sensors_part.map((s:any) => {
        // structureList.value
        s.machineStructure.map((m:any) => {
          let structureInfo = structureList.value.find((p:any) => p.id === m)
          // 轴承齿轮需要绑定测点
          if(m === "672c55e3e19a0d0e17e653b7" || m === "672c55e3e19a0d0e17e653b9" || m === "672c55e3e19a0d0e17e653ba") {
            structures.push({
              sensorPlace:  s.sensorPlace,
              machineStructureId: m,
              machineStructureName: structureInfo?.name,
              parmarList: getStructureParmar(m)
            })
          } else {
            if(!structures.find((p:any) => p.machineStructureId === m)) {
              structures.push({
                sensorPlace: '',
                machineStructureId: m,
                machineStructureName: structureInfo?.name,
                parmarList: getStructureParmar(m)
              })
            }
          }


        })
      })

      parts.push({
        partUniqueId: d,
        partName: partInfo.name,
        machineTypeSegmentId: null,
        params: {
          Rated_speed: null,
          PowerFre: null,
          startFre: null,
          stopFre: null,
        },
        structures,
        segments,
      })

      parts.map((part:any) => {
        let part_old = parts_old.value?.find((p:any) => p.partUniqueId === part.partUniqueId) || null
        if(part_old) {
          part.params = part_old.params
          part.disable = true
                                                           
          part.structures.map((structure:any) => {
            let structure_old = part_old.structures.find((p:any) => p.sensorPlace === structure.sensorPlace && p.machineStructureId === structure.machineStructureId)
            structure.parmarList && structure.parmarList.map((parmar:any) => {
              if( structure_old?.params && structure_old?.params[parmar.key] ) {
                parmar.value = structure_old?.params[parmar.key] 
              }
            })
          })

        }
      })


    }
  }

  addMachineForm.value.machineTypeDetails.parts = parts

}
// 获取轴承型号
let bearingParameters:any = ref([])
const getBearingParameter = async() => {
  let config: any = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get('/api/bearing-parameters/all', config)
  if (result?.data?.length) {
    bearingParameters.value = result.data.map((d:any) => ({
      label: d.bearingModel,
      value: d.bearingModel,
    }))
  }
}

// 获取不同结构的参数
const getStructureParmar = (id:any) => {
  let list = [
    {
      id: "672c55e3e19a0d0e17e653b6",
      name: '叶轮',
      parmar: [
        {label: '叶轮级数', value: 1, key: 'Impeller_series'},
        {label: '叶轮数目', value: null, key: 'BladeNum'},
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653bc",
      name: '电机',
      parmar: [
        {label: '额定转速', value: null, key: 'Rated_speed'},
        {label: '额定电源频率', value: 50, key: 'PowerFre'},
        {label: '电机极对数', value: null, key: 'p'},
        {label: '转子条数', value: null, key: 'rot_n'},
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653b7",
      name: '轴承',
      parmar: [
        {label: '轴承类型', value: null, key: 'type', type: 'select', option:[{label: '滚动轴承', value:1}, {label: '滑动轴承', value:2}]},
        // {label: '轴承参数id', value: null, key: 'bearingParameterId', type: 'string'},
        {label: '轴承型号', value: null, key: 'bearingModel', type: 'select', option:bearingParameters.value},
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653b9",
      name: '行星齿轮',
      parmar: [
        {label: '太阳轮齿数', value: null, key: 'Zs', },
        {label: '一倍啮合频率', value: null, key: 'MeshFre'},
        {label: '行星轮个数', value: null, key: 'PLanetary'},
        {label: '齿圈齿数', value: null, key: 'RingTeeth'},
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653ba",
      name: '定轴齿轮',
      parmar: [
        {label: '主动齿轮齿数', value: null, key: 'GeaTeethNum', },
        {label: '从动齿轮齿数', value: null, key: 'GeaOutTeethNum'},
        {label: '提取啮合频率谐波阶数', value: 5, key: 'mesh_n'},
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653b8",
      name: '柱塞',
      parmar: [
        {label: '柱塞数目', value: null, key: 'z', },
        {label: '柱塞泵滤波参数', value: 2000, key: 'wps'},
        {label: '柱塞泵滤波参数', value: 3000, key: 'wss'},
        {label: '柱塞泵转频上限', value: null, key: 'fr_Min'},
        {label: '柱塞泵转频下限', value: null, key: 'fr_Max'},
        {label: '轴柱塞泵个数', value: null, key: 'PlungerNum'},
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653bb",
      name: '螺杆',
      parmar: [
        {label: '螺杆阳转子齿数', value: null, key: 'ScrTeethNum', },
        {label: '螺杆阴转子齿数', value: null, key: 'ScrOutTeethNum'}
      ]
    },

  ]
  return list.find((p:any) => p.id === id)?.parmar || []
}


// 选择细分内容
const selectPartParmar = (segments:any, index:any) => {
  if(index+1 >= segments.columns.length) {
    let dataListC = JSON.parse(JSON.stringify(segments.dataList))
    for(let i in segments.columns) {
      let c = segments.columns[i]
      dataListC = dataListC.filter((p:any) => p.columns[c] === segments['data'+i])
    }
    if(dataListC?.length) {
      segments.id = dataListC[0].id
    } else {
      message.warning(getLanguage('暂无选择的细分， 请重新选择'))
    }
    return
  }
  let column = segments.columns[index+1]
  let columnsData:any = []
  let dataListS = segments.dataList.filter((p:any) => p.columns[segments.columns[index]])
  dataListS.map((t:any) => {
    columnsData.push(t?.columns[column]) 
  })
  segments['columnsData'+(index+1)] = columnsData
}


// 提交提资信息
let isSubmitV:any = ref(false)
const submitMachineNew = async() => {
  if(!selectSensorList.value?.length) return message.warning('请先选择测点！')
  let isSub = true
  let submit = JSON.parse(JSON.stringify(addMachineForm.value))
  submit.machineTypeDetails.parts.map((d:any) => {
    d.machineTypeSegmentId = d.segments.id
    delete d.segments
    d.structures.map((s:any) => {
      let parmarList = s.parmarList
      delete s.parmarList
      let parmar:any = {}
      parmarList.map((p:any) => {
        parmar[p.key] = p.value
        if(!p.value && p.value !== 0) {
          isSub = false
        }
      })
      s.params = parmar
    })
  }) 

  if(!isSub && !isSubmitV.value) {
    Modal.confirm({
      title: '',
      icon: createVNode(ExclamationCircleOutlined),
      content: createVNode('div', { style: 'color:#fff;' }, '有部件或结构信息未填写完整，对应的健康指标将不会显示，您确认要提交吗?'),
      onOk() {
        isSubmitV.value = true
        submitMachineNew()
      },
      onCancel() {
      },
      style: {color: '#fff'},
      zIndex: 1000000003
    });
    return
  }

  submit.id = machine_select_id.value

  let result = await request.post('/api/machines/machine-type-details', submit)
  if(result?.status == 200) {
    message.success('操作成功!')
    visibleAddMachineNew.value = false 
    visibleAddMachine.value = false
  } else {
    message.warning('操作失败,请联系管理员!')
  }

}

const editMachine = async () => {
  groupList.value = getGroupListByFactory();

  machineFileList.value = [];
  let dataValue = JSON.parse(JSON.stringify(machineInfo.value));
  if (!dataValue.params) {
    dataValue.params = {}
  }
  if (!dataValue.mode) {
    dataValue.mode = []
  }
  delete dataValue.machineTypeAll;
  machineForm.value = dataValue;
  if (dataValue.machineTypeId) {
    let info: any = machineTypeListAll.value.find(
      (p: any) => p.id === dataValue.machineTypeId
    );
    if (info) {
      machineForm.value.type = [
        info.FirstCategory,
        info.SecondCategory,
        info.ThirdCategory,
      ];
    } else {
      machineForm.value.type = null
    }

  }

  if (machineForm.value.pictures?.length > 0) {
    machineForm.value.pictures.map((p: any) => {
      p.type = "";
    });
    machineFileList.value = machineForm.value.pictures;
  }

  visibleAddMachine.value = true;
  setTimeout(() => {
    macForm.value.clearValidate();
  }, 200);
};

// 选择区域
const selectGroupM = (ev: any) => {
  machineForm.value.factoryId = groupList.value.find(
    (p: any) => p.id === ev
  )?.factoryId;
  machineForm.value.parentFactoryId = groupList.value.find(
    (p: any) => p.id === ev
  )?.parentId;
};

// 获取设备类型
const getMachineType = async () => {
  let result = await request("/api/sensors/machine-types");
  if (result && result.data) {
    machineTypeListAll.value = result.data;
    let firstCategoryList = result.data.map((d: any) => d.FirstCategory);
    firstCategoryList = [...new Set(firstCategoryList)];
    let list: any = [];
    firstCategoryList.map((d1: any) => {
      let first: any = {
        value: d1,
        label: d1,
        children: [],
      };
      let list1 = [
        ...new Set(
          result.data
            .filter((p: any) => p.FirstCategory === d1)
            .map((p: any) => p.SecondCategory)
        ),
      ];

      // console.log('list1', list1)
      list1.map((d2: any) => {
        let second: any = {
          value: d2,
          label: d2,
          children: [],
        };

        let list2 = result.data
          .filter((p: any) => p.SecondCategory === d2 && p.FirstCategory === d1)
          .map((p: any) => p.ThirdCategory);
        // console.log('list2', list2)

        list2.map((d3: any) => {
          let third = {
            value: d3,
            label: d3,
          };

          second.children.push(third);
        });

        first.children.push(second);
      });

      list.push(first);
    });
    machineTypeList.value = list;

  }
};

const submitMachine = () => {
  macForm.value?.clearValidate();
  macForm.value
    .validate()
    .then(async (res: any) => {
      let form = JSON.parse(JSON.stringify(machineForm.value));

      if (machineFileList.value && machineFileList.value.length) {
        form.pictures = machineFileList.value.map((p: any) => ({
          url: p.url,
          type: 1,
        }));
      }

      if (form.type && form.type.length === 3) {
        let info = machineTypeListAll.value.find(
          (p: any) =>
            p.FirstCategory === form.type[0] &&
            p.SecondCategory === form.type[1] &&
            p.ThirdCategory === form.type[2]
        );
        form.machineTypeId = info.id;
      } else {
        form.machineTypeId = null;
      }
      delete form.type;

      let result = form.id
        ? await request.put("/api/machines/info", form)
        : await request.post("/api/machines", form);
      if (result && result.status === 200) {
        message.success("操作成功");
        visibleAddMachine.value = false;
        init();
      } else {
        message.warning("操作失败,请联系管理员!");
      }
    })
    .catch((err: any) => {
      console.log(err); //失败后执行
    });
};

// 下载报警
let downLoadVisible = ref(false);
let downLoadList: any = ref([]);
const downLoad = async () => {
  let config: any = {
    params: {
      skip: 1,
      take: 100000,
      machineId: machine_id.value,
      typeList: [1, 4],
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  if (warnSearchModel.value.type) {
    delete config.params.typeList;
    config.params.type = warnSearchModel.value.type;
  }

  if (warnSearchModel.value.date && warnSearchModel.value.date.length === 2) {
    config.params.begin = transformDate2(
      new Date(warnSearchModel.value.date[0]),
      1
    );
    config.params.end = transformDate2(
      new Date(warnSearchModel.value.date[1]),
      2
    );
  }

  if (warnSearchModel.value.condition) {
    config.params.conditionList = conditionList.find(
      (p: any) => p.valueR === warnSearchModel.value.condition
    )?.value;
  }

  let result = await request.get("/api/workflows", config);
  let list: any = [];
  if (result) {
    list = result.data.list;
  }
  downLoadList.value = list;
  downLoadVisible.value = true;
};

// 新增编辑测点
let visibleAddSensor = ref(false);
let selectMachineVisible = ref(false);
let selectSensorVisible = ref(false);
let sensorForm: any = ref({
  sensorSn: null,
  sensorPlace: "",
  machineName: "",
  machineId: "",
  fcSensorId: "",
  fcSensorPlace: "",
  machineTypeId: "",
  national: "",
  remark: "",
  type: null,
  sensorGroup: [{ coordinate: "", sonId: "", sensorGroupName: "" }],
  disable: false,
  onOff: null,
  quotaInterval: null,
  inspectionSubId: null,
  inspectionSubs: null
});
let powerEtcList: any = ref([]);
let dataTypeList = ref<any>();
const sensorAddColumns = [
  {
    title: langObj.value["传感器编号"],
    dataIndex: "sonId",
  },
  {
    title: langObj.value["安装坐标"],
    dataIndex: "coordinate",
  },
  {
    title: langObj.value["轴名称"],
    dataIndex: "sensorGroupName",
  },
  {
    title: langObj.value["联系人"],
    dataIndex: "value",
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];
let sensorForm2: any = ref();

const loadDatatype = () => {
  const config = {
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/sensors/datatype", config).then((res: any) => {
    if (res) {
      dataTypeList.value = res.data.map((t: any) => ({
        value: t.name,
        label: t.name,
      }));
    }
  });
};

//新增
const addSensor = () => {
  sensorForm.value = {
    sensorSn: null,
    sensorPlace: "",
    machineName: "",
    machineId: "",
    fcSensorId: "",
    fcSensorPlace: "",
    machineTypeId: "",
    national: null,
    remark: "",
    dataType: [],
    type: null,
    sensorGroup: [{ coordinate: "", sonId: "", sensorGroupName: "" }],
    disable: false,
    onOff: null,
    quotaInterval: null,
    inspectionSubId: null,
    inspectionSubs: null
  };
  machineFileList.value = [];
  visibleAddSensor.value = true;
  setTimeout(() => {
    sensorForm2.value.clearValidate();
  }, 200);
};
let speedList: any = ref([])

// 选择巡检设备细分
const changeISub = (ev: any) => {
  if (ev?.length === 3) {
    speedList.value = categoryAll.value.filter((p: any) => p.firstCategory === ev[0] && p.secondCategory === ev[1] && p.thirdCategory === ev[2])
  } else {
    speedList.value = []
  }
}

// 编辑
const editSensor = async (ev: any) => {
  machineFileList.value = [];
  let evValue = JSON.parse(JSON.stringify(ev));
  sensorForm.value = evValue;
  if (ev.machineTypeId) {
    let info = machineTypeListAll.value.find(
      (p: any) => p.id === ev.machineTypeId
    );
    if (info) {
      sensorForm.value.type = [
        info.FirstCategory,
        info.SecondCategory,
        info.ThirdCategory,
      ];
    } else {
      sensorForm.value.type = null
    }

  }
  if (ev.machine) {
    sensorForm.value.machineName = ev.machine.machineName;
  }

  if (sensorForm.value.pictures?.length > 0) {
    sensorForm.value.pictures.map((p: any) => {
      p.type = "";
    });
    machineFileList.value = sensorForm.value.pictures;
  }

  if (sensorForm.value?.inspectionSubs?.length === 3) {
    speedList.value = categoryAll.value.filter((p: any) => p.firstCategory === sensorForm.value.inspectionSubs[0] && p.secondCategory === sensorForm.value.inspectionSubs[1] && p.thirdCategory === sensorForm.value.inspectionSubs[2])
  } else {
    speedList.value = []
  }

  visibleAddSensor.value = true;
  setTimeout(() => {
    sensorForm2.value.clearValidate();
  }, 200);
};

// 选择类型
const changeThirdTypeId = async (ev: any) => {
  if (ev && ev.length === 3) {
    let info = machineTypeListAll.value.find(
      (p: any) =>
        p.FirstCategory === ev[0] &&
        p.SecondCategory === ev[1] &&
        p.ThirdCategory === ev[2]
    );
    sensorForm.value.machineTypeId = info.id;

    let config = {
      params: { id: info.id },
      headers: {
        requestId: uuidv4(),
      },
    };

    let result = await request.get(
      "/api/sensors/machine-types/power-etc",
      config
    );
    if (!result?.data) {
      powerEtcList.value = [];
      sensorForm.value.national = null;
      return;
    }

    powerEtcList.value = result.data;
    if (result.data.length > 0) {
      sensorForm.value.national = result.data[0].powerEtc;
    } else {
      sensorForm.value.national = null;
    }
  }
};

const addSensorParmar = () => {
  sensorForm.value.sensorGroup.push({ coordinate: "" });
};

const deleteSensorParmar = (ev: any) => {
  sensorForm.value.sensorGroup.splice(ev, 1);
};

// 选择设备
const selectMachineS = () => {
  selectMachineVisible.value = true;
};

const selectMachineSen = (ev: any) => {
  selectMachineVisible.value = false;
  if (ev) {
    sensorForm.value.machineId = ev.id;
    sensorForm.value.machineName = ev.machineName;
  } else {
    sensorForm.value.machineId = "";
    sensorForm.value.machineName = "";
  }
};

//选择转频提取测点
const selectSensorS = () => {
  if (!sensorForm.value.machineId) {
    message.warning('请选择所属设备后选择转频提取测点')
    return
  }

  selectSensorVisible.value = true;
};

const selectSensorSen = (ev: any) => {
  selectSensorVisible.value = false;
  if (ev) {
    sensorForm.value.fcSensorId = ev.id;
    sensorForm.value.fcSensorPlace = ev.sensorPlace;
  } else {
    sensorForm.value.fcSensorId = "";
    sensorForm.value.fcSensorPlace = "";
  }
};

const submitSensor = () => {
  sensorForm2.value?.clearValidate();
  sensorForm2.value
    .validate()
    .then(async (res: any) => {
      let form = JSON.parse(JSON.stringify(sensorForm.value));
      if (form.inspectionSubs?.length !== 3) {
        form.inspectionSubs = []
      }
      if (form.type && form.type.length === 3) {
        let info = machineTypeListAll.value.find(
          (p: any) =>
            p.FirstCategory === form.type[0] &&
            p.SecondCategory === form.type[1] &&
            p.ThirdCategory === form.type[2]
        );
        form.machineTypeId = info.id;
      } else {
        form.machineTypeId = null;
      }
      delete form.type;

      if (machineFileList.value && machineFileList.value.length) {
        form.pictures = machineFileList.value.map((p: any) => ({
          url: p.url,
          type: 9,
        }));
      }

      let result: any = form.id
        ? await request.put("/api/sensors/info", form)
        : await request.post("/api/sensors", form);

      if (result && result.status) {
        message.success("操作成功");
        visibleAddSensor.value = false;
        getSensorList();
      } else {
        message.warning("操作失败");
      }
    })
    .catch((err: any) => {
      console.log(err); //失败后执行
    });
};

// 获取有效期
const getValidity = (ev: any) => {
  if (!ev) {
    return ''
  }

  let now = new Date().getTime()
  let end = new Date(ev).getTime()

  if (now >= end) {
    return '已过期'
  } else {
    let date = Math.round((end - now) / (1000 * 60 * 60 * 24))
    return date + '天'
  }
}

// 获取巡检设备细分
const getInspectionSubs = (ev: any) => {
  if (ev?.length !== 3) {
    return ''
  } else {
    return ev.join('/')
  }
}

const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return index % 2 === 0 ? "odd-row" : "even-row";
};

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 60;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 60;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};


// 查看图片
let previewImage2 = ref('')
let previewVisible2 = ref(false)
let previewTitle2 = ref('')

const handlePreview2 = async (file: any) => {
  previewImage2.value = file.url;
  previewVisible2.value = true;
  previewTitle2.value = "图片";
};

const handleCancel2 = () => {
  previewImage2.value = '';
  previewVisible2.value = false;
  previewTitle2.value = "";
}


// 可复制的设备3D图
let machineId_copy = ref('')
let machineList_copy = ref([])

// 获取该工厂所有设备
const getMachineAll = async () => {
  machineList_copy.value = []
  let config = {
    parmar: {
      factoryId: localStorage.getItem('factory_id')
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let factory_id = ''
  if (localStorage.getItem('factory_id')) {
    factory_id = localStorage.getItem('factory_id')
  } else {
    return
  }

  let result = await request.post("/api/machines/all", { factoryIds: [factory_id] })
  console.log('result', result)
  if (result?.data?.length > 0) {
    machineList_copy.value = result.data
  }

}

// 选择覆盖的设备
const selectMachineCopy = async () => {

  let config: any = {
    params: {
      machineId: machineId_copy.value,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let id = config_3d.value?.id
  config_3d.value = { marks: [] };
  let result = await request.get("/api/machine-3D", config);
  if (result?.data) {
    if (
      (!result.data || !result.data.picture) &&
      machineInfo.value.machineTypeId
    ) {
      config = {
        params: {
          _id: machineInfo.value.machineTypeId,
        },
        headers: {
          requestId: uuidv4(),
        },
      };
      // 查询3d模型库
      let res = await request.get("/api/machine-type", config);
      if (res.data && res.data.picture) {
        result.data = {
          picture: res.data.picture,
          marks: [],
        };
      }
    }
    result.data.id = id

    config_3d.value = result.data || {
      marks: [],
    };
    markList.value = config_3d.value.marks.map((d: any) => {
      return {
        id: d.id,
        x: d.x,
        y: d.y,
        color: d.color,
        available: d.available,
        bind: '',
      };
    })
  }

  setTimeout(() => {
    $("#container").ZoomMark({
      markColor: "red",
      afterMark: addRowToTabel,
      showMarkNumber: false,
      markList: [],
    });
    setTimeout(() => {
      if (config_3d.value.img) {
        $("#container").ZoomMark("config", config_3d.value.img);
      }

      setTimeout(() => {
        if (config_3d.value.marks.length) {
          $("#container").ZoomMark("addMarks", config_3d.value.marks);
        }
      }, 100);
    }, 100);

    $("#reset").click(function () {
      $("#container").ZoomMark("reset");
    });
    $("#rotate").click(function () {
      angle = angle + 90 >= 360 ? 0 : angle + 90;

      $("#container").ZoomMark("rotate", angle);
    });
  }, 100);


}


</script>

<style>
.gh3d .ant-upload-list {
  display: none !important;
}
</style>
<style lang="less" scoped>
.lipButton {
  margin-right: 20px;
}

.d3_line {
  position: absolute;
  border-top: 1px solid #b0b1b1;
  transform-origin: left top;
  height: 1px;
}

.d3_point {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  background-color: #b0b1b1;
}

.d3_bq {
  width: 150px;
  height: 80px;
  position: absolute;
  border: 2px solid #072499;
  background: #000837;
  z-index: 9;
}

/deep/ .ant-btn-primary {
  color: #fff;
  border-color: rgb(7, 36, 153) !important;
  background: rgb(7, 36, 153) !important;
}

::-webkit-scrollbar {
  width: 0;
}

.tips {
  margin-top: 20px;
}

.m_r {
  width: 400px;
  height: 600px;
  overflow-y: scroll;

  .list {
    width: 300px;
    margin-left: 30px;

    .item {
      margin-top: 10px;
      display: flex;

      .lleft {
        width: 50px;
        line-height: 30px;
        text-align: center;
      }

      .lright {
        width: 200px;
      }

      .licon {
        width: 50px;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}

.mrs {
  margin-left: 20px;
}

.main {
  width: 1200px;
  display: flex;
  margin-top: 10px;
}

.zmt {
  min-height: 60px;
}

.fr {
  float: right;
  margin-right: 10px;
}

.box {
  border: 1px solid #0d53b7cc;
  background: #07249933;
  padding: 20px 10px;
}

.box1 {
  border: 1px solid #0d53b7cc;
  padding: 20px 0px;
}

.btn {
  border: 1px solid #0d53b7cc;
  background: #07249933;
}

.fff {
  color: #fff;
}

.txt-select {
  border-bottom: 2px solid #00fff4;
}

/deep/ .ant-table {
  background: #020f46;
  color: #fff;
}

/deep/ .ant-table-tbody>tr.ant-table-placeholder:hover>td {
  background: transparent;
}

/deep/ .ant-empty-normal {
  color: #fff;
}

/deep/ .ant-descriptions-item-content {
  color: #fff;
}

/deep/ .ant-table-tbody>tr>td {
  border-bottom: 0px solid #fff;
}
</style>
