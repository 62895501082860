import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, isRef as _isRef, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '@/assets/cliqueD3.png'
import _imports_1 from '@/assets/cliqueD1.png'
import _imports_2 from '@/assets/cliqueD2.png'
import _imports_3 from '@/assets/cliqueD4.png'
import _imports_4 from '@/assets/facMark.png'


const _hoisted_1 = {
  class: "w-full flex px-3 py-2 theme-bg theme-clique theme-common",
  style: {"background-color":"#000A31","color":"#fff","overflow-y":"scroll","height":"calc(100vh - 100px)"}
}
const _hoisted_2 = { class: "flex flex-col whiteBgShadow" }
const _hoisted_3 = { class: "title flex items-center" }
const _hoisted_4 = { style: {"font-size":"20px"} }
const _hoisted_5 = { style: {"background":"#fff"} }
const _hoisted_6 = {
  class: "flex items-center mt-2",
  style: {"color":"#06DEF5"}
}
const _hoisted_7 = { class: "flex items-center" }
const _hoisted_8 = { class: "ml-2 enW" }
const _hoisted_9 = { class: "flex items-center" }
const _hoisted_10 = { style: {"font-size":"20px"} }
const _hoisted_11 = { style: {"font-size":"14px"} }
const _hoisted_12 = { style: {"color":"#fff"} }
const _hoisted_13 = {
  class: "flex items-center",
  style: {"color":"#5B9BFF"}
}
const _hoisted_14 = { class: "ml-2 enW" }
const _hoisted_15 = { class: "flex items-center" }
const _hoisted_16 = { style: {"font-size":"20px"} }
const _hoisted_17 = { style: {"font-size":"14px"} }
const _hoisted_18 = { style: {"color":"#fff"} }
const _hoisted_19 = {
  class: "flex items-center",
  style: {"color":"#5BFEB7"}
}
const _hoisted_20 = { class: "ml-2 enW" }
const _hoisted_21 = { class: "flex items-center" }
const _hoisted_22 = { style: {"font-size":"20px"} }
const _hoisted_23 = { style: {"font-size":"14px"} }
const _hoisted_24 = { style: {"color":"#fff"} }
const _hoisted_25 = { class: "title flex items-center mt-7" }
const _hoisted_26 = { style: {"font-size":"20px"} }
const _hoisted_27 = { class: "flex items-center mt-5" }
const _hoisted_28 = { class: "box1" }
const _hoisted_29 = { class: "content" }
const _hoisted_30 = {
  class: "flex w-full items-center",
  style: {"justify-content":"center"}
}
const _hoisted_31 = { style: {"font-size":"20px"} }
const _hoisted_32 = {
  class: "ml-2",
  style: {"font-size":"14px"}
}
const _hoisted_33 = { style: {"background":"#fff"} }
const _hoisted_34 = {
  class: "w-full whiteBgColor",
  style: {"text-align":"center","color":"#fff"}
}
const _hoisted_35 = { class: "box1 ml-5" }
const _hoisted_36 = { class: "content" }
const _hoisted_37 = {
  class: "flex w-full items-center",
  style: {"justify-content":"center"}
}
const _hoisted_38 = { style: {"font-size":"20px"} }
const _hoisted_39 = {
  class: "ml-3",
  style: {"font-size":"14px"}
}
const _hoisted_40 = { style: {"background":"#fff"} }
const _hoisted_41 = {
  class: "w-full whiteBgColor",
  style: {"text-align":"center","color":"#fff"}
}
const _hoisted_42 = { class: "title flex items-center mt-7" }
const _hoisted_43 = { style: {"font-size":"20px"} }
const _hoisted_44 = { class: "w-full" }
const _hoisted_45 = { class: "w-full flex" }
const _hoisted_46 = { class: "mr-5" }
const _hoisted_47 = { class: "w-full flex items-center" }
const _hoisted_48 = { class: "ml-12" }
const _hoisted_49 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_50 = { class: "ml-2" }
const _hoisted_51 = { class: "ml-2" }
const _hoisted_52 = { class: "ml-2" }
const _hoisted_53 = { class: "ml-2" }
const _hoisted_54 = { class: "title flex items-center mt-7" }
const _hoisted_55 = { style: {"font-size":"20px"} }
const _hoisted_56 = { class: "w-full" }
const _hoisted_57 = { class: "w-full flex" }
const _hoisted_58 = { class: "mr-5" }
const _hoisted_59 = { class: "w-full flex items-center" }
const _hoisted_60 = { class: "ml-12" }
const _hoisted_61 = { class: "ml-2" }
const _hoisted_62 = { class: "ml-2" }
const _hoisted_63 = { class: "flex-1" }
const _hoisted_64 = {
  class: "w-full flex",
  style: {"justify-content":"center"}
}
const _hoisted_65 = { class: "title2 text-center mt-12" }
const _hoisted_66 = { class: "w-full flex" }
const _hoisted_67 = { style: {"position":"relative","width":"90%"} }
const _hoisted_68 = ["src"]
const _hoisted_69 = { class: "flex flex-col whiteBgShadow" }
const _hoisted_70 = { class: "title flex items-center" }
const _hoisted_71 = { style: {"font-size":"20px"} }
const _hoisted_72 = { class: "mt-4" }
const _hoisted_73 = { class: "flex items-center" }
const _hoisted_74 = { class: "mt-4 class flex items-center" }
const _hoisted_75 = {
  key: 0,
  id: "chartC",
  style: {"width":"300px","height":"150px"}
}
const _hoisted_76 = { class: "ml-12" }
const _hoisted_77 = { class: "ml-2" }
const _hoisted_78 = { class: "flex items-center mt-3" }
const _hoisted_79 = { class: "ml-2" }
const _hoisted_80 = { class: "flex items-center mt-3" }
const _hoisted_81 = { class: "ml-2" }
const _hoisted_82 = { class: "title flex items-center mt-7" }
const _hoisted_83 = { style: {"font-size":"20px"} }
const _hoisted_84 = { class: "mt-4" }
const _hoisted_85 = { class: "mt-4 class flex items-center" }
const _hoisted_86 = {
  key: 0,
  id: "chartD",
  style: {"width":"300px","height":"150px"}
}
const _hoisted_87 = { class: "ml-12" }
const _hoisted_88 = { class: "flex items-center" }
const _hoisted_89 = { class: "ml-2" }
const _hoisted_90 = { class: "flex items-center mt-3" }
const _hoisted_91 = { class: "ml-2" }
const _hoisted_92 = { class: "flex items-center mt-3" }
const _hoisted_93 = { class: "ml-2" }
const _hoisted_94 = { class: "title flex items-center mt-7" }
const _hoisted_95 = { style: {"font-size":"20px"} }
const _hoisted_96 = { class: "mt-4" }
const _hoisted_97 = { class: "mt-4 class flex items-center" }
const _hoisted_98 = {
  key: 0,
  id: "chartE",
  style: {"width":"300px","height":"150px"}
}
const _hoisted_99 = { class: "ml-12" }
const _hoisted_100 = { class: "flex items-center" }
const _hoisted_101 = { class: "ml-2" }
const _hoisted_102 = { class: "flex items-center mt-3" }
const _hoisted_103 = { class: "ml-2" }
const _hoisted_104 = { class: "flex items-center mt-3" }
const _hoisted_105 = { class: "ml-2" }
const _hoisted_106 = { style: {"color":"#fff"} }
const _hoisted_107 = { class: "w-full flex" }
const _hoisted_108 = {
  class: "text-center w-full mb-4",
  style: {"font-size":"24px"}
}
const _hoisted_109 = { class: "flex items-center" }
const _hoisted_110 = {
  class: "mt-4",
  style: {"width":"100%","border":"1px solid #0d53b7cc","background":"#07249933"}
}
const _hoisted_111 = { key: 0 }
const _hoisted_112 = { key: 1 }
const _hoisted_113 = { key: 2 }
const _hoisted_114 = {
  key: 3,
  class: "flex items-center"
}
const _hoisted_115 = { style: {"color":"#fff"} }
const _hoisted_116 = { class: "w-full flex" }

import { defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import * as echarts from "echarts";
import {
    transformDate,
    getFactoryName,
    getGroupName,
    transformDate2,
    getGroupListByFactory,
    getRealFactoryIdList
} from "../../common/tools";
import MapImg from "../../assets/map.png";
import { langList } from "../../common/lang";
import { getRealFactoryIdListById } from "../../common/tools";
import { getLanguage } from "../../common/translate"

export default /*@__PURE__*/_defineComponent({
  __name: 'clique',
  setup(__props) {

let factory_id = ''

let value = ref('')

let stopLabel:any = ref('停机')
let isShowRate:any = ref(false)
let availabilityRatio:any = ref(0)

let totalTime: any = ref(null) //系统运行时间
let weightedAverage: any = ref(0)    //全厂运行设备健康分数
let factoryList: any = ref([])
let groupList: any = ref([])     //区域
let machineList: any = ref([])   //
let sensorList: any = ref([])   //

let conditionSelectFac = ref('')    //设备健康状态统计 工厂选择
let conditionChartData: any = ref([      //设备健康状态统计 数据
    { label: '健康', num: 0, color: '#61c08f' },
    { label: '可用', num: 0, color: '#d2de49' },
    { label: '警戒', num: 0, color: '#cd5f3b' },
    { label: '故障', num: 0, color: '#921e37' },
])

let statusSelectFac = ref('')    //设备运行状态统计 工厂选择
let statusChartData: any = ref([      //设备运行状态统计 数据
    { label: '运行', num: 0, color: '#06DEF5' },
    { label: '停机', num: 0, color: '#ADADAD' },
])


// 设备报警数量统计时间
let machineWarningTime = ref(1)
let machineWarningFac = ref('')
let machineWarningChartData = ref([
    { label: '可用报警', num: [0, 0, 0], color: '#DDD246' },
    { label: '警戒报警', num: [0, 0, 0], color: '#CB6A34' },
    { label: '故障报警', num: [0, 0, 0], color: '#B81212' },
])

// 各厂报警数量排行
let factoryWarningTime = ref(1)
let factoryWarningChartData: any = ref([])

// 设备报警趋势
let warningTrendTime = ref(1)
let warningTrendFac = ref('')
let warningTrendChartData: any = ref([])

let factory_name: any = ref('')
let factory_info: any = ref({})


let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
    theme.value = localStorage.getItem('theme') || 'blue'
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang()

let facPic = ref('')
let localeList:any = ref([])

onMounted(() => {
    getLang()
    init()
})

const init = () => {
    factory_id = localStorage.getItem('factory_id') || ''
    if (!factory_id) return
    if(factory_id === '616bb0a6e731767afe63723a' || factory_id === '63bfaa5b4e5403690c2f098c' || factory_id === '63e5dcac4e5403690c2f0fe0') {
        stopLabel.value = '待机'
        isShowRate.value = true
    }

    conditionSelectFac.value = factory_id
    statusSelectFac.value = factory_id
    machineWarningFac.value = factory_id
    warningTrendFac.value = factory_id
    loadFactory()
}

// 获取工厂
const loadFactory = async() => {
    localeList.value = []
    let groups:any = []
    factoryList.value = []
    const config = {
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get("/api/factories", config)
    if(result.data && result.data.length) {
        // 演示账号
        factoryList.value = result.data.filter((p:any) => p._id === factory_id || p.parentId === factory_id)

        let config: any = {
            params: {
                factoryId: factory_id
            },
            headers: {
                requestId: uuidv4(),
            },
        };
        let reslut = await request.get('/api/factories/info', config)
        if(reslut?.data) {
            if(reslut?.data?.picture) {
                facPic.value = reslut.data.picture.url
            } else {
                facPic.value = MapImg
            }

            let facInfo = reslut.data
            if(facInfo) {
                if(facInfo?.position?.length > 0) {
                    let list:any = []
                    setTimeout(() => {
                        facInfo.position.map((d:any) => {
                            let left = 0
                            let right = 0

                            let div:any = document.getElementById('facImg');
                            let width = div.offsetWidth || 0;
                            let height = div.offsetHeight || 0;
                            left = width * (d[0] / 100) - 25
                            right = height * (d[1] / 100) - 25
                            list.push([left, right])
                        }) 

                        localeList.value = list

                    }, 500);
                }

                if(!facInfo.position) {
                    facInfo.position = []
                }

                factory_name.value = facInfo.factoryName
                factory_info.value = facInfo
            } else {
                factory_name.value = ''
            }
            
            
        }

        

        //获取运行时间
        totalTime.value = 0
        let facL = factoryList.value.filter((p: any) => p.parentId === factory_id)
        if (facL && facL.length) {
            let time: any = 0
            facL.map((d: any) => {
                let targetTime: any = new Date(d.createAt); // 假设目标时间是 2023 年 7 月 16 日 12:00:00 UTC
                let currentTime: any = new Date(); // 当前时间
                let time2 = Math.floor(
                    (currentTime - targetTime) / (1000 * 60 * 60)
                );
                if (time2 > time) {
                    time = time2
                }
            })
            totalTime.value = time

        } else {
            let info = factoryList.value.find((p: any) => p._id === factory_id)
            if (info) {
                let targetTime: any = new Date(info.createAt); // 假设目标时间是 2023 年 7 月 16 日 12:00:00 UTC
                let currentTime: any = new Date(); // 当前时间
                totalTime.value = Math.floor(
                    (currentTime - targetTime) / (1000 * 60 * 60)
                );
            } else {
                totalTime.value = 0
            }

        }

        result.data.forEach((element: any) => {
            element.groups = element.groups.map((t: any) => ({
                ...t,
                Name: element.factoryName + "-" + t.name,
            }));
            groups = groups.concat(element.groups);
        });
        getMain(groups);
    }

}

// 获取区域列表
const getMain = async (groups: any) => {
    const config = {
        params: {
            factoryIds: getRealFactoryIdListById(factory_id) 
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.get("/api/external/machines/workstatus", config)

    weightedAverage.value = 0
    // groupList.value = [];
    groupList.value = getGroupListByFactory();

    machineList.value = [];
    sensorList.value = [];

    if (result && result.data) {
        let weightAll = 0
        let count = 0
        groups.forEach((item2: any) => {
            item2.machines = result.data.filter(
                (t2: any) => t2.groupId == item2.sonID
            );
            // console.log('item2.machines', item2.machines)
            item2.machines.map((d: any) => {
                sensorList.value = sensorList.value.concat(d.sensors)
                if (!d.workStatus) {
                    d.workStatus = {
                        condition: 0,
                        wkStatus: 0
                    }
                }
                if (!d.workStableStatus) {
                    d.workStableStatus = {
                        condition: 0,
                        wkStatus: 0
                    }
                }
                if(!d.workStableStatus.condition) {
                    weightAll = weightAll + 100
                }
                if (d.workStableStatus.condition >= 1 && d.workStableStatus.condition <= 2) {
                    weightAll = weightAll + 80
                }
                if (d.workStableStatus.condition >= 3 && d.workStableStatus.condition <= 5) {
                    weightAll = weightAll + 60
                }
                if (d.workStableStatus.condition >= 6 && d.workStableStatus.condition <= 7) {
                    weightAll = weightAll + 30
                }
                console.log('weightAll', weightAll)
                count++
            })
            machineList.value = machineList.value.concat(item2.machines)

            // groupList.value.push(item2)
        })
        weightedAverage.value = count ? (weightAll / count).toFixed(2) : 0
    }


    setTimeout(() => {
        getConditionChartData()
        getStatusChartData()
        getMachineWarningChartData()
        getFactoryWarningChartData()
        getwarningTrendChartData()
    }, 500);

}

// 获取运行设备数及运行率
const getRunMachine = (ev: any) => {
    let num: any = 0
    let rate: any = 0
    if (ev && ev.length) {
        ev.map((d: any) => {
            if (d.workStatus.wkStatus) {
                num++
            }
        })
        rate = ((num / ev.length) * 100).toFixed(2)
    }

    return { num, rate }
}

// 获取设备健康状态统计/设备运行状态统计 数据
const getConditionChartData = () => {
    let n1 = 0, n2 = 0, n3 = 0, n4 = 0
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === conditionSelectFac.value)
    if (facList.length) {
        facList.map((f: any) => {
            f.groups && f.groups.map((g: any) => {
                g.machines && g.machines.map((m: any) => {
                    if (m.workStableStatus.condition === 0) {
                        n1++
                    }
                    if (m.workStableStatus.condition >= 1 && m.workStableStatus.condition <= 2) {
                        n2++
                    }
                    if (m.workStableStatus.condition >= 3 && m.workStableStatus.condition <= 5) {
                        n3++
                    }
                    if (m.workStableStatus.condition >= 6 && m.workStableStatus.condition <= 7) {
                        n4++
                    }
                })
            })
        })
    } else {
        let f = factoryList.value.find((p: any) => p._id === conditionSelectFac.value)
        f?.groups && f.groups.map((g: any) => {
            g.machines && g.machines.map((m: any) => {
                if (!m.workStableStatus.condition) {
                    n1++
                }
                if (m.workStableStatus.condition >= 1 && m.workStableStatus.condition <= 2) {
                    n2++
                }
                if (m.workStableStatus.condition >= 3 && m.workStableStatus.condition <= 5) {
                    n3++
                }
                if (m.workStableStatus.condition >= 6 && m.workStableStatus.condition <= 7) {
                    n4++
                }
            })
        })
    }


    conditionChartData.value = [
        { label: '健康', num: n1, color: '#61c08f' },
        { label: '可用', num: n2, color: '#d2de49' },
        { label: '警戒', num: n3, color: '#cd5f3b' },
        { label: '故障', num: n4, color: '#921e37' },
    ]

    if((n1 + n2 + n3 + n4) >0) {
        availabilityRatio.value = (((n1 + n2) / (n1 + n2 + n3 + n4))*100).toFixed(2)
    } else {
        availabilityRatio.value = '0.00'
    }

    initConditionChart()
}

// 绘制设备健康状态统计图
const initConditionChart = () => {
    if (!document.getElementById("chartA")) return
    let myChart = echarts.init(document.getElementById("chartA") as HTMLDivElement);

    let legendData = conditionChartData.value.map((t: any) => t.label)
    let DData = conditionChartData.value.map((t: any) => ({
        value: t.num,
        name: t.label + "设备数：" + t.num
    }))

    let options = {
        title: {
            text: "",
            left: "center",
        },
        tooltip: {
            show: false,
            trigger: "item",
            formatter: "{b}: {c} ({d}%)",
        },
        legend: {
            show: false,
            orient: "vertical",
            right: 10,
            top: 30,
            textStyle: {
                color: theme.value=='white'? "#333": "#ffffff",
                fontSize: 12,
            },
            data: legendData,
            selected: {
                健康: true,
            },
        },
        graphic: {
            type: "text",
            left: "center",
            top: "center",
            style: {
                text: langObj.value["总设备数"] +":" + (conditionChartData.value[0].num + conditionChartData.value[1].num + conditionChartData.value[2].num + conditionChartData.value[3].num),
                textAlign: "center",
                fontSize: 16,
                fill:  theme.value=='white'? "#333": "#ffffff",
            },
            tooltip: {
                show: false,
            },
        },
        series: [
            {
                name: "",
                type: "pie",
                radius: ["65%", "85%"],
                center: ["50%", "50%"],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: "center",
                },
                emphasis: {
                    label: {
                        show: false,
                        fontSize: 12,
                        fontWeight: "bold",
                        formatter: "{b}\n{c} ({d}%)",
                    },
                },
                labelLine: {
                    show: false,
                },
                data: DData,
            },
        ],
        color: ['rgb(28, 196, 139)', 'rgb(206, 224, 8)', 'rgb(223, 87, 45)', 'rgb(162, 3, 53)']
    };

    myChart.setOption(options);
    
    
   
    myChart.on("mouseover", (params: any) => {
        const { name } = params;
        if(name){
            let nameArr = name.split("：");
            options.graphic.style.text = langObj.value[nameArr[0]] +"："+ nameArr[1];
        }else{
            options.graphic.style.text =  langObj.value["总设备数"] +":" + (conditionChartData.value[0].num + conditionChartData.value[1].num + conditionChartData.value[2].num + conditionChartData.value[3].num);
        }
       
        myChart.setOption(options);
    });
    myChart.on("mouseout", () => {
        options.graphic.style.text = langObj.value["总设备数"] +":" + (conditionChartData.value[0].num + conditionChartData.value[1].num + conditionChartData.value[2].num + conditionChartData.value[3].num)
        myChart.setOption(options);
        myChart.setOption(options);
    });

}

// 获取设备运行状态统计 数据
const getStatusChartData = () => {
    let s1 = 0, s2 = 0
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === statusSelectFac.value)
    if (facList.length) {
        facList.map((f: any) => {
            f.groups && f.groups.map((g: any) => {
                g.machines && g.machines.map((m: any) => {
                    if (m.workStatus.wkStatus) {
                        s1++
                    } else {
                        s2++
                    }
                })
            })
        })
    } else {
        let f = factoryList.value.find((p: any) => p._id === statusSelectFac.value)
        f?.groups && f.groups.map((g: any) => {
            g.machines && g.machines.map((m: any) => {
                if (m.workStatus.wkStatus) {
                    s1++
                } else {
                    s2++
                }
            })
        })
    }

    statusChartData.value = [
        { label: '运行', num: s1, color: '#06DEF5' },
        { label: '停机', num: s2, color: '#ADADAD' },
    ]

    initStatusChart()
}

// 绘制设备运行状态统计图
const initStatusChart = () => {
    if (!document.getElementById("chartB")) return
    let myChart = echarts.init(document.getElementById("chartB") as HTMLDivElement);

    let legendData = statusChartData.value.map((t: any) => t.label)
    let DData = statusChartData.value.map((t: any) => ({
        value: t.num,
        name: t.label + "设备数：" + t.num
    }))

    let options = {
        title: {
            text: "",
            left: "center",
        },
        tooltip: {
            show: false,
            trigger: "item",
            formatter: "{b}: {c} ({d}%)",
        },
        legend: {
            show: false,
            orient: "vertical",
            right: 10,
            top: 30,
            textStyle: {
                color: theme.value=='white'?'#333':"#ffffff",
                fontSize: 12,
            },
            data: legendData,
            selected: {
                健康: true,
            },
        },
        graphic: {
            type: "text",
            left: "center",
            top: "center",
            bouding: 'all',
            style: {
                text: langObj.value["总设备数"] +":" + (statusChartData.value[0].num + statusChartData.value[1].num),
                textAlign: "center",
                fontSize: 16,
                fill: theme.value=='white'?'#333':"#ffffff",
            },
            tooltip: {
                show: false,
            },
        },
        series: [
            {
                name: "",
                type: "pie",
                radius: ["65%", "85%"],
                center: ["50%", "50%"],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: "center",
                },
                emphasis: {
                    label: {
                        show: false,
                        fontSize: 12,
                        fontWeight: "bold",
                        formatter: "{b}\n{c} ({d}%)",
                    },
                },
                labelLine: {
                    show: false,
                },
                data: DData,
            },
        ],
        color: statusChartData.value.map((t: any) => t.color)
    };

    myChart.setOption(options);
    myChart.on("mouseover", (params: any) => {
        const { name } = params;
        if(name){
            let nameArr = name.split("：");
            if(nameArr[0] == '停机设备数') {
                nameArr[0] = stopLabel.value + '设备数'
            }
            options.graphic.style.text = langObj.value[nameArr[0]] +"："+ nameArr[1] 
        }
  
        myChart.setOption(options);
    });
    myChart.on("mouseout", () => {
        options.graphic.style.text = langObj.value["总设备数"] +":" + (statusChartData.value[0].num + statusChartData.value[1].num)
        myChart.setOption(options);
        myChart.setOption(options);
    });
}

// 获取设备报警数量
let visibleChartC = ref(true)
const getMachineWarningChartData = async () => {
    let time = new Date()
    let endDate = transformDate(time)
    let startDate: any = ''
    let year = time.getFullYear()
    let month = time.getMonth() + 1
    let week = time.getDay()

    //本年
    if (machineWarningTime.value === 1) {
        startDate = `${year}/01/01 00:00:00`
    }
    //本季度
    if (machineWarningTime.value === 2) {
        let m: any = 0
        if (month >= 1 && month <= 3) {
            m = 1
        }
        if (month >= 4 && month <= 6) {
            m = 4
        }
        if (month >= 7 && month <= 9) {
            m = 7
        }
        if (month >= 10 && month <= 12) {
            m = 10
        }
        m = m > 9 ? m : '0' + m
        startDate = `${year}/${m}/01 00:00:00`
    }
    //本月
    if (machineWarningTime.value === 3) {
        let m = month > 9 ? month : '0' + month
        startDate = `${year}/${m}/01 00:00:00`
    }
    // 本周
    if (machineWarningTime.value === 4) {
        let num = week - 1
        let date1 = new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * num))
        let y = date1.getFullYear()
        let m = date1.getMonth() + 1 > 9 ? date1.getMonth() + 1 : '0' + (date1.getMonth() + 1)
        let d = date1.getDate() > 9 ? date1.getDate() : '0' + date1.getDate()
        startDate = `${y}/${m}/${d} 00:00:00`
    }

    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === machineWarningFac.value)
    let factoryIds = []
    if (facList && facList.length) {
        factoryIds = facList.map((p: any) => p._id)
    } else {
        factoryIds = [machineWarningFac.value]
    }

    let config: any = {
        params: {
            skip: 1,
            take: 1000000,
            factoryIds,
            begin: startDate,
            end: endDate,
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/workflows', config)

    // 1未处理，2已检修, 3已关闭，
    let status1 = [0, 0, 0]
    let status2 = [0, 0, 0]
    let status3 = [0, 0, 0]

    if (result && result.data) {
        let statusL1 = result.data.list.filter((p: any) => !p.handleStatus || p.handleStatus === 1)
        let statusL2 = result.data.list.filter((p: any) => p.handleStatus === 2)
        let statusL3 = result.data.list.filter((p: any) => p.handleStatus === 3)

        status1[0] = statusL1.filter((p: any) => p.condition >= 1 && p.condition <= 2).length
        status2[0] = statusL1.filter((p: any) => p.condition >= 3 && p.condition <= 5).length
        status3[0] = statusL1.filter((p: any) => p.condition >= 6 && p.condition <= 7).length

        status1[1] = statusL2.filter((p: any) => p.condition >= 1 && p.condition <= 2).length
        status2[1] = statusL2.filter((p: any) => p.condition >= 3 && p.condition <= 5).length
        status3[1] = statusL2.filter((p: any) => p.condition >= 6 && p.condition <= 7).length


        status1[2] = statusL3.filter((p: any) => p.condition >= 1 && p.condition <= 2).length
        status2[2] = statusL3.filter((p: any) => p.condition >= 3 && p.condition <= 5).length
        status3[2] = statusL3.filter((p: any) => p.condition >= 6 && p.condition <= 7).length

    }

    visibleChartC.value = false
    visibleChartC.value = true

    setTimeout(() => {
        initMachineWarningChart(status1, status2, status3)
    }, 200);
}

// 绘制设备报警数量图
const initMachineWarningChart = (status1: any, status2: any, status3: any) => {
    if (!document.getElementById("chartC")) return
    let myChart = echarts.init(document.getElementById("chartC") as HTMLDivElement);
    let option = {
        grid: {
            top: '4%',
            left: '0%',
            right: '0%',
            bottom: '0%',
            containLabel: true
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            show: false,
        },
        xAxis: [
            {
                type: 'category',
                axisTick: { show: false },
                data: [langObj.value['未处理'], langObj.value['已检修'], langObj.value['已关闭']],
                axisLabel: {
                    color: theme.value=='white'?'#333':"#ffffff",
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {    //网格线
                    lineStyle: {
                        color: 'rgba(255,255,255,0.5)',
                        type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
                    },
                    show: true //隐藏或显示
                },
            }
        ],
        series: [
            {
                name: langObj.value['可用'],
                type: 'bar',
                barGap: 0,
                emphasis: {
                    focus: 'series'
                },
                data: status1,
                itemStyle: {
                    color: '#d2de49'
                }
            },
            {
                name: langObj.value['警戒'],
                type: 'bar',
                emphasis: {
                    focus: 'series'
                },
                data: status2,
                itemStyle: {
                    color: '#cd5f3b'
                }
            },
            {
                name: langObj.value['故障'],
                type: 'bar',
                emphasis: {
                    focus: 'series'
                },
                data: status3,
                itemStyle: {
                    color: '#921e37'
                }
            },
        ]
    };

    myChart && myChart.setOption(option)
}

// 获取各厂报警数量排行数据
let visibleChartD = ref(true)
const getFactoryWarningChartData = async () => {
    let time = new Date()
    let endDate = transformDate(time)
    let startDate: any = ''
    let year = time.getFullYear()
    let month = time.getMonth() + 1
    let week = time.getDay()

    //本年
    if (factoryWarningTime.value === 1) {
        startDate = `${year}/01/01 00:00:00`
    }
    //本季度
    if (factoryWarningTime.value === 2) {
        let m: any = 0
        if (month >= 1 && month <= 3) {
            m = 1
        }
        if (month >= 4 && month <= 6) {
            m = 4
        }
        if (month >= 7 && month <= 9) {
            m = 7
        }
        if (month >= 10 && month <= 12) {
            m = 10
        }
        m = m > 9 ? m : '0' + m
        startDate = `${year}/${m}/01 00:00:00`
    }
    //本月
    if (factoryWarningTime.value === 3) {
        let m = month > 9 ? month : '0' + month
        startDate = `${year}/${m}/01 00:00:00`
    }
    // 本周
    if (factoryWarningTime.value === 4) {
        let num = week - 1
        let date1 = new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * num))
        let y = date1.getFullYear()
        let m = date1.getMonth() + 1 > 9 ? date1.getMonth() + 1 : '0' + (date1.getMonth() + 1)
        let d = date1.getDate() > 9 ? date1.getDate() : '0' + date1.getDate()
        startDate = `${y}/${m}/${d} 00:00:00`
    }

    // let factoryIds = factoryList.value.map((p:any) => p._id)

    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === machineWarningFac.value)
    let factoryIds = []
    if (facList && facList.length) {
        factoryIds = facList.map((p: any) => p._id)
    } else {
        factoryIds = [machineWarningFac.value]
    }


    let config: any = {
        params: {
            skip: 1,
            take: 1000000,
            factoryIds,
            begin: startDate,
            end: endDate,
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/workflows', config)
    factoryWarningChartData.value = []

    if (result && result.data) {
        let facIds = [... new Set(result.data.list.map((p: any) => p.factoryId))]
        facIds.map((d: any) => {
            let list = result.data.list.filter((p: any) => p.factoryId === d)
            let condition1 = list.filter((p: any) => p.condition >= 1 && p.condition <= 2).length
            let condition2 = list.filter((p: any) => p.condition >= 3 && p.condition <= 5).length
            let condition3 = list.filter((p: any) => p.condition >= 6 && p.condition <= 7).length

            // factoryWarningChartData.value.push([
            //     { label: '可用报警', num: condition1, color: '#DDD246' },
            //     { label: '警戒报警', num: condition2, color: '#CB6A34' },
            //     { label: '故障报警', num: condition3, color: '#B81212' },
            // ])

            factoryWarningChartData.value.push({
                factoryName: factoryList.value.find((p: any) => p._id === d)?.factoryName,
                data: [condition1, condition2, condition3],
            })
        })
    }

    visibleChartD.value = false
    visibleChartD.value = true

    setTimeout(() => {
        initFactoryWarningChart()
    }, 200);

}

// 绘制各厂报警数量排行图
const initFactoryWarningChart = () => {
    if (!document.getElementById("chartD")) return
    let myChart = echarts.init(document.getElementById("chartD") as HTMLDivElement);
    let list1: any = [], list2: any = [], list3: any = [], list4: any = []
    factoryWarningChartData.value.map((d: any) => {
        list1.push(d.factoryName)
        list2.push(d.data[0])
        list3.push(d.data[1])
        list4.push(d.data[2])
    })

    let option = {
        grid: {
            top: '4%',
            left: '0%',
            right: '0%',
            bottom: '0%',
            containLabel: true
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            show: false,
        },
        xAxis: [
            {
                type: 'category',
                axisTick: { show: false },
                data: list1,
                axisLabel: {
                    color: theme.value=='white'?'#333':"#ffffff",
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {    //网格线
                    lineStyle: {
                        color: 'rgba(255,255,255,0.5)',
                        type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
                    },
                    show: true //隐藏或显示
                },
            }
        ],
        series: [
            {
                name: langObj.value['可用'],
                type: 'bar',
                barGap: 0,
                emphasis: {
                    focus: 'series'
                },
                data: list2,
                itemStyle: {
                    color: '#d2de49'
                }
            },
            {
                name: langObj.value['警戒'],
                type: 'bar',
                emphasis: {
                    focus: 'series'
                },
                data: list3,
                itemStyle: {
                    color: '#cd5f3b'
                }
            },
            {
                name: langObj.value['故障'],
                type: 'bar',
                emphasis: {
                    focus: 'series'
                },
                data: list4,
                itemStyle: {
                    color: '#921e37'
                }
            },
        ]
    };


    myChart && myChart.setOption(option)
}

// 获取设备报警趋势数据
let visibleChartE = ref(true)
const getwarningTrendChartData = async () => {
    let time = new Date()
    let endDate = transformDate(time)
    let startDate: any = ''
    let year = time.getFullYear()
    let month = time.getMonth() + 1
    let week = time.getDay()
    //本月
    if (warningTrendTime.value === 1) {
        let m = month > 9 ? month : '0' + month
        startDate = `${year}/${m}/01 00:00:00`
    }
    // 本周
    if (warningTrendTime.value === 2) {
        let num = week - 1
        let date1 = new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * num))
        let y = date1.getFullYear()
        let m = date1.getMonth() + 1 > 9 ? date1.getMonth() + 1 : '0' + (date1.getMonth() + 1)
        let d = date1.getDate() > 9 ? date1.getDate() : '0' + date1.getDate()
        startDate = `${y}/${m}/${d} 00:00:00`
    }

    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === warningTrendFac.value)
    let factoryIds = []
    if (facList && facList.length) {
        factoryIds = facList.map((p: any) => p._id)
    } else {
        factoryIds = [warningTrendFac.value]
    }

    let config: any = {
        params: {
            skip: 1,
            take: 1000000,
            factoryIds,
            begin: startDate,
            end: endDate,
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/workflows', config)
    warningTrendChartData.value = []

    if (result && result.data && result.data.list) {
        result.data.list.reverse()

        let dateList = result.data.list.map((d: any) => d.date.substring(0, 10))
        dateList = [... new Set(dateList)]

        dateList.map((d: any) => {
            let list = result.data.list.filter((p: any) => p.date.includes(d))

            let statusList1 = list.filter((p: any) => p.condition >= 1 && p.condition <= 2).length
            let statusList2 = list.filter((p: any) => p.condition >= 3 && p.condition <= 5).length
            let statusList3 = list.filter((p: any) => p.condition >= 6 && p.condition <= 7).length

            warningTrendChartData.value.push({
                date: d,
                statusList1,
                statusList2,
                statusList3,
            })
        })


    }

    visibleChartE.value = false
    visibleChartE.value = true

    setTimeout(() => {
        initWarningTrendChart()
    }, 200);

}

// 绘制设备报警趋势图
const initWarningTrendChart = () => {
    if (!document.getElementById("chartE")) return
    let myChart = echarts.init(document.getElementById("chartE") as HTMLDivElement);
    // myChart.clear()
    let colors: any = ['#DDD246', '#CB6A34', '#B81212']
    let xData: any = []

    xData = warningTrendChartData.value.map((d: any) => d.date.substring(5, 10))
    let statusList1 = warningTrendChartData.value.map((d: any) => d.statusList1)
    let statusList2 = warningTrendChartData.value.map((d: any) => d.statusList2)
    let statusList3 = warningTrendChartData.value.map((d: any) => d.statusList3)

    let option = {
        // color: colors,
        tooltip: {
            hideDelay: 100,
            transitionDuration: 0,
            animation: false,
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
            }
        },
        grid: {
            top: '3%',
            left: '3%',
            right: '5%',
            bottom: '3%',
            containLabel: true
        },
        toolbox: {
            trigger: 'axis'
        },
        dataZoom: [{ type: 'inside' }],
        legend: {
            show: false,
        },
        xAxis: [
            {
                show: true,
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                data: xData,
                splitLine: {
                    show: false,
                    lineStyle: {
                        opacity: 0.3
                    }
                },
                axisLabel: {
                    color: theme.value=='white'?'#333':"#ffffff",
                    fontSize: 12,
                    showMaxLabel: true
                },
            }
        ],
        yAxis: {
            type: 'value',
            scale: true,
            splitLine: {    //网格线
                lineStyle: {
                    color: 'rgba(255,255,255,0.5)',
                    type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
                },
                show: true //隐藏或显示
            },
        },

        series: [
            {
                name: langObj.value['可用报警'],
                type: 'line',
                data: statusList1,
                itemStyle: {
                    color: '#d2de49'
                }
            },
            {
                name: langObj.value['警戒报警'],
                type: 'line',
                data: statusList2,
                itemStyle: {
                    color: '#cd5f3b'
                }
            },
            {
                name: langObj.value['故障报警'],
                type: 'line',
                data: statusList3,
                itemStyle: {
                    color: '#921e37'
                }
            }
        ]
    };

    myChart && myChart.setOption(option)
}


// 设备列表弹框
let machineModelSearch:any = ref({
    condition: null,
    status: null,
    groupId: null,
    factoryId: '',
    keyWord: '',
})
let machineVisible = ref(false)
let machineDataList: any = ref([])

let conditionList = [
    { label: "健康", value: 0, color: "#61c08f" },
    { label: "可用", value: 1, color: "#d2de49" },
    { label: "可用", value: 2, color: "#d2de49" },
    { label: "警戒", value: 3, color: "#cd5f3b" },
    { label: "警戒", value: 4, color: "#cd5f3b" },
    { label: "警戒", value: 5, color: "#cd5f3b" },
    { label: "故障", value: 6, color: "#921e37" },
    { label: "故障", value: 7, color: "#921e37" },
];

let conditionLists = [
    { label: getLanguage("健康", language.value), value: 1, realValue: [0] },
    { label: getLanguage("可用", language.value), value: 2, realValue: [1, 2] },
    { label: getLanguage("警戒", language.value), value: 3, realValue: [3, 4, 5] },
    { label: getLanguage("故障", language.value), value: 4, realValue: [6, 7] },
];

let statusList = [
    { label: getLanguage("运行", language.value), value: 1, color: "#0AF9F6" },
    { label: getLanguage("停机", language.value), value: 0, color: "rgb(162, 166, 171)" },
];

let whetherList = [
    { label: "是", value: true },
    { label: "否", value: false },
];

const machineColumns = [
    {
        title: getLanguage('设备编号', language.value),
        dataIndex: "machineCode",
    },
    {
        title: getLanguage('设备名称', language.value),
        dataIndex: "machineName",
    },
    {
        title: getLanguage('所属工厂', language.value),
        dataIndex: "factoryId",
    },
    {
        title: getLanguage('设备区域', language.value),
        dataIndex: "groupId",
    },
    {
        title: getLanguage('设备位置', language.value),
        dataIndex: "position",
    },
    {
        title: getLanguage('设备厂家', language.value),
        dataIndex: "supplier",
    },
    {
        title: getLanguage('设备型号', language.value),
        dataIndex: "model",
    },
    {
        title: getLanguage('设备类型', language.value),
        dataIndex: "machineTypeAll",
    },
    {
        title: getLanguage('设备SN号', language.value),
        dataIndex: "sn",
    },
    {
        title: getLanguage('巡检序号', language.value),
        dataIndex: "sort",
    },
    {
        title: getLanguage('设备描述', language.value),
        dataIndex: "remark",
    },
    {
        title: getLanguage('健康状态', language.value),
        dataIndex: "condition",
    },
    {
        title: getLanguage('运行状态', language.value),
        dataIndex: "status",
    },
    //   {
    //     title: "操作",
    //     dataIndex: "act",
    //   },
];

const selectMachineCondition = (condition: any) => {
    resetMachine()
    machineModelSearch.value.condition = condition
    machineModelSearch.value.factoryId = conditionSelectFac.value
    machineVisible.value = true
    getDataMachine()
}

const selectMachineStatus = (status: any) => {
    resetMachine()
    machineModelSearch.value.status = status
    machineModelSearch.value.factoryId = statusSelectFac.value
    machineVisible.value = true
    getDataMachine()
}


const resetMachine = () => {
    machineModelSearch.value = {
        condition: null,
        status: null,
        groupId: null,
        factoryId: '',
        keyWord: '',
    }
}

const getDataMachine = async () => {
    let config: any = {
        params: {
            groupId: machineModelSearch.value.groupId,
            status: machineModelSearch.value.status,
            factoryIds: getRealFactoryIdListById(machineModelSearch.value.factoryId),
            keyWord: machineModelSearch.value.keyWord,
            mode: [1],
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    if (machineModelSearch.value.condition) {
        config.params.conditionList = conditionLists.find(
            (p: any) => p.value === machineModelSearch.value.condition
        )?.realValue;
    }

    let result = await request("/api/machines", config);

    if (result && result.data) {
        machineDataList.value = result.data
    } else {
        machineDataList.value = []
    }
}

// 获取设备健康状态
const getMachienCondition = (ev: any) => {
    if (ev && ev.workStatus && ev.workStatus.stableCondition) {
        return conditionList.find(
            (p: any) => p.value === ev.workStatus.stableCondition
        );
    } else {
        return conditionList[0];
    }
};

// 获取设备运行状态
const getMachienStatus = (ev: any) => {
    if(ev.workStatus?.quotaWkStatus === true) {
        return statusList[0]
    } else if(ev.workStatus?.quotaWkStatus === false) {
        return statusList[1];
    } else if(ev.workStatus?.scoreWkStatus) {
        return statusList.find((p: any) => p.value === ev.workStatus?.scoreWkStatus);
    } else {
        return statusList[1];
    }
};

const getRowClassName = (record: any, index: any) => {
    // 根据索引设置不同的类名
    return index % 2 === 0 ? "even-row" : "odd-row";
};



// 设备报警列表弹窗
let warnVisible = ref(false)
let warnSearch: any = ref({
    groupId: null,
})

const selectWarningStatus = (condition: any) => {

    // warnVisible.value = true
}


return (_ctx: any,_cache: any) => {
  const _component_question_circle_outlined = _resolveComponent("question-circle-outlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[22] || (_cache[22] = _createElementVNode("div", { class: "shu mr-3" }, null, -1)),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(langObj)['监测范围']), 1),
        _createVNode(_component_a_tooltip, { placement: "topLeft" }, {
          title: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", null, _toDisplayString(_unref(langObj)['监控设备数：用户所选组织下的监测设备数统计']), 1),
              _createElementVNode("div", null, _toDisplayString(_unref(langObj)['监控测点数：用户所选组织下的监测测点数统计']), 1),
              _createElementVNode("div", null, _toDisplayString(_unref(langObj)['运行设备数：用户所选组织下的监测设备中运行设备数统计']), 1),
              _createElementVNode("div", null, _toDisplayString(_unref(langObj)['运行率：用户所选组织下的监测设备中运行设备数/监测设备总数']), 1)
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_question_circle_outlined, {
              style: {"font-size":"14px"},
              class: "ml-2"
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _cache[23] || (_cache[23] = _createElementVNode("img", {
            src: _imports_0,
            style: {"width":"40px"}
          }, null, -1)),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("span", _hoisted_10, _toDisplayString(_unref(machineList).length), 1),
              _createElementVNode("span", _hoisted_11, _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
            ]),
            _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(langObj)['监控设备数']), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _cache[24] || (_cache[24] = _createElementVNode("img", {
            src: _imports_1,
            style: {"width":"40px"}
          }, null, -1)),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("span", _hoisted_16, _toDisplayString(_unref(sensorList).length), 1),
              _createElementVNode("span", _hoisted_17, _toDisplayString(_unref(getLanguage)("个", _unref(language))), 1)
            ]),
            _createElementVNode("div", _hoisted_18, _toDisplayString(_unref(langObj)['监控测点数']), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_19, [
          _cache[25] || (_cache[25] = _createElementVNode("img", {
            src: _imports_2,
            style: {"width":"40px"}
          }, null, -1)),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("span", _hoisted_22, _toDisplayString(getRunMachine(_unref(machineList)).num), 1),
              _createElementVNode("span", _hoisted_23, _toDisplayString(_unref(getLanguage)("台", _unref(language))) + " (" + _toDisplayString(getRunMachine(_unref(machineList)).rate) + "%)", 1)
            ]),
            _createElementVNode("div", _hoisted_24, _toDisplayString(_unref(langObj)['运行设备数(运行率)']), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_25, [
        _cache[26] || (_cache[26] = _createElementVNode("div", { class: "shu mr-3" }, null, -1)),
        _createElementVNode("span", _hoisted_26, _toDisplayString(_unref(langObj)['全厂运行情况']), 1)
      ]),
      _createElementVNode("div", _hoisted_27, [
        _createElementVNode("div", _hoisted_28, [
          _cache[27] || (_cache[27] = _createElementVNode("img", {
            class: "w-full h-full theme-white-hide",
            src: _imports_3,
            alt: ""
          }, null, -1)),
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("div", _hoisted_31, _toDisplayString(_unref(totalTime)), 1),
              _createElementVNode("div", _hoisted_32, _toDisplayString(_unref(getLanguage)("小时", _unref(language))), 1),
              _createVNode(_component_a_tooltip, { placement: "topLeft" }, {
                title: _withCtx(() => [
                  _createElementVNode("div", _hoisted_33, _toDisplayString(_unref(langObj)['显示该系统为工厂上线运行的时间']), 1)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_question_circle_outlined, {
                    style: {"font-size":"14px"},
                    class: "ml-2"
                  })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_34, _toDisplayString(_unref(langObj)['系统持续服务运行 ']), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_35, [
          _cache[28] || (_cache[28] = _createElementVNode("img", {
            class: "w-full h-full theme-white-hide",
            src: _imports_3,
            alt: ""
          }, null, -1)),
          _createElementVNode("div", _hoisted_36, [
            _createElementVNode("div", _hoisted_37, [
              _createElementVNode("div", _hoisted_38, _toDisplayString(_unref(weightedAverage)), 1),
              _createElementVNode("div", _hoisted_39, _toDisplayString(_unref(getLanguage)("分", _unref(language))), 1),
              _createVNode(_component_a_tooltip, { placement: "topLeft" }, {
                title: _withCtx(() => [
                  _createElementVNode("div", _hoisted_40, _toDisplayString(_unref(langObj)['针对运行中的设备, 状态得分的加权平均数计算为全厂健康分数, 健康设备记100分, 可用设备记80分, 警戒设备记60分, 故障设备记30分']), 1)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_question_circle_outlined, {
                    style: {"font-size":"14px"},
                    class: "ml-2"
                  })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_41, _toDisplayString(_unref(langObj)['全厂运行设备健康分数']), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_42, [
        _cache[29] || (_cache[29] = _createElementVNode("div", { class: "shu mr-3" }, null, -1)),
        _createElementVNode("span", _hoisted_43, _toDisplayString(_unref(langObj)['设备健康状态统计']), 1)
      ]),
      _createElementVNode("div", _hoisted_44, [
        _createElementVNode("div", _hoisted_45, [
          _cache[30] || (_cache[30] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
          _createElementVNode("div", _hoisted_46, [
            _createVNode(_component_a_select, {
              ref: "select",
              value: _unref(conditionSelectFac),
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_isRef(conditionSelectFac) ? (conditionSelectFac).value = $event : conditionSelectFac = $event)),
              style: {"width":"120px"},
              size: "small",
              onChange: getConditionChartData,
              dropdownMatchSelectWidth: false
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(factoryList), (item, index) => {
                  return (_openBlock(), _createBlock(_component_a_select_option, {
                    key: index,
                    value: item._id
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.factoryName), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["value"])
          ])
        ]),
        _createElementVNode("div", _hoisted_47, [
          _cache[36] || (_cache[36] = _createElementVNode("div", {
            style: {"width":"200px","height":"200px"},
            id: "chartA"
          }, null, -1)),
          _createElementVNode("div", _hoisted_48, [
            (_unref(isShowRate))
              ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
                  _cache[31] || (_cache[31] = _createElementVNode("div", null, _toDisplayString('可用率') + "：", -1)),
                  _createElementVNode("div", null, _toDisplayString(_unref(availabilityRatio)) + "%", 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: "flex items-center cursor-pointer mt-3",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (selectMachineCondition(1)))
            }, [
              _cache[32] || (_cache[32] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#61c08f"} }, null, -1)),
              _createElementVNode("div", _hoisted_50, _toDisplayString(_unref(langObj)['健康']) + "：", 1),
              _createElementVNode("div", null, _toDisplayString(_unref(conditionChartData)[0].num) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
            ]),
            _createElementVNode("div", {
              class: "flex items-center cursor-pointer mt-3",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (selectMachineCondition(2)))
            }, [
              _cache[33] || (_cache[33] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#d2de49"} }, null, -1)),
              _createElementVNode("div", _hoisted_51, _toDisplayString(_unref(langObj)['可用']) + "：", 1),
              _createElementVNode("div", null, _toDisplayString(_unref(conditionChartData)[1].num) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
            ]),
            _createElementVNode("div", {
              class: "flex items-center cursor-pointer mt-3",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (selectMachineCondition(3)))
            }, [
              _cache[34] || (_cache[34] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#cd5f3b"} }, null, -1)),
              _createElementVNode("div", _hoisted_52, _toDisplayString(_unref(langObj)['警戒']) + "：", 1),
              _createElementVNode("div", null, _toDisplayString(_unref(conditionChartData)[2].num) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
            ]),
            _createElementVNode("div", {
              class: "flex items-center cursor-pointer mt-3",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (selectMachineCondition(4)))
            }, [
              _cache[35] || (_cache[35] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#921e37"} }, null, -1)),
              _createElementVNode("div", _hoisted_53, _toDisplayString(_unref(langObj)['故障']) + "：", 1),
              _createElementVNode("div", null, _toDisplayString(_unref(conditionChartData)[3].num) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_54, [
        _cache[37] || (_cache[37] = _createElementVNode("div", { class: "shu mr-3" }, null, -1)),
        _createElementVNode("span", _hoisted_55, _toDisplayString(_unref(langObj)['设备运行状态统计']), 1)
      ]),
      _createElementVNode("div", _hoisted_56, [
        _createElementVNode("div", _hoisted_57, [
          _cache[38] || (_cache[38] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
          _createElementVNode("div", _hoisted_58, [
            _createVNode(_component_a_select, {
              ref: "select",
              value: _unref(statusSelectFac),
              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => (_isRef(statusSelectFac) ? (statusSelectFac).value = $event : statusSelectFac = $event)),
              style: {"width":"120px"},
              size: "small",
              onChange: getStatusChartData,
              dropdownMatchSelectWidth: false
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(factoryList), (item, index) => {
                  return (_openBlock(), _createBlock(_component_a_select_option, {
                    key: index,
                    value: item._id
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.factoryName), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["value"])
          ])
        ]),
        _createElementVNode("div", _hoisted_59, [
          _cache[41] || (_cache[41] = _createElementVNode("div", {
            id: "chartB",
            style: {"width":"200px","height":"200px"}
          }, null, -1)),
          _createElementVNode("div", _hoisted_60, [
            _createElementVNode("div", {
              class: "flex items-center cursor-pointer",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (selectMachineStatus(1)))
            }, [
              _cache[39] || (_cache[39] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#06DEF5"} }, null, -1)),
              _createElementVNode("div", _hoisted_61, _toDisplayString(_unref(langObj)['运行']) + "：", 1),
              _createElementVNode("div", null, _toDisplayString(_unref(statusChartData)[0].num) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
            ]),
            _createElementVNode("div", {
              class: "flex items-center cursor-pointer mt-3",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (selectMachineStatus(0)))
            }, [
              _cache[40] || (_cache[40] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#ADADAD"} }, null, -1)),
              _createElementVNode("div", _hoisted_62, _toDisplayString(_unref(langObj)[_unref(stopLabel)]) + "：", 1),
              _createElementVNode("div", null, _toDisplayString(_unref(statusChartData)[1].num) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_63, [
      _createElementVNode("div", _hoisted_64, [
        _createElementVNode("div", _hoisted_65, _toDisplayString(_unref(factory_name)), 1)
      ]),
      _createElementVNode("div", _hoisted_66, [
        _cache[43] || (_cache[43] = _createElementVNode("div", { style: {"width":"5%"} }, null, -1)),
        _createElementVNode("div", _hoisted_67, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(localeList), (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              style: _normalizeStyle([{"position":"absolute","color":"red"}, {'left': item[0] + 'px', 'top': item[1]+'px'}])
            }, _cache[42] || (_cache[42] = [
              _createElementVNode("img", {
                style: {"width":"50px"},
                src: _imports_4,
                alt: ""
              }, null, -1)
            ]), 4))
          }), 128)),
          _createElementVNode("img", {
            id: "facImg",
            style: {"width":"100%"},
            src: _unref(facPic),
            alt: ""
          }, null, 8, _hoisted_68)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_69, [
      _createElementVNode("div", _hoisted_70, [
        _cache[44] || (_cache[44] = _createElementVNode("div", { class: "shu mr-3" }, null, -1)),
        _createElementVNode("span", _hoisted_71, _toDisplayString(_unref(langObj)['设备报警数量统计']), 1)
      ]),
      _createElementVNode("div", _hoisted_72, [
        _createElementVNode("div", _hoisted_73, [
          _createVNode(_component_a_select, {
            ref: "select",
            value: _unref(machineWarningTime),
            "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => (_isRef(machineWarningTime) ? (machineWarningTime).value = $event : machineWarningTime = $event)),
            style: {"width":"120px"},
            size: "small",
            onChange: getMachineWarningChartData
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select_option, { value: 1 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(langObj)['本年']), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: 2 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(langObj)['本季度']), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: 3 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(langObj)['本月']), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_select_option, { value: 4 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(langObj)['本周']), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value"]),
          _createVNode(_component_a_select, {
            ref: "select",
            value: _unref(machineWarningFac),
            "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => (_isRef(machineWarningFac) ? (machineWarningFac).value = $event : machineWarningFac = $event)),
            style: {"width":"120px","margin-left":"10px"},
            size: "small",
            onChange: getMachineWarningChartData,
            dropdownMatchSelectWidth: false
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(factoryList), (item, index) => {
                return (_openBlock(), _createBlock(_component_a_select_option, {
                  key: index,
                  value: item._id
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.factoryName), 1)
                  ]),
                  _: 2
                }, 1032, ["value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value"])
        ]),
        _createElementVNode("div", _hoisted_74, [
          (_unref(visibleChartC))
            ? (_openBlock(), _createElementBlock("div", _hoisted_75))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_76, [
            _createElementVNode("div", {
              class: "flex items-center",
              onClick: _cache[10] || (_cache[10] = ($event: any) => (selectWarningStatus(1)))
            }, [
              _cache[45] || (_cache[45] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#d2de49"} }, null, -1)),
              _createElementVNode("div", _hoisted_77, _toDisplayString(_unref(langObj)['可用报警']), 1)
            ]),
            _createElementVNode("div", _hoisted_78, [
              _cache[46] || (_cache[46] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#cd5f3b"} }, null, -1)),
              _createElementVNode("div", _hoisted_79, _toDisplayString(_unref(langObj)['警戒报警']), 1)
            ]),
            _createElementVNode("div", _hoisted_80, [
              _cache[47] || (_cache[47] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#921e37"} }, null, -1)),
              _createElementVNode("div", _hoisted_81, _toDisplayString(_unref(langObj)['故障报警']), 1)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_82, [
        _cache[48] || (_cache[48] = _createElementVNode("div", { class: "shu mr-3" }, null, -1)),
        _createElementVNode("span", _hoisted_83, _toDisplayString(_unref(langObj)['各厂报警数量排行']), 1)
      ]),
      _createElementVNode("div", _hoisted_84, [
        _createVNode(_component_a_select, {
          ref: "select",
          value: _unref(factoryWarningTime),
          "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => (_isRef(factoryWarningTime) ? (factoryWarningTime).value = $event : factoryWarningTime = $event)),
          style: {"width":"120px"},
          size: "small",
          onChange: getFactoryWarningChartData
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select_option, { value: 1 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(langObj)['本年']), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: 2 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(langObj)['本季度']), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: 3 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(langObj)['本月']), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: 4 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(langObj)['本周']), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value"]),
        _createElementVNode("div", _hoisted_85, [
          (_unref(visibleChartD))
            ? (_openBlock(), _createElementBlock("div", _hoisted_86))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_87, [
            _createElementVNode("div", _hoisted_88, [
              _cache[49] || (_cache[49] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#d2de49"} }, null, -1)),
              _createElementVNode("div", _hoisted_89, _toDisplayString(_unref(langObj)['可用报警']), 1)
            ]),
            _createElementVNode("div", _hoisted_90, [
              _cache[50] || (_cache[50] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#cd5f3b"} }, null, -1)),
              _createElementVNode("div", _hoisted_91, _toDisplayString(_unref(langObj)['警戒报警']), 1)
            ]),
            _createElementVNode("div", _hoisted_92, [
              _cache[51] || (_cache[51] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#921e37"} }, null, -1)),
              _createElementVNode("div", _hoisted_93, _toDisplayString(_unref(langObj)['故障报警']), 1)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_94, [
        _cache[52] || (_cache[52] = _createElementVNode("div", { class: "shu mr-3" }, null, -1)),
        _createElementVNode("span", _hoisted_95, _toDisplayString(_unref(langObj)['设备报警趋势']), 1)
      ]),
      _createElementVNode("div", _hoisted_96, [
        _createVNode(_component_a_select, {
          ref: "select",
          value: _unref(warningTrendTime),
          "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => (_isRef(warningTrendTime) ? (warningTrendTime).value = $event : warningTrendTime = $event)),
          style: {"width":"120px"},
          size: "small",
          onChange: getwarningTrendChartData
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select_option, { value: 1 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(langObj)['本月']), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: 2 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(langObj)['本周']), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value"]),
        _createVNode(_component_a_select, {
          ref: "select",
          value: _unref(warningTrendFac),
          "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => (_isRef(warningTrendFac) ? (warningTrendFac).value = $event : warningTrendFac = $event)),
          style: {"width":"120px","margin-left":"10px"},
          size: "small",
          onChange: getwarningTrendChartData,
          dropdownMatchSelectWidth: false
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(factoryList), (item, index) => {
              return (_openBlock(), _createBlock(_component_a_select_option, {
                key: index,
                value: item._id
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.factoryName), 1)
                ]),
                _: 2
              }, 1032, ["value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["value"])
      ]),
      _createElementVNode("div", _hoisted_97, [
        (_unref(visibleChartE))
          ? (_openBlock(), _createElementBlock("div", _hoisted_98))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_99, [
          _createElementVNode("div", _hoisted_100, [
            _cache[53] || (_cache[53] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#d2de49"} }, null, -1)),
            _createElementVNode("div", _hoisted_101, _toDisplayString(_unref(langObj)['可用报警']), 1)
          ]),
          _createElementVNode("div", _hoisted_102, [
            _cache[54] || (_cache[54] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#cd5f3b"} }, null, -1)),
            _createElementVNode("div", _hoisted_103, _toDisplayString(_unref(langObj)['警戒报警']), 1)
          ]),
          _createElementVNode("div", _hoisted_104, [
            _cache[55] || (_cache[55] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#921e37"} }, null, -1)),
            _createElementVNode("div", _hoisted_105, _toDisplayString(_unref(langObj)['故障报警']), 1)
          ])
        ])
      ])
    ]),
    _createVNode(_component_a_modal, {
      visible: _unref(machineVisible),
      "onUpdate:visible": _cache[19] || (_cache[19] = ($event: any) => (_isRef(machineVisible) ? (machineVisible).value = $event : machineVisible = $event)),
      closable: false,
      footer: null,
      bodyStyle: { background: '#020F46' },
      style: {"top":"30%"},
      width: "1400px",
      maskClosable: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_106, [
          _createElementVNode("div", _hoisted_107, [
            _cache[56] || (_cache[56] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: _cache[14] || (_cache[14] = ($event: any) => (_isRef(machineVisible) //@ts-ignore
 ? machineVisible.value = false : machineVisible = false))
            }, [
              _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
            ])
          ]),
          _createElementVNode("div", _hoisted_108, _toDisplayString(_unref(getLanguage)('设备列表', _unref(language))), 1),
          _createVNode(_component_a_form, {
            model: _unref(machineModelSearch),
            name: "horizontal_login",
            layout: "inline",
            autocomplete: "off"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: _unref(getLanguage)('设备区域', _unref(language)),
                name: "groupId",
                allowClear: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _unref(machineModelSearch).groupId,
                    "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_unref(machineModelSearch).groupId) = $event)),
                    style: {"width":"220px"},
                    allowClear: "",
                    placeholder: _unref(getLanguage)('请选择区域', _unref(language))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList), (item, index) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: index,
                          value: item.id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _unref(getLanguage)('健康状态', _unref(language)),
                name: "condition"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _unref(machineModelSearch).condition,
                    "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_unref(machineModelSearch).condition) = $event)),
                    style: {"width":"220px"},
                    allowClear: "",
                    placeholder: _unref(getLanguage)('请选择健康状态', _unref(language))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(conditionLists), (item, index) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: index,
                          value: item.value
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _unref(getLanguage)('运行状态', _unref(language)),
                name: "status"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _unref(machineModelSearch).status,
                    "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_unref(machineModelSearch).status) = $event)),
                    style: {"width":"220px"},
                    allowClear: "",
                    placeholder: _unref(getLanguage)('请选择运行状态', _unref(language))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(statusList), (item, index) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: index,
                          value: item.value
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _unref(getLanguage)('搜索', _unref(language)),
                name: "status"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _unref(machineModelSearch).keyWord,
                    "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_unref(machineModelSearch).keyWord) = $event)),
                    placeholder: _unref(getLanguage)('请输入设备名称/编号', _unref(language))
                  }, null, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_109, [
                    _createElementVNode("div", {
                      onClick: resetMachine,
                      class: "box px-5 py-1 fff cursor-pointer ml-4 btn-reset",
                      style: {"background-color":"#072499","color":"#fff"}
                    }, _toDisplayString(_unref(getLanguage)('重置', _unref(language))), 1),
                    _createElementVNode("div", {
                      onClick: getDataMachine,
                      class: "box px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                      style: {"background-color":"#072499","color":"#fff"}
                    }, _toDisplayString(_unref(getLanguage)('查询', _unref(language))), 1)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"]),
          _createElementVNode("div", _hoisted_110, [
            _createVNode(_component_a_table, {
              style: {"width":"100%"},
              size: "small",
              "row-class-name": getRowClassName,
              columns: machineColumns,
              "data-source": _unref(machineDataList),
              pagination: false
            }, {
              bodyCell: _withCtx(({ column, text, record }) => [
                (column.dataIndex === 'factoryId')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_111, _toDisplayString(_unref(getFactoryName)(text)), 1))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'groupId')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_112, _toDisplayString(_unref(getGroupName)(text)), 1))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'experiment')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_113, _toDisplayString(_unref(getLanguage)(_unref(whetherList).find((p) => p.value === text)?.label, _unref(language))), 1))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'condition')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_114, [
                      _createElementVNode("div", {
                        style: _normalizeStyle([{"width":"14px","height":"14px","border-radius":"50%"}, { background: getMachienCondition(record)?.color }])
                      }, null, 4),
                      _createElementVNode("div", {
                        class: "ml-1",
                        style: _normalizeStyle({ color: getMachienCondition(record)?.color })
                      }, _toDisplayString(_unref(getLanguage)(getMachienCondition(record)?.label, _unref(language))), 5)
                    ]))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'status')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 4,
                      class: "ml-1",
                      style: _normalizeStyle({ color: getMachienStatus(record)?.color })
                    }, _toDisplayString(_unref(getLanguage)(getMachienStatus(record)?.label, _unref(language))), 5))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["data-source"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      visible: _unref(warnVisible),
      "onUpdate:visible": _cache[21] || (_cache[21] = ($event: any) => (_isRef(warnVisible) ? (warnVisible).value = $event : warnVisible = $event)),
      closable: false,
      footer: null,
      bodyStyle: { background: '#020F46' },
      style: {"top":"30%"},
      width: "1400px",
      maskClosable: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_115, [
          _createElementVNode("div", _hoisted_116, [
            _cache[57] || (_cache[57] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: _cache[20] || (_cache[20] = ($event: any) => (_isRef(warnVisible) //@ts-ignore
 ? warnVisible.value = false : warnVisible = false))
            }, [
              _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
            ])
          ]),
          _cache[58] || (_cache[58] = _createElementVNode("div", {
            class: "text-center w-full",
            style: {"font-size":"24px"}
          }, "报警记录", -1)),
          _cache[59] || (_cache[59] = _createElementVNode("div", { class: "w-full" }, null, -1))
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}
}

})