import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, isRef as _isRef, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex items-center" }
const _hoisted_2 = {
  class: "w-full",
  style: {"color":"#fff"}
}
const _hoisted_3 = { style: {"width":"100%","padding":"0 16px 0 60px","display":"flex"} }
const _hoisted_4 = { style: {"width":"100px"} }
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { style: {"flex":"1","padding-left":"5px"} }
const _hoisted_11 = {
  class: "w-full mt-1 mb-1",
  style: {"padding":"0 16px 0 164px"}
}
const _hoisted_12 = { class: "card p-2 w-full flex" }
const _hoisted_13 = { class: "flex-1" }
const _hoisted_14 = { class: "flex-1" }
const _hoisted_15 = { class: "flex items-center mt-2 mb-2" }
const _hoisted_16 = { style: {"width":"100%","padding":"0 16px 0 44px","display":"flex"} }
const _hoisted_17 = { style: {"flex":"1"} }
const _hoisted_18 = { class: "card w100" }
const _hoisted_19 = { class: "card-title flex" }
const _hoisted_20 = ["onClick"]
const _hoisted_21 = ["onClick"]
const _hoisted_22 = { key: 0 }
const _hoisted_23 = ["src"]

import {
  ref,
  watch,
  onMounted,
} from "vue";
import { v4 as uuidv4 } from "uuid";
import request from "../../../common/request";
import { message } from "ant-design-vue";
import selectHisComponent from "./select-histrend.vue";
import selectScoreComponent from "./select-score.vue";
import * as echarts from "echarts";
import moment from 'moment';
import dayjs, { Dayjs } from 'dayjs';

interface Option {
  value: string;
  label: string;
  disabled?: boolean;
  children?: Option[];
}

export default /*@__PURE__*/_defineComponent({
  __name: 'diagnosticReport-add',
  props: {
  reportMachineInfo: null,
  reportCreateInfo: null,
  showSubmitPut: null
},
  emits: ["status", "sendId"],
  setup(__props, { expose: __expose, emit: __emit }) {

let formState: any = ref({
  date: "",
  factoryID: "",
  machineId: "",
  faultEvent: "",
  suggestion: "",
  grade: null,
  sensorId: '',
  diagnosisDetails: [],
});

let factoryList: any = ref([]);
let machineList: any = ref([]);
let cascaderOptions: any = ref([]);
const filter = (inputValue: string, path: Option[]) => {
  return path.some(
    (option) =>
      option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  );
};
const conditionList = [
  { label: "健康", value: 0 },
  { label: "可用上", value: 1 },
  { label: "可用下", value: 2 },
  { label: "警戒上", value: 3 },
  { label: "警戒中", value: 4 },
  { label: "警戒下", value: 5 },
  { label: "故障上", value: 6 },
  { label: "故障下", value: 7 },
];

let machineInfo: any = null;
let isHisVisible = ref(false);
let isHisDVisible = ref(false);
let hisIndex: any = null;
let chartVisible = ref(false);

const props: any = __props;

watch(
  () => props.reportMachineInfo,
  (newValue, oldValue) => {
    if (props.reportMachineInfo) {

      init();
    }
  }
);

// 添加子集
const addItem = () => {
  let form = formState.value;
  if (!form.faultEvent || !form.faultEvent.length) {
    message.warning("请选择故障模式后再添加!");
    return;
  }
  if (!form.grade && form.grade != 0) {
    message.warning("请选择严重程度后再添加!");
    return;
  }

  let list1 =
    cascaderOptions.value.find((p: any) => p.value === form.faultEvent[0])
      ?.children || [];
  let fault = list1.find((p: any) => p.value === form.faultEvent[1]);

  formState.value.diagnosisDetails.push({
    faultEvent: form.faultEvent[1],
    grade: form.grade,
    sonId: new Date().getTime().toString(),
    faultCause: fault.faultCause,
    faultSuggest: fault.faultSuggest,
    signalAnalysis:fault.signalAnalysis
  });
};

// 删除子集元素
const del = (index: number) => {
  formState.value.diagnosisDetails.splice(index, 1);
};

// 获取颜色
const loadColor = (i: any) => {
  let border = "";
  let color = "";
  if (formState.value.diagnosisDetails[i].grade >0 && formState.value.diagnosisDetails[i].grade <= 2) {
    border = "2px solid #d2de49";
    color = "#d2de49";
  }
  if (formState.value.diagnosisDetails[i].grade >= 3 && formState.value.diagnosisDetails[i].grade <= 5) {
    border = "2px solid #cd5f3b";
    color = "#cd5f3b";
  }
  if (formState.value.diagnosisDetails[i].grade >= 6 && formState.value.diagnosisDetails[i].grade <= 7) {
    border = "2px solid #921e37";
    color = "#921e37";
  }
  if (formState.value.diagnosisDetails[i].grade == 0) {
    border = "2px solid #61c08f";
    color = "#61c08f";
  }
  return [border, color];
};

// 获取故障模式列表
const getFaultModelList = () => {
  cascaderOptions.value = [];
  const config = {
    headers: {
      requestId: uuidv4(),
    },
  };
  request
    .post("/api/faultinfos", { FeatureType: "" }, config)
    .then((res: any) => {
      if (res) {
        let list = res.data;
        let expects = list.filter(
          (item: { featureType: string }) => item.featureType === "Expect"
        );
        let commons = list.filter(
          (item: { featureType: string }) => item.featureType === "Common"
        );
        let others = list.filter(
          (item: { featureType: string }) => item.featureType === "Other"
        );
        let expectList: any[] = [];
        let commonsList: any[] = [];
        let othersList: any[] = [];
        expects.forEach((item: any) => {
          if (
            expectList.findIndex(
              (ele: any) => ele.value === item.segmentFault
            ) === -1
          ) {
            expectList.push({
              value: item.segmentFault,
              label: item.segmentFault,
              faultSuggest: item.faultSuggest,
              faultCause: item.faultCause,
              signalAnalysis:item.signalAnalysis
            });
          }
        });

        commons.forEach((item: any) => {
          if (
            commonsList.findIndex(
              (ele: any) => ele.value === item.segmentFault
            ) === -1
          ) {
            commonsList.push({
              value: item.segmentFault,
              label: item.segmentFault,
              faultSuggest: item.faultSuggest,
              faultCause: item.faultCause,
              signalAnalysis:item.signalAnalysis
            });
          }
        });

        others.forEach((item: any) => {
          if (
            othersList.findIndex(
              (ele: any) => ele.value === item.segmentFault
            ) === -1
          ) {
            othersList.push({
              value: item.segmentFault,
              label: item.segmentFault,
              faultSuggest: item?.faultSuggest,
              faultCause: item?.faultCause,
              signalAnalysis:item.signalAnalysis
            });
          }
        });

        cascaderOptions.value = [
          {
            value: "专业指标",
            label: "专业指标",
            children: expectList,
          },
          {
            value: "振动指标",
            label: "振动指标",
            children: commonsList,
          },
          {
            value: "其他",
            label: "其他",
            children: othersList,
          },
        ];
      }
    });
};


// 状态修改
const changeGrade = (i: number, grade: number) => {
  formState.value.diagnosisDetails[i].grade = grade;
};

// 添加后台指标
//打开历史趋势选择
const openHis = (i: number) => {
  if (!formState.value.machineId) {
    message.warning("请选择设备");
    return;
  }

  let machine = {
    id: props.reportMachineInfo.id,
    machineName: props.reportMachineInfo.machineName,
  };
  machineInfo = machine;
  hisIndex = i;
  setTimeout(() => {
    isHisVisible.value = true;
  }, 500);
};
const openHisD = (i: number) => {
  if (!formState.value.machineId) {
    message.warning("请选择设备");
    return;
  }

  let machine = {
    id: props.reportMachineInfo.id,
    machineName: props.reportMachineInfo.machineName,
  };
  machineInfo = machine;
  hisIndex = i;
  setTimeout(() => {
    isHisDVisible.value = true;
  }, 500);
};


const getFactoryList = async () => {
  let result = await request.get("/api/members/info");
  result.data.factoryIds = result.data?.factories.map((t: any) => t.id);
  let memberInfo = result.data;
  const config = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  let result2 = await request.get("/api/factories", config);

  let list = result2.data.filter((p: any) =>
    memberInfo.factoryIds.includes(p._id)
  );

  let boldList = list.map((p: any) => p.parentId).filter((p: any) => p);

  boldList = [...new Set(boldList)];

  factoryList.value = list.filter((p: any) => !boldList.includes(p._id));
};

const selectFactory = async (ev: any) => {
  const config = {
    params: { factoryId: ev },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machines", config);
  machineList.value = result.data;
};

const getForm = (ev: any) => {
  if (!formState.value.diagnosisDetails[hisIndex].scoreData) {
    formState.value.diagnosisDetails[hisIndex].scoreData = [];
  }
  formState.value.diagnosisDetails[hisIndex].scoreData.push(ev);
  isHisVisible.value = false;
};

// 查看chart
const watchScore = (ev: any) => {
  chartVisible.value = true;
  setTimeout(() => {
    initChart(ev);
  }, 500);
};

const initChart = (item: any) => {
  const { date, value, title, markLine } = item;
  const ec = echarts as any;
  let myChart = ec.init(document.getElementById("scoreChart"));
  let option = {
    title: {
      text: title,
      left: "40%",
      top: "0",
    },
    grid: {
      left: "6%",
      right: "2%",
    },
    tooltip: {
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      data: date,
    },
    yAxis: {
      type: "value",
      scale: true,
      min: 0,
      max: 100,
      splitNumber: 5,
    },
    dataZoom: [
      {
        id: "dataZoomX",
        type: "slider",
        xAxisIndex: [0],
        filterMode: "filter",
        bottom: "0",
      },
      {
        type: "inside",
      },
    ],
    series: [
      {
        data: value,
        type: "line",
        markLine: markLine,
        connectNulls: true,
      },
    ],
  };

  option && myChart.setOption(option);
};

// 删除
const deletScore = (i: any, j: any) => {
  formState.value.diagnosisDetails[i].scoreData.splice(j, 1);
};

const emits = __emit;

// 提交
const submit = async () => {
  let form = formState.value;
  if (!form.date) {
    message.warning("请选择创建时间");
    return
  }

  let date = tranformDate(form.date);

  let content = "";
  // let diagnosisDetails =  form.diagnosisDetails || []
  let trendList: any = []
  form.diagnosisDetails &&
    form.diagnosisDetails.map((item: any) => {
      content = content + item.diagnoseMessage;
      item.scoreData &&
        item.scoreData.map((d: any) => {
          trendList.push({
            ...d,
            sonId: item.sonId
          })
          // subnitTrend(d, item.sonId);
        });
    });

  let trendResult = await request.put("/api/reports/trend", trendList);
  if (trendResult?.data) { } else {
    message.error('提交失败')
    return
  }

  let diagnosisDetails = form.diagnosisDetails || [];

  let list = diagnosisDetails.map((p: any) => ({
    sonId: p.sonId,
    diagnoseMessage: p.diagnoseMessage,
    faultEvent: p.faultEvent,
    faultCause: p.faultCause,
    faultSuggest: p.faultSuggest,
    grade: p.grade,
    signalAnalysis:p.signalAnalysis
  }));

  let submitForm = {
    date,
    factoryId: form.factoryID,
    sensorId: form.sensorId,
    // faultCause: form.faultCause,
    type: 1,
    title: "",
    content,
    machineIds: [form.machineId],
    expertReport: list,
    pictures: fileList.value.map((t: any) => ({
      url: t.url,
      remark: t.message,
    })),
    workflow: false,
  };


  // return

  let result = null;

  if (props.showSubmitPut) {
    submitForm.id = form.id
    result = await request.put("/api/reports", submitForm);
    if (result && result.status === 200) {
      message.success("编辑成功");
      // emits("status", true);
      emits("sendId", result.data);
    } else {
      message.warning("编辑失败");
    }
  } else {
    result = await request.post("/api/reports", submitForm);
    if (result && result.status === 200) {
      message.success("新增成功");
      // emits("status", true);
      emits("sendId", result.data);
    } else {
      message.warning("新增失败");
    }
  }



};

const subnitTrend = async (item: any, sonId: any) => {
  let form = {
    date: item.date,
    title: item.title,
    value: item.value,
    markLine: item.markLine,
    sonId,
  };
  let result = await request.post("/api/reports/trend", form);
};

const tranformDate = (date: any) => {
  let time = new Date(date);
  let year = time.getFullYear();
  let month =
    time.getMonth() + 1 > 9 ? time.getMonth() + 1 : "0" + (time.getMonth() + 1);
  let day = time.getDate() > 9 ? time.getDate() : "0" + time.getDate();
  let hour = time.getHours() > 9 ? time.getHours() : "0" + time.getHours();
  let min = time.getMinutes() > 9 ? time.getMinutes() : "0" + time.getMinutes();
  let second =
    time.getSeconds() > 9 ? time.getSeconds() : "0" + time.getSeconds();
  return `${year}/${month}/${day} ${hour}:${min}:${second}`;
};
const showSubmitPut = ref(false)
const init = () => {
  showSubmitPut.value = props.showSubmitPut
  getFaultModelList();
  if (props.reportCreateInfo) {
    formState.value = props.reportCreateInfo
    formState.value.date = dayjs(props.reportCreateInfo.date, 'YYYY/MM/DD HH:mm:ss')
    // formState.value.date = moment(props.reportCreateInfo.date, 'YYYY/MM/DD HH:mm:ss') 
    formState.value.diagnosisDetails = props.reportCreateInfo.expertReport.diagnosisDetails
    formState.value.sensorId = props.reportCreateInfo?.expertReport?.sensorId
  } else {
    formState.value = {
      date: "",
      factoryID: "",
      machineId: "",
      faultEvent: "",
      grade: null,
      diagnosisDetails: [],
    }
  }

  if (props.reportMachineInfo) {
    setData();
  }
};


__expose({
  submit
})

// 设置传参
const setData = () => {
  formState.value.machineName = props.reportMachineInfo.machineName;
  formState.value.machineId = props.reportMachineInfo.id;
  formState.value.factoryID = props.reportMachineInfo.factoryId;
  if (!props.reportCreateInfo) {
    formState.value.sensorId = props.reportMachineInfo.sensorId;
  }
};

onMounted(() => {
  init();
});

//**图片上传* */
let fileList = ref<any>([]);
const previewVisible = ref(false);
const previewImage = ref("");
const previewTitle = ref("");
const handleCancel = () => {
  previewVisible.value = false;
  previewTitle.value = "";
};
const deleteThis = (index: any) => {
  fileList.value.splice(index, 1);
};
const handlePreview = async (file: any) => {
  previewImage.value = file.url;
  previewVisible.value = true;
  previewTitle.value = "";
};

let customRequest = async (options: any) => {
  const { onSuccess, onError, file } = options;
  let formData = new FormData();
  formData.append("file", file); // file为要上传的文件
  const config = {
    headers: {
      requestId: uuidv4(),
      "Content-Type": "multipart/form-data",
    },
  };
  let res = await request.post("/api/upload", formData, config);
  if (res) {
    file.url = res.data;
    onSuccess({ url: file.url, status: "done" });
  }
};

return (_ctx: any,_cache: any) => {
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_cascader = _resolveComponent("a-cascader")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_popover = _resolveComponent("a-popover")!
  const _component_CloseSquareOutlined = _resolveComponent("CloseSquareOutlined")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_form, {
      model: _unref(formState),
      "label-col": { span: 4 },
      "wrapper-col": { span: 18 }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, {
          label: "创建时间",
          required: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_date_picker, {
              value: _unref(formState).date,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(formState).date) = $event)),
              "show-time": "",
              placeholder: "请选择创建时间"
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { label: "设备" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              value: _unref(formState).machineName,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_unref(formState).machineName) = $event)),
              readonly: ""
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { label: "故障模式" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_cascader, {
              class: "w-full",
              value: _unref(formState).faultEvent,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(formState).faultEvent) = $event)),
              options: _unref(cascaderOptions),
              "show-search": { filter: filter }
            }, null, 8, ["value", "options", "show-search"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { label: "严重程度" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_a_select, {
                value: _unref(formState).grade,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_unref(formState).grade) = $event)),
                placeholder: "请选择设备"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(conditionList, (item, index) => {
                    return _createVNode(_component_a_select_option, {
                      key: index,
                      value: item.value
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.label), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"])
                  }), 64))
                ]),
                _: 1
              }, 8, ["value"]),
              _createVNode(_component_a_button, {
                style: {"margin-left":"30px"},
                type: "primary",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (addItem()))
              }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode("添加")
                ])),
                _: 1
              })
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { "wrapper-col": { span: 22, offset: 0 } }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(formState).diagnosisDetails, (item, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: i,
                  style: {"width":"100%","margin-top":"10px"}
                }, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_a_popover, { placement: "top" }, {
                        content: _withCtx(() => [
                          _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("div", {
                              style: {"padding":"5px","cursor":"pointer","border":"2px solid #61c08f","color":"#61c08f"},
                              onClick: ($event: any) => (changeGrade(i, 0))
                            }, [
                              _createElementVNode("span", null, _toDisplayString(_unref(formState).diagnosisDetails[i].faultEvent), 1)
                            ], 8, _hoisted_6),
                            _createElementVNode("div", {
                              class: "ml-4",
                              style: {"padding":"5px","cursor":"pointer","border":"2px solid #d2de49","color":"#d2de49"},
                              onClick: ($event: any) => (changeGrade(i, 1))
                            }, [
                              _createElementVNode("span", null, _toDisplayString(_unref(formState).diagnosisDetails[i].faultEvent), 1)
                            ], 8, _hoisted_7),
                            _createElementVNode("div", {
                              class: "ml-4",
                              style: {"padding":"5px","cursor":"pointer","border":"2px solid #cd5f3b","color":"#cd5f3b"},
                              onClick: ($event: any) => (changeGrade(i, 3))
                            }, [
                              _createElementVNode("span", null, _toDisplayString(_unref(formState).diagnosisDetails[i].faultEvent), 1)
                            ], 8, _hoisted_8),
                            _createElementVNode("div", {
                              class: "ml-4",
                              style: {"padding":"5px","cursor":"pointer","border":"2px solid #921e37","color":"#921e37"},
                              onClick: ($event: any) => (changeGrade(i, 6))
                            }, [
                              _createElementVNode("span", null, _toDisplayString(_unref(formState).diagnosisDetails[i].faultEvent), 1)
                            ], 8, _hoisted_9)
                          ])
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("div", {
                            style: _normalizeStyle([{ border: loadColor(i)[0], color: loadColor(i)[1] }, {"text-align":"center","padding":"5px 0 5px 0","margin-bottom":"10px"}]),
                            "nz-popover": "",
                            nzPopoverTrigger: "click"
                          }, [
                            _createElementVNode("span", null, _toDisplayString(_unref(formState).diagnosisDetails[i].faultEvent), 1)
                          ], 4)
                        ]),
                        _: 2
                      }, 1024),
                      _createElementVNode("div", null, [
                        _createVNode(_component_CloseSquareOutlined, {
                          onClick: ($event: any) => (del(i)),
                          style: {"cursor":"pointer","font-size":"25px"}
                        }, null, 8, ["onClick"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_a_textarea, {
                        value: item.diagnoseMessage,
                        "onUpdate:value": ($event: any) => ((item.diagnoseMessage) = $event),
                        placeholder: "",
                        rows: 4
                      }, null, 8, ["value", "onUpdate:value"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _cache[10] || (_cache[10] = _createElementVNode("div", { style: {"font-weight":"700"} }, "故障原因:", -1)),
                        _createVNode(_component_a_textarea, {
                          value: item.faultCause,
                          "onUpdate:value": ($event: any) => ((item.faultCause) = $event),
                          placeholder: "",
                          rows: 4
                        }, null, 8, ["value", "onUpdate:value"])
                      ]),
                      _createElementVNode("div", _hoisted_14, [
                        _cache[11] || (_cache[11] = _createElementVNode("div", { style: {"font-weight":"700"} }, "建议及措施:", -1)),
                        _createVNode(_component_a_textarea, {
                          value: item.faultSuggest,
                          "onUpdate:value": ($event: any) => ((item.faultSuggest) = $event),
                          placeholder: "",
                          rows: 4
                        }, null, 8, ["value", "onUpdate:value"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _cache[12] || (_cache[12] = _createElementVNode("div", {
                        style: {"font-weight":"700"},
                        class: "mr-2"
                      }, "信号分析:", -1)),
                      _createVNode(_component_a_input, {
                        class: "flex-1",
                        value: item.signalAnalysis,
                        "onUpdate:value": ($event: any) => ((item.signalAnalysis) = $event)
                      }, null, 8, ["value", "onUpdate:value"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _cache[17] || (_cache[17] = _createElementVNode("div", { style: {"width":"120px"} }, null, -1)),
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                          _cache[13] || (_cache[13] = _createElementVNode("span", null, "前台分数", -1)),
                          _cache[14] || (_cache[14] = _createElementVNode("div", { style: {"flex":"1"} }, null, -1)),
                          _createElementVNode("a", {
                            onClick: ($event: any) => (openHis(i))
                          }, "添加", 8, _hoisted_20)
                        ]),
                        _createElementVNode("div", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.scoreData, (scoreItem, j) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: j,
                              class: "his-list flex items-center"
                            }, [
                              _createElementVNode("span", {
                                onClick: ($event: any) => (watchScore(scoreItem)),
                                style: {"cursor":"pointer","margin-right":"5px"}
                              }, _toDisplayString(scoreItem.title), 9, _hoisted_21),
                              _cache[16] || (_cache[16] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                              _createVNode(_component_a_button, {
                                type: "primary",
                                onClick: ($event: any) => (deletScore(i, j))
                              }, {
                                default: _withCtx(() => _cache[15] || (_cache[15] = [
                                  _createTextVNode(" 删除 ")
                                ])),
                                _: 2
                              }, 1032, ["onClick"])
                            ]))
                          }), 128))
                        ])
                      ])
                    ])
                  ])
                ]))
              }), 128))
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          label: "图片",
          name: "pic"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_upload, {
              "file-list": _unref(fileList),
              "onUpdate:fileList": _cache[5] || (_cache[5] = ($event: any) => (_isRef(fileList) ? (fileList).value = $event : fileList = $event)),
              "list-type": "picture-card",
              onPreview: handlePreview,
              "custom-request": _unref(customRequest),
              "auto-upload": false,
              accept: "image",
              "max-count": 6
            }, {
              default: _withCtx(() => [
                (!_unref(fileList) || _unref(fileList).length < 9)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                      _createVNode(_component_plus_outlined),
                      _cache[18] || (_cache[18] = _createElementVNode("div", { style: {"margin-top":"8px"} }, "上传", -1))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["file-list", "custom-request"]),
            _createVNode(_component_a_modal, {
              visible: previewVisible.value,
              title: previewTitle.value,
              footer: null,
              onCancel: handleCancel
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  alt: "example",
                  style: {"width":"100%"},
                  src: previewImage.value
                }, null, 8, _hoisted_23)
              ]),
              _: 1
            }, 8, ["visible", "title"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(fileList), (item, index) => {
              return (_openBlock(), _createElementBlock("div", { key: index }, [
                _createVNode(_component_a_row, { style: {"border":"1px solid #eee"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 6 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_image, {
                          alt: "example",
                          style: {"width":"80px","height":"80px","margin-left":"20px"},
                          src: item.url
                        }, null, 8, ["src"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_a_col, { span: 14 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_textarea, {
                          style: {"margin-top":"15px"},
                          type: "textarea",
                          value: item.message,
                          "onUpdate:value": ($event: any) => ((item.message) = $event),
                          placeholder: "请输入"
                        }, null, 8, ["value", "onUpdate:value"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_a_col, {
                      span: 4,
                      onClick: ($event: any) => (deleteThis(index))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DeleteOutlined, { style: {"font-size":"25px","margin":"28px"} })
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1024)
              ]))
            }), 128))
          ]),
          _: 1
        }),
        (!showSubmitPut.value)
          ? (_openBlock(), _createBlock(_component_a_form_item, {
              key: 0,
              "wrapper-col": { span: 14, offset: 4 }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: submit,
                  class: "btn-submit"
                }, {
                  default: _withCtx(() => _cache[19] || (_cache[19] = [
                    _createTextVNode("提交")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["model"]),
    _createVNode(_component_a_modal, {
      visible: _unref(isHisVisible),
      "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => (_isRef(isHisVisible) ? (isHisVisible).value = $event : isHisVisible = $event)),
      title: "截取前台指标",
      footer: null,
      maskClosable: false,
      width: "1200px",
      zIndex: 1002
    }, {
      default: _withCtx(() => [
        _createVNode(selectHisComponent, {
          onSendForm: getForm,
          machineInfo: _unref(machineInfo)
        }, null, 8, ["machineInfo"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      visible: _unref(isHisDVisible),
      "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => (_isRef(isHisDVisible) ? (isHisDVisible).value = $event : isHisDVisible = $event)),
      title: "后台指标",
      footer: null,
      maskClosable: false,
      width: "1200px",
      zIndex: 1002
    }, {
      default: _withCtx(() => [
        _createVNode(selectScoreComponent, {
          onSendForm: getForm,
          machineInfo: _unref(machineInfo)
        }, null, 8, ["machineInfo"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      visible: _unref(chartVisible),
      "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => (_isRef(chartVisible) ? (chartVisible).value = $event : chartVisible = $event)),
      title: "查看分数图",
      footer: null,
      maskClosable: false,
      width: "1200px"
    }, {
      default: _withCtx(() => _cache[20] || (_cache[20] = [
        _createElementVNode("div", {
          id: "scoreChart",
          style: {"min-width":"700px","height":"470px"}
        }, null, -1)
      ])),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})