<template>
  <div>
    <a-layout-content style="padding: 0 20px">
      <a-form layout="inline">
        <a-form-item label="时间">
          <a-range-picker v-model:value="formState.date" style="width: 400px" show-time format="YYYY/MM/DD HH:mm:ss"
            valueFormat="YYYY/MM/DD HH:mm:ss"  :disabled-date="disabledDate" @calendarChange="onCalendarChange"/>
        </a-form-item>
        <a-form-item label="人员">
          <a-select
          v-model:value="formState.userId"
          style="width: 200px"
          allowClear
          showSearch
          optionFilterProp="label"
          >
              <a-select-option v-for="(item, index) in userList" :key="index" :value="item._id" :label="item.nickName">{{ item.nickName }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="search">搜索</a-button>
        </a-form-item>
      </a-form>
      <div class="main">
        <a-table bordered :emptyText="'暂无数据'" :columns="columns" :data-source="dataSource" :pagination="pagination"
          :loading="loading" @change="handleTableChange" size="samall">
        </a-table>
      </div>
    </a-layout-content>
  </div>
</template>

<script lang="ts" setup>
import request from "../../common/request";
import { useRouter } from "vue-router";
import { computed, onMounted } from "vue";
import { ref, reactive, toRaw } from "vue";
import type { UploadProps } from "ant-design-vue";
const router = useRouter();
const loading = ref(false);
const formRef = ref();
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
let factoryId = "";
import { Dayjs } from 'dayjs';
/**操作日志记录 */
import { operateLogs } from "@/common/logs";
import { init } from "echarts";
import {getRealFactoryIdList} from "../../common/tools";

operateLogs('访问操作日志');
/**操作日志记录 */
const formState = ref({
  keyWord: "",
  date: [],
  skip: 1,
  take: 15,
  total: 0,
  userId: '',
});
const pagination = computed(() => ({
  total: formState.value.total,
  current: formState.value.skip,
  pageSize: formState.value.take,
}));

const dataSource = ref([]);
const columns = [
  {
    title: "操作人",
    dataIndex: "userName",
    align: "center"
  },
  {
    title: "操作路由",
    dataIndex: "router",
    align: "center"
  },
  {
    title: "操作详情",
    dataIndex: "desc",
    align: "center"
  },
  {
    title: "时间",
    dataIndex: "date",
    align: "center"
  }
];

let userList:any = ref([])


const handleTableChange = (pagination: any, filters: any, sorter: any) => {
  console.log(`当前页码：${pagination.current}，每页显示条数：${pagination.pageSize}`);
  // 处理分页数据
  formState.value.skip = pagination.current
  search();
}
// 查询
const search = () => {
  if(!formState.value.userId)  return message.warning('请选择人员后查询!')

  var params = {
    skip: formState.value.skip,
    take: formState.value.take,
    userId: formState.value.userId,
  }
  if (formState.value.date && formState.value.date.length) {
    params.begin = formState.value.date[0]
    params.end = formState.value.date[1]
  } else {
    delete params.begin
    delete params.end
  }
  const config = {
    params: params,
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/logs/operate", config).then((res: any) => {
    if (res) {
      dataSource.value = res.data.list;
      formState.value.total = res.data.count
    }
  });
};

// 在组件加载完成后执行一些操作
onMounted(async () => {
  // search();
  init()
});

const init = () => {
  getUserList()
}

const getUserList = async() => {
  const config = {
    params: {
      skip: 1,
      take: 99999,
      factoryIds: getRealFactoryIdList()
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result =  await request.get('/api/members', config)
 
  userList.value = result?.data?.list || []
}

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 30;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};


</script>
<style scoped>
.main {
  margin-top: 30px;
}
</style>