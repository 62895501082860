import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "ml-2" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = {
  key: 5,
  class: "w-full flex"
}
const _hoisted_7 = { style: {"width":"180px"} }
const _hoisted_8 = { class: "freqx_formTitle" }
const _hoisted_9 = { class: "freqx_formTitle" }
const _hoisted_10 = { class: "code-box-meta markdown" }
const _hoisted_11 = {
  class: "code-box-title",
  style: {"background":"#03155C"}
}
const _hoisted_12 = { class: "code-box-description" }
const _hoisted_13 = { class: "flex" }
const _hoisted_14 = { class: "code-box-description" }
const _hoisted_15 = { class: "code-box-meta markdown" }
const _hoisted_16 = {
  class: "code-box-title",
  style: {"background":"#03155C"}
}
const _hoisted_17 = {
  class: "code-box-description",
  style: {"line-height":"32px"}
}
const _hoisted_18 = { class: "flex items-center" }
const _hoisted_19 = { style: {"width":"140px"} }
const _hoisted_20 = {
  class: "code-box-description",
  style: {"line-height":"32px"}
}
const _hoisted_21 = {
  class: "markdown flex",
  style: {"color":"#fff","margin-left":"4%"}
}

import request from "../../common/request";
import { useRouter } from "vue-router";
import { computed, onMounted, watch } from "vue";
import { ref, reactive } from "vue";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { getLanguage } from "../../common/translate"

import { operateLogs } from "@/common/logs";

interface Props {
  hideExpertCareOptions?: boolean // 是否隐藏专家看护选项，诊断管理中需要显示，报警管理中需要隐藏
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DiagnosisWaring',
  props: {
    hideExpertCareOptions: { type: Boolean, default: false }
  },
  setup(__props: any) {

const router = useRouter();
const loading = ref(false);
const formRef = ref();
/**操作日志记录 */
operateLogs('访问智能诊断报警');

let language: any = ref('Chinese')
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
}
getLang()

const props = __props

let showExpertCareOptions = computed<boolean>(() => {
  return !props.hideExpertCareOptions
});

/**操作日志记录 */
const formState = reactive({
  keyword: "",
  factory: "",
  skip: 1,
  take: 10,
});
const formStateRef = ref<any>();

const modalState = reactive({
  visible: false,
  title: "新增",
  confirmLoading: false,
  roleName: "",
  meun: [],
});

const pagination = computed(() => ({
  total: 200,
  current: formState.skip,
  pageSize: formState.take,
}));

const dataSource = ref<any>();
let columns = ref<any[]>([
  {
    title: getLanguage("工厂", language.value),
    dataIndex: "factoryName",
    align: "center"
  },
  {
    title: getLanguage("区域", language.value),
    dataIndex: "group",
    align: "center"
  },
  {
    title: getLanguage("设备", language.value),
    dataIndex: "machines",
    align: "center"
  },
  {
    title: getLanguage("报警策略", language.value),
    dataIndex: "alarmType",
    align: "center"
  },
  {
    title: getLanguage("工况异常处理", language.value),
    dataIndex: "removeErrorData",
    align: "center"
  },
  {
    title: getLanguage("操作", language.value),
    dataIndex: "act",
    with: '200px',
    align: "center"
  }
]);

watch(showExpertCareOptions, (show) => {
  let idx = columns.value.findIndex(t => t.dataIndex === "exceptConfirmSendIndex");

  if (show) {
    if (idx < 0) {
      columns.value.splice(columns.value.length - 1, 0, {
        title: getLanguage("是否专家看护", language.value),
        dataIndex: "exceptConfirmSendIndex",
        align: "center"
      });
    }
  } else {
    if (idx >= 0) {
      columns.value.splice(idx, 1)
    }
  }
}, { immediate: true })

// 新增
const add = () => {
  modalState.title = getLanguage("新增", language.value);
  formStateRef.value?.clearValidate();
  formInfo = ref<any>({
    id: 0,
    factoryId: '',
    factoryName: "",
    groupId: "", //object =>  sonID  name
    group: {}, //object =>  sonID  name
    alarmTactics: 0, // 报警策略 0=分指标报警 1=按设备整体状态
    alarmType: 1, // 报警类型 0=单点即刻报警 1=短期重复出现报警
    exceptConfirmSendIndex: 1,
    removeErrorData: false, // 开关机工况异常数据剔除
    exceptConfirmSend: true, // 专家诊断确认后发送警报 bool
    needExamine: false, // 
    machines: [], // 绑定的设备编号 array => string
    deadline: 12, // ? 小时内
    counter: 2, // 次数
    affectTimeSpan: 10, //  开关机影响时间范围 0-30 分钟
    nearBootStart: 12, // 距最近开机?笔数
    nearBootEnd: 24, // 距最近开机?笔数
    prevNumber: 72, // 向前?笔数
    upLevelHour: 72,
    upLevelCount: 16 
  });

  let factoryId = localStorage.getItem('factory_id') || ''
  let facInfo: any = null
  factoryList.value.map((d: any) => {
    if (d.id === factoryId) {
      facInfo = d
    } else {
      if (d?.children?.length > 0) {
        let info = d.children.find((p: any) => p.id === factoryId)
        if (info) {
          facInfo = info
        }
      }
    }
  })

  if (facInfo) {
    selectFactory(null, facInfo, null)
    formInfo.value.factoryId = facInfo.id
    formInfo.value.factoryName = facInfo.label
  }


  modalState.visible = true;
};
const editThis = (val: any) => {
  modalState.visible = true;
  modalState.title = getLanguage("编辑", language.value);

  factoryList.value.map((f: any) => {
    if (f?.children?.length > 0) {
      let facInfo = f.children.find((p: any) => p.id === val.factoryId)
      if (facInfo) {
        groupList.value = facInfo.groups;
        let groupInfo = facInfo.groups.find((p: any) => p.id === val.groupId)
        formInfo.value.group = {
          sonID: groupInfo?.value,
          name: groupInfo?.label,
        };
        machinesList.value = groupInfo.machine
      }

    } else {
      if (f.id == val.factoryId) {
        groupList.value = f.groups;
        let groupInfo = f.groups.find((p: any) => p.id === val.groupId)
        formInfo.value.group = {
          sonID: groupInfo?.value,
          name: groupInfo?.label,
        };
        machinesList.value = groupInfo.machine
      }
    }
  })
  setTimeout(() => {
    formInfo.value = JSON.parse(JSON.stringify(val));
    if (!formInfo.value.exceptConfirmSend && !formInfo.value.needExamine) {
      formInfo.value.exceptConfirmSendIndex = 0
    }
    if (formInfo.value.exceptConfirmSend) {
      formInfo.value.exceptConfirmSendIndex = 1
    }
    if (!formInfo.value.exceptConfirmSend && formInfo.value.needExamine) {
      formInfo.value.exceptConfirmSendIndex = 2
    }
    formStateRef.value.clearValidate();
  }, 500);
};
const clearValidate = (name: any) => {
  formStateRef.value?.clearValidate(name);
};
const deleteThis = (val: any) => {
  const config = {
    params: { id: val.id },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.delete("/api/event-alarm/config", config).then((res) => {
    if (res) {
      operateLogs('删除智能诊断报警，id:' + val.id);
      message.success("操作成功");
      search();
    } else {
    }
  });
};
// 保存
const handleOk = () => {

  formStateRef.value
    .validate()
    .then((result: any) => {
      formInfo.value = {
        ...formInfo.value,
        deadline: Number(formInfo.value.deadline), // ? 小时内
        counter: Number(formInfo.value.counter), // 次数
        affectTimeSpan: Number(formInfo.value.affectTimeSpan), //  开关机影响时间范围 0-30 分钟
        nearBootStart: Number(formInfo.value.nearBootStart), // 距最近开机?笔数
        nearBootEnd: Number(formInfo.value.nearBootEnd), // 距最近开机?笔数
        prevNumber: Number(formInfo.value.prevNumber), // 向前?笔数
        upLevelCount: Number(formInfo.value.upLevelCount), //提级管理 小时
        upLevelHour: Number(formInfo.value.upLevelHour), // 提级管理 笔
      }
      if (formInfo.value.id) {
        const config = {
          headers: {
            requestId: uuidv4(),
          },
        };
        let body = formInfo.value;
        if (body.exceptConfirmSendIndex == 0) {
          body.exceptConfirmSend = false
          body.needExamine = false

        } else if (body.exceptConfirmSendIndex == 1) {
          body.exceptConfirmSend = true
        } else {
          body.exceptConfirmSend = false
          body.needExamine = true

        }
        if (body.id && !showExpertCareOptions.value) {
          delete body.exceptConfirmSend;
        }

        request
          .put("/api/event-alarm/config", formInfo.value, config)
          .then((res) => {
            if (res) {
              operateLogs('修改智能诊断报警，id:' + formInfo.value.id);
              modalState.visible = false;
              message.success("操作成功");
              search();
            }
          });
      } else {
        const config = {
          headers: {
            requestId: uuidv4(),
          },
        };
        request
          .post("/api/event-alarm/config", formInfo.value, config)
          .then((res) => {
            if (res) {
              operateLogs('添加智能诊断报警');
              modalState.visible = false;
              message.success("操作成功");
              search();
            }
          });
      }
    })
    .catch((err: any) => {
      console.log(err); //失败后执行
    });
};

const handleTableChange = (val: any) => { };
// 查询
let machineMatch: any = ref([])
const search = async () => {
  const config = {
    params: {
      factoryId: localStorage.getItem('factory_id') || ''
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let ms = await request.get("/api/machines", config)
  if (ms && ms.data) {
    machineMatch.value = ms.data
  }


  request.get("/api/event-alarm/config", config).then((res: any) => {
    if (res) {
      dataSource.value = res.data;
    }
  });
};

let formInfo = ref<any>({
  id: 0,
  factoryId: "",
  factoryName: "",
  groupId: "", //object =>  sonID  name
  group: {}, //object =>  sonID  name
  alarmTactics: 0, // 报警策略 0=分指标报警 1=按设备整体状态
  alarmType: 1, // 报警类型 0=单点即刻报警 1=短期重复出现报警
  deadline: 12, // ? 小时内
  counter: 2, // 次数
  removeErrorData: false, // 开关机工况异常数据剔除
  exceptConfirmSend: true, // 专家诊断确认后发送警报 bool
  needExamine: false,
  exceptConfirmSendIndex: 0,
  machines: [], // 绑定的设备编号 array => string

  affectTimeSpan: 10, //  开关机影响时间范围 0-30 分钟
  nearBootStart: 12, // 距最近开机?笔数
  nearBootEnd: 24, // 距最近开机?笔数
  prevNumber: 72, // 向前?笔数
  upLevelCount: 10,
  upLevelHour: 10
});

onMounted(() => {
  var requestId = uuidv4();
  loadFactory(requestId);
  search();
});
let selectedFactory = ref<any>([]);
let machinesLists = ref<any>([]);
const selectFactory = (value: any, node: any, extra: any) => {
  formInfo.value.factoryName = node.label;
  formInfo.value.group = [];
  formInfo.value.machines = [];
  groupList.value = node.groups;
  selectedFactory.value = node.groups;
  machinesLists.value = []
};
const selectGroup = (value: any, node: any, extra: any) => {
  formInfo.value.group = {
    sonID: node.value,
    name: node.label,
  };
  formInfo.value.machines = [];
  machinesList.value = node.machine
};

let machinesList = ref<any>();
let groupList = ref<any>();
let factoryList = ref<any>();
let allFactory = ref<any>([]);

const loadFactory = (requestId: any) => {
  const config = {
    params: {},
    headers: {
      requestId: requestId,
    },
  };
  request.get("/api/factories", config).then((res: any) => {
    if (res) {
      allFactory.value = res.data;
      // 2级工厂
      var list = res.data.map((t: any) => ({
        key: t._id,
        title: t.factoryName,
        value: t._id,
        label: t.factoryName,
        parentId: t.parentId,
        id: t._id,
        type: "factory",
        groups: t.groups.map((group: any) => ({
          id: group.sonID,
          type: "group",
          value: group.sonID,
          label: group.name,
          title: group.name,
          key: group.sonID,
        })),
      }));
      let factory = [];
      for (var i = 0; i < list.length; i++) {
        var element = list[i];
        if (!element.parentId) {
          const children = list.filter((t: any) => t.parentId == element.id);
          if (children && children.length) {
            element.children = children
          }
          factory.push(element);
        }
      }
      factoryList.value = factory;
      loadMachine(requestId);
    }
  });
};
let allMachine = ref<any>([]);
const loadMachine = async (requestId: any) => {
  let factoryIds = allFactory.value.map((p: any) => p._id)
  let config = {
    params: { factoryIds: factoryIds },
    headers: {
      requestId: requestId,
    },
  }
  let res = await request.post("/api/machines/all", { factoryIds })

  factoryList.value.forEach((element: any) => {
    if (res) {
      if (element?.children?.length > 0) {
        element.children.forEach((sonFact: any) => {
          if (sonFact.type == "factory" && sonFact.groups) {
            sonFact.groups.forEach((groupFact: any) => {
              if (groupFact.type == "group") {
                groupFact.machine = res.data
                  .filter((t: any) => t.groupId == groupFact.value)
                  .map((t: any) => ({
                    type: "machine",
                    ...t,
                    value: t.id,
                    label: t.machineName,
                    title: t.machineName,
                    key: t.id,
                  }));
              }
            });
          }
        });
      } else {
        element.groups.forEach((sonFact: any) => {
          if (sonFact.type == "factory" && sonFact.children) {
            sonFact.children.forEach((groupFact: any) => {
              if (groupFact.type == "group") {
                groupFact.machine = res.data
                  .filter((t: any) => t.groupId == groupFact.value)
                  .map((t: any) => ({
                    type: "machine",
                    ...t,
                    value: t.id,
                    label: t.machineName,
                    title: t.machineName,
                    key: t.id,
                  }));
              }
            });
          } else if (sonFact.type == "group") {
            sonFact.machine = res.data
              .filter((t: any) => t.groupId == sonFact.value)
              .map((t: any) => ({
                type: "machine",
                ...t,
                value: t.id,
                label: t.machineName,
                title: t.machineName,
                key: t.id,
              }));
          }
        });

      }


    }
  });

  // factoryList.value.forEach((element: any) => {
  //   const config = {
  //     params: { factoryId: element.value },
  //     headers: {
  //       requestId: requestId,
  //     },
  //   };
  //   request.get("/api/machines", config).then((res: any) => {
  //     if (res) {
  //       allMachine.value = res.data;
  //       res.data = res.data.map((t: any) => ({
  //         ...t,
  //         value: t.id,
  //         label: t.machineName,
  //         title: t.machineName,
  //         key: t.id,
  //       }));
  //       element.machine = res.data;
  //       element.children?.forEach((sonFact: any) => {
  //         if (sonFact.type == "factory") {
  //           sonFact.machine = res.data;
  //         }
  //       });
  //     }
  //   });
  // });
  setTimeout(() => {
    // console.log('factoryList.value2222222222', factoryList.value)
  }, 1000);
};


return (_ctx: any,_cache: any) => {
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_tree_select = _resolveComponent("a-tree-select")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_question_circle_outlined = _resolveComponent("question-circle-outlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_unref(showExpertCareOptions) ? {} : { 'padding': '30px 0' }),
    class: "bule_list_page theme-common"
  }, [
    _createVNode(_component_a_layout_content, { style: {"padding":"0 20px"} }, {
      default: _withCtx(() => [
        _createVNode(_component_a_row, { type: "flex" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, {
              span: 24,
              order: 2
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form, { layout: "inline" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, {
                          type: "primary",
                          onClick: add,
                          class: "btn-submit"
                        }, {
                          icon: _withCtx(() => [
                            _createVNode(_component_plus_outlined)
                          ]),
                          default: _withCtx(() => [
                            _createTextVNode(" " + _toDisplayString(_unref(getLanguage)('新增', _unref(language))), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_a_table, {
                    emptyText: '暂无数据',
                    bordered: "",
                    columns: _unref(columns),
                    "data-source": dataSource.value,
                    pagination: false,
                    loading: loading.value,
                    onChange: handleTableChange,
                    size: "small"
                  }, {
                    bodyCell: _withCtx(({ column, text, record }) => [
                      (column.dataIndex === 'group')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createTextVNode(_toDisplayString(record.group.Name || record.group.name), 1)
                          ], 64))
                        : _createCommentVNode("", true),
                      (column.dataIndex === 'machines')
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(text, (item, index) => {
                            return (_openBlock(), _createElementBlock("span", { key: index }, [
                              (_unref(machineMatch).find((t) => t.id == item))
                                ? (_openBlock(), _createBlock(_component_a_tag, {
                                    key: 0,
                                    color: "#2db7f5"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_unref(machineMatch).find((t) => t.id == item).machineName), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                : _createCommentVNode("", true)
                            ]))
                          }), 128))
                        : _createCommentVNode("", true),
                      (column.dataIndex === 'alarmType')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            _createElementVNode("span", null, _toDisplayString(record.alarmTactics == 0 ? _unref(getLanguage)('分指标报警', _unref(language)) : ""), 1),
                            _createElementVNode("span", null, _toDisplayString(record.alarmTactics == 1 ? _unref(getLanguage)('按设备整体状态', _unref(language)) : ""), 1),
                            _createElementVNode("span", null, _toDisplayString(record.alarmTactics == 2 ? _unref(getLanguage)('分测点报警', _unref(language)) : ""), 1),
                            _cache[20] || (_cache[20] = _createTextVNode(", ")),
                            _createElementVNode("span", _hoisted_2, _toDisplayString(record.alarmType == 0 ? _unref(getLanguage)('单点即刻报警', _unref(language)) : ""), 1),
                            _createElementVNode("span", null, _toDisplayString(record.alarmType == 1 ? _unref(getLanguage)('短期重复出现报警', _unref(language)) : ""), 1)
                          ], 64))
                        : _createCommentVNode("", true),
                      (column.dataIndex === 'removeErrorData')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                            _createTextVNode(_toDisplayString(text ? _unref(getLanguage)('是', _unref(language)) : _unref(getLanguage)('否', _unref(language))), 1)
                          ], 64))
                        : _createCommentVNode("", true),
                      (column.dataIndex === 'exceptConfirmSendIndex')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                            (!record.exceptConfirmSend && !record.needExamine)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_unref(getLanguage)('直接放出不审核', _unref(language))), 1))
                              : _createCommentVNode("", true),
                            (record.exceptConfirmSend)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_unref(getLanguage)('审核后放出', _unref(language))), 1))
                              : _createCommentVNode("", true),
                            (!record.exceptConfirmSend && record.needExamine)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_unref(getLanguage)('直接放出可审核编辑',
                    _unref(language))), 1))
                              : _createCommentVNode("", true)
                          ], 64))
                        : _createCommentVNode("", true),
                      (column.dataIndex === 'act')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _cache[21] || (_cache[21] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                            _createElementVNode("div", _hoisted_7, [
                              _createVNode(_component_a_popconfirm, {
                                title: _unref(getLanguage)('确定删除此条数据？', _unref(language)),
                                "ok-text": _unref(getLanguage)('确定', _unref(language)),
                                "cancel-text": _unref(getLanguage)('取消', _unref(language)),
                                onConfirm: ($event: any) => (deleteThis(record))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_button, {
                                    type: "primary",
                                    danger: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_delete_outlined),
                                      _createTextVNode(" " + _toDisplayString(_unref(getLanguage)('删除', _unref(language))), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 2
                              }, 1032, ["title", "ok-text", "cancel-text", "onConfirm"]),
                              _createVNode(_component_a_button, {
                                type: "primary",
                                class: "plft",
                                onClick: ($event: any) => (editThis(record))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_edit_outlined),
                                  _createTextVNode(" " + _toDisplayString(_unref(getLanguage)('编辑', _unref(language))), 1)
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["columns", "data-source", "loading"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_a_modal, {
      width: 1200,
      visible: modalState.visible,
      "onUpdate:visible": _cache[19] || (_cache[19] = ($event: any) => ((modalState.visible) = $event)),
      title: modalState.title,
      maskClosable: false,
      "confirm-loading": modalState.confirmLoading,
      onOk: handleOk,
      cancelText: _unref(getLanguage)('取消', _unref(language)),
      okText: _unref(getLanguage)('确定', _unref(language))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: _unref(formInfo),
          name: "basic",
          autocomplete: "off",
          layout: "inline",
          ref_key: "formStateRef",
          ref: formStateRef,
          "label-col": { style: { width: '120px' } },
          style: {"color":"#fff"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(getLanguage)('基本信息', _unref(language))), 1),
            _createVNode(_component_a_form_item, {
              label: _unref(getLanguage)('工厂', _unref(language)),
              name: "factoryId",
              lab: "",
              rules: [{ required: true, message: _unref(getLanguage)('请选择', _unref(language)) }],
              onChange: _cache[1] || (_cache[1] = ($event: any) => (clearValidate('factoryId')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_tree_select, {
                  value: _unref(formInfo).factoryId,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(formInfo).factoryId) = $event)),
                  style: {"width":"200px"},
                  "tree-data": _unref(factoryList),
                  "search-placeholder": _unref(getLanguage)('请选择', _unref(language)),
                  onSelect: selectFactory
                }, null, 8, ["value", "tree-data", "search-placeholder"])
              ]),
              _: 1
            }, 8, ["label", "rules"]),
            _createVNode(_component_a_form_item, {
              label: _unref(getLanguage)('区域', _unref(language)),
              name: "groupId",
              rules: [{ required: true, message: _unref(getLanguage)('请选择', _unref(language)) }],
              onChange: _cache[3] || (_cache[3] = ($event: any) => (clearValidate('groupId')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  value: _unref(formInfo).groupId,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(formInfo).groupId) = $event)),
                  options: _unref(groupList),
                  placeholder: _unref(getLanguage)('请选择', _unref(language)),
                  style: {"min-width":"150px"},
                  onSelect: selectGroup
                }, null, 8, ["value", "options", "placeholder"])
              ]),
              _: 1
            }, 8, ["label", "rules"]),
            _createVNode(_component_a_form_item, {
              label: _unref(getLanguage)('设备', _unref(language)),
              name: "machines",
              rules: [{ required: true, message: _unref(getLanguage)('请选择', _unref(language)) }],
              onChange: _cache[5] || (_cache[5] = ($event: any) => (clearValidate('machines')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  value: _unref(formInfo).machines,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(formInfo).machines) = $event)),
                  style: {"width":"380px"},
                  options: _unref(machinesList),
                  placeholder: _unref(getLanguage)('请选择', _unref(language)),
                  mode: "multiple",
                  "max-tag-count": 5,
                  "max-tag-text-length": 6
                }, null, 8, ["value", "options", "placeholder"])
              ]),
              _: 1
            }, 8, ["label", "rules"]),
            _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(getLanguage)('策略配置', _unref(language))), 1),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, _toDisplayString(_unref(getLanguage)('报警策略', _unref(language))), 1),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_a_form_item, {
                  label: "",
                  name: "alarmTactics"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_13, [
                      _createVNode(_component_a_radio_group, {
                        name: "radioGroup",
                        value: _unref(formInfo).alarmTactics,
                        "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_unref(formInfo).alarmTactics) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_radio, { value: 0 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(getLanguage)('分指标报警', _unref(language))), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_radio, { value: 1 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(getLanguage)('按设备整体状态', _unref(language))), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_radio, { value: 2 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(getLanguage)('分测点报警', _unref(language))), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["value"]),
                      (_openBlock(), _createBlock(_component_a_tooltip, {
                        key: 1,
                        placement: "top",
                        color: "#fff",
                        overlayStyle: { 'max-width': '1600px' }
                      }, {
                        title: _withCtx(() => _cache[22] || (_cache[22] = [
                          _createElementVNode("div", null, "分指标报警：每个指标状态下降，都单独发送报警", -1),
                          _createElementVNode("div", null, "按设备整体状态：仅当设备整体状态下降时，发送报警", -1),
                          _createElementVNode("div", null, "分测点报警：每个测点状态下降，都单独发送报警 单点即刻报警 一旦监测到分数异常，即刻报警 短期重复出现报警 统计短期内重复出现同类型异常报警，去除工况突变等短时异常值误报", -1)
                        ])),
                        default: _withCtx(() => [
                          _createVNode(_component_question_circle_outlined, {
                            style: {"color":"#fff"},
                            class: "freqx_tips"
                          })
                        ]),
                        _: 1
                      }))
                    ])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_a_form_item, {
                  label: "",
                  name: "alarmType"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio_group, {
                      name: "alarmType",
                      value: _unref(formInfo).alarmType,
                      "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_unref(formInfo).alarmType) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_radio, { value: 0 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(getLanguage)('单点即刻报警', _unref(language))), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_radio, { value: 1 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(getLanguage)('短期重复出现报警', _unref(language))), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, {
                  label: "",
                  name: "deadline",
                  style: {"width":"80px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      suffix: "笔内",
                      disabled: !_unref(formInfo).alarmType,
                      value: _unref(formInfo).deadline,
                      "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_unref(formInfo).deadline) = $event))
                    }, null, 8, ["disabled", "value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, {
                  label: "",
                  name: "counter",
                  style: {"width":"80px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      suffix: "次",
                      disabled: !_unref(formInfo).alarmType,
                      value: _unref(formInfo).counter,
                      "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_unref(formInfo).counter) = $event))
                    }, null, 8, ["disabled", "value"])
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, _toDisplayString(_unref(getLanguage)('工况异常处理', _unref(language))), 1),
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_a_form_item, {
                  label: "",
                  name: "removeErrorData"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio_group, {
                      name: "alarmType",
                      value: _unref(formInfo).removeErrorData,
                      "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_unref(formInfo).removeErrorData) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_radio, { value: true }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(getLanguage)('是', _unref(language))), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_radio, { value: false }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(getLanguage)('否', _unref(language))), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, _toDisplayString(_unref(getLanguage)('开关机影响时间范围', _unref(language))) + ":", 1),
                  _createVNode(_component_a_input, {
                    style: {"width":"230px"},
                    suffix: _unref(getLanguage)('分钟（ 范围：0 - 30 ）', _unref(language)),
                    value: _unref(formInfo).affectTimeSpan,
                    "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_unref(formInfo).affectTimeSpan) = $event))
                  }, null, 8, ["suffix", "value"])
                ])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createTextVNode(_toDisplayString(_unref(getLanguage)('距最近开机', _unref(language))) + "    ", 1),
                _createVNode(_component_a_form_item, {
                  label: "",
                  name: "nearBootStart",
                  style: {"width":"100px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(formInfo).nearBootStart,
                      "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_unref(formInfo).nearBootStart) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _cache[23] || (_cache[23] = _createTextVNode(" -     ")),
                _createVNode(_component_a_form_item, {
                  label: "",
                  name: "nearBootEnd",
                  style: {"width":"100px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(formInfo).nearBootEnd,
                      "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_unref(formInfo).nearBootEnd) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createTextVNode(" " + _toDisplayString(_unref(getLanguage)('笔数据判断状态，向前', _unref(language))) + "    ", 1),
                _createVNode(_component_a_form_item, {
                  label: "",
                  name: "prevNumber",
                  style: {"width":"100px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(formInfo).prevNumber,
                      "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_unref(formInfo).prevNumber) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createTextVNode(" " + _toDisplayString(_unref(getLanguage)('笔数据判断状态。', _unref(language))), 1)
              ])
            ]),
            (_unref(showExpertCareOptions))
              ? (_openBlock(), _createBlock(_component_a_form_item, {
                  key: 0,
                  label: _unref(getLanguage)('是否专家看护', _unref(language)),
                  name: "alarmType"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio_group, {
                      name: "alarmType",
                      value: _unref(formInfo).exceptConfirmSendIndex,
                      "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_unref(formInfo).exceptConfirmSendIndex) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_radio, { value: 0 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(getLanguage)('直接放出不审核', _unref(language))), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_radio, { value: 1 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(getLanguage)('审核后放出', _unref(language))), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_radio, { value: 2 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(getLanguage)('直接放出可审核编辑', _unref(language))), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_21, [
              _createTextVNode(_toDisplayString(_unref(getLanguage)('提级管理', _unref(language))) + " :    " + _toDisplayString(_unref(getLanguage)('工单未审核超过', _unref(language))) + "     ", 1),
              _createVNode(_component_a_form_item, { name: "upLevelHour" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    style: {"width":"100px"},
                    value: _unref(formInfo).upLevelHour,
                    "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_unref(formInfo).upLevelHour) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createTextVNode(" " + _toDisplayString(_unref(getLanguage)('小时', _unref(language))) + "      " + _toDisplayString(_unref(getLanguage)('或', _unref(language))) + "    " + _toDisplayString(_unref(getLanguage)('单测点工单未审核，', _unref(language))) + _toDisplayString(_unref(getLanguage)('状态突破', _unref(language))) + "    ", 1),
              _createVNode(_component_a_form_item, { name: "upLevelCount" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    style: {"width":"100px"},
                    value: _unref(formInfo).upLevelCount,
                    "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_unref(formInfo).upLevelCount) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createTextVNode("     " + _toDisplayString(_unref(getLanguage)('笔', _unref(language))), 1)
            ]),
            _createVNode(_component_a_form_item, {
              style: {"width":"100%"},
              label: _unref(getLanguage)('开关机测点数', _unref(language)),
              name: "shutdownSensorCount"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input_number, {
                  value: _unref(formInfo).shutdownSensorCount,
                  "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_unref(formInfo).shutdownSensorCount) = $event)),
                  min: 1
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["visible", "title", "confirm-loading", "cancelText", "okText"])
  ], 4))
}
}

})