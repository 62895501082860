import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, isRef as _isRef, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full px-5 py-6 bule_list_page theme-common" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

import request from "../../common/request";
import { ref, reactive, toRaw, onMounted, computed } from "vue";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { operateLogs } from "@/common/logs";
import { langList } from "../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";

export default /*@__PURE__*/_defineComponent({
  __name: 'fault',
  setup(__props) {

let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang()
operateLogs("访问知识库");

let columns:any = []

if(language.value === 'Chinese') {
    columns = [
        {
            title: langObj.value['类型'],
            dataIndex: 'featureType',
            width: '120px',
        },
        {
            title: langObj.value['细分故障'],
            dataIndex: 'segmentFault',
            width: '120px',
        },
        {
            title: langObj.value['故障释义'],
            dataIndex: 'faultExplain',
            width: '150px',
        },
        {
            title: langObj.value['信号分析'],
            dataIndex: 'signalAnalysis',
            width: '150px',
        },
        {
            title: langObj.value['故障原因'],
            dataIndex: 'faultCause',
            width: '150px',
        },
        {
            title: langObj.value['建议及措施'],
            dataIndex: 'faultSuggest',
            width: '150px',
        },
    ]
} else {
    columns = [
        {
            title: langObj.value['类型'],
            dataIndex: 'featureType',
            width: '120px',
        },
        {
            title: langObj.value['细分故障'],
            dataIndex: 'segmentFaultEng',
            width: '120px',
        },
        {
            title: langObj.value['故障释义'],
            dataIndex: 'faultExplainEng2',
            width: '150px',
        },
        {
            title: langObj.value['信号分析'],
            dataIndex: 'signalAnalysisEng',
            width: '150px',
        },
        {
            title: langObj.value['故障原因'],
            dataIndex: 'faultCauseEng',
            width: '150px',
        },
        {
            title: langObj.value['建议及措施'],
            dataIndex: 'faultSuggestEng',
            width: '150px',
        },
    ]
}



let activeKey = ref('Expect')

let searchModel:any = ref({
    segmentFault: '',
    faultCause: '',
    faultSuggest: '',
    skip: 1,
    take: 10,
    total: 0,
})

const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));

let dataListAll:any = ref([])
let dataList:any = ref([])
let visible = ref(false)
let form:any = ref({})

const typeList = [
    {label: '健康指标', value: 'Expect'},
    {label: '振动指标', value: 'Common'},
]
let isEditable:any = ref(false)

onMounted(() => {
    init()
})

const init = () => {
    isEditable.value = true
    searchModel.value.skip = 1
    search()
}

const search = async() => {
    const config = {
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.post("/api/faultinfos",{FeatureType:''}, config)
    if(result.status === 200) {
        dataListAll.value = result.data
    } else {
        dataListAll.value = []
    }
    
    dealWithData()
}

const dealWithData = () => {
    let list = JSON.parse(JSON.stringify(dataListAll.value))
    list = list.filter((p:any) => p.featureType === activeKey.value)
   let arr = list.map((p:any)=>p.segmentFault);
   console.log(arr)
    
    if(searchModel.value.segmentFault) {
        list = list.filter((p:any) => p.segmentFault && p.segmentFault.includes(searchModel.value.segmentFault))
    }
    if(searchModel.value.faultCause) {
        list = list.filter((p:any) => p.faultCause && p.faultCause.includes(searchModel.value.faultCause))
    }
    if(searchModel.value.faultSuggest) {
        list = list.filter((p:any) => p.faultSuggest && p.faultSuggest.includes(searchModel.value.faultSuggest))
    }

    dataList.value = list
    searchModel.value.total = dataList.value.length
}



const edit = (ev:any) => {
    form.value = JSON.parse(JSON.stringify(ev))
    visible.value = true
}


const submit = async() => {
    operateLogs('修改数据')
    let result = await request.put('/api/faultinfos', form.value)
    if(result && result.data) {
        message.success('操作成功')
        visible.value = false
        init()
    } else {
        message.warning('操作失败,请联系管理员！')
    }   
}



const handleTableChange = (ev:any) => {
    searchModel.value.skip = ev.current
    searchModel.value.take = ev.pageSize
}


// 选择指标
const selectTab = (ev:any) => {
    searchModel.value.skip = 1
    dealWithData()
}


return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_SearchOutlined = _resolveComponent("SearchOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _unref(enUS) : _unref(zhCN)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_form, { layout: "inline" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: _unref(langObj)['细分故障']
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _unref(searchModel).segmentFault,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(searchModel).segmentFault) = $event)),
                  placeholder: _unref(langObj)['搜索细分故障']
                }, null, 8, ["value", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _unref(langObj)['故障原因']
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _unref(searchModel).faultCause,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_unref(searchModel).faultCause) = $event)),
                  placeholder: _unref(langObj)['搜索故障原因']
                }, null, 8, ["value", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _unref(langObj)['建议及措施']
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _unref(searchModel).faultSuggest,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(searchModel).faultSuggest) = $event)),
                  placeholder: _unref(langObj)['搜索建议及措施']
                }, null, 8, ["value", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, { label: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: search
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_SearchOutlined)
                  ]),
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(langObj)['搜索']), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_a_tabs, {
          size: "large",
          activeKey: _unref(activeKey),
          "onUpdate:activeKey": _cache[3] || (_cache[3] = ($event: any) => (_isRef(activeKey) ? (activeKey).value = $event : activeKey = $event)),
          onChange: selectTab
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_tab_pane, {
              style: {"font-size":"18px"},
              key: "Expect",
              tab: _unref(langObj)['健康指标']
            }, null, 8, ["tab"]),
            _createVNode(_component_a_tab_pane, {
              key: "Common",
              tab: _unref(langObj)['振动指标']
            }, null, 8, ["tab"])
          ]),
          _: 1
        }, 8, ["activeKey"]),
        _createVNode(_component_a_table, {
          bordered: "",
          emptyText: _unref(langObj)['暂无数据'],
          columns: _unref(columns),
          "data-source": _unref(dataList),
          pagination: pagination.value,
          onChange: handleTableChange
        }, {
          bodyCell: _withCtx(({ column, text, record }) => [
            (column.dataIndex === 'featureType')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (text === 'Expect')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(langObj)['健康指标']), 1))
                    : _createCommentVNode("", true),
                  (text === 'Common')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_unref(langObj)['振动指标']), 1))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["emptyText", "columns", "data-source", "pagination"]),
        _createVNode(_component_a_modal, {
          visible: _unref(visible),
          "onUpdate:visible": _cache[9] || (_cache[9] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
          title: _unref(langObj)['编辑'],
          maskClosable: false,
          footer: null,
          width: "800px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, {
              model: _unref(form),
              name: "basic",
              "label-col": { span: 7 },
              "wrapper-col": { span: 14 }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['类型'],
                  name: "featureType",
                  rules: [{ required: true, message: _unref(langObj)['请选择类型'] }]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      ref: "select",
                      value: _unref(form).featureType,
                      "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(form).featureType) = $event)),
                      options: typeList
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label", "rules"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['细分故障'],
                  name: "segmentFault"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(form).segmentFault,
                      "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_unref(form).segmentFault) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['故障释义'],
                  name: "faultExplain"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_textarea, {
                      value: _unref(form).faultExplain,
                      "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_unref(form).faultExplain) = $event)),
                      rows: 4
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['故障原因'],
                  name: "faultCause"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_textarea, {
                      value: _unref(form).faultCause,
                      "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_unref(form).faultCause) = $event)),
                      rows: 4
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['建议及措施'],
                  name: "faultSuggest"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_textarea, {
                      value: _unref(form).faultSuggest,
                      "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_unref(form).faultSuggest) = $event)),
                      rows: 4
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, { "wrapper-col": { offset: 7, span: 14 } }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: submit,
                      class: "btn-submit"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(langObj)['提交']), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _: 1
        }, 8, ["visible", "title"])
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})