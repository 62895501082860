import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, isRef as _isRef } from "vue"
import _imports_0 from '@/assets/zhenduan.png'
import _imports_1 from '@/assets/weixiu.png'
import _imports_2 from '@/assets/jiancha.png'
import _imports_3 from '@/assets/menxian.png'


const _hoisted_1 = {
  style: {"padding":"16px 16px","height":"calc(100vh - 100px)","background-color":"#000a32","overflow-y":"scroll","padding-bottom":"30px","color":"#fff"},
  class: "theme-common"
}
const _hoisted_2 = { class: "box" }
const _hoisted_3 = {
  class: "w-full theme-box-title",
  style: {"border-bottom":"1px solid #495985"}
}
const _hoisted_4 = {
  class: "flex items-center px-5",
  style: {"font-size":"18px"}
}
const _hoisted_5 = { class: "w-full p-5" }
const _hoisted_6 = { class: "w-full" }
const _hoisted_7 = { class: "flex items-center" }
const _hoisted_8 = {
  class: "box mt-4",
  style: {"width":"100%"}
}
const _hoisted_9 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 2 }
const _hoisted_13 = { key: 3 }
const _hoisted_14 = { class: "ml-2" }
const _hoisted_15 = {
  key: 2,
  class: "flex items-center"
}
const _hoisted_16 = { class: "ml-2" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { key: 1 }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = ["onClick"]
const _hoisted_21 = ["onClick"]
const _hoisted_22 = { style: {"color":"#fff"} }
const _hoisted_23 = { class: "w-full flex" }
const _hoisted_24 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_25 = { class: "w-full flex justify-center" }
const _hoisted_26 = { style: {"color":"#fff"} }
const _hoisted_27 = { class: "w-full flex" }
const _hoisted_28 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_29 = { class: "w-full flex justify-center" }
const _hoisted_30 = { style: {"color":"#fff"} }
const _hoisted_31 = { class: "w-full flex" }
const _hoisted_32 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_33 = { style: {"color":"#fff"} }
const _hoisted_34 = { class: "w-full flex" }
const _hoisted_35 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_36 = {
  class: "mt-4",
  style: {"font-size":"20px"}
}
const _hoisted_37 = { key: 0 }
const _hoisted_38 = {
  class: "opt6",
  style: {"margin-top":"8px","color":"#fff"}
}
const _hoisted_39 = ["src"]
const _hoisted_40 = { class: "w-full flex justify-center" }

import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import {
  transformDate,
  transformDate2,
  getGroupListByFactory,
  getRealFactoryIdList,
  getGroupName
} from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import DownLoadComponent from "../components/downLoad.vue";
import { Dayjs } from "dayjs";
import { langList } from "../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import DiagnosticReportDetails from "../report4/common/DiagnosticReportDetails2.vue";
import { getMemberOnboarding } from "../../common/tools";
import { highlightStep, getPopover } from "../../common/registdriver";

export default /*@__PURE__*/_defineComponent({
  __name: 'warning-list',
  setup(__props) {

const router = useRouter();
const getLang = () => {
  language.value = localStorage.getItem("language") || "Chinese";
  langObj.value = langList[language.value];
};
let typeList = [
  { label: "智能诊断", value: 1 },
  // {label: '维修记录', value: 2},
  // {label: '设备检查', value: 3},
  { label: "门限报警", value: 4 },
];
let langObj: any = ref({});
let language: any = ref("Chinese");
getLang();

let conditionList = [
  { label: "健康", value: 0, color: "#61c08f" },
  { label: "可用", value: 1, color: "#d2de49" },
  { label: "可用", value: 2, color: "#d2de49" },
  { label: "警戒", value: 3, color: "#cd5f3b" },
  { label: "警戒", value: 4, color: "#cd5f3b" },
  { label: "警戒", value: 5, color: "#cd5f3b" },
  { label: "故障", value: 6, color: "#921e37" },
  { label: "故障", value: 7, color: "#921e37" },
];
let conditionList2 = [
  { label: "健康", value: 0, color: "#61c08f" },
  { label: "可用上", value: 1, color: "#d2de49" },
  { label: "可用下", value: 2, color: "#d2de49" },
  { label: "警戒上", value: 3, color: "#cd5f3b" },
  { label: "警戒中", value: 4, color: "#cd5f3b" },
  { label: "警戒下", value: 5, color: "#cd5f3b" },
  { label: "故障上", value: 6, color: "#921e37" },
  { label: "故障下", value: 7, color: "#921e37" },
];
let conditionLists = [
  { label: "健康", value: 1, real: [0] },
  { label: "可用", value: 2, real: [1, 2] },
  { label: "警戒", value: 3, real: [3, 4, 5] },
  { label: "故障", value: 4, real: [6, 7] },
];
let conditionAdd = [
  { label: "健康", value: 0 },
  { label: "可用", value: 1 },
  { label: "警戒", value: 3 },
  { label: "故障", value: 6 },
];
let statusList = [
  { label: "未处理", value: 1 },
  { label: "已处理", value: 2 },
  { label: "已关闭", value: 3 },
];
let expertCertificationList = [
  { value: null, label: "不限" },
  {
    value: true,
    label: "是",
  },
  { value: false, label: "否" },
];
let warningColumns = [
  {
    title: langObj.value["报警时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["报警类型"],
    dataIndex: "type",
  },
  {
    title: langObj.value["严重程度"],
    dataIndex: "condition",
  },
  {
    title: langObj.value["设备"],
    dataIndex: "machineName",
  },
  {
    title: langObj.value["报警内容"],
    dataIndex: "diagnosticNotes",
  },
  {
    title: langObj.value["联系人"],
    dataIndex: "contactName",
  },
  {
    title: langObj.value["处理状态"],
    dataIndex: "handleStatus",
  },
  {
    title: langObj.value["是否专家认证"],
    dataIndex: "expertCertification",
  },

  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];
const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));
let data: any = ref([]);
let menuSelect: any = ref(1);
let searchModel: any = ref({
  groupId: null,
  machineId: null,
  date: null,
  skip: 1,
  take: 20,
  condition: [],
  handleStatus: null,
  expertCertification: null,
});
let selectedRowKeys: any = ref();
let groupList: any = ref([]);
let machineList: any = ref([]);
let visibledel = ref(false);
let formClose: any = ref({
  closeCause: "",
});
let isEditable: any = ref(false);
let isAdmin: any = ref(false);
let editVisible: any = ref(false);
let editForm: any = ref({});

onMounted(() => {
  isEditable.value = true;
  getAdmin();
  getCount();
  getGroupList();
  search();
  getLang();

  setTimeout(() => {
    //新注册用户，未经过引导的需弹窗进行引导
    if (!getMemberOnboarding()) {
      highlightStep(getPopover(".alarmHandle", "7/9 报警处理", "针对智能诊断报警，可点击关闭报警，提交检修报告闭环报警。若对报警的严重程度有疑问可点击报警反馈。", "", "right", "start", nextRoute, null));
    }
  }, 200)

});

const getAdmin = () => {
  let memberInfo = JSON.parse(localStorage.getItem("memberInfo") || "{}");
  isAdmin.value = memberInfo?.admin;
};

let counts: any = ref({
  count0: 0,
  count1: 0,
  count2: 0,
  count3: 0,
});
// 获取计数统计
const getCount = async () => {
  let config: any = {
    params: {
      factoryIds: getRealFactoryIdList(),
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get("/api/workflows/count/pc", config);
  counts.value = result.data;
};

// 获取区域列表
const getGroupList = () => {
  groupList.value = getGroupListByFactory();
};

// 选择区域
const selectGroup = (ev: any) => {
  let memberInfo = JSON.parse(localStorage.getItem("memberInfo") || "");
  searchModel.value.machineId = "";
  if (ev) {
    machineList.value = memberInfo.machines.filter(
      (p: any) => p.groupId === ev
    );
  } else {
    machineList.value = [];
  }
};

const search = () => {
  searchModel.value.skip = 1;
  getData();
};

const reset = () => {
  searchModel.value = {
    ...searchModel.value,
    groupId: null,
    machineId: null,
    date: null,
    type: null,
    condition: null,
  };
};
let visibleAdd: any = ref(false);
//**图片上传* */
let fileList = ref<any>([]);
const previewVisible = ref(false);
const previewImage = ref("");
const previewTitle = ref("");
const handleCancel = () => {
  previewVisible.value = false;
  previewTitle.value = "";
};

const handlePreview = async (file: any) => {
  previewImage.value = file.url;
  previewVisible.value = true;
  previewTitle.value = file.url;
};

let customRequest = async (options: any) => {
  const { onSuccess, onError, file } = options;
  let formData = new FormData();
  console.log('file', file)
  console.log('formData', formData)
  formData.append("file", file); // file为要上传的文件
  const config = {
    headers: {
      requestId: uuidv4(),
      "Content-Type": "multipart/form-data",
    },
  };
  let res = await request.post("/api/upload", formData, config);
  if (res) {
    file.url = res.data;
    onSuccess({ url: file.url, status: "done" });
  }
};

let form: any = ref({
  value: "",
  begin: null,
  end: null,
  type: 2,
  machineName: "",
  machineId: "",
  groupId: "",
  groupName: "",
  factoryId: "",
  condition: null,
  faultCause: "",
  list: [],

});
const addRepair = (machineInfo: any) => {
  machineInfo.groupName = getGroupName(machineInfo.groupId);
  form.value.groupName = machineInfo.groupName;
  form.value.machineName = machineInfo.machineName;
  form.value.machineId = machineInfo.machineId;
  form.value.groupId = machineInfo.groupId;
  form.value.factoryId = machineInfo.factoryId;
  form.value.list = [{
    date: machineInfo.date,
    condition: machineInfo.condition,
    diagnosticNotes: machineInfo.diagnosticNotes,
    id: machineInfo.id,
  }]
  form.value.maintainPerson = JSON.parse(
    localStorage.getItem("memberInfo") || ""
  )?.nickName;
  // nohandleList.value = [];
  visibleAdd.value = true;

};
const submitAdd = async () => {
  let submit = JSON.parse(JSON.stringify(form.value));
  if (!submit.begin) {
    message.warning(langObj.value["请选择开始时间"]);
    return;
  } else {
    submit.begin = transformDate(submit.begin);
  }

  if (!submit.end) {
    message.warning(langObj.value["请选择结束时间"]);
    return;
  } else {
    submit.end = transformDate(submit.end);
  }

  if (!submit.maintainPerson) {
    message.warning(langObj.value["请填写检修人员"]);
    return;
  }

  if (!submit.condition && submit.condition !== 0) {
    message.warning(langObj.value["请选择现场设备状态"]);
    return;
  }

  if (!submit.faultCause) {
    message.warning(langObj.value["请填写故障原因"]);
    return;
  }

  if (!submit.maintainMessage) {
    message.warning(langObj.value["请填写检修内容"]);
    return;
  }



  if (fileList.value && fileList.value.length) {
    submit.pictures = fileList.value.map((p: any) => ({
      url: p.url,
    }));
  } else {
    submit.pictures = [];
  }


  let data: any = {
    repairReport: submit,
    date: submit.end,
    machineIds: [submit.machineId],
    factoryId: submit.factoryId,
    pictures: submit.pictures,
    type: 2,
    workflow: true,
  };

  let result = await request.post("/api/reports", data);

  if (result && result.status) {
    message.success(langObj.value["新增成功"]);
    visibleAdd.value = false;
    // getOrderData();
  } else {
    message.warning(langObj.value["操作失败"] + "!");
  }
};
const getData = async () => {
  let config: any = {
    params: {
      skip: searchModel.value.skip,
      take: searchModel.value.take,
      groupId: searchModel.value.groupId,
      machineId: searchModel.value.machineId,
      type: searchModel.value.type,
      handleStatus: searchModel.value.handleStatus,
      expertCertification: searchModel.value.expertCertification,
      factoryIds: getRealFactoryIdList(),
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  if (!searchModel.value.type) {
    config.params.typeList = [1, 4];
  }

  if (searchModel.value.date && searchModel.value.date.length === 2) {
    config.params.begin = transformDate2(
      new Date(searchModel.value.date[0]),
      1
    );
    config.params.end = transformDate2(new Date(searchModel.value.date[1]), 2);
  }

  if (searchModel.value.condition) {
    config.params.conditionList = conditionLists.find(
      (p: any) => p.value === searchModel.value.condition
    )?.real;
  }

  let result = await request.get("/api/workflows", config);

  if (result) {
    data.value = result.data.list;
    searchModel.value.total = result.data.total;
  }
};

const handleTableChange = (pagination: any, filters: any, sorter: any) => {
  // 处理分页数据
  if (Object.keys(sorter).length) {
    var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
    searchModel.value.sort = sorter.field;
    searchModel.value.order = order;
  }
  // 处理分页数据
  searchModel.value.skip = pagination.current;
  searchModel.value.take = pagination.pageSize;
  getData();
};

// 编辑工单
const edit = (ev: any) => {
  editForm.value = {
    id: ev.id,
    diagnosticNotes: ev.diagnosticNotes,
    condition: ev.condition,
  };
  editVisible.value = true;
};

// 提交编辑
const submitEdit = async () => {
  let result = await request.put("/api/workflows", editForm.value);
  if (result?.data) {
    message.success("操作成功");
    editVisible.value = false;
    getData();
  }
};

//复选框选择
const onSelectChange = (ev: any) => {
  selectedRowKeys.value = ev;
};

// 关闭警报
const closeWarning = () => {
  if (!selectedRowKeys.value) {
    message.warning(langObj.value["请选择报警"]);
    return;
  }

  let list = data.value.filter((p: any) =>
    selectedRowKeys.value.includes(p.id)
  );

  for (let d of list) {
    if (d.handleStatus && (d.handleStatus === 2 || d.handleStatus === 3)) {
      message.warning(langObj.value["请选择未处理的报警"]);
      return;
    }
  }

  formClose.value.closeCause = "";
  visibledel.value = true;
};

const submitClose = async () => {
  if (!formClose.value.closeCause) {
    message.warning(langObj.value["请填写关闭原因"]);
    return;
  }

  let form = {
    closeCause: formClose.value.closeCause,
    list: [],
  };

  let list = data.value.filter((p: any) =>
    selectedRowKeys.value.includes(p.id)
  );

  if (list && list.length) {
    form.list = list.map((d: any) => ({
      date: d.date,
      condition: d.condition,
      diagnosticNotes: d.diagnosticNotes,
      id: d.id,
    }));
  }

  let result = await request.post("/api/workflows/close", form);
  if (result && result.status == 200) {
    message.success(langObj.value["关闭成功"]);
    visibledel.value = false;
    getData();
  } else {
    message.warning(langObj.value["操作失败"]);
  }
};

const selectMenu = (ev: any, value: any) => {
  menuSelect.value = ev;
  searchModel.value.handleStatus = value;
  searchModel.value.skip = 1;
  getData();
};

// 查看已关闭
let visibledel2 = ref(false);
const viewClose = (ev: any) => {
  formClose.value.closeCause = ev.closeCause;
  visibledel2.value = true;
};

//查看报告
let visibleDR: any = ref(false);
let reportDRId: any = ref("");
let mrDref: any = ref();
const viewReport = (ev: any) => {
  if (ev.reportIds.length) {
    visibleDR.value = true;
    reportDRId.value = ev.reportIds[0];
    setTimeout(() => {
      mrDref.value.show(reportDRId.value);
    }, 500);
  } else {
    message.warning("暂无报告");
  }
  // router.push('/report/list')
};

const cancelDR = () => {
  visibleDR.value = false;
};

const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return index % 2 === 0 ? "even-row" : "odd-row";
};

// 下载报警
let downLoadVisible = ref(false);
let downLoadList: any = ref([]);
const downLoad = async () => {
  let config: any = {
    params: {
      skip: 1,
      take: 100000,
      groupId: searchModel.value.groupId,
      machineId: searchModel.value.machineId,
      type: searchModel.value.type,
      handleStatus: searchModel.value.handleStatus,
      expertCertification: searchModel.value.expertCertification,
      factoryIds: getRealFactoryIdList(),
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  if (!searchModel.value.type) {
    config.params.typeList = [1, 4];
  }
  if (searchModel.value.date && searchModel.value.date.length === 2) {
    config.params.begin = transformDate2(
      new Date(searchModel.value.date[0]),
      1
    );
    config.params.end = transformDate2(new Date(searchModel.value.date[1]), 2);
  }

  if (searchModel.value.condition) {
    config.params.conditionList = conditionLists.find(
      (p: any) => p.value === searchModel.value.condition
    )?.real;
  }

  let result = await request.get("/api/workflows", config);
  let list: any = [];
  if (result) {
    list = result.data.list;
  }
  downLoadList.value = list;
  downLoadVisible.value = true;
};

const downLoadCancel = () => {
  downLoadVisible.value = false;
};

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], "days") > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, "days") > 30;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};

//新用户引导报警处理下一步按钮回调
const nextRoute = () => {
  router.push("/warning-manage/push-rule");
  return;
};

return (_ctx: any,_cache: any) => {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_drawer = _resolveComponent("a-drawer")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _unref(enUS) : _unref(zhCN)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                class: _normalizeClass(["py-3 cursor-pointer", { 'txt-select': _unref(menuSelect) === 1 }]),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (selectMenu(1, null)))
              }, _toDisplayString(_unref(langObj)["全部"]) + "(" + _toDisplayString(_unref(counts).count0) + ") ", 3),
              _createElementVNode("div", {
                class: _normalizeClass(["py-3 cursor-pointer ml-24", { 'txt-select': _unref(menuSelect) === 2 }]),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (selectMenu(2, 1)))
              }, _toDisplayString(_unref(langObj)["未处理"]) + "(" + _toDisplayString(_unref(counts).count1) + ") ", 3),
              _createElementVNode("div", {
                class: _normalizeClass(["py-3 cursor-pointer ml-24", { 'txt-select': _unref(menuSelect) === 3 }]),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (selectMenu(3, 2)))
              }, _toDisplayString(_unref(langObj)["已处理"]) + "(" + _toDisplayString(_unref(counts).count2) + ") ", 3),
              _createElementVNode("div", {
                class: _normalizeClass(["py-3 cursor-pointer ml-24", { 'txt-select': _unref(menuSelect) === 4 }]),
                onClick: _cache[3] || (_cache[3] = ($event: any) => (selectMenu(4, 3)))
              }, _toDisplayString(_unref(langObj)["已关闭"]) + "(" + _toDisplayString(_unref(counts).count3) + ") ", 3)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_a_form, {
                model: _unref(searchModel),
                name: "horizontal_login",
                layout: "inline",
                autocomplete: "off"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _unref(langObj)['设备区域'],
                    name: "groupId"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select, {
                        value: _unref(searchModel).groupId,
                        "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(searchModel).groupId) = $event)),
                        style: {"width":"220px"},
                        placeholder: _unref(langObj)['请选择设备区域'],
                        onChange: selectGroup
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList), (item, index) => {
                            return (_openBlock(), _createBlock(_component_a_select_option, {
                              key: index,
                              value: item.id
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.name), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["value", "placeholder"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_a_form_item, {
                    label: _unref(langObj)['设备'],
                    name: "status"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select, {
                        value: _unref(searchModel).machineId,
                        "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_unref(searchModel).machineId) = $event)),
                        style: {"width":"220px"},
                        placeholder: _unref(langObj)['请选择设备'],
                        allowClear: ""
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(machineList), (item, index) => {
                            return (_openBlock(), _createBlock(_component_a_select_option, {
                              key: index,
                              value: item.id
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.name), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["value", "placeholder"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_a_form_item, {
                    label: _unref(langObj)['报警时间'],
                    name: "status"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_range_picker, {
                        value: _unref(searchModel).date,
                        "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_unref(searchModel).date) = $event)),
                        "disabled-date": disabledDate,
                        onCalendarChange: onCalendarChange
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_a_form_item, {
                    label: _unref(langObj)['报警类型'],
                    name: "status"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select, {
                        value: _unref(searchModel).type,
                        "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_unref(searchModel).type) = $event)),
                        style: {"width":"220px"},
                        allowClear: "",
                        placeholder: _unref(langObj)['请选择报警类型']
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(typeList), (item, index) => {
                            return (_openBlock(), _createBlock(_component_a_select_option, {
                              key: index,
                              value: item.value
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(langObj)[item.label]), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["value", "placeholder"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_a_form_item, {
                    label: _unref(langObj)['严重程度'],
                    name: "status"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select, {
                        value: _unref(searchModel).condition,
                        "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_unref(searchModel).condition) = $event)),
                        style: {"width":"220px"},
                        allowClear: "",
                        placeholder: _unref(langObj)['请选择严重程度']
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(conditionLists), (item, index) => {
                            return (_openBlock(), _createBlock(_component_a_select_option, {
                              key: index,
                              value: item.value
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(langObj)[item.label]), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["value", "placeholder"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_a_form_item, {
                    label: _unref(langObj)['是否专家认证'],
                    name: "status"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select, {
                        value: _unref(searchModel).expertCertification,
                        "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_unref(searchModel).expertCertification) = $event)),
                        style: {"width":"220px"},
                        allowClear: "",
                        placeholder: _unref(langObj)['是否专家认证']
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(expertCertificationList), (item, index) => {
                            return (_openBlock(), _createBlock(_component_a_select_option, {
                              key: index,
                              value: item.value
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(langObj)[item.label]), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["value", "placeholder"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_a_form_item, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", {
                          onClick: reset,
                          class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-reset"
                        }, _toDisplayString(_unref(langObj)["重置"]), 1),
                        _createElementVNode("div", {
                          onClick: search,
                          class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                          style: {"background-color":"#072499"}
                        }, _toDisplayString(_unref(langObj)["查询"]), 1),
                        _createElementVNode("div", {
                          onClick: downLoad,
                          class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                          style: {"background-color":"#072499"}
                        }, _toDisplayString(_unref(langObj)["下载报警"]), 1),
                        (_unref(isEditable))
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              onClick: closeWarning,
                              class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-submit alarmHandle",
                              style: {"background-color":"#072499"}
                            }, _toDisplayString(_unref(langObj)["关闭报警"]), 1))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_a_table, {
                style: {"width":"100%"},
                size: "small",
                "row-class-name": getRowClassName,
                columns: _unref(warningColumns),
                "data-source": _unref(data),
                "row-selection": {
                selectedRowKeys: _unref(selectedRowKeys),
                onChange: onSelectChange,
              },
                pagination: pagination.value,
                onChange: handleTableChange,
                rowKey: "id"
              }, {
                bodyCell: _withCtx(({ column, text, record }) => [
                  (column.dataIndex === 'type')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        (text === 1)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[33] || (_cache[33] = [
                              _createElementVNode("img", {
                                style: {"width":"20px"},
                                src: _imports_0,
                                alt: ""
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        (text === 2)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[34] || (_cache[34] = [
                              _createElementVNode("img", {
                                style: {"width":"20px"},
                                src: _imports_1,
                                alt: ""
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        (text === 3)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[35] || (_cache[35] = [
                              _createElementVNode("img", {
                                style: {"width":"20px"},
                                src: _imports_2,
                                alt: ""
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        (text === 4)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_13, _cache[36] || (_cache[36] = [
                              _createElementVNode("img", {
                                style: {"width":"20px"},
                                src: _imports_3,
                                alt: ""
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_14, _toDisplayString(_unref(langObj)[_unref(typeList).find((p) => p.value === text)?.label]), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'expertCertification')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(text ? _unref(langObj)["是"] : _unref(langObj)["否"]), 1)
                      ], 64))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'condition')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                        _createElementVNode("div", {
                          style: _normalizeStyle([{"width":"12px","height":"12px","border-radius":"50%"}, {
                      background: _unref(conditionList).find((p) => p.value === text)
                        ?.color,
                    }])
                        }, null, 4),
                        _createElementVNode("div", _hoisted_16, _toDisplayString(_unref(langObj)[
                        _unref(conditionList).find((p) => p.value === text)?.label
                        ]), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'handleStatus')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                        (text === 3)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              onClick: ($event: any) => (viewClose(record))
                            }, [
                              _createElementVNode("a", null, _toDisplayString(_unref(langObj)["已关闭"]), 1)
                            ], 8, _hoisted_17))
                          : _createCommentVNode("", true),
                        (text !== 3)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(_unref(statusList).find((p) => p.value === text)
                        ? _unref(langObj)[
                        _unref(statusList).find((p) => p.value === text)?.label
                        ]
                        : _unref(langObj)["未处理"]), 1))
                          : _createCommentVNode("", true)
                      ], 64))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'act')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                        (_unref(isAdmin) && record.type == 1)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              onClick: ($event: any) => (edit(record))
                            }, "编辑", 8, _hoisted_19))
                          : _createCommentVNode("", true),
                        (_unref(isAdmin) && record.type == 1)
                          ? (_openBlock(), _createBlock(_component_a_divider, {
                              key: 1,
                              type: "vertical"
                            }))
                          : _createCommentVNode("", true),
                        (record.reportIds && record.reportIds.length)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 2,
                              onClick: ($event: any) => (viewReport(record))
                            }, _toDisplayString(_unref(langObj)["查看报告"]), 9, _hoisted_20))
                          : _createCommentVNode("", true),
                        (!record.handleStatus || record.handleStatus==1)
                          ? (_openBlock(), _createBlock(_component_a_divider, {
                              key: 3,
                              type: "vertical"
                            }))
                          : _createCommentVNode("", true),
                        (!record.handleStatus || record.handleStatus==1)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 4,
                              onClick: ($event: any) => (addRepair(record))
                            }, _toDisplayString(_unref(langObj)["添加检修记录"]), 9, _hoisted_21))
                          : _createCommentVNode("", true)
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["columns", "data-source", "row-selection", "pagination"])
            ])
          ])
        ]),
        _createVNode(_component_a_modal, {
          visible: _unref(editVisible),
          "onUpdate:visible": _cache[13] || (_cache[13] = ($event: any) => (_isRef(editVisible) ? (editVisible).value = $event : editVisible = $event)),
          closable: false,
          footer: null,
          bodyStyle: { background: '#020F46' },
          style: {"top":"30%"},
          width: "1200px",
          maskClosable: false
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _cache[37] || (_cache[37] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                _createElementVNode("div", {
                  class: "cursor-pointer",
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_isRef(editVisible) //@ts-ignore
 ? editVisible.value = false : editVisible = false))
                }, [
                  _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
                ])
              ]),
              _createElementVNode("div", _hoisted_24, _toDisplayString(_unref(langObj)["编辑"]), 1),
              _createVNode(_component_a_form, {
                ref: "formRef",
                name: "advanced_search",
                class: "ant-advanced-search-form",
                style: {"margin-top":"20px"},
                model: _unref(editForm)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    name: "condition",
                    label: '稳定状态',
                    required: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select, {
                        value: _unref(editForm).condition,
                        "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_unref(editForm).condition) = $event))
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(conditionList2), (item, index) => {
                            return (_openBlock(), _createBlock(_component_a_select_option, {
                              key: index,
                              value: item.value
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.label), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["value"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_form_item, {
                    name: "diagnosticNotes",
                    label: '报警内容',
                    required: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_textarea, {
                        value: _unref(editForm).diagnosticNotes,
                        "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_unref(editForm).diagnosticNotes) = $event)),
                        placeholder: "",
                        rows: 4
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model"]),
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("div", {
                  onClick: submitEdit,
                  style: {"background-color":"#072499","padding":"5px 10px","width":"100px","font-size":"20px","text-align":"center","cursor":"pointer"},
                  class: "btn-submit"
                }, _toDisplayString(_unref(langObj)["提交"]), 1)
              ])
            ])
          ]),
          _: 1
        }, 8, ["visible"]),
        _createVNode(_component_a_modal, {
          visible: _unref(visibledel),
          "onUpdate:visible": _cache[16] || (_cache[16] = ($event: any) => (_isRef(visibledel) ? (visibledel).value = $event : visibledel = $event)),
          closable: false,
          footer: null,
          bodyStyle: { background: '#020F46' },
          style: {"top":"30%"},
          width: "1200px",
          maskClosable: false
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_26, [
              _createElementVNode("div", _hoisted_27, [
                _cache[38] || (_cache[38] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                _createElementVNode("div", {
                  class: "cursor-pointer",
                  onClick: _cache[14] || (_cache[14] = ($event: any) => (_isRef(visibledel) //@ts-ignore
 ? visibledel.value = false : visibledel = false))
                }, [
                  _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
                ])
              ]),
              _createElementVNode("div", _hoisted_28, _toDisplayString(_unref(langObj)["关闭报警"]), 1),
              _createVNode(_component_a_form, {
                ref: "formRef",
                name: "advanced_search",
                class: "ant-advanced-search-form",
                style: {"margin-top":"20px"},
                model: _unref(formClose)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_row, { gutter: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            name: "closeCause",
                            label: _unref(langObj)['关闭原因'],
                            required: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_textarea, {
                                value: _unref(formClose).closeCause,
                                "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_unref(formClose).closeCause) = $event)),
                                placeholder: "",
                                rows: 4
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model"]),
              _createElementVNode("div", _hoisted_29, [
                _createElementVNode("div", {
                  onClick: submitClose,
                  style: {"background-color":"#072499","padding":"5px 10px","width":"100px","font-size":"20px","text-align":"center","cursor":"pointer"},
                  class: "btn-submit"
                }, _toDisplayString(_unref(langObj)["提交"]), 1)
              ])
            ])
          ]),
          _: 1
        }, 8, ["visible"]),
        _createVNode(_component_a_modal, {
          visible: _unref(visibledel2),
          "onUpdate:visible": _cache[19] || (_cache[19] = ($event: any) => (_isRef(visibledel2) ? (visibledel2).value = $event : visibledel2 = $event)),
          closable: false,
          footer: null,
          bodyStyle: { background: '#020F46' },
          style: {"top":"30%"},
          width: "1200px",
          maskClosable: false
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("div", _hoisted_31, [
                _cache[39] || (_cache[39] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                _createElementVNode("div", {
                  class: "cursor-pointer",
                  onClick: _cache[17] || (_cache[17] = ($event: any) => (_isRef(visibledel2) //@ts-ignore
 ? visibledel2.value = false : visibledel2 = false))
                }, [
                  _createVNode(_component_close_outlined, { style: {"font-size":"20px","color":"#fff !important"} })
                ])
              ]),
              _createElementVNode("div", _hoisted_32, _toDisplayString(_unref(langObj)["关闭报警"]), 1),
              _createVNode(_component_a_form, {
                ref: "formRef",
                name: "advanced_search",
                class: "ant-advanced-search-form",
                style: {"margin-top":"20px"},
                model: _unref(formClose)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_row, { gutter: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            name: "closeCause",
                            label: "关闭原因",
                            required: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_textarea, {
                                value: _unref(formClose).closeCause,
                                "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_unref(formClose).closeCause) = $event)),
                                placeholder: "",
                                rows: 4,
                                readonly: ""
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model"])
            ])
          ]),
          _: 1
        }, 8, ["visible"]),
        _createVNode(_component_a_modal, {
          width: 1400,
          visible: _unref(downLoadVisible),
          "onUpdate:visible": _cache[20] || (_cache[20] = ($event: any) => (_isRef(downLoadVisible) ? (downLoadVisible).value = $event : downLoadVisible = $event)),
          title: _unref(langObj)['下载'],
          maskClosable: false,
          footer: false
        }, {
          default: _withCtx(() => [
            _createVNode(DownLoadComponent, {
              onDownLoadCancel: downLoadCancel,
              type: 2,
              dataList: _unref(downLoadList)
            }, null, 8, ["dataList"])
          ]),
          _: 1
        }, 8, ["visible", "title"]),
        _createVNode(_component_a_modal, {
          visible: _unref(visibleAdd),
          "onUpdate:visible": _cache[31] || (_cache[31] = ($event: any) => (_isRef(visibleAdd) ? (visibleAdd).value = $event : visibleAdd = $event)),
          closable: false,
          footer: null,
          bodyStyle: { background: '#020F46' },
          style: {"top":"15%"},
          width: "1200px",
          maskClosable: false
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_33, [
              _createElementVNode("div", _hoisted_34, [
                _cache[40] || (_cache[40] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                _createElementVNode("div", {
                  class: "cursor-pointer",
                  onClick: _cache[21] || (_cache[21] = ($event: any) => (_isRef(visibleAdd) //@ts-ignore
 ? visibleAdd.value = false : visibleAdd = false))
                }, [
                  _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
                ])
              ]),
              _createElementVNode("div", _hoisted_35, _toDisplayString(_unref(langObj)['添加检修记录']), 1),
              _createElementVNode("div", _hoisted_36, _toDisplayString(_unref(langObj)['维修详情']), 1),
              _createVNode(_component_a_form, {
                ref: "formRef",
                name: "advanced_search",
                class: "ant-advanced-search-form",
                model: _unref(form),
                "label-col": { style: { width: '180px' } }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_row, { gutter: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            name: "begin",
                            label: _unref(langObj)['开始时间'],
                            rules: [{ required: true, message: _unref(langObj)['请选择开始时间'] }],
                            allowClear: false
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_date_picker, {
                                value: _unref(form).begin,
                                "onUpdate:value": _cache[22] || (_cache[22] = ($event: any) => ((_unref(form).begin) = $event)),
                                "show-time": "",
                                placeholder: _unref(langObj)['请选择时间']
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          }, 8, ["label", "rules"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            name: "end",
                            label: _unref(langObj)['结束时间'],
                            rules: [{ required: true, message: _unref(langObj)['请选择结束时间'] }],
                            allowClear: false
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_date_picker, {
                                value: _unref(form).end,
                                "onUpdate:value": _cache[23] || (_cache[23] = ($event: any) => ((_unref(form).end) = $event)),
                                "show-time": "",
                                placeholder: _unref(langObj)['请选择时间']
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          }, 8, ["label", "rules"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            name: "value",
                            label: _unref(langObj)['设备名称'],
                            rules: [{ required: true }]
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _unref(form).machineName,
                                "onUpdate:value": _cache[24] || (_cache[24] = ($event: any) => ((_unref(form).machineName) = $event)),
                                style: {"width":"210px"},
                                readonly: ""
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            name: "value",
                            label: _unref(langObj)['设备区域'],
                            rules: [{ required: true }]
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _unref(form).groupName,
                                "onUpdate:value": _cache[25] || (_cache[25] = ($event: any) => ((_unref(form).groupName) = $event)),
                                style: {"width":"210px"},
                                readonly: ""
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            name: "maintainPerson",
                            label: _unref(langObj)['检修人员'],
                            rules: [{ required: true }]
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _unref(form).maintainPerson,
                                "onUpdate:value": _cache[26] || (_cache[26] = ($event: any) => ((_unref(form).maintainPerson) = $event)),
                                style: {"width":"210px"}
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            name: "condition",
                            label: _unref(langObj)['现场设备状态'],
                            rules: [{ required: true }]
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_select, {
                                value: _unref(form).condition,
                                "onUpdate:value": _cache[27] || (_cache[27] = ($event: any) => ((_unref(form).condition) = $event)),
                                style: {"width":"210px"},
                                placeholder: _unref(langObj)['请选择现场设备状态'],
                                allowClear: ""
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(conditionAdd), (item, index) => {
                                    return (_openBlock(), _createBlock(_component_a_select_option, {
                                      key: index,
                                      value: item.value
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_unref(langObj)[item.label]), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            name: "faultCause",
                            label: _unref(langObj)['故障原因'],
                            rules: [{ required: true }]
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_textarea, {
                                value: _unref(form).faultCause,
                                "onUpdate:value": _cache[28] || (_cache[28] = ($event: any) => ((_unref(form).faultCause) = $event)),
                                placeholder: _unref(langObj)['请输入故障原因'],
                                rows: 4
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            name: "maintainMessage",
                            label: _unref(langObj)['检修内容'],
                            rules: [{ required: true }]
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_textarea, {
                                value: _unref(form).maintainMessage,
                                "onUpdate:value": _cache[29] || (_cache[29] = ($event: any) => ((_unref(form).maintainMessage) = $event)),
                                placeholder: _unref(langObj)['请输入检修内容'],
                                rows: 4
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            style: {"margin-left":"10px"},
                            name: "value",
                            label: _unref(langObj)['检修图片']
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_upload, {
                                "file-list": _unref(fileList),
                                "onUpdate:fileList": _cache[30] || (_cache[30] = ($event: any) => (_isRef(fileList) ? (fileList).value = $event : fileList = $event)),
                                "list-type": "picture-card",
                                onPreview: handlePreview,
                                "custom-request": _unref(customRequest),
                                "auto-upload": false,
                                accept: "image",
                                "max-count": 9
                              }, {
                                default: _withCtx(() => [
                                  (!_unref(fileList) || _unref(fileList).length < 9)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                                        _createVNode(_component_plus_outlined, {
                                          class: "opt6",
                                          style: {"color":"#fff"}
                                        }),
                                        _createElementVNode("div", _hoisted_38, _toDisplayString(_unref(langObj)['上传']), 1)
                                      ]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              }, 8, ["file-list", "custom-request"]),
                              _createVNode(_component_a_modal, {
                                visible: previewVisible.value,
                                title: previewTitle.value,
                                footer: null,
                                onCancel: handleCancel
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("img", {
                                    alt: "example",
                                    style: {"width":"100%"},
                                    src: previewImage.value
                                  }, null, 8, _hoisted_39)
                                ]),
                                _: 1
                              }, 8, ["visible", "title"])
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model"]),
              _createElementVNode("div", _hoisted_40, [
                _createElementVNode("div", {
                  onClick: submitAdd,
                  style: {"background-color":"#072499","padding":"5px 10px","width":"100px","font-size":"20px","text-align":"center","cursor":"pointer"},
                  class: "btn-submit"
                }, _toDisplayString(_unref(langObj)['提交']), 1)
              ])
            ])
          ]),
          _: 1
        }, 8, ["visible"]),
        _createVNode(_component_a_drawer, {
          visible: _unref(visibleDR),
          "onUpdate:visible": _cache[32] || (_cache[32] = ($event: any) => (_isRef(visibleDR) ? (visibleDR).value = $event : visibleDR = $event)),
          class: "custom-class",
          size: "large",
          closable: false,
          placement: "right"
        }, {
          title: _withCtx(() => _cache[41] || (_cache[41] = [])),
          default: _withCtx(() => [
            _createVNode(DiagnosticReportDetails, {
              ref_key: "mrDref",
              ref: mrDref,
              reportId: _unref(reportDRId),
              onCancel: cancelDR
            }, null, 8, ["reportId"])
          ]),
          _: 1
        }, 8, ["visible"])
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})