import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "main" }

import request from "../../common/request";
import { useRouter } from "vue-router";
import { computed, onMounted } from "vue";
import { ref, reactive, toRaw } from "vue";
import type { UploadProps } from "ant-design-vue";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { operateLogs } from "@/common/logs";

import { Dayjs } from 'dayjs';
import {getRealFactoryIdList} from "../../common/tools";

export default /*@__PURE__*/_defineComponent({
  __name: 'LoginLogs',
  setup(__props) {

const router = useRouter();
const loading = ref(false);
const formRef = ref();
let factoryId = "";
/**操作日志记录 */
operateLogs('访问操作日志');
/**操作日志记录 */
const formState = ref({
  keyWord: "",
  date: [],
  skip: 1,
  take: 15,
  total: 0,
  userId: ''
});
const pagination = computed(() => ({
  total: formState.value.total,
  current: formState.value.skip,
  pageSize: formState.value.take,
}));

const dataSource = ref([]);
const columns = [
  {
    title: "操作人",
    dataIndex: "userName",
    align: "center"
  },
  // {
  //   title: "操作路由",
  //   dataIndex: "router",
  // },
  {
    title: "操作详情",
    dataIndex: "desc",
    align: "center"
  },
  {
    title: "时间",
    dataIndex: "date",
    align: "center"
  }
];
let userList:any = ref([])
const handleTableChange = (pagination: any, filters: any, sorter: any) => {
  console.log(`当前页码：${pagination.current}，每页显示条数：${pagination.pageSize}`);
  // 处理分页数据
  formState.value.skip = pagination.current
  search();
}
// 查询
const search = () => {
  if(!formState.value.userId)  return message.warning('请选择人员后查询!')
  var params = {
    skip: formState.value.skip,
    take: formState.value.take,
    userId: formState.value.userId,
  }
  if (formState.value.date && formState.value.date.length) {
    params.begin = formState.value.date[0]
    params.end = formState.value.date[1]
  } else {
    delete params.begin
    delete params.end
  }
  const config = {
    params: params,
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/logs/login", config).then((res: any) => {
    if (res) {
      dataSource.value = res.data.list;
      formState.value.total = res.data.count
    }
  });

};

// 在组件加载完成后执行一些操作
onMounted(async () => {
  init();
});

const init = () => {
  getUserList()
}

const getUserList = async() => {
  const config = {
    params: {
      skip: 1,
      take: 99999,
      factoryIds: getRealFactoryIdList()
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result =  await request.get('/api/members', config)
 
  userList.value = result?.data?.list || []
}

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 30;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};



return (_ctx: any,_cache: any) => {
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_layout_content, { style: {"padding":"0 20px"} }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, { layout: "inline" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, { label: "时间" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_range_picker, {
                  value: formState.value.date,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((formState.value.date) = $event)),
                  style: {"width":"400px"},
                  "show-time": "",
                  format: "YYYY/MM/DD HH:mm:ss",
                  valueFormat: "YYYY/MM/DD HH:mm:ss",
                  "disabled-date": disabledDate,
                  onCalendarChange: onCalendarChange
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { label: "人员" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  value: formState.value.userId,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((formState.value.userId) = $event)),
                  style: {"width":"200px"},
                  allowClear: "",
                  showSearch: "",
                  optionFilterProp: "label"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(userList), (item, index) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: index,
                        value: item._id,
                        label: item.nickName
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.nickName), 1)
                        ]),
                        _: 2
                      }, 1032, ["value", "label"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: search
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("搜索")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_a_table, {
            bordered: "",
            emptyText: '暂无数据',
            columns: columns,
            "data-source": dataSource.value,
            pagination: pagination.value,
            loading: loading.value,
            onChange: handleTableChange,
            size: "small"
          }, null, 8, ["data-source", "pagination", "loading"])
        ])
      ]),
      _: 1
    })
  ]))
}
}

})