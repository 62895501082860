import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "pl-1" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "size20" }
const _hoisted_4 = { class: "flex-1 text-center" }
const _hoisted_5 = { class: "w-full text-center p-3" }
const _hoisted_6 = { class: "w-full flex" }
const _hoisted_7 = {
  class: "p-3 box mr-4 mb-4",
  style: {"width":"370px"}
}
const _hoisted_8 = { class: "w-full mb-2" }
const _hoisted_9 = { class: "flex w-full items-center mb-2" }
const _hoisted_10 = { class: "flex items-center" }
const _hoisted_11 = { class: "con_wrap" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "flex w-full score-box mb-10" }
const _hoisted_15 = { class: "flex w-full items-center mb-2" }
const _hoisted_16 = { class: "flex items-center" }
const _hoisted_17 = { class: "con_wrap" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { class: "flex w-full score-box mb-10" }
const _hoisted_21 = { class: "flex w-full items-center mb-2" }
const _hoisted_22 = { class: "flex items-center" }
const _hoisted_23 = {
  key: 0,
  class: "flex w-full score-box mb-10"
}
const _hoisted_24 = { class: "score-60" }
const _hoisted_25 = { class: "score-txt" }
const _hoisted_26 = { class: "score-30" }
const _hoisted_27 = { class: "score-txt" }
const _hoisted_28 = { class: "score-0" }
const _hoisted_29 = { class: "score-txt" }
const _hoisted_30 = {
  key: 1,
  class: "score-default mb-10"
}
const _hoisted_31 = { class: "score-txt" }
const _hoisted_32 = { class: "flex w-full items-center mb-2" }
const _hoisted_33 = { class: "flex items-center" }
const _hoisted_34 = {
  key: 2,
  class: "flex w-full score-box mb-10"
}
const _hoisted_35 = { class: "score-60" }
const _hoisted_36 = { class: "score-txt" }
const _hoisted_37 = { class: "score-30" }
const _hoisted_38 = { class: "score-txt" }
const _hoisted_39 = { class: "score-0" }
const _hoisted_40 = { class: "score-txt" }
const _hoisted_41 = {
  key: 3,
  class: "score-default mb-10"
}
const _hoisted_42 = { class: "score-txt" }
const _hoisted_43 = { class: "flex w-full items-center mb-2" }
const _hoisted_44 = { class: "flex items-center" }
const _hoisted_45 = {
  key: 4,
  class: "flex w-full score-box mb-10"
}
const _hoisted_46 = { class: "score-60" }
const _hoisted_47 = { class: "score-txt" }
const _hoisted_48 = { class: "score-30" }
const _hoisted_49 = { class: "score-txt" }
const _hoisted_50 = { class: "score-0" }
const _hoisted_51 = { class: "score-txt" }
const _hoisted_52 = {
  key: 5,
  class: "score-default mb-10"
}
const _hoisted_53 = { class: "score-txt" }
const _hoisted_54 = { class: "flex w-full items-center mb-2" }
const _hoisted_55 = { class: "flex items-center" }
const _hoisted_56 = {
  key: 0,
  class: "flex w-full score-box mb-10"
}
const _hoisted_57 = { class: "score-60" }
const _hoisted_58 = { class: "score-txt" }
const _hoisted_59 = { class: "score-30" }
const _hoisted_60 = { class: "score-txt" }
const _hoisted_61 = { class: "score-0" }
const _hoisted_62 = { class: "score-txt" }
const _hoisted_63 = {
  key: 1,
  class: "score-default mb-10"
}
const _hoisted_64 = { class: "score-txt" }
const _hoisted_65 = { class: "flex w-full items-center mb-2" }
const _hoisted_66 = { class: "flex items-center" }
const _hoisted_67 = { class: "con_wrap" }
const _hoisted_68 = { class: "tit_wrap" }
const _hoisted_69 = { class: "tit_con" }
const _hoisted_70 = { class: "tit_wrap" }
const _hoisted_71 = { class: "tit_con" }
const _hoisted_72 = { class: "tit_wrap" }
const _hoisted_73 = { class: "tit_con" }
const _hoisted_74 = {
  key: 0,
  class: "flex w-full score-box mb-10"
}
const _hoisted_75 = { class: "score-60" }
const _hoisted_76 = { class: "score-txt" }
const _hoisted_77 = { class: "score-30" }
const _hoisted_78 = { class: "score-txt" }
const _hoisted_79 = { class: "score-0" }
const _hoisted_80 = { class: "score-txt" }
const _hoisted_81 = {
  key: 1,
  class: "score-default mb-10"
}
const _hoisted_82 = { class: "score-txt" }
const _hoisted_83 = { class: "flex w-full items-center mb-2" }
const _hoisted_84 = { class: "flex items-center" }
const _hoisted_85 = { class: "con_wrap" }
const _hoisted_86 = { class: "tit_wrap" }
const _hoisted_87 = { class: "tit_con" }
const _hoisted_88 = { class: "tit_wrap" }
const _hoisted_89 = { class: "tit_con" }
const _hoisted_90 = { class: "tit_wrap" }
const _hoisted_91 = { class: "tit_con" }
const _hoisted_92 = {
  key: 2,
  class: "flex w-full score-box mb-10"
}
const _hoisted_93 = { class: "score-60" }
const _hoisted_94 = { class: "score-txt" }
const _hoisted_95 = { class: "score-30" }
const _hoisted_96 = { class: "score-txt" }
const _hoisted_97 = { class: "score-0" }
const _hoisted_98 = { class: "score-txt" }
const _hoisted_99 = {
  key: 3,
  class: "score-default mb-10"
}
const _hoisted_100 = { class: "score-txt" }
const _hoisted_101 = { class: "flex w-full items-center mb-2" }
const _hoisted_102 = { class: "flex items-center" }
const _hoisted_103 = { class: "con_wrap" }
const _hoisted_104 = { class: "tit_wrap" }
const _hoisted_105 = { class: "tit_con" }
const _hoisted_106 = { class: "tit_wrap" }
const _hoisted_107 = { class: "tit_con" }
const _hoisted_108 = { class: "tit_wrap" }
const _hoisted_109 = { class: "tit_con" }
const _hoisted_110 = {
  key: 4,
  class: "flex w-full score-box mb-10"
}
const _hoisted_111 = { class: "score-60" }
const _hoisted_112 = { class: "score-txt" }
const _hoisted_113 = { class: "score-30" }
const _hoisted_114 = { class: "score-txt" }
const _hoisted_115 = { class: "score-0" }
const _hoisted_116 = { class: "score-txt" }
const _hoisted_117 = {
  key: 5,
  class: "score-default mb-10"
}
const _hoisted_118 = { class: "score-txt" }
const _hoisted_119 = { class: "flex w-full items-center mb-2" }
const _hoisted_120 = { class: "flex items-center" }
const _hoisted_121 = { class: "con_wrap" }
const _hoisted_122 = { class: "tit_wrap" }
const _hoisted_123 = { class: "tit_con" }
const _hoisted_124 = { class: "tit_wrap" }
const _hoisted_125 = { class: "tit_con" }
const _hoisted_126 = { class: "tit_wrap" }
const _hoisted_127 = { class: "tit_con" }
const _hoisted_128 = {
  key: 6,
  class: "flex w-full score-box mb-10"
}
const _hoisted_129 = { class: "score-60" }
const _hoisted_130 = { class: "score-txt" }
const _hoisted_131 = { class: "score-30" }
const _hoisted_132 = { class: "score-txt" }
const _hoisted_133 = { class: "score-0" }
const _hoisted_134 = { class: "score-txt" }
const _hoisted_135 = {
  key: 7,
  class: "score-default mb-10"
}
const _hoisted_136 = { class: "score-txt" }
const _hoisted_137 = { class: "flex w-full items-center mb-2" }
const _hoisted_138 = { class: "flex items-center" }
const _hoisted_139 = { class: "con_wrap" }
const _hoisted_140 = { class: "tit_wrap" }
const _hoisted_141 = { class: "tit_con" }
const _hoisted_142 = { class: "tit_wrap" }
const _hoisted_143 = { class: "tit_con" }
const _hoisted_144 = { class: "tit_wrap" }
const _hoisted_145 = { class: "tit_con" }
const _hoisted_146 = {
  key: 8,
  class: "flex w-full score-box mb-10"
}
const _hoisted_147 = { class: "score-60" }
const _hoisted_148 = { class: "score-txt" }
const _hoisted_149 = { class: "score-30" }
const _hoisted_150 = { class: "score-txt" }
const _hoisted_151 = { class: "score-0" }
const _hoisted_152 = { class: "score-txt" }
const _hoisted_153 = {
  key: 9,
  class: "score-default mb-10"
}
const _hoisted_154 = { class: "score-txt" }
const _hoisted_155 = { class: "flex w-full items-center mb-2" }
const _hoisted_156 = { class: "flex items-center" }
const _hoisted_157 = { class: "con_wrap" }
const _hoisted_158 = { class: "tit_wrap" }
const _hoisted_159 = { class: "tit_con" }
const _hoisted_160 = { class: "tit_wrap" }
const _hoisted_161 = { class: "tit_con" }
const _hoisted_162 = { class: "tit_wrap" }
const _hoisted_163 = { class: "tit_con" }
const _hoisted_164 = {
  key: 10,
  class: "flex w-full score-box mb-10"
}
const _hoisted_165 = { class: "score-60" }
const _hoisted_166 = { class: "score-txt" }
const _hoisted_167 = { class: "score-30" }
const _hoisted_168 = { class: "score-txt" }
const _hoisted_169 = { class: "score-0" }
const _hoisted_170 = { class: "score-txt" }
const _hoisted_171 = {
  key: 11,
  class: "score-default mb-10"
}
const _hoisted_172 = { class: "score-txt" }
const _hoisted_173 = { class: "flex w-full items-center mb-2" }
const _hoisted_174 = { class: "flex items-center" }
const _hoisted_175 = {
  key: 12,
  class: "flex w-full score-box mb-10"
}
const _hoisted_176 = { class: "score-60" }
const _hoisted_177 = { class: "score-txt" }
const _hoisted_178 = { class: "score-30" }
const _hoisted_179 = { class: "score-txt" }
const _hoisted_180 = { class: "score-0" }
const _hoisted_181 = { class: "score-txt" }
const _hoisted_182 = {
  key: 13,
  class: "score-default mb-10"
}
const _hoisted_183 = { class: "score-txt" }
const _hoisted_184 = { key: 0 }
const _hoisted_185 = { class: "flex w-full items-center mb-2" }
const _hoisted_186 = { class: "flex items-center" }
const _hoisted_187 = { class: "flex w-full items-center mb-2" }
const _hoisted_188 = { class: "flex items-center" }
const _hoisted_189 = { class: "w-full mb-2 mt-2" }
const _hoisted_190 = { class: "w-full" }
const _hoisted_191 = { class: "w-full mb-2" }
const _hoisted_192 = { class: "w-full mb-2" }
const _hoisted_193 = {
  class: "flex",
  style: {"flex-wrap":"wrap"}
}
const _hoisted_194 = { class: "w-full text-center" }

import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { langList } from "../../common/lang";
import * as echarts from "echarts";
import {
  getGroupListByFactory,
  getGroupName,
} from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";

import { Dayjs } from 'dayjs';
import { getLanguage } from "../../common/translate";


export default /*@__PURE__*/_defineComponent({
  __name: 'reportInfo',
  props: {
  reportIdObj: {
    type: Object,
    default: {}
  }
},
  setup(__props) {

let conditionLists = [
  { label: "健康", value: 1, realValue: [0] },
  { label: "可用", value: 2, realValue: [1, 2] },
  { label: "警戒", value: 3, realValue: [3, 4, 5] },
  { label: "故障", value: 4, realValue: [6, 7] },
];

let typeLists = [
  { label: "基础", value: 2 },
  { label: "智能", value: 1 },
];


const router = useRouter();
let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
  theme.value = localStorage.getItem('theme') || 'blue'
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]

  conditionLists = [
    { label: langObj.value["健康"], value: 1, realValue: [0] },
    { label: langObj.value["可用"], value: 2, realValue: [1, 2] },
    { label: langObj.value["警戒"], value: 3, realValue: [3, 4, 5] },
    { label: langObj.value["故障"], value: 4, realValue: [6, 7] },
  ];
}
getLang()

let isShowRander = ref(true)  //是否在有效期


const bindPickerChange1 = (e: any) => {

  inspectionBase.value.directionIn = e;
  let zz = "Z";
  if (e == 0) {
    zz = "Z"
  } else if (e == 1) {
    zz = "X";
  } else {
    zz = "Y";
  }

  inspectionBase.value.fx_code3_v = inspectionBase.value.reportInfo?.wkstatus?.find((it: any) => it.code == 107 && it.coordinate == zz)?.value || 0;

  inspectionBase.value.fx_code3 = YZCOM(inspectionBase.value.sdD, inspectionBase.value.fx_code3_v);


};

const bindPickerChange2 = (e: any) => {

  inspectionBase.value.directionInB = e;
  let zz = "Z";
  if (e == 0) {
    zz = "Z"
    inspectionBase.value.fx_code4_v = inspectionBase.value.reportInfo?.wkstatus?.find((it: any) => it.code == 112)?.value || 0;
  } else if (e == 1) {
    zz = "X";
    inspectionBase.value.fx_code4_v = inspectionBase.value.reportInfo?.wkstatus?.find((it: any) => it.code == 101 && it.coordinate == zz)?.value || 0;
  } else {
    zz = "Y";
    inspectionBase.value.fx_code4_v = inspectionBase.value.reportInfo?.wkstatus?.find((it: any) => it.code == 101 && it.coordinate == zz)?.value || 0;
  }



  inspectionBase.value.fx_code4 = YZCOM(inspectionBase.value.jsdzD, inspectionBase.value.fx_code4_v);


};
const bindPickerChangeF = (e: any) => {

  inspectionBase.value.directionInF = e;
  let zz = "Z";
  if (e == 0) {
    zz = "Z"
  } else if (e == 1) {
    zz = "X";
  } else {
    zz = "Y";
  }

  inspectionBase.value.fx_code6_v = inspectionBase.value.reportInfo?.wkstatus?.find((it: any) => it.code == 308 && it.coordinate == zz)?.value || 0;

  inspectionBase.value.fx_code6 = YZCOM(inspectionBase.value.ffz, inspectionBase.value.fx_code6_v);


};



const bindPickerChange3 = (e: any) => {



  inspectionBase.value.directionInC = e;
  let url = "";

  if (e == 0) {
    url = inspectionBase.value.reportInfo.waves?.find((tt: any) => tt.coordinate == "Z")?.url
  } else if (e == 1) {
    url = inspectionBase.value.reportInfo.waves?.find((tt: any) => tt.coordinate == "X")?.url
  } else if (e == 2) {
    url = inspectionBase.value.reportInfo.waves?.find((tt: any) => tt.coordinate == "Y")?.url
  }
  if (document.getElementById("chartB")) {
    let myChart = echarts.init(document.getElementById("chartB"));
    myChart && myChart.clear()
  }
  if (!url) {



    return false
  }

  getFftTime(url);


};


const bindPickerChange4 = (e: any) => {

  inspectionBase.value.directionInD = e;
  let url = "";

  if (e == 0) {
    url = inspectionBase.value.reportInfo.waves?.find((tt: any) => tt.coordinate == "Z")?.url
  } else if (e == 1) {
    url = inspectionBase.value.reportInfo.waves?.find((tt: any) => tt.coordinate == "X")?.url
  } else if (e == 2) {
    url = inspectionBase.value.reportInfo.waves?.find((tt: any) => tt.coordinate == "Y")?.url
  }
  if (document.getElementById("chartC")) {
    let myChart = echarts.init(document.getElementById("chartC"));
    myChart && myChart.clear()
  }

  if (!url) {

    return false
  }

  getFft(url);


};

let inspectionBase: any = ref({
  reportInfo: {},
  isShowFFTTIME: false,
  isShowFFZ: false,
  reportInfoAll: {
    type: 2,
  },
  sensorId: "",
  machineInfo: {},
  fx_code1: 0,
  fx_code1_dis1: 0,
  fx_code2: 0,
  fx_code1_dis2: 0,
  fx_code3: 0,
  fx_code3_1: 0,
  fx_code3_2: 0,
  fx_code4: 0,
  fx_code4_1: 0,
  fx_code4_2: 0,
  fx_code5: 0,
  fx_code6: 0,
  fx_code3_v: 0,
  fx_code3_v1: 0,
  fx_code3_v2: 0,
  fx_code4_v: 0,
  fx_code4_v1: 0,
  fx_code4_v2: 0,
  fx_code5_v: 0,
  fx_code6_v: 0,
  jsdzD: {},
  sdD: {},
  tempD: {},
  ffz: {},
  directionA: ["z轴", "x轴", "y轴"],
  directionIn: 0,
  directionInB: 0,
  directionInC: 0,
  directionInD: 0,
  directionInF: 0,
  fx_code4_v_x: 0,
  fx_code4_v_y: 0,
  fx_code3_xy: 0,
  fx_code3_v_y: 0,
  resultTIME: {},
  resultFFT: {},
  listT: [],
  reportType: 1
})
let props = __props

let reportIdObj: any = ref({})
watch(
  () => props.reportIdObj,
  (newValue, oldValue) => {
    reportIdObj.value = props.reportIdObj

    getSensorList()

  }
);
let conditionList = [
  { label: "健康", value: 0, color: "#61c08f" },
  { label: "可用", value: 1, color: "#d2de49" },
  { label: "可用", value: 2, color: "#d2de49" },
  { label: "警戒", value: 3, color: "#cd5f3b" },
  { label: "警戒", value: 4, color: "#cd5f3b" },
  { label: "警戒", value: 5, color: "#cd5f3b" },
  { label: "故障", value: 6, color: "#921e37" },
  { label: "故障", value: 7, color: "#921e37" },
];






const stateColumns = [
  {
    title: langObj.value["故障模式"],
    dataIndex: "text",
  },
  {
    title: langObj.value["分数"],
    dataIndex: "score",
  },

];


let groupList: any = ref([]);


onMounted(() => {

  // isEditable.value = true
  //   loadDatatype();

  //   getGroupList();
  //   search();
  
  inspectionBase.value.isShowFFZ =  localStorage.getItem('isShowFFZ') || false
  console.log(1111)

});

const clearData = () => {
  if (document.getElementById("chartA")) {
    let myChart = echarts.init(document.getElementById("chartA"));
    myChart && myChart.clear()
  }
  if (document.getElementById("chartB")) {
    let myChart = echarts.init(document.getElementById("chartB"));
    myChart && myChart.clear()
  }
  if (document.getElementById("chartC")) {
    let myChart = echarts.init(document.getElementById("chartC"));
    myChart && myChart.clear()
  }


  inspectionBase.value = {
    ...inspectionBase.value,
    reportInfo: {},
    // machineInfo: {},
    fx_code1: 0,
    fx_code1_dis1: 0,
    fx_code2: 0,
    fx_code1_dis2: 0,
    fx_code3: 0,
    fx_code3_1: 0,
    fx_code3_2: 0,
    fx_code4: 0,
    fx_code4_1: 0,
    fx_code4_2: 0,
    fx_code5: 0,
    fx_code6: 0,
    fx_code3_v: 0,
    fx_code3_v1: 0,
    fx_code3_v2: 0,
    fx_code4_v: 0,
    fx_code4_v1: 0,
    fx_code4_v2: 0,
    fx_code5_v: 0,
    fx_code6_v: 0,
    jsdzD: {},
    sdD: {},
    tempD: {},
    ffz: {},
    directionA: ["z轴", "x轴", "y轴"],
    directionIn: 0,
    directionInB: 0,
    directionInC: 0,
    directionInD: 0,
    directionInF: 0,
    fx_code4_v_x: 0,
    fx_code4_v_y: 0,
    fx_code3_xy: 0,
    fx_code3_v_y: 0,
    resultTIME: {},
    resultFFT: {},
    listT: [],


  }
}



const getReportInfo = (id: any) => {

  clearData()
  
  inspectionBase.value.reportInfoAll = { type: 2 }


  if (!id) {
    return false;
  }

  const config: any = {
    params: {
      id: id,

    },
    headers: {
      requestId: uuidv4(),
    },

  };
  request.get("/api/external/inspection/info", config).then((res: any) => {
    if (res) {


      inspectionBase.value.reportInfoAll = res.data;


      if (sensorList.value.length == 1) {
        infoFun(res.data);
      } else {
        let res2 = res.data.inspectionInfoList?.find((tt: any) => tt.sensorId == inspectionBase.value.sensorId)
        infoFun(res2)
      }


    }
  });
};


const infoFun = (res: any) => {
  inspectionBase.value.reportInfo = res;
  inspectionBase.value.directionIn = 0;
  inspectionBase.value.directionInB = 0;
  inspectionBase.value.directionInF = 0;
  let url = inspectionBase.value.reportInfo?.waves?.find((v: any) => v.coordinate == "Z")?.url
  getFaultName(inspectionBase.value.reportInfo)
  getSCoreV()
  if (!url) {
    inspectionBase.value.isShowFFTTIME = false
    inspectionBase.value.resultTIME = [];
    initChartBC(inspectionBase.value.resultTIME, 'chartB', 's', 'm/s²')
    inspectionBase.value.resultFFT = [];
    initChartBC(inspectionBase.value.resultFFT, 'chartC', 'hz', 'm/s²');
    return false
  }
  inspectionBase.value.isShowFFTTIME = true

  getFftTime(url);
  getFft(url)
}


const getFftTime = (url: any) => {

  const config: any = {
    params: {
      url: [url],

    },

    headers: {
      requestId: uuidv4(),
    },

  };
  request.post("/draw/api/time", { url: [url] }).then((res: any) => {
    if (res && res.code == 200) {
      let axisX = res?.data?.axisX || []
      if (axisX.length > 0) {
        inspectionBase.value.isShowFFTTIME = true
      } else {
        inspectionBase.value.isShowFFTTIME = false
      }


      inspectionBase.value.resultTIME = res.data;
      initChartBC(inspectionBase.value.resultTIME, 'chartB', 's', 'm/s²')

    } else {
      inspectionBase.value.isShowFFTTIME = false
    }
  });
};
const getFft = async (url: any) => {


  const config: any = {
    params: {
      url: [url],
    },

    headers: {
      requestId: uuidv4(),
    },
  };
  request.post("/draw/api/fft", { url: [url] }).then((res: any) => {
    if (res && res.code == 200) {
      let axisX = res?.data?.axisX || []
      if (axisX.length > 0) {
        inspectionBase.value.isShowFFTTIME = true
      } else {
        inspectionBase.value.isShowFFTTIME = false
      }
      inspectionBase.value.resultFFT = res.data;
      initChartBC(inspectionBase.value.resultFFT, 'chartC', 'hz', 'm/s²')
    } else {
      inspectionBase.value.isShowFFTTIME = false
    }
  });
};


// 获取区域列表
const getGroupList = () => {
  groupList.value = getGroupListByFactory();
};
const sensorList: any = ref([])
//获取测点数据
const getSensorList = async () => {


  inspectionBase.value.sensorId = ""
  sensorList.value = [];
  let senSearch = {
    params: { machineId: reportIdObj.value.machineId },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get('/api/sensors', senSearch)

  let sensorList1 = result?.data || []
  if (reportIdObj.value?.sensorIds) {

    reportIdObj.value?.sensorIds?.map((s: any) => {
      sensorList.value.push(sensorList1.find((tt: any) => tt.id == s));
    })
  }
  if (reportIdObj.value?.sensorId) {

    sensorList.value.push(sensorList1.find((tt: any) => tt.id == reportIdObj.value?.sensorId));
  }


  if (sensorList.value.length > 0) {
    let validityEnd = sensorList.value[0]?.validityEnd
    if (validityEnd && new Date(validityEnd).getTime() > new Date().getTime()) {
      isShowRander.value = true
    } else {
      isShowRander.value = false
    }
    inspectionBase.value.sensorId = sensorList.value[0].id
  } else {
    isShowRander.value = true
  }
  getReportInfo(reportIdObj.value.id)



}

const handleChangeS = (e: any) => {

  if (e) {
    clearData()
    inspectionBase.value.sensorId = e;
    let res = inspectionBase.value.reportInfoAll?.inspectionInfoList?.find((tt: any) => tt.sensorId == inspectionBase.value.sensorId)

    infoFun(res)
  }
}



// 获取报告健康状态
const getReportCondition = (ev: any) => {
  if (ev && ev.condition) {
    return conditionList.find(
      (p: any) => p.value === ev.condition
    );
  } else {
    return conditionList[0];
  }
};




const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return index % 2 === 0 ? "even-row" : "odd-row";
};










const handlePreview = async (file: any) => {
  previewImage.value = file.url;
  previewVisible.value = true;
  previewTitle.value = "";
};






const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 60;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 60;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};

const getDis = (fx_code: any) => {
  if (fx_code == 100) {
    return 93
  } else if (fx_code == 0) {
    return 0
  } else {
    return fx_code - 4;
  }
};
const getSCoreV = () => {
  inspectionBase.value.fx_code1 = inspectionBase.value.reportInfo?.wkstatus?.filter((it: any) => it.code == 301)[0] ? inspectionBase.value.reportInfo?.wkstatus
    .filter((it: any) => it.code == 301)[0].value : 0;
  inspectionBase.value.fx_code1_dis1 = getDis(inspectionBase.value.fx_code1);
  inspectionBase.value.fx_code2 = inspectionBase.value.reportInfo?.wkstatus?.filter((it: any) => it.code == 302)[0] ? inspectionBase.value.reportInfo?.wkstatus
    .filter((it: any) => it.code == 302)[0].value : 0;
  inspectionBase.value.fx_code1_dis2 = getDis(inspectionBase.value.fx_code2)
  let code3_val = inspectionBase.value?.reportInfo?.wkstatus?.find((it: any) => it.code == 107 && it.coordinate == "Z")?.value || 0;
  inspectionBase.value.fx_code3_v = code3_val;
  let code3_val1 = inspectionBase.value?.reportInfo?.wkstatus?.find((it: any) => it.code == 107 && it.coordinate == "X")?.value || 0;
  inspectionBase.value.fx_code3_v1 = code3_val1;
  let code3_val2 = inspectionBase.value?.reportInfo?.wkstatus?.find((it: any) => it.code == 107 && it.coordinate == "Y")?.value || 0;
  inspectionBase.value.fx_code3_v2 = code3_val2;
  //112 z轴 高频 101 xy轴 低频
  let code4_val = inspectionBase.value?.reportInfo?.wkstatus?.find((it: any) => it.code == 112)?.value || 0;
  inspectionBase.value.fx_code4_v = code4_val;
  let code4_val1 = inspectionBase.value?.reportInfo?.wkstatus?.find((it: any) => it.code == 101 && it.coordinate == 'X')?.value || 0;
  inspectionBase.value.fx_code4_v1 = code4_val1;
  let code4_val2 = inspectionBase.value?.reportInfo?.wkstatus?.find((it: any) => it.code == 101 && it.coordinate == 'Y')?.value || 0;
  inspectionBase.value.fx_code4_v2 = code4_val2;
  let code5_val = inspectionBase.value.reportInfo?.wkstatus?.filter((it: any) => it.code == 100)[0] ? inspectionBase.value.reportInfo?.wkstatus
    .filter((it: any) => it.code == 100)[0].value : 0;
  inspectionBase.value.fx_code5_v = code5_val;

  let code6_val = inspectionBase.value.reportInfo?.wkstatus?.filter((it: any) => it.code == 308)[0] ? inspectionBase.value.reportInfo?.wkstatus
    .filter((it: any) => it.code == 308)[0].value : 0;
  inspectionBase.value.fx_code6_v = code6_val;

  let jsdz = null,
    sd = null,
    ffz = null,
    temp = null;
  if (inspectionBase.value.reportInfo?.threshold) {
    jsdz = inspectionBase.value.reportInfo?.threshold?.find((p: any) => p.code == 112) || {};
    inspectionBase.value.jsdzD = jsdz;
    sd = inspectionBase.value.reportInfo?.threshold?.find((item: any) => item.code == 107) || {};
    inspectionBase.value.sdD = sd;
    temp = inspectionBase.value.reportInfo?.threshold?.find((item: any) => item.code == 100) || {};
    inspectionBase.value.tempD = temp;
    ffz = inspectionBase.value.reportInfo?.threshold?.find((item: any) => item.code == 308) || {};
    inspectionBase.value.ffz = ffz;
    inspectionBase.value.fx_code3 = YZCOM(sd, code3_val);
    inspectionBase.value.fx_code3_1 = YZCOM(sd, code3_val1);
    inspectionBase.value.fx_code3_2 = YZCOM(sd, code3_val2);
    inspectionBase.value.fx_code4 = YZCOM(jsdz, code4_val);
    inspectionBase.value.fx_code4_1 = YZCOM(jsdz, code4_val1);
    inspectionBase.value.fx_code4_2 = YZCOM(jsdz, code4_val2);
    inspectionBase.value.fx_code5 = YZCOM(temp, code5_val);
    inspectionBase.value.fx_code6 = YZCOM(ffz, code6_val);
  }
};

const getFaultName = (info: any) => {
  if (!info?.machineId) {
    return false
  }
  const config: any = {
    params: {
      machineId: info.machineId
    },
    headers: {
      requestId: uuidv4(),
    },

  };
  request.get("/api/machines/faultnames", config).then((res: any) => {
    if (res) {
      // inspectionBase.value.faultName = res.data.faultNames;
      // inspectionBase.value.faultNameII = res.data.faultNameExpects;
      inspectionBase.value.reportInfo.vibrationLabel = {};
      inspectionBase.value.reportInfo.healthLabel = {};

      if (res.data?.faultNameExpects.length > 0) {
        res.data.faultNameExpects.map((d: any) => {
          inspectionBase.value.reportInfo.healthLabel[d.column] = d.title
        })
        getRandarData()
      }
      if (!res.data.faultNameExpects && info.inspectionSubId || res.data.faultNameExpects?.length == 0 && info.inspectionSubId) {
        getFaultName2(info.inspectionSubId)
      }





    }
  });
};

const getFaultName2 = (id: any) => {
  const config: any = {
    params: {
      id: id
    },
    headers: {
      requestId: uuidv4(),
    },

  };
  request.get("/api/external/inspection/faultNames", config).then((res: any) => {
    if (res) {
      // inspectionBase.value.faultName = res.data.faultNames;
      // inspectionBase.value.faultNameII = res.data.faultNameExpects;
      inspectionBase.value.reportInfo.vibrationLabel = {};
      inspectionBase.value.reportInfo.healthLabel = {};

      if (res.data.faultNameExpects) {
        res.data.faultNameExpects.map((d: any) => {
          inspectionBase.value.reportInfo.healthLabel[d.column] = d.title
        })
        getRandarData()
      }


      // getRandarData()


    }
  });
};


const getRandarData = () => {
  inspectionBase.value.listT = []
  let statusInfo = inspectionBase.value.reportInfo;
  let healthLabel = statusInfo.healthLabel;

  let scoreList = statusInfo.inspectionScoreFinalPartsExpertFit;

  // let changeCondition = 1;

  let listS = [];
  let Slist = [];
  let Tlist = [];

  // 处理数据
  // for (let index in scoreList2) {
  // 	const item = scoreList2[index];
  // 	if (index.startsWith('S')) {
  // 		Slist.push({
  // 			quota: index,
  // 			score: item,
  // 			text: vibrationLabel[index] || '',
  // 		})
  // 	}

  // }


  for (let index in scoreList) {
    const item2 = scoreList[index];
    if (index.includes('T')) {
      Tlist.push({
        quota: index,
        score: item2,
        text: healthLabel[index] || '',
      })
    }

  }

  inspectionBase.value.listT = Tlist;
  if (Tlist.length > 0) {

    initChart(Tlist, 'chartA')

  }


};


// 雷达图绘制
const initChart = (dataList: any, id: any) => {
  if (!document.getElementById("chartA")) return
  let myChart = echarts.init(document.getElementById("chartA") as HTMLDivElement);

  // if (!dataList.length) return

  let data: any = []
  let error: any = []
  let indicator: any = []

  dataList.map((d: any) => {
    data.push(d.score)
    error.push(60)
    let color = ''
    if (d.score >= 0 && d.score < 60) {
      color = '#CD5F3B'
    }
    if (d.score >= 60 && d.score < 80) {
      color = '#D2DE49'
    }
    if (d.score >= 80 && d.score <= 100) {
      color = '#A6A6A6'
    }

    indicator.push({
      color,
      max: 100,
      text: d.text.length > 4 ? (d.text.substring(0, 4) + '\n' + d.text.substring(4, d
        .text.length)) : d.text,
    })
  })


  const option = {
    // grid: {
    // 	right: '19%',
    // 	left: '10%',
    // 	top: '10%',
    // 	bottom: '0%'
    // },
    title: {
      text: ''
    },
    tooltip: {
      // position: ['20%', '0%']
      confine: true
    },
    radar: [{
      indicator: indicator,
      center: ['50%', '50%'],
      radius: 70,
      nameGap: 1,
      startAngle: 90,
      splitNumber: 5,
      name: {
        formatter: '{value}',
        textStyle: {
          color: 'rgba(46, 214, 157, 1)',
          fontSize: 13
        }
      },
      splitArea: {
        areaStyle: {
          color: [
            '#605969',
            '#453A47',
          ]
        }
      },
      axisLine: {
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.1)'
        }
      },
      splitLine: {
        lineStyle: {
          color: [
            'rgb(241,243,250)'
          ],
          width: 0
        }
      }
    }],
    series: [{
      name: '当前值',
      type: 'radar',
      emphasis: {
        lineStyle: {
          width: 4
        }
      },
      symbolSize: 2, // 拐点的大小
      data: [{
        value: data,
        lineStyle: {
          width: 1,
          color: 'rgba(247,220,225,0.1)',
          normal: {
            type: 'dotted'
          }
        },
        itemStyle: {
          color: '#FF4E13',
          borderWidth: 5
        },
        areaStyle: {
          color: '#F7DCE1'
        }
      }]
    },
    {
      name: '预警值',
      type: 'radar',
      emphasis: {
        lineStyle: {
          width: 1
        }
      },
      symbolSize: 2, // 拐点的大小
      data: [{
        value: error,
        name: '',
        lineStyle: {
          width: 2,
          color: 'rgba(255, 25, 59, 0.2)'
        },
        itemStyle: {
          color: 'rgba(255, 25, 59, 0.2)',
          borderWidth: 0
        }
      }]
    }
    ]
  }
  myChart.setOption(option)

};





const initChartBC = (data: any, id: any, xUnit: any, yUnit: any) => {
  if (!document.getElementById(id)) return
  let myChart = echarts.init(document.getElementById(id) as HTMLDivElement);
  myChart.clear()

  if (!data) {

    return false
  }

  let axisX = data?.axisX?.map((tt: any) => Number(tt.toFixed(4))) || [];
  let axisY = data?.axisY?.map((tt: any) => Number(tt.toFixed(4))) || [];

  let options = {
    color: theme.value == 'white' ? ["#274CE0"] : ["#00FFF4"],
    grid: {
      left: "4%",
      top: '5%',
      right: "10%",
      containLabel: true
    },

    tooltip: {
      trigger: 'axis',
      // position: ['10%', '0%'],
      axisPointer: {
        type: 'cross'
      }
    },
    toolbox: {
      trigger: 'axis'
    },
    dataZoom: [{
      id: "dataZoomX",
      type: "slider",
      xAxisIndex: [0],
      filterMode: 'filter',
      realtime: false, //拖动时，是否实时更新系列的视图
    },
    { type: "inside" }
    ],
    xAxis: {
      name: xUnit,
      type: 'category',

      axisTick: {
        alignWithLabel: true
      },
      axisLabel: {
        showMaxLabel: true,
        rotate: 30
      },
      splitLine: {
        show: true,
        lineStyle: {
          opacity: 0.3
        }
      },
      data: axisX
    },
    yAxis: {
      name: yUnit,
      type: 'value',
      axisLabel: {
        width: '60', // 将内容的宽度固定
        overflow: 'truncate', // 超出的部分截断
        truncate: '...', // 截断的部分用...代替
      }
    },
    series: [{
      data: axisY,
      type: 'line',
      // sampling: 'average', //降采样策略
    }]
  };
  myChart.setOption(options);


};


const YZCOM = (obj: any, value: any) => {
  if (!value) {
    return 0
  }
  if (!obj) {
    return {}
  }
  if (!obj.threshold_80) {
    return false
  }
  if (!obj.threshold_60) {
    return false
  }
  if (!obj.threshold_30) {
    return false
  }
  if (value > 0 && value <= obj.threshold_80) {
    let a = obj.threshold_80 - 0;
    if ((value / a) * 20 >= 18) {
      return (value / a) * 20 - 7
    } else {
      return (value / a) * 20
    }

  }
  if (value > obj.threshold_80 && value <= obj.threshold_60) {
    let a = obj.threshold_60 - obj.threshold_80;
    let b = value - obj.threshold_80
    if ((b / a) * 20 >= 18) {
      return (b / a) * 20 + 20 - 7
    } else {
      return (b / a) * 20 + 20
    }

  }
  if (value > obj.threshold_60 && value <= obj.threshold_30) {
    let a = obj.threshold_30 - obj.threshold_60;
    let b = value - obj.threshold_60
    if ((b / a) * 30 >= 25) {
      return (b / a) * 30 + 40 - 7
    } else {
      return (b / a) * 30 + 40
    }

  }
  if (value > obj.threshold_30) {
    return 70 + generateRandomNumber()
  }
};

const getTextZc = (score: any) => {
  // 轴承因子分数在85分以上    无异常，85分以下   有异常
  if (inspectionBase.value.reportInfo?.sensorModel) {
    if (inspectionBase.value.reportInfo.sensorModel == 21) {
      if (!score) {
        return 0 + "分"
      }
      if (score >= 85) {
        return "健康"
      } else {
        return "异常"
      }
    } else {
      return score + "分"
    }
  } else {
    return "--"
  }



};
const getTextCj = (score: any) => {
  if (inspectionBase.value.reportInfo?.sensorModel) {

    if (inspectionBase.value.reportInfo.sensorModel == 21) {
      if (!score) {
        return 0 + "分"
      }

      if (score >= 82) {
        return "健康"
      } else {
        return "异常"
      }
    } else {
      return score + "分"
    }
  } else {
    return "--"
  }


};



const generateRandomNumber = () => {
  return Math.floor(Math.random() * 23); // 生成 0 到 23 之间的随机整数
};
const getColorFxZc = (score: any) => {
  if (!score) {
    return "#1CC48B"
  }
  let color = '#1CC48B'
  if (score >= 85) {
    color = '#1CC48B'
  } else {
    color = '#B81212'
  }
  return color
};
const getColorFxCj = (score: any) => {
  if (!score) {
    return "#1CC48B"
  }
  let color = '#1CC48B'
  if (score >= 82) {
    color = '#1CC48B'
  } else {
    color = '#B81212'
  }
  return color
};

const getColor = (score: any) => {
  let color = '#1CC48B'
  if (score >= 80 && score <= 100) {
    color = '#1CC48B'
  }
  if (score >= 60 && score < 80) {
    color = '#DDD246'
  }
  if (score >= 30 && score < 60) {
    color = '#CB6A34'
  }
  if (score >= 0 && score < 30) {
    color = '#B81212'
  }

  return color
};
const getColorLeft = (score: any) => {

  if (!score) {
    return '#1CC48B'
  }
  let color = '#1CC48B'
  if (score >= 70 && score <= 100) {
    color = '#B81212'
  }
  if (score >= 40 && score < 70) {
    color = '#CB6A34'
  }
  if (score >= 20 && score < 40) {
    color = '#DDD246'
  }
  if (score >= 0 && score < 20) {
    color = '#1CC48B'
  }

  return color
};






return (_ctx: any,_cache: any) => {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_QuestionCircleOutlined = _resolveComponent("QuestionCircleOutlined")!
  const _component_a_popover = _resolveComponent("a-popover")!
  const _component_a_image = _resolveComponent("a-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(inspectionBase).reportInfoAll.type == 1 ? _unref(langObj)["智能诊断报告"] : _unref(langObj)["基础巡检报告"]), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, _toDisplayString(_unref(inspectionBase).reportInfoAll.machineName), 1),
        _createElementVNode("div", null, _toDisplayString(_unref(getGroupName)(_unref(reportIdObj)?.machine?.groupId)), 1)
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_a_select, {
          ref: "select",
          value: _unref(inspectionBase).sensorId,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(inspectionBase).sensorId) = $event)),
          style: {"min-width":"150px"},
          onChange: handleChangeS
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sensorList.value, (item, index) => {
              return (_openBlock(), _createBlock(_component_a_select_option, {
                key: index,
                value: item.id
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.sensorPlace), 1)
                ]),
                _: 2
              }, 1032, ["value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["value"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createTextVNode(_toDisplayString(_unref(langObj)['巡检状态']) + "：", 1),
      _createElementVNode("text", {
        style: _normalizeStyle({ color: getReportCondition(_unref(inspectionBase).reportInfo)?.color })
      }, _toDisplayString(_unref(langObj)[getReportCondition(_unref(inspectionBase).reportInfo)?.label]), 5)
    ]),
    _withDirectives(_createElementVNode("div", { class: "w-full" }, _toDisplayString(_unref(langObj)['健康指标']), 513), [
      [_vShow, _unref(inspectionBase).reportInfoAll.type == 1 && _unref(inspectionBase).listT.length > 0 && _unref(isShowRander)]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_6, [
      _cache[6] || (_cache[6] = _createElementVNode("div", { style: {"width":"300px"} }, [
        _createElementVNode("div", {
          id: "chartA",
          style: {"width":"300px","height":"300px"}
        })
      ], -1)),
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_a_table, {
          style: {"width":"100%"},
          size: "small",
          columns: stateColumns,
          "data-source": _unref(inspectionBase).listT,
          pagination: false
        }, null, 8, ["data-source"])
      ])
    ], 512), [
      [_vShow, _unref(inspectionBase).reportInfoAll.type == 1 && _unref(inspectionBase).listT.length > 0 && _unref(isShowRander)]
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(langObj)['基础指标']), 1),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_10, [
            _cache[10] || (_cache[10] = _createTextVNode("FX轴承因子 ")),
            _createVNode(_component_a_popover, { title: "FX轴承因子" }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_11, [
                  (_unref(inspectionBase).value?.reportInfo?.sensorModel > 21)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[8] || (_cache[8] = [
                        _createElementVNode("div", { class: "con_wrap" }, [
                          _createElementVNode("p", { class: "tit_wrap" }, "指标释义 "),
                          _createElementVNode("p", { class: "tit_con" }, " 监测电机、水泵、风机、离心压缩机等旋转设备滚动轴承故障的指标。 "),
                          _createElementVNode("p", { class: "tit_wrap" }, "适用场景 "),
                          _createElementVNode("p", { class: "tit_con" }, " 对轴承损伤较为敏感，适用于电机、水泵、风机、离心压缩机等旋转设备的滚动轴承监测，不能在齿轮箱或其他存在强背景噪音的设备上使用。 "),
                          _createElementVNode("p", { class: "tit_wrap" }, "故障原因 "),
                          _createElementVNode("p", { class: "tit_con" }, [
                            _createElementVNode("text", null, "1.设备存在滚动轴承异常、部件碰摩等故障"),
                            _createElementVNode("text", null, "2.设备存在工况导致的异常冲击"),
                            _createElementVNode("text", null, "3.设备存在轴承润滑不良等故障")
                          ]),
                          _createElementVNode("div", { class: "grid_wrap" }, [
                            _createElementVNode("div", { class: "grid_item" }, [
                              _createElementVNode("div", null, "分数"),
                              _createElementVNode("div", null, "建议客户操作")
                            ]),
                            _createElementVNode("div", { class: "grid_item" }, [
                              _createElementVNode("div", null, "80分以上"),
                              _createElementVNode("div", null, "无需操作")
                            ]),
                            _createElementVNode("div", { class: "grid_item" }, [
                              _createElementVNode("div", null, "70-80分"),
                              _createElementVNode("div", null, "关注设备分数变化情况")
                            ]),
                            _createElementVNode("div", { class: "grid_item" }, [
                              _createElementVNode("div", null, "60-70分"),
                              _createElementVNode("div", null, "注意设备运行时是否存在异响，定期改善润滑，关注设备状态及分数变化情况")
                            ]),
                            _createElementVNode("div", { class: "grid_item" }, [
                              _createElementVNode("div", null, "40-60分"),
                              _createElementVNode("div", null, "注意设备运行时是否存在异响，密切关注设备状态及分数变化情况，并结合现场实际情况适时安排检查，根据现场检查结果更新维护计划")
                            ]),
                            _createElementVNode("div", { class: "grid_item" }, [
                              _createElementVNode("div", null, "40分以下"),
                              _createElementVNode("div", null, "及时安排检查，根据现场检查结果更新维护计划")
                            ])
                          ])
                        ], -1)
                      ])))
                    : (_openBlock(), _createElementBlock("div", _hoisted_13, _cache[9] || (_cache[9] = [
                        _createElementVNode("div", { class: "con_wrap" }, [
                          _createElementVNode("p", { class: "tit_wrap" }, "指标释义 "),
                          _createElementVNode("p", { class: "tit_con" }, " 监测电机、水泵、风机、离心压缩机等旋转设备滚动轴承故障的指标。 "),
                          _createElementVNode("p", { class: "tit_wrap" }, "适用场景 "),
                          _createElementVNode("p", { class: "tit_con" }, " 对轴承损伤较为敏感，适用于电机、水泵、风机、离心压缩机等旋转设备的滚动轴承监测，不能在齿轮箱或其他存在强背景噪音的设备上使用；传感器频响较低，对早中期故障不敏感。 "),
                          _createElementVNode("p", { class: "tit_wrap" }, "故障原因 "),
                          _createElementVNode("p", { class: "tit_con" }, [
                            _createElementVNode("text", null, "1.设备存在滚动轴承异常、部件碰摩等故障"),
                            _createElementVNode("text", null, "2.设备存在工况导致的异常冲击")
                          ]),
                          _createElementVNode("div", { class: "grid_wrap" }, [
                            _createElementVNode("div", { class: "grid_item" }, [
                              _createElementVNode("div", null, "分数"),
                              _createElementVNode("div", null, "建议客户操作")
                            ]),
                            _createElementVNode("div", { class: "grid_item" }, [
                              _createElementVNode("div", null, "健康"),
                              _createElementVNode("div", null, "注意设备运行时是否存在异响")
                            ]),
                            _createElementVNode("div", { class: "grid_item" }, [
                              _createElementVNode("div", null, "异常"),
                              _createElementVNode("div", null, "注意设备运行时是否存在异响，密切关注设备状态变化情况，并结合现场实际情况适时安排检查，根据现场检查结果更新维护计划")
                            ])
                          ])
                        ], -1)
                      ])))
                ])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_QuestionCircleOutlined)
              ]),
              _: 1
            })
          ]),
          _cache[11] || (_cache[11] = _createElementVNode("div", null, "FX-Bearing Factor", -1))
        ]),
        _cache[12] || (_cache[12] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
        _createElementVNode("div", null, _toDisplayString(getTextZc(_unref(inspectionBase).fx_code1)), 1)
      ]),
      _createElementVNode("div", _hoisted_14, [
        _cache[13] || (_cache[13] = _createStaticVNode("<div class=\"score-80\" data-v-3f73c99a><div class=\"score-txt\" data-v-3f73c99a>100</div></div><div class=\"score-60\" data-v-3f73c99a><div class=\"score-txt\" data-v-3f73c99a>80</div></div><div class=\"score-30\" data-v-3f73c99a><div class=\"score-txt\" data-v-3f73c99a>60</div></div><div class=\"score-0\" data-v-3f73c99a><div class=\"score-txt\" data-v-3f73c99a>30</div></div>", 4)),
        _createElementVNode("div", {
          style: _normalizeStyle({ right: _unref(inspectionBase).fx_code1_dis1 + '%', backgroundColor: getColor(_unref(inspectionBase).fx_code1) }),
          class: "score-posi"
        }, null, 4)
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_16, [
            _cache[16] || (_cache[16] = _createTextVNode("FX冲击因子 ")),
            _createVNode(_component_a_popover, { title: "FX冲击因子" }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_17, [
                  (_unref(inspectionBase).value?.reportInfo?.sensorModel > 21)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, _cache[14] || (_cache[14] = [
                        _createElementVNode("div", { class: "con_wrap" }, [
                          _createElementVNode("p", { class: "tit_wrap" }, "指标释义 "),
                          _createElementVNode("p", { class: "tit_con" }, " 监测电机、水泵、风机、离心压缩机、齿轮箱等旋转设备周期性冲击故障的指标。 "),
                          _createElementVNode("p", { class: "tit_wrap" }, "适用场景 "),
                          _createElementVNode("p", { class: "tit_con" }, " 对周期性冲击较为感敏，适用于电机、水泵、风机、离心压缩机、齿轮箱等旋转设备的监测，不能在存在强背景噪音的设备上使用。 "),
                          _createElementVNode("p", { class: "tit_wrap" }, "故障原因 "),
                          _createElementVNode("p", { class: "tit_con" }, [
                            _createElementVNode("text", null, "1.设备存在部件碰摩、滚动轴承异常、齿轮啮合不良等规律性冲击故障"),
                            _createElementVNode("text", null, "2.设备存在工况导致的规律性冲击")
                          ]),
                          _createElementVNode("div", { class: "grid_wrap" }, [
                            _createElementVNode("div", { class: "grid_item" }, [
                              _createElementVNode("div", null, "分数"),
                              _createElementVNode("div", null, "建议客户操作")
                            ]),
                            _createElementVNode("div", { class: "grid_item" }, [
                              _createElementVNode("div", null, "80分以上"),
                              _createElementVNode("div", null, "无需操作")
                            ]),
                            _createElementVNode("div", { class: "grid_item" }, [
                              _createElementVNode("div", null, "70-80分"),
                              _createElementVNode("div", null, "关注设备分数变化情况")
                            ]),
                            _createElementVNode("div", { class: "grid_item" }, [
                              _createElementVNode("div", null, "60-70分"),
                              _createElementVNode("div", null, "注意设备运行时是否存在异响，定期改善润滑，关注设备状态及分数变化情况")
                            ]),
                            _createElementVNode("div", { class: "grid_item" }, [
                              _createElementVNode("div", null, "40-60分"),
                              _createElementVNode("div", null, "注意设备运行时是否存在异响，密切关注设备状态及分数变化情况，并结合现场实际情况适时安排检查，根据现场检查结果更新维护计划")
                            ]),
                            _createElementVNode("div", { class: "grid_item" }, [
                              _createElementVNode("div", null, "40分以下"),
                              _createElementVNode("div", null, "及时安排检查，根据现场检查结果更新维护计划")
                            ])
                          ])
                        ], -1)
                      ])))
                    : (_openBlock(), _createElementBlock("div", _hoisted_19, _cache[15] || (_cache[15] = [
                        _createElementVNode("div", { class: "con_wrap" }, [
                          _createElementVNode("p", { class: "tit_wrap" }, "指标释义 "),
                          _createElementVNode("p", { class: "tit_con" }, " 监测电机、水泵、风机、离心压缩机、齿轮箱等旋转设备周期性冲击故障的指标。 "),
                          _createElementVNode("p", { class: "tit_wrap" }, "适用场景 "),
                          _createElementVNode("p", { class: "tit_con" }, " 对周期性冲击较为感敏，适用于电机、水泵、风机、离心压缩机、齿轮箱等旋转设备的监测，不能在存在强背景噪音的设备上使用；传感器频响较低，对早中期故障不敏感。 "),
                          _createElementVNode("p", { class: "tit_wrap" }, "故障原因 "),
                          _createElementVNode("p", { class: "tit_con" }, [
                            _createElementVNode("text", null, "1.设备存在部件碰摩、滚动轴承异常、齿轮啮合不良等规律性冲击故障"),
                            _createElementVNode("text", null, "2.设备存在工况导致的规律性冲击")
                          ]),
                          _createElementVNode("div", { class: "grid_wrap" }, [
                            _createElementVNode("div", { class: "grid_item" }, [
                              _createElementVNode("div", null, "分数"),
                              _createElementVNode("div", null, "建议客户操作")
                            ]),
                            _createElementVNode("div", { class: "grid_item" }, [
                              _createElementVNode("div", null, "健康"),
                              _createElementVNode("div", null, "注意设备运行时是否存在异响")
                            ]),
                            _createElementVNode("div", { class: "grid_item" }, [
                              _createElementVNode("div", null, "异常"),
                              _createElementVNode("div", null, "注意设备运行时是否存在异响，密切关注设备状态变化情况，并结合现场实际情况适时安排检查，根据现场检查结果更新维护计划")
                            ])
                          ])
                        ], -1)
                      ])))
                ])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_QuestionCircleOutlined)
              ]),
              _: 1
            })
          ]),
          _cache[17] || (_cache[17] = _createElementVNode("div", null, "FX-Impulsive Factor", -1))
        ]),
        _cache[18] || (_cache[18] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
        _createElementVNode("div", null, _toDisplayString(getTextCj(_unref(inspectionBase).fx_code2)), 1)
      ]),
      _createElementVNode("div", _hoisted_20, [
        _cache[19] || (_cache[19] = _createStaticVNode("<div class=\"score-80\" data-v-3f73c99a><div class=\"score-txt\" data-v-3f73c99a>100</div></div><div class=\"score-60\" data-v-3f73c99a><div class=\"score-txt\" data-v-3f73c99a>80</div></div><div class=\"score-30\" data-v-3f73c99a><div class=\"score-txt\" data-v-3f73c99a>60</div></div><div class=\"score-0\" data-v-3f73c99a><div class=\"score-txt\" data-v-3f73c99a>30</div></div>", 4)),
        _createElementVNode("div", {
          style: _normalizeStyle({ right: _unref(inspectionBase).fx_code1_dis2 + '%', backgroundColor: getColor(_unref(inspectionBase).fx_code2) }),
          class: "score-posi"
        }, null, 4)
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_22, [
            _createTextVNode(_toDisplayString(_unref(langObj)['速度均方根']) + " ", 1),
            _createVNode(_component_a_popover, { title: "速度均方根" }, {
              content: _withCtx(() => _cache[20] || (_cache[20] = [
                _createElementVNode("div", { class: "con_wrap" }, [
                  _createElementVNode("p", { class: "tit_wrap" }, "指标释义 "),
                  _createElementVNode("p", { class: "tit_con" }, " 速度均方根就是指频率10-1000Hz以内物体振动速度的均方根值，又名振动速度有效值或振动烈度。 "),
                  _createElementVNode("p", { class: "tit_wrap" }, "适用场景 "),
                  _createElementVNode("p", { class: "tit_con" }, " 覆盖故障类型广，如电机、水泵、风机、齿轮箱、螺杆压缩机、汽轮机等有对应速度均方根国标的设备，其他可使用通用国标或结合经验设定阈值的设备。 "),
                  _createElementVNode("p", { class: "tit_wrap" }, "故障原因 "),
                  _createElementVNode("p", { class: "tit_con" }, [
                    _createElementVNode("text", null, "1.设备存在转子不平衡、转子不对中、机械松动等轴系故障"),
                    _createElementVNode("text", null, "2.设备存在螺杆啮合不良、齿轮啮合不良等故障"),
                    _createElementVNode("text", null, "3.设备存在轴瓦碰摩、轴瓦磨损等故障"),
                    _createElementVNode("text", null, "4.设备存在工况导致的低频振动")
                  ])
                ], -1)
              ])),
              default: _withCtx(() => [
                _createVNode(_component_QuestionCircleOutlined)
              ]),
              _: 1
            }),
            _createVNode(_component_a_select, {
              onChange: bindPickerChange1,
              style: {"margin-left":"10px"},
              value: _unref(inspectionBase).directionIn,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_unref(inspectionBase).directionIn) = $event)),
              placeholder: _unref(langObj)['请选择']
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(inspectionBase).directionA, (item, index) => {
                  return (_openBlock(), _createBlock(_component_a_select_option, {
                    key: index,
                    value: index
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["value", "placeholder"])
          ]),
          _cache[21] || (_cache[21] = _createElementVNode("div", null, null, -1))
        ]),
        _cache[22] || (_cache[22] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
        _createElementVNode("div", null, _toDisplayString(_unref(inspectionBase).fx_code3_v.toFixed(4)) + "mm/s", 1)
      ]),
      (!!_unref(inspectionBase).sdD.threshold_80)
        ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
            _cache[23] || (_cache[23] = _createElementVNode("div", { class: "score-80" }, [
              _createElementVNode("div", { class: "score-txt" }, "0")
            ], -1)),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", _hoisted_25, _toDisplayString(_unref(inspectionBase).sdD?.threshold_80 || 0), 1)
            ]),
            _createElementVNode("div", _hoisted_26, [
              _createElementVNode("div", _hoisted_27, _toDisplayString(_unref(inspectionBase).sdD?.threshold_60 || 0), 1)
            ]),
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("div", _hoisted_29, _toDisplayString(_unref(inspectionBase).sdD?.threshold_30 || 0), 1)
            ]),
            _createElementVNode("div", {
              style: _normalizeStyle({ left: _unref(inspectionBase).fx_code3 + '%', backgroundColor: getColorLeft(_unref(inspectionBase).fx_code3) }),
              class: "score-posi"
            }, null, 4)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_30, [
            _createElementVNode("div", _hoisted_31, _toDisplayString(_unref(inspectionBase).fx_code3_v.toFixed(4)), 1),
            _cache[24] || (_cache[24] = _createElementVNode("div", { class: "score-txt" }, "0", -1)),
            _cache[25] || (_cache[25] = _createElementVNode("div", { class: "score-posi" }, null, -1))
          ])),
      _createElementVNode("div", _hoisted_32, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_33, [
            _createTextVNode(_toDisplayString(_unref(langObj)['加速度均方根']) + " ", 1),
            _createVNode(_component_a_popover, { title: "加速度均方根" }, {
              content: _withCtx(() => _cache[26] || (_cache[26] = [
                _createElementVNode("div", { class: "con_wrap" }, [
                  _createElementVNode("p", { class: "tit_wrap" }, "指标释义 "),
                  _createElementVNode("p", { class: "tit_con" }, " 加速度均方根就是指1/2采样频率范围以内物体振动加速度的均方根值。 "),
                  _createElementVNode("p", { class: "tit_wrap" }, "适用场景 "),
                  _createElementVNode("p", { class: "tit_con" }, " 覆盖故障类型广，如电机、水泵、风机、压缩机、齿轮箱等旋转设备和活塞压缩机等往复式设备，需结合经验设定阈值。 "),
                  _createElementVNode("p", { class: "tit_wrap" }, "故障原因 "),
                  _createElementVNode("p", { class: "tit_con" }, [
                    _createElementVNode("text", null, "1.设备存在滚动轴承异常、部件碰摩、齿轮啮合不良、螺杆啮合不良等故障"),
                    _createElementVNode("text", null, "2.设备存在轴承润滑不良、共振、电磁振动等故障"),
                    _createElementVNode("text", null, "3.设备存在液力冲击、工况异常等故障")
                  ])
                ], -1)
              ])),
              default: _withCtx(() => [
                _createVNode(_component_QuestionCircleOutlined)
              ]),
              _: 1
            }),
            _createVNode(_component_a_select, {
              onChange: bindPickerChange2,
              style: {"margin-left":"10px"},
              value: _unref(inspectionBase).directionInB,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(inspectionBase).directionInB) = $event)),
              placeholder: _unref(langObj)['请选择']
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(inspectionBase).directionA, (item, index) => {
                  return (_openBlock(), _createBlock(_component_a_select_option, {
                    key: index,
                    value: index
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["value", "placeholder"])
          ]),
          _cache[27] || (_cache[27] = _createElementVNode("div", null, null, -1))
        ]),
        _cache[28] || (_cache[28] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
        _createElementVNode("div", null, _toDisplayString(_unref(inspectionBase).fx_code4_v.toFixed(4)) + "m/s²", 1)
      ]),
      (!!_unref(inspectionBase).jsdzD.threshold_80)
        ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
            _cache[29] || (_cache[29] = _createElementVNode("div", { class: "score-80" }, [
              _createElementVNode("div", { class: "score-txt" }, "0")
            ], -1)),
            _createElementVNode("div", _hoisted_35, [
              _createElementVNode("div", _hoisted_36, _toDisplayString(_unref(inspectionBase).jsdzD?.threshold_80 || 0), 1)
            ]),
            _createElementVNode("div", _hoisted_37, [
              _createElementVNode("div", _hoisted_38, _toDisplayString(_unref(inspectionBase).jsdzD?.threshold_60 || 0), 1)
            ]),
            _createElementVNode("div", _hoisted_39, [
              _createElementVNode("div", _hoisted_40, _toDisplayString(_unref(inspectionBase).jsdzD?.threshold_30 || 0), 1)
            ]),
            _createElementVNode("div", {
              style: _normalizeStyle({ left: _unref(inspectionBase).fx_code4 + '%', backgroundColor: getColorLeft(_unref(inspectionBase).fx_code4) }),
              class: "score-posi"
            }, null, 4)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_41, [
            _createElementVNode("div", _hoisted_42, _toDisplayString(_unref(inspectionBase).fx_code4_v.toFixed(4)), 1),
            _cache[30] || (_cache[30] = _createElementVNode("div", { class: "score-txt" }, "0", -1)),
            _cache[31] || (_cache[31] = _createElementVNode("div", { class: "score-posi" }, null, -1))
          ])),
      _createElementVNode("div", _hoisted_43, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_44, _toDisplayString(_unref(langObj)['温度']), 1),
          _cache[32] || (_cache[32] = _createElementVNode("div", null, null, -1))
        ]),
        _cache[33] || (_cache[33] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
        _createElementVNode("div", null, _toDisplayString(_unref(inspectionBase).fx_code5_v.toFixed(4)) + "℃", 1)
      ]),
      (!!_unref(inspectionBase).tempD.threshold_80)
        ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
            _cache[34] || (_cache[34] = _createElementVNode("div", { class: "score-80" }, [
              _createElementVNode("div", { class: "score-txt" }, "0")
            ], -1)),
            _createElementVNode("div", _hoisted_46, [
              _createElementVNode("div", _hoisted_47, _toDisplayString(_unref(inspectionBase).tempD?.threshold_80 || 0), 1)
            ]),
            _createElementVNode("div", _hoisted_48, [
              _createElementVNode("div", _hoisted_49, _toDisplayString(_unref(inspectionBase).tempD?.threshold_60 || 0), 1)
            ]),
            _createElementVNode("div", _hoisted_50, [
              _createElementVNode("div", _hoisted_51, _toDisplayString(_unref(inspectionBase).tempD?.threshold_30 || 0), 1)
            ]),
            _createElementVNode("div", {
              style: _normalizeStyle({ left: _unref(inspectionBase).fx_code5 + '%', backgroundColor: getColorLeft(_unref(inspectionBase).fx_code5) }),
              class: "score-posi"
            }, null, 4)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_52, [
            _createElementVNode("div", _hoisted_53, _toDisplayString(_unref(inspectionBase).fx_code5_v.toFixed(4)), 1),
            _cache[35] || (_cache[35] = _createElementVNode("div", { class: "score-txt" }, "0", -1)),
            _cache[36] || (_cache[36] = _createElementVNode("div", { class: "score-posi" }, null, -1))
          ])),
      (_unref(inspectionBase).isShowFFZ)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
            _createElementVNode("div", _hoisted_54, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_55, [
                  _createTextVNode(_toDisplayString(_unref(langObj)['位移峰峰值']) + " ", 1),
                  _createVNode(_component_a_select, {
                    onChange: bindPickerChangeF,
                    style: {"margin-left":"10px"},
                    value: _unref(inspectionBase).directionInF,
                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_unref(inspectionBase).directionInF) = $event)),
                    placeholder: _unref(langObj)['请选择']
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(inspectionBase).directionA, (item, index) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: index,
                          value: index
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value", "placeholder"])
                ]),
                _cache[37] || (_cache[37] = _createElementVNode("div", null, null, -1))
              ]),
              _cache[38] || (_cache[38] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
              _createElementVNode("div", null, _toDisplayString(_unref(inspectionBase).fx_code6_v.toFixed(4)) + "μm", 1)
            ]),
            (!!_unref(inspectionBase).ffz.threshold_80)
              ? (_openBlock(), _createElementBlock("div", _hoisted_56, [
                  _cache[39] || (_cache[39] = _createElementVNode("div", { class: "score-80" }, [
                    _createElementVNode("div", { class: "score-txt" }, "0")
                  ], -1)),
                  _createElementVNode("div", _hoisted_57, [
                    _createElementVNode("div", _hoisted_58, _toDisplayString(_unref(inspectionBase).ffz?.threshold_80 || 0), 1)
                  ]),
                  _createElementVNode("div", _hoisted_59, [
                    _createElementVNode("div", _hoisted_60, _toDisplayString(_unref(inspectionBase).ffz?.threshold_60 || 0), 1)
                  ]),
                  _createElementVNode("div", _hoisted_61, [
                    _createElementVNode("div", _hoisted_62, _toDisplayString(_unref(inspectionBase).ffz?.threshold_30 || 0), 1)
                  ]),
                  _createElementVNode("div", {
                    style: _normalizeStyle({ left: _unref(inspectionBase).fx_code6 + '%', backgroundColor: getColorLeft(_unref(inspectionBase).fx_code6) }),
                    class: "score-posi"
                  }, null, 4)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_63, [
                  _createElementVNode("div", _hoisted_64, _toDisplayString(_unref(inspectionBase).fx_code6_v.toFixed(4)), 1),
                  _cache[40] || (_cache[40] = _createElementVNode("div", { class: "score-txt" }, "0", -1)),
                  _cache[41] || (_cache[41] = _createElementVNode("div", { class: "score-posi" }, null, -1))
                ]))
          ], 64))
        : _createCommentVNode("", true)
    ], 512), [
      [_vShow, _unref(inspectionBase).reportInfoAll.type == 1]
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_65, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_66, [
            _createTextVNode(_toDisplayString(_unref(langObj)['速度均方根'] + " Z" + _unref(getLanguage)('轴', _unref(language))) + " ", 1),
            _createVNode(_component_a_popover, {
              title: _unref(getLanguage)('速度均方根', _unref(language))
            }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_67, [
                  _createElementVNode("p", _hoisted_68, _toDisplayString(_unref(getLanguage)('指标释义', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_69, _toDisplayString(_unref(getLanguage)('速度均方根就是指频率10-1000Hz以内物体振动速度的均方根值，又名振动速度有效值或振动烈度。', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_70, _toDisplayString(_unref(getLanguage)('适用场景', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_71, _toDisplayString(_unref(getLanguage)('覆盖故障类型广，如电机、水泵、风机、齿轮箱、螺杆压缩机、汽轮机等有对应速度均方根国标的设备，其他可使用通用国标或结合经验设定阈值的设备。', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_72, _toDisplayString(_unref(getLanguage)('故障原因', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_73, [
                    _createElementVNode("text", null, "1." + _toDisplayString(_unref(getLanguage)('设备存在转子不平衡、转子不对中、机械松动等轴系故障', _unref(language))), 1),
                    _createElementVNode("text", null, "2." + _toDisplayString(_unref(getLanguage)('设备存在螺杆啮合不良、齿轮啮合不良等故障', _unref(language))), 1),
                    _createElementVNode("text", null, "3." + _toDisplayString(_unref(getLanguage)('设备存在轴瓦碰摩、轴瓦磨损等故障', _unref(language))), 1),
                    _createElementVNode("text", null, "4." + _toDisplayString(_unref(getLanguage)('设备存在工况导致的低频振动', _unref(language))), 1)
                  ])
                ])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_QuestionCircleOutlined)
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _cache[42] || (_cache[42] = _createElementVNode("div", null, null, -1))
        ]),
        _cache[43] || (_cache[43] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
        _createElementVNode("div", null, _toDisplayString(_unref(inspectionBase).fx_code3_v.toFixed(4)) + "mm/s", 1)
      ]),
      (!!_unref(inspectionBase).sdD.threshold_80)
        ? (_openBlock(), _createElementBlock("div", _hoisted_74, [
            _cache[44] || (_cache[44] = _createElementVNode("div", { class: "score-80" }, [
              _createElementVNode("div", { class: "score-txt" }, "0")
            ], -1)),
            _createElementVNode("div", _hoisted_75, [
              _createElementVNode("div", _hoisted_76, _toDisplayString(_unref(inspectionBase).sdD?.threshold_80 || 0), 1)
            ]),
            _createElementVNode("div", _hoisted_77, [
              _createElementVNode("div", _hoisted_78, _toDisplayString(_unref(inspectionBase).sdD?.threshold_60 || 0), 1)
            ]),
            _createElementVNode("div", _hoisted_79, [
              _createElementVNode("div", _hoisted_80, _toDisplayString(_unref(inspectionBase).sdD?.threshold_30 || 0), 1)
            ]),
            _createElementVNode("div", {
              style: _normalizeStyle({ left: _unref(inspectionBase).fx_code3 + '%', backgroundColor: getColorLeft(_unref(inspectionBase).fx_code3) }),
              class: "score-posi"
            }, null, 4)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_81, [
            _createElementVNode("div", _hoisted_82, _toDisplayString(_unref(inspectionBase).fx_code3_v.toFixed(4)), 1),
            _cache[45] || (_cache[45] = _createElementVNode("div", { class: "score-txt" }, "0", -1)),
            _cache[46] || (_cache[46] = _createElementVNode("div", { class: "score-posi" }, null, -1))
          ])),
      _createElementVNode("div", _hoisted_83, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_84, [
            _createTextVNode(_toDisplayString(_unref(langObj)['速度均方根'] + " X" + _unref(getLanguage)('轴', _unref(language))) + " ", 1),
            _createVNode(_component_a_popover, {
              title: _unref(getLanguage)('速度均方根', _unref(language))
            }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_85, [
                  _createElementVNode("p", _hoisted_86, _toDisplayString(_unref(getLanguage)('指标释义', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_87, _toDisplayString(_unref(getLanguage)('速度均方根就是指频率10-1000Hz以内物体振动速度的均方根值，又名振动速度有效值或振动烈度。', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_88, _toDisplayString(_unref(getLanguage)('适用场景', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_89, _toDisplayString(_unref(getLanguage)('覆盖故障类型广，如电机、水泵、风机、齿轮箱、螺杆压缩机、汽轮机等有对应速度均方根国标的设备，其他可使用通用国标或结合经验设定阈值的设备。', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_90, _toDisplayString(_unref(getLanguage)('故障原因', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_91, [
                    _createElementVNode("text", null, "1." + _toDisplayString(_unref(getLanguage)('设备存在转子不平衡、转子不对中、机械松动等轴系故障', _unref(language))), 1),
                    _createElementVNode("text", null, "2." + _toDisplayString(_unref(getLanguage)('设备存在螺杆啮合不良、齿轮啮合不良等故障', _unref(language))), 1),
                    _createElementVNode("text", null, "3." + _toDisplayString(_unref(getLanguage)('设备存在轴瓦碰摩、轴瓦磨损等故障', _unref(language))), 1),
                    _createElementVNode("text", null, "4." + _toDisplayString(_unref(getLanguage)('设备存在工况导致的低频振动', _unref(language))), 1)
                  ])
                ])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_QuestionCircleOutlined)
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _cache[47] || (_cache[47] = _createElementVNode("div", null, null, -1))
        ]),
        _cache[48] || (_cache[48] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
        _createElementVNode("div", null, _toDisplayString(_unref(inspectionBase).fx_code3_v1.toFixed(4)) + "mm/s", 1)
      ]),
      (!!_unref(inspectionBase).sdD.threshold_80)
        ? (_openBlock(), _createElementBlock("div", _hoisted_92, [
            _cache[49] || (_cache[49] = _createElementVNode("div", { class: "score-80" }, [
              _createElementVNode("div", { class: "score-txt" }, "0")
            ], -1)),
            _createElementVNode("div", _hoisted_93, [
              _createElementVNode("div", _hoisted_94, _toDisplayString(_unref(inspectionBase).sdD?.threshold_80 || 0), 1)
            ]),
            _createElementVNode("div", _hoisted_95, [
              _createElementVNode("div", _hoisted_96, _toDisplayString(_unref(inspectionBase).sdD?.threshold_60 || 0), 1)
            ]),
            _createElementVNode("div", _hoisted_97, [
              _createElementVNode("div", _hoisted_98, _toDisplayString(_unref(inspectionBase).sdD?.threshold_30 || 0), 1)
            ]),
            _createElementVNode("div", {
              style: _normalizeStyle({ left: _unref(inspectionBase).fx_code3_1 + '%', backgroundColor: getColorLeft(_unref(inspectionBase).fx_code3_1) }),
              class: "score-posi"
            }, null, 4)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_99, [
            _createElementVNode("div", _hoisted_100, _toDisplayString(_unref(inspectionBase).fx_code3_v1.toFixed(4)), 1),
            _cache[50] || (_cache[50] = _createElementVNode("div", { class: "score-txt" }, "0", -1)),
            _cache[51] || (_cache[51] = _createElementVNode("div", { class: "score-posi" }, null, -1))
          ])),
      _createElementVNode("div", _hoisted_101, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_102, [
            _createTextVNode(_toDisplayString(_unref(langObj)['速度均方根'] + ' Y' + _unref(getLanguage)('轴', _unref(language))) + " ", 1),
            _createVNode(_component_a_popover, {
              title: _unref(getLanguage)('速度均方根', _unref(language))
            }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_103, [
                  _createElementVNode("p", _hoisted_104, _toDisplayString(_unref(getLanguage)('指标释义', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_105, _toDisplayString(_unref(getLanguage)('速度均方根就是指频率10-1000Hz以内物体振动速度的均方根值，又名振动速度有效值或振动烈度。', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_106, _toDisplayString(_unref(getLanguage)('适用场景', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_107, _toDisplayString(_unref(getLanguage)('覆盖故障类型广，如电机、水泵、风机、齿轮箱、螺杆压缩机、汽轮机等有对应速度均方根国标的设备，其他可使用通用国标或结合经验设定阈值的设备。', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_108, _toDisplayString(_unref(getLanguage)('故障原因', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_109, [
                    _createElementVNode("text", null, "1." + _toDisplayString(_unref(getLanguage)('设备存在转子不平衡、转子不对中、机械松动等轴系故障', _unref(language))), 1),
                    _createElementVNode("text", null, "2." + _toDisplayString(_unref(getLanguage)('设备存在螺杆啮合不良、齿轮啮合不良等故障', _unref(language))), 1),
                    _createElementVNode("text", null, "3." + _toDisplayString(_unref(getLanguage)('设备存在轴瓦碰摩、轴瓦磨损等故障', _unref(language))), 1),
                    _createElementVNode("text", null, "4." + _toDisplayString(_unref(getLanguage)('设备存在工况导致的低频振动', _unref(language))), 1)
                  ])
                ])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_QuestionCircleOutlined)
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _cache[52] || (_cache[52] = _createElementVNode("div", null, null, -1))
        ]),
        _cache[53] || (_cache[53] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
        _createElementVNode("div", null, _toDisplayString(_unref(inspectionBase).fx_code3_v2.toFixed(4)) + "mm/s", 1)
      ]),
      (!!_unref(inspectionBase).sdD.threshold_80)
        ? (_openBlock(), _createElementBlock("div", _hoisted_110, [
            _cache[54] || (_cache[54] = _createElementVNode("div", { class: "score-80" }, [
              _createElementVNode("div", { class: "score-txt" }, "0")
            ], -1)),
            _createElementVNode("div", _hoisted_111, [
              _createElementVNode("div", _hoisted_112, _toDisplayString(_unref(inspectionBase).sdD?.threshold_80 || 0), 1)
            ]),
            _createElementVNode("div", _hoisted_113, [
              _createElementVNode("div", _hoisted_114, _toDisplayString(_unref(inspectionBase).sdD?.threshold_60 || 0), 1)
            ]),
            _createElementVNode("div", _hoisted_115, [
              _createElementVNode("div", _hoisted_116, _toDisplayString(_unref(inspectionBase).sdD?.threshold_30 || 0), 1)
            ]),
            _createElementVNode("div", {
              style: _normalizeStyle({ left: _unref(inspectionBase).fx_code3_2 + '%', backgroundColor: getColorLeft(_unref(inspectionBase).fx_code3_2) }),
              class: "score-posi"
            }, null, 4)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_117, [
            _createElementVNode("div", _hoisted_118, _toDisplayString(_unref(inspectionBase).fx_code3_v2.toFixed(4)), 1),
            _cache[55] || (_cache[55] = _createElementVNode("div", { class: "score-txt" }, "0", -1)),
            _cache[56] || (_cache[56] = _createElementVNode("div", { class: "score-posi" }, null, -1))
          ])),
      _createElementVNode("div", _hoisted_119, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_120, [
            _createTextVNode(_toDisplayString(_unref(langObj)['加速度均方根'] + " Z" + _unref(getLanguage)('轴', _unref(language))) + " ", 1),
            _createVNode(_component_a_popover, { title: "加速度均方根" }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_121, [
                  _createElementVNode("p", _hoisted_122, _toDisplayString(_unref(getLanguage)('指标释义', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_123, _toDisplayString(_unref(getLanguage)('加速度均方根就是指1/2采样频率范围以内物体振动加速度的均方根值。', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_124, _toDisplayString(_unref(getLanguage)('适用场景', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_125, _toDisplayString(_unref(getLanguage)('覆盖故障类型广，如电机、水泵、风机、压缩机、齿轮箱等旋转设备和活塞压缩机等往复式设备，需结合经验设定阈值。', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_126, _toDisplayString(_unref(getLanguage)('故障原因', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_127, [
                    _createElementVNode("text", null, "1." + _toDisplayString(_unref(getLanguage)('设备存在滚动轴承异常、部件碰摩、齿轮啮合不良、螺杆啮合不良等故障', _unref(language))), 1),
                    _createElementVNode("text", null, "2." + _toDisplayString(_unref(getLanguage)('设备存在轴承润滑不良、共振、电磁振动等故障', _unref(language))), 1),
                    _createElementVNode("text", null, "3." + _toDisplayString(_unref(getLanguage)('设备存在液力冲击、工况异常等故障', _unref(language))), 1)
                  ])
                ])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_QuestionCircleOutlined)
              ]),
              _: 1
            })
          ]),
          _cache[57] || (_cache[57] = _createElementVNode("div", null, null, -1))
        ]),
        _cache[58] || (_cache[58] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
        _createElementVNode("div", null, _toDisplayString(_unref(inspectionBase).fx_code4_v.toFixed(4)) + "m/s²", 1)
      ]),
      (!!_unref(inspectionBase).jsdzD.threshold_80)
        ? (_openBlock(), _createElementBlock("div", _hoisted_128, [
            _cache[59] || (_cache[59] = _createElementVNode("div", { class: "score-80" }, [
              _createElementVNode("div", { class: "score-txt" }, "0")
            ], -1)),
            _createElementVNode("div", _hoisted_129, [
              _createElementVNode("div", _hoisted_130, _toDisplayString(_unref(inspectionBase).jsdzD?.threshold_80 || 0), 1)
            ]),
            _createElementVNode("div", _hoisted_131, [
              _createElementVNode("div", _hoisted_132, _toDisplayString(_unref(inspectionBase).jsdzD?.threshold_60 || 0), 1)
            ]),
            _createElementVNode("div", _hoisted_133, [
              _createElementVNode("div", _hoisted_134, _toDisplayString(_unref(inspectionBase).jsdzD?.threshold_30 || 0), 1)
            ]),
            _createElementVNode("div", {
              style: _normalizeStyle({ left: _unref(inspectionBase).fx_code4 + '%', backgroundColor: getColorLeft(_unref(inspectionBase).fx_code4) }),
              class: "score-posi"
            }, null, 4)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_135, [
            _createElementVNode("div", _hoisted_136, _toDisplayString(_unref(inspectionBase).fx_code4_v.toFixed(4)), 1),
            _cache[60] || (_cache[60] = _createElementVNode("div", { class: "score-txt" }, "0", -1)),
            _cache[61] || (_cache[61] = _createElementVNode("div", { class: "score-posi" }, null, -1))
          ])),
      _createElementVNode("div", _hoisted_137, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_138, [
            _createTextVNode(_toDisplayString(_unref(langObj)['加速度均方根'] + " X" + _unref(getLanguage)('轴', _unref(language))) + " ", 1),
            _createVNode(_component_a_popover, { title: "加速度均方根" }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_139, [
                  _createElementVNode("p", _hoisted_140, _toDisplayString(_unref(getLanguage)('指标释义', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_141, _toDisplayString(_unref(getLanguage)('加速度均方根就是指1/2采样频率范围以内物体振动加速度的均方根值。', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_142, _toDisplayString(_unref(getLanguage)('适用场景', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_143, _toDisplayString(_unref(getLanguage)('覆盖故障类型广，如电机、水泵、风机、压缩机、齿轮箱等旋转设备和活塞压缩机等往复式设备，需结合经验设定阈值。', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_144, _toDisplayString(_unref(getLanguage)('故障原因', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_145, [
                    _createElementVNode("text", null, "1." + _toDisplayString(_unref(getLanguage)('设备存在滚动轴承异常、部件碰摩、齿轮啮合不良、螺杆啮合不良等故障', _unref(language))), 1),
                    _createElementVNode("text", null, "2." + _toDisplayString(_unref(getLanguage)('设备存在轴承润滑不良、共振、电磁振动等故障', _unref(language))), 1),
                    _createElementVNode("text", null, "3." + _toDisplayString(_unref(getLanguage)('设备存在液力冲击、工况异常等故障', _unref(language))), 1)
                  ])
                ])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_QuestionCircleOutlined)
              ]),
              _: 1
            })
          ]),
          _cache[62] || (_cache[62] = _createElementVNode("div", null, null, -1))
        ]),
        _cache[63] || (_cache[63] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
        _createElementVNode("div", null, _toDisplayString(_unref(inspectionBase).fx_code4_v1.toFixed(4)) + "m/s²", 1)
      ]),
      (!!_unref(inspectionBase).jsdzD.threshold_80)
        ? (_openBlock(), _createElementBlock("div", _hoisted_146, [
            _cache[64] || (_cache[64] = _createElementVNode("div", { class: "score-80" }, [
              _createElementVNode("div", { class: "score-txt" }, "0")
            ], -1)),
            _createElementVNode("div", _hoisted_147, [
              _createElementVNode("div", _hoisted_148, _toDisplayString(_unref(inspectionBase).jsdzD?.threshold_80 || 0), 1)
            ]),
            _createElementVNode("div", _hoisted_149, [
              _createElementVNode("div", _hoisted_150, _toDisplayString(_unref(inspectionBase).jsdzD?.threshold_60 || 0), 1)
            ]),
            _createElementVNode("div", _hoisted_151, [
              _createElementVNode("div", _hoisted_152, _toDisplayString(_unref(inspectionBase).jsdzD?.threshold_30 || 0), 1)
            ]),
            _createElementVNode("div", {
              style: _normalizeStyle({ left: _unref(inspectionBase).fx_code4_1 + '%', backgroundColor: getColorLeft(_unref(inspectionBase).fx_code4_1) }),
              class: "score-posi"
            }, null, 4)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_153, [
            _createElementVNode("div", _hoisted_154, _toDisplayString(_unref(inspectionBase).fx_code4_v1.toFixed(4)), 1),
            _cache[65] || (_cache[65] = _createElementVNode("div", { class: "score-txt" }, "0", -1)),
            _cache[66] || (_cache[66] = _createElementVNode("div", { class: "score-posi" }, null, -1))
          ])),
      _createElementVNode("div", _hoisted_155, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_156, [
            _createTextVNode(_toDisplayString(_unref(langObj)['加速度均方根'] + " Y" + _unref(getLanguage)('轴', _unref(language))) + " ", 1),
            _createVNode(_component_a_popover, { title: "加速度均方根" }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_157, [
                  _createElementVNode("p", _hoisted_158, _toDisplayString(_unref(getLanguage)('指标释义', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_159, _toDisplayString(_unref(getLanguage)('加速度均方根就是指1/2采样频率范围以内物体振动加速度的均方根值。', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_160, _toDisplayString(_unref(getLanguage)('适用场景', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_161, _toDisplayString(_unref(getLanguage)('覆盖故障类型广，如电机、水泵、风机、压缩机、齿轮箱等旋转设备和活塞压缩机等往复式设备，需结合经验设定阈值。', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_162, _toDisplayString(_unref(getLanguage)('故障原因', _unref(language))), 1),
                  _createElementVNode("p", _hoisted_163, [
                    _createElementVNode("text", null, "1." + _toDisplayString(_unref(getLanguage)('设备存在滚动轴承异常、部件碰摩、齿轮啮合不良、螺杆啮合不良等故障', _unref(language))), 1),
                    _createElementVNode("text", null, "2." + _toDisplayString(_unref(getLanguage)('设备存在轴承润滑不良、共振、电磁振动等故障', _unref(language))), 1),
                    _createElementVNode("text", null, "3." + _toDisplayString(_unref(getLanguage)('设备存在液力冲击、工况异常等故障', _unref(language))), 1)
                  ])
                ])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_QuestionCircleOutlined)
              ]),
              _: 1
            })
          ]),
          _cache[67] || (_cache[67] = _createElementVNode("div", null, null, -1))
        ]),
        _cache[68] || (_cache[68] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
        _createElementVNode("div", null, _toDisplayString(_unref(inspectionBase).fx_code4_v2.toFixed(4)) + "m/s²", 1)
      ]),
      (!!_unref(inspectionBase).jsdzD.threshold_80)
        ? (_openBlock(), _createElementBlock("div", _hoisted_164, [
            _cache[69] || (_cache[69] = _createElementVNode("div", { class: "score-80" }, [
              _createElementVNode("div", { class: "score-txt" }, "0")
            ], -1)),
            _createElementVNode("div", _hoisted_165, [
              _createElementVNode("div", _hoisted_166, _toDisplayString(_unref(inspectionBase).jsdzD?.threshold_80 || 0), 1)
            ]),
            _createElementVNode("div", _hoisted_167, [
              _createElementVNode("div", _hoisted_168, _toDisplayString(_unref(inspectionBase).jsdzD?.threshold_60 || 0), 1)
            ]),
            _createElementVNode("div", _hoisted_169, [
              _createElementVNode("div", _hoisted_170, _toDisplayString(_unref(inspectionBase).jsdzD?.threshold_30 || 0), 1)
            ]),
            _createElementVNode("div", {
              style: _normalizeStyle({ left: _unref(inspectionBase).fx_code4_2 + '%', backgroundColor: getColorLeft(_unref(inspectionBase).fx_code4_2) }),
              class: "score-posi"
            }, null, 4)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_171, [
            _createElementVNode("div", _hoisted_172, _toDisplayString(_unref(inspectionBase).fx_code4_v2.toFixed(4)), 1),
            _cache[70] || (_cache[70] = _createElementVNode("div", { class: "score-txt" }, "0", -1)),
            _cache[71] || (_cache[71] = _createElementVNode("div", { class: "score-posi" }, null, -1))
          ])),
      _createElementVNode("div", _hoisted_173, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_174, _toDisplayString(_unref(langObj)['温度']), 1),
          _cache[72] || (_cache[72] = _createElementVNode("div", null, null, -1))
        ]),
        _cache[73] || (_cache[73] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
        _createElementVNode("div", null, _toDisplayString(_unref(inspectionBase).fx_code5_v.toFixed(4)) + "℃", 1)
      ]),
      (!!_unref(inspectionBase).tempD.threshold_80)
        ? (_openBlock(), _createElementBlock("div", _hoisted_175, [
            _cache[74] || (_cache[74] = _createElementVNode("div", { class: "score-80" }, [
              _createElementVNode("div", { class: "score-txt" }, "0")
            ], -1)),
            _createElementVNode("div", _hoisted_176, [
              _createElementVNode("div", _hoisted_177, _toDisplayString(_unref(inspectionBase).tempD?.threshold_80 || 0), 1)
            ]),
            _createElementVNode("div", _hoisted_178, [
              _createElementVNode("div", _hoisted_179, _toDisplayString(_unref(inspectionBase).tempD?.threshold_60 || 0), 1)
            ]),
            _createElementVNode("div", _hoisted_180, [
              _createElementVNode("div", _hoisted_181, _toDisplayString(_unref(inspectionBase).tempD?.threshold_30 || 0), 1)
            ]),
            _createElementVNode("div", {
              style: _normalizeStyle({ left: _unref(inspectionBase).fx_code5 + '%', backgroundColor: getColorLeft(_unref(inspectionBase).fx_code5) }),
              class: "score-posi"
            }, null, 4)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_182, [
            _createElementVNode("div", _hoisted_183, _toDisplayString(_unref(inspectionBase).fx_code5_v.toFixed(4)), 1),
            _cache[75] || (_cache[75] = _createElementVNode("div", { class: "score-txt" }, "0", -1)),
            _cache[76] || (_cache[76] = _createElementVNode("div", { class: "score-posi" }, null, -1))
          ]))
    ], 512), [
      [_vShow, _unref(inspectionBase).reportInfoAll.type == 2]
    ]),
    (_unref(inspectionBase).isShowFFTTIME && _unref(inspectionBase).reportInfoAll.type == 2 || _unref(inspectionBase).reportInfoAll.type == 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_184, [
          _createElementVNode("div", _hoisted_185, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_186, _toDisplayString(_unref(langObj)['时域图']), 1),
              _cache[77] || (_cache[77] = _createElementVNode("div", null, null, -1))
            ]),
            _cache[78] || (_cache[78] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", null, [
              _createVNode(_component_a_select, {
                onChange: bindPickerChange3,
                style: {"margin-left":"10px"},
                value: _unref(inspectionBase).directionInC,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(inspectionBase).directionInC) = $event)),
                placeholder: _unref(langObj)['请选择']
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(inspectionBase).directionA, (item, index) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      key: index,
                      value: index
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value", "placeholder"])
            ])
          ]),
          _cache[81] || (_cache[81] = _createElementVNode("div", {
            id: "chartB",
            style: {"height":"300px"}
          }, null, -1)),
          _createElementVNode("div", _hoisted_187, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_188, _toDisplayString(_unref(langObj)['幅值图']), 1),
              _cache[79] || (_cache[79] = _createElementVNode("div", null, null, -1))
            ]),
            _cache[80] || (_cache[80] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", null, [
              _createVNode(_component_a_select, {
                onChange: bindPickerChange4,
                style: {"margin-left":"10px"},
                value: _unref(inspectionBase).directionInD,
                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_unref(inspectionBase).directionInD) = $event)),
                placeholder: _unref(langObj)['请选择']
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(inspectionBase).directionA, (item, index) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      key: index,
                      value: index
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value", "placeholder"])
            ])
          ]),
          _cache[82] || (_cache[82] = _createElementVNode("div", {
            id: "chartC",
            style: {"height":"300px"}
          }, null, -1))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_189, _toDisplayString(_unref(langObj)['现场情况']), 1),
    _createElementVNode("div", _hoisted_190, _toDisplayString(_unref(langObj)['备注']) + "：", 1),
    _createElementVNode("div", _hoisted_191, _toDisplayString(_unref(inspectionBase).reportInfo?.remark), 1),
    _createElementVNode("div", _hoisted_192, _toDisplayString(_unref(langObj)['现场照片']), 1),
    _createElementVNode("div", _hoisted_193, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(inspectionBase).reportInfo?.pictures, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          style: {"width":"100px","height":"100px","overflow":"hidden","margin-right":"10px","margin-bottom":"10px"},
          key: index
        }, [
          _createElementVNode("div", _hoisted_194, _toDisplayString(item.remark), 1),
          _createVNode(_component_a_image, {
            class: "w-full",
            src: item.url,
            alt: item.remark
          }, null, 8, ["src", "alt"])
        ]))
      }), 128))
    ])
  ]))
}
}

})