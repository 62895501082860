import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, isRef as _isRef } from "vue"
import _imports_0 from '@/assets/sort-up.png'
import _imports_1 from '@/assets/sort-down.png'
import _imports_2 from '@/assets/zhuanjia.png'
import _imports_3 from '@/assets/zhenduan.png'
import _imports_4 from '@/assets/weixiu.png'
import _imports_5 from '@/assets/jiancha.png'
import _imports_6 from '@/assets/menxian.png'


const _hoisted_1 = { class: "content-o mt-2 w-full relative theme-common" }
const _hoisted_2 = {
  class: "flex items-center w-full justify-center",
  style: {"border-bottom":"1px solid #30406a"}
}
const _hoisted_3 = {
  key: 0,
  class: "mt-2 w-full px-2"
}
const _hoisted_4 = { class: "w-full flex items-center justify-center" }
const _hoisted_5 = { class: "w-full flex items-center justify-center mt-2" }
const _hoisted_6 = { class: "mt-2 w-full flex items-center justify-center" }
const _hoisted_7 = {
  key: 0,
  style: {"width":"16px","height":"16px"},
  src: _imports_0,
  alt: ""
}
const _hoisted_8 = {
  key: 1,
  style: {"width":"16px","height":"16px"},
  src: _imports_1,
  alt: ""
}
const _hoisted_9 = { class: "ml-2 workColor" }
const _hoisted_10 = { class: "w-full px-2 mt-2" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = {
  key: 0,
  class: "w-full flex items-center"
}
const _hoisted_13 = {
  key: 0,
  class: "img-zhuanjia",
  src: _imports_2,
  alt: ""
}
const _hoisted_14 = {
  key: 1,
  class: "box1-o"
}
const _hoisted_15 = {
  key: 1,
  class: "w-full flex items-center"
}
const _hoisted_16 = {
  key: 0,
  class: "img-zhuanjia",
  src: _imports_2,
  alt: ""
}
const _hoisted_17 = { class: "ml-2" }
const _hoisted_18 = {
  key: 1,
  class: "box1-o"
}
const _hoisted_19 = {
  key: 2,
  class: "w-full flex items-center"
}
const _hoisted_20 = {
  key: 0,
  class: "img-zhuanjia",
  src: _imports_2,
  alt: ""
}
const _hoisted_21 = { class: "ml-2" }
const _hoisted_22 = {
  key: 1,
  class: "box1-o"
}
const _hoisted_23 = {
  key: 3,
  class: "w-full flex items-center"
}
const _hoisted_24 = {
  key: 0,
  class: "img-zhuanjia",
  src: _imports_2,
  alt: ""
}
const _hoisted_25 = { class: "ml-2" }
const _hoisted_26 = { class: "mt-2 word-break" }
const _hoisted_27 = { class: "mt-2 opt6" }
const _hoisted_28 = { class: "flex my-4 cursor-pointer" }
const _hoisted_29 = { style: {"color":"#fff"} }
const _hoisted_30 = { class: "w-full flex" }
const _hoisted_31 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_32 = { class: "w-full flex items-center" }
const _hoisted_33 = { style: {"font-size":"20px"} }
const _hoisted_34 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_35 = { class: "ml-2" }
const _hoisted_36 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_37 = { key: 0 }
const _hoisted_38 = { key: 1 }
const _hoisted_39 = { key: 2 }
const _hoisted_40 = { key: 3 }
const _hoisted_41 = { class: "ml-2" }
const _hoisted_42 = {
  class: "mt-4",
  style: {"font-size":"20px"}
}
const _hoisted_43 = { key: 0 }
const _hoisted_44 = {
  class: "opt6",
  style: {"margin-top":"8px","color":"#fff"}
}
const _hoisted_45 = ["src"]
const _hoisted_46 = { class: "w-full flex justify-center" }
const _hoisted_47 = { style: {"color":"#fff"} }
const _hoisted_48 = { class: "w-full flex" }
const _hoisted_49 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_50 = { class: "w-full flex items-center" }
const _hoisted_51 = { style: {"font-size":"20px"} }
const _hoisted_52 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_53 = { class: "ml-2" }
const _hoisted_54 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_55 = { key: 0 }
const _hoisted_56 = { key: 1 }
const _hoisted_57 = { key: 2 }
const _hoisted_58 = { key: 3 }
const _hoisted_59 = { class: "ml-2" }
const _hoisted_60 = { class: "w-full flex justify-center" }
const _hoisted_61 = {
  key: 1,
  style: {"position":"absolute","left":"-1200px","width":"1200px","top":"0"}
}

import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { transformDate, getGroupName } from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import MaintenanceReportDetailComponent from "../report4/common/maintenanceReportDetails.vue";
import DiagnosticReportDetails2Component from "../report4/common/DiagnosticReportDetails2.vue";
import { message } from "ant-design-vue";
import { langList } from "../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";

export default /*@__PURE__*/_defineComponent({
  __name: 'orderList',
  props: {
  machineInfo: null,
},
  setup(__props, { expose: __expose }) {

const router = useRouter();
let value = "";
let visibleAdd = ref(false);
let visibledel = ref(false);
let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
  theme.value = localStorage.getItem('theme') || 'blue'
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}
getLang()

let selectedRowKeys = ref([]);
// const pagination = computed(() => ({
//   total: searchModel.value.total,
//   current: searchModel.value.skip,
//   pageSize: searchModel.value.take,
// }));
let conditionList = [
  { label: "健康", value: 0, color: "#61c08f" },
  { label: "可用", value: 1, color: "#d2de49" },
  { label: "可用", value: 2, color: "#d2de49" },
  { label: "警戒", value: 3, color: "#cd5f3b" },
  { label: "警戒", value: 4, color: "#cd5f3b" },
  { label: "警戒", value: 5, color: "#cd5f3b" },
  { label: "故障", value: 6, color: "#921e37" },
  { label: "故障", value: 7, color: "#921e37" },
];

let conditionLists = [
  { label: "健康", value: 1, real: [0] },
  { label: "可用", value: 2, real: [1, 2] },
  { label: "警戒", value: 3, real: [3, 4, 5] },
  { label: "故障", value: 4, real: [6, 7] },
];
let typeList = [
  { label: "智能诊断", value: 1 },
  // {label: '维修记录', value: 2},
  // {label: '设备检查', value: 3},
  { label: "门限报警", value: 4 },
];
let searchModel: any = ref({
  sort: -1,
  condition: null,
  type: 1,
  select: 1,
  typeList: [1, 4],
});
const props: any = __props;
let machine_id = ref("");
let machineInfo: any = ref({});
let workFlowList: any = ref([]);
let orderId = ref();
let retporId = ref();
let retportype = ref();
let maint = ref();
let Diagn = ref();

watch(
  () => props.machineInfo,
  (newValue, oldValue) => {
    machine_id.value = props.machineInfo.id;
    machineInfo.value = props.machineInfo;
    init();
  }
);

onMounted(() => {
  machine_id.value = props.machineInfo?.id;
  machineInfo.value = props.machineInfo;
  init();
});

const init = () => {
  workFlowList.value = [];
  if (!machine_id.value) return;
  getOrderData();
};



const changeSort = () => {
  if (searchModel.value.sort == 1) {
    searchModel.value.sort = -1;
  } else {
    searchModel.value.sort = 1;
  }

  getOrderData();
};

const selectType = (ev: any) => {
  searchModel.value = {
    sort: -1,
    condition: null,
    type: null,
    select: ev,
    typeList: [],
  };
  if (ev === 1) {
    searchModel.value.typeList = [1, 4];
  }
  if (ev === 2) {
    searchModel.value.typeList = [2, 3];
  }

  getOrderData();
};
const warnFun = (type: any) => {
  searchModel.value.type = type;
  getOrderData()
}

const getOrderData = async () => {
  let config: any = {
    params: {
      skip: 1,
      take: 7,
      machineId: machine_id.value,
      type: searchModel.value.type,
      sort: searchModel.value.sort,
      typeList: searchModel.value.typeList,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  if (searchModel.value.condition) {
    config.params.conditionList = conditionLists.find(
      (p: any) => p.value === searchModel.value.condition
    )?.real;
  }

  let result = await request.get("/api/workflows", config);

  if (result) {
    workFlowList.value = result.data.list;
  }
};

const viewReport = (ev: any) => {
  console.log("ev", ev);
  orderId.value = ev.id;
  retportype.value = ev.type;
  if (ev.reportIds.length) {
    retporId.value = ev.reportIds[0];
  }

  setTimeout(() => {
    if ((ev.type === 2 || ev.type === 3) && retporId.value) {
      maint.value.show(retporId.value);
    }
  }, 500);

  setTimeout(() => {
    if (ev.type === 1 && retporId.value) {
      Diagn.value.show(retporId.value);
    }
  }, 500);
};

const cancel = () => {
  retporId.value = null;
  retportype.value = null;
};

const onSelectChange = (ev: any) => {
  selectedRowKeys.value = ev;
};

// 添加检修记录
let nohandleList: any = ref([]);
let columns = [
  {
    title: langObj.value["报警时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["报警类型"],
    dataIndex: "type",
  },
  {
    title: langObj.value["严重程度"],
    dataIndex: "condition",
  },
  {
    title: langObj.value["故障描述"],
    dataIndex: "diagnosticNotes",
  },
];
let conditionAdd = [
  { label: "健康", value: 0 },
  { label: "可用", value: 1 },
  { label: "警戒", value: 3 },
  { label: "故障", value: 6 },
];
let form: any = ref({
  value: "",
  begin: null,
  end: null,
  type: 2,
  machineName: "",
  machineId: "",
  groupId: "",
  groupName: "",
  factoryId: "",
  condition: null,
  faultCause: "",
});

const addRepair = () => {
  machineInfo.value.groupName = getGroupName(machineInfo.value.groupId);
  form.value.groupName = machineInfo.value.groupName;
  form.value.machineName = machineInfo.value.machineName;
  form.value.machineId = machineInfo.value.id;
  form.value.groupId = machineInfo.value.groupId;
  form.value.factoryId = machineInfo.value.factoryId;
  form.value.maintainPerson = JSON.parse(
    localStorage.getItem("memberInfo") || ""
  )?.nickName;
  nohandleList.value = [];
  visibleAdd.value = true;
  getNotHandleOrder();
};
const handleTableChange = (pagination: any, filters: any, sorter: any) => {
  // 处理分页数据
  // if (Object.keys(sorter).length) {
  //   var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
  //   searchModel.value.sort = sorter.field;
  //   searchModel.value.order = order;
  // }
  // // 处理分页数据
  // searchModel.value.skip = pagination.current;
  // searchModel.value.take = pagination.pageSize;
  // getData();
};


//获取未处理的诊断工单
const getNotHandleOrder = async () => {
  let config: any = {
    params: {
      machineId: machine_id.value,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/workflows/nohandle", config);
  if (result && result.data) {
    nohandleList.value = result.data;
  }
};

const submitAdd = async () => {
  let submit = JSON.parse(JSON.stringify(form.value));
  if (!submit.begin) {
    message.warning(langObj.value["请选择开始时间"]);
    return;
  } else {
    submit.begin = transformDate(submit.begin);
  }

  if (!submit.end) {
    message.warning(langObj.value["请选择结束时间"]);
    return;
  } else {
    submit.end = transformDate(submit.end);
  }

  if (!submit.maintainPerson) {
    message.warning(langObj.value["请填写检修人员"]);
    return;
  }

  if (!submit.condition && submit.condition !== 0) {
    message.warning(langObj.value["请选择现场设备状态"]);
    return;
  }

  if (!submit.faultCause) {
    message.warning(langObj.value["请填写故障原因"]);
    return;
  }

  if (!submit.maintainMessage) {
    message.warning(langObj.value["请填写检修内容"]);
    return;
  }

  let list = nohandleList.value.filter((p: any) =>
    selectedRowKeys.value.includes(p._id)
  );

  submit.list = list.map((d: any) => ({
    date: d.date,
    condition: d.condition,
    diagnosticNotes: d.diagnosticNotes,
    id: d._id,
  }));
  

  if (fileList.value && fileList.value.length) {
    submit.pictures = fileList.value.map((p: any) => ({
      url: p.url,
    }));
  } else {
    submit.pictures = [];
  }

  console.log(submit.pictures)

  let data: any = {
    repairReport: submit,
    date: submit.end,
    machineIds: [machine_id.value],
    factoryId: submit.factoryId,
    pictures: submit.pictures,
    type: 2,
    workflow: true,
  };

  let result = await request.post("/api/reports", data);

  if (result && result.status) {
    message.success(langObj.value["新增成功"]);
    visibleAdd.value = false;
    getOrderData();
  } else {
    message.warning(langObj.value["操作失败"] + "!");
  }
};

//批量关闭报警
let formClose = ref({
  closeCause: "",
});

const deleteWaring = () => {
  formClose.value.closeCause = "";
  nohandleList.value = [];
  visibledel.value = true;
  getNotHandleOrder();
};

// 关闭报警
let selectedRowKeysDel: any = ref([]);

const onSelectChangeDel = (ev: any) => {
  selectedRowKeysDel.value = ev;
};

const submitClose = async () => {
  if (!formClose.value.closeCause) {
    message.warning(langObj.value["请填写关闭原因"]);
    return;
  }
  if (!selectedRowKeysDel.value || !selectedRowKeysDel.value.length) {
    message.warning(langObj.value["请选择关闭的故障项"]);
    return;
  }

  let form = {
    closeCause: formClose.value.closeCause,
    list: [],
  };

  let list = nohandleList.value.filter((p: any) =>
    selectedRowKeysDel.value.includes(p._id)
  );
  if (list && list.length) {
    form.list = list.map((d: any) => ({
      date: d.date,
      condition: d.condition,
      diagnosticNotes: d.diagnosticNotes,
      id: d._id,
    }));
  }
  let result = await request.post("/api/workflows/close", form);
  if (result && result.status == 200) {
    message.success(langObj.value["关闭成功"] + "!");
    visibledel.value = false;
  } else {
    message.warning(langObj.value["操作失败"]);
  }
};

//**图片上传* */
let fileList = ref<any>([]);
const previewVisible = ref(false);
const previewImage = ref("");
const previewTitle = ref("");
const handleCancel = () => {
  previewVisible.value = false;
  previewTitle.value = "";
};

const handlePreview = async (file: any) => {
  previewImage.value = file.url;
  previewVisible.value = true;
  previewTitle.value = file.url;
};

let customRequest = async (options: any) => {
  const { onSuccess, onError, file } = options;
  let formData = new FormData();
  console.log('file', file)
  console.log('formData', formData)
  formData.append("file", file); // file为要上传的文件
  const config = {
    headers: {
      requestId: uuidv4(),
      "Content-Type": "multipart/form-data",
    },
  };
  let res = await request.post("/api/upload", formData, config);
  if (res) {
    file.url = res.data;
    onSuccess({ url: file.url, status: "done" });
  }
};

// 查看更多
const viewMore = () => {
  if (searchModel.value.select === 1) {
    router.push("/warning-manage/warning-list");
  }
  if (searchModel.value.select === 2) {
    localStorage.setItem("report_type", "2");
    router.push("/report/list");
  }
};

// 关闭所有弹窗
const closeAll = () => {
  cancel();
  searchModel.value = {
    sort: -1,
    condition: null,
    type: 1,
    select: 1,
    typeList: [1, 4],
  };
};

__expose({
  closeAll,
});

return (_ctx: any,_cache: any) => {
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_block = _resolveComponent("block")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_right_outlined = _resolveComponent("right-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _unref(enUS) : _unref(zhCN)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[19] || (_cache[19] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (selectType(1))),
            class: _normalizeClass([{ 'btn1-select-o': _unref(searchModel).select === 1 }, "py-1 cursor-pointer"])
          }, _toDisplayString(_unref(langObj)['报警事件']), 3),
          _cache[20] || (_cache[20] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
          _createElementVNode("div", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (selectType(2))),
            class: _normalizeClass([{ 'btn1-select-o': _unref(searchModel).select === 2 }, "py-1 cursor-pointer"])
          }, _toDisplayString(_unref(langObj)['检修事件']), 3),
          _cache[21] || (_cache[21] = _createElementVNode("div", { class: "flex-1" }, null, -1))
        ]),
        (_unref(searchModel).select === 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", {
                  class: "btn2-o flex items-center cursor-pointer",
                  onClick: addRepair,
                  style: {"padding":"4px 8px","max-width":"145px","line-height":"normal"}
                }, [
                  _createVNode(_component_plus_outlined, { class: "mr-1" }),
                  _createTextVNode(" " + _toDisplayString(_unref(langObj)['添加检修记录']), 1)
                ]),
                _createElementVNode("div", {
                  class: "btn2-o ml-2 flex items-center cursor-pointer",
                  onClick: deleteWaring,
                  style: {"padding":"4px 18px","width":"145px","line-height":"normal"}
                }, [
                  _createVNode(_component_close_outlined, { class: "mr-1" }),
                  _createTextVNode(" " + _toDisplayString(_unref(langObj)['批量关闭报警']), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", {
                  class: _normalizeClass(["btn2-o items-center cursor-pointer", _unref(searchModel).type == 1?'btn2-o-s':'']),
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (warnFun(1))),
                  style: {"max-width":"145px","padding":"4px 31px","line-height":"normal"}
                }, _toDisplayString(_unref(langObj)['智能诊断']), 3),
                _createElementVNode("div", {
                  class: _normalizeClass(["btn2-o ml-2 items-center cursor-pointer", _unref(searchModel).type == 4?'btn2-o-s':'']),
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (warnFun(4))),
                  style: {"width":"145px","padding":"4px 0","text-align":"center","line-height":"normal"}
                }, _toDisplayString(_unref(langObj)['门限报警']), 3)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", {
                  onClick: changeSort,
                  class: "btn2-o flex items-center cursor-pointer mr-2",
                  style: {"padding":"4px 8px","width":"120px","justify-content":"center","line-height":"normal"}
                }, [
                  (_unref(theme) == 'white')
                    ? (_openBlock(), _createBlock(_component_block, { key: 0 }))
                    : (_openBlock(), _createBlock(_component_block, { key: 1 }, {
                        default: _withCtx(() => [
                          (_unref(searchModel).sort === -1)
                            ? (_openBlock(), _createElementBlock("img", _hoisted_7))
                            : _createCommentVNode("", true),
                          (_unref(searchModel).sort === 1)
                            ? (_openBlock(), _createElementBlock("img", _hoisted_8))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })),
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(langObj)['排序']), 1)
                ]),
                _createVNode(_component_a_select, {
                  ref: "select",
                  value: _unref(searchModel).condition,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(searchModel).condition) = $event)),
                  style: {"width":"144px","height":"28px"},
                  placeholder: _unref(langObj)['严重程度'],
                  onChange: getOrderData,
                  allowClear: ""
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(conditionLists), (item, index) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: index,
                        value: item.value
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(langObj)[item.label]), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value", "placeholder"])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_10, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(workFlowList), (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "w-full mt-2 cursor-pointer workBg",
              onClick: ($event: any) => (viewReport(item)),
              key: index
            }, [
              (item.type === 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    (item.expertCertification)
                      ? (_openBlock(), _createElementBlock("img", _hoisted_13))
                      : _createCommentVNode("", true),
                    _cache[22] || (_cache[22] = _createElementVNode("img", {
                      class: "img-ico",
                      src: _imports_3,
                      alt: ""
                    }, null, -1)),
                    _createElementVNode("div", {
                      class: "ml-2",
                      style: _normalizeStyle({
              color: _unref(conditionList).find((p) => p.value === item.condition)
                ?.color,
            })
                    }, _toDisplayString(_unref(langObj)['诊断报警']) + " : " + _toDisplayString(_unref(langObj)[_unref(conditionList).find((p) => p.value === item.condition)?.label]), 5),
                    _cache[23] || (_cache[23] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                    (item.reportIds && item.reportIds.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_unref(langObj)['诊断报告']), 1))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (item.type === 2)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    (item.expertCertification)
                      ? (_openBlock(), _createElementBlock("img", _hoisted_16))
                      : _createCommentVNode("", true),
                    _cache[24] || (_cache[24] = _createElementVNode("img", {
                      class: "img-ico",
                      src: _imports_4,
                      alt: ""
                    }, null, -1)),
                    _createElementVNode("div", _hoisted_17, _toDisplayString(_unref(langObj)['现场维修']), 1),
                    _cache[25] || (_cache[25] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                    (item.reportIds && item.reportIds.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(_unref(langObj)['检修报告']), 1))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (item.type === 3)
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    (item.expertCertification)
                      ? (_openBlock(), _createElementBlock("img", _hoisted_20))
                      : _createCommentVNode("", true),
                    _cache[26] || (_cache[26] = _createElementVNode("img", {
                      class: "img-ico",
                      src: _imports_5,
                      alt: ""
                    }, null, -1)),
                    _createElementVNode("div", _hoisted_21, _toDisplayString(_unref(langObj)['现场检查']), 1),
                    _cache[27] || (_cache[27] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                    (item.reportIds && item.reportIds.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(_unref(langObj)['检修报告']), 1))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (item.type === 4)
                ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                    (item.expertCertification)
                      ? (_openBlock(), _createElementBlock("img", _hoisted_24))
                      : _createCommentVNode("", true),
                    _cache[28] || (_cache[28] = _createElementVNode("img", {
                      class: "img-ico",
                      src: _imports_6,
                      alt: ""
                    }, null, -1)),
                    _createElementVNode("div", _hoisted_25, _toDisplayString(_unref(langObj)['门限报警']), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_26, _toDisplayString(item.diagnosticNotes), 1),
              _createElementVNode("div", _hoisted_27, _toDisplayString(item.date), 1)
            ], 8, _hoisted_11))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_28, [
          _cache[29] || (_cache[29] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
          _createElementVNode("div", {
            onClick: viewMore,
            class: "mr-4 flex items-center opt6"
          }, [
            _createTextVNode(_toDisplayString(_unref(langObj)['查看更多']) + " ", 1),
            _createVNode(_component_right_outlined, { class: "ml-1" })
          ])
        ]),
        _createVNode(_component_a_modal, {
          visible: _unref(visibleAdd),
          "onUpdate:visible": _cache[15] || (_cache[15] = ($event: any) => (_isRef(visibleAdd) ? (visibleAdd).value = $event : visibleAdd = $event)),
          closable: false,
          footer: null,
          bodyStyle: { background: '#020F46' },
          style: {"top":"15%"},
          width: "1200px",
          maskClosable: false
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("div", _hoisted_30, [
                _cache[30] || (_cache[30] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                _createElementVNode("div", {
                  class: "cursor-pointer",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_isRef(visibleAdd) //@ts-ignore
 ? visibleAdd.value = false : visibleAdd = false))
                }, [
                  _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
                ])
              ]),
              _createElementVNode("div", _hoisted_31, _toDisplayString(_unref(langObj)['添加检修记录']), 1),
              _createElementVNode("div", _hoisted_32, [
                _createElementVNode("span", _hoisted_33, _toDisplayString(_unref(langObj)['故障信息(请勾选此次维修的故障项)']), 1)
              ]),
              _createVNode(_component_a_table, {
                "row-selection": {
            selectedRowKeys: _unref(selectedRowKeys),
            onChange: onSelectChange,
          },
                columns: _unref(columns),
                "data-source": _unref(nohandleList),
                pagination: true,
                rowKey: "_id",
                style: {"background-color":"#020f46"}
              }, {
                bodyCell: _withCtx(({ column, text }) => [
                  (column.dataIndex === 'condition')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                        _createElementVNode("div", _hoisted_35, _toDisplayString(_unref(langObj)[_unref(conditionList).find((p) => p.value === text)?.label]), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'type')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                        (text === 1)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_37, _cache[31] || (_cache[31] = [
                              _createElementVNode("img", {
                                style: {"width":"20px"},
                                src: _imports_3,
                                alt: ""
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        (text === 2)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_38, _cache[32] || (_cache[32] = [
                              _createElementVNode("img", {
                                style: {"width":"20px"},
                                src: _imports_4,
                                alt: ""
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        (text === 3)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_39, _cache[33] || (_cache[33] = [
                              _createElementVNode("img", {
                                style: {"width":"20px"},
                                src: _imports_5,
                                alt: ""
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        (text === 4)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_40, _cache[34] || (_cache[34] = [
                              _createElementVNode("img", {
                                style: {"width":"20px"},
                                src: _imports_6,
                                alt: ""
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_41, _toDisplayString(_unref(langObj)[_unref(typeList).find((p) => p.value === text)?.label]), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["row-selection", "columns", "data-source"]),
              _createElementVNode("div", _hoisted_42, _toDisplayString(_unref(langObj)['维修详情']), 1),
              _createVNode(_component_a_form, {
                ref: "formRef",
                name: "advanced_search",
                class: "ant-advanced-search-form",
                model: _unref(form),
                "label-col": { style: { width: '180px' } }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_row, { gutter: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            name: "begin",
                            label: _unref(langObj)['开始时间'],
                            rules: [{ required: true, message: _unref(langObj)['请选择开始时间'] }],
                            allowClear: false
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_date_picker, {
                                value: _unref(form).begin,
                                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_unref(form).begin) = $event)),
                                "show-time": "",
                                placeholder: _unref(langObj)['请选择时间']
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          }, 8, ["label", "rules"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            name: "end",
                            label: _unref(langObj)['结束时间'],
                            rules: [{ required: true, message: _unref(langObj)['请选择结束时间'] }],
                            allowClear: false
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_date_picker, {
                                value: _unref(form).end,
                                "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_unref(form).end) = $event)),
                                "show-time": "",
                                placeholder: _unref(langObj)['请选择时间']
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          }, 8, ["label", "rules"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            name: "value",
                            label: _unref(langObj)['设备名称'],
                            rules: [{ required: true }]
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _unref(form).machineName,
                                "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_unref(form).machineName) = $event)),
                                style: {"width":"210px"},
                                readonly: ""
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            name: "value",
                            label: _unref(langObj)['设备区域'],
                            rules: [{ required: true }]
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _unref(form).groupName,
                                "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_unref(form).groupName) = $event)),
                                style: {"width":"210px"},
                                readonly: ""
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            name: "maintainPerson",
                            label: _unref(langObj)['检修人员'],
                            rules: [{ required: true }]
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _unref(form).maintainPerson,
                                "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_unref(form).maintainPerson) = $event)),
                                style: {"width":"210px"}
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            name: "condition",
                            label: _unref(langObj)['现场设备状态'],
                            rules: [{ required: true }]
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_select, {
                                value: _unref(form).condition,
                                "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_unref(form).condition) = $event)),
                                style: {"width":"210px"},
                                placeholder: _unref(langObj)['请选择现场设备状态'],
                                allowClear: ""
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(conditionAdd), (item, index) => {
                                    return (_openBlock(), _createBlock(_component_a_select_option, {
                                      key: index,
                                      value: item.value
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_unref(langObj)[item.label]), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            name: "faultCause",
                            label: _unref(langObj)['故障原因'],
                            rules: [{ required: true }]
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_textarea, {
                                value: _unref(form).faultCause,
                                "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_unref(form).faultCause) = $event)),
                                placeholder: _unref(langObj)['请输入故障原因'],
                                rows: 4
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            name: "maintainMessage",
                            label: _unref(langObj)['检修内容'],
                            rules: [{ required: true }]
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_textarea, {
                                value: _unref(form).maintainMessage,
                                "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_unref(form).maintainMessage) = $event)),
                                placeholder: _unref(langObj)['请输入检修内容'],
                                rows: 4
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            style: {"margin-left":"10px"},
                            name: "value",
                            label: _unref(langObj)['检修图片']
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_upload, {
                                "file-list": _unref(fileList),
                                "onUpdate:fileList": _cache[14] || (_cache[14] = ($event: any) => (_isRef(fileList) ? (fileList).value = $event : fileList = $event)),
                                "list-type": "picture-card",
                                onPreview: handlePreview,
                                "custom-request": _unref(customRequest),
                                "auto-upload": false,
                                accept: "image",
                                "max-count": 9
                              }, {
                                default: _withCtx(() => [
                                  (!_unref(fileList) || _unref(fileList).length < 9)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                                        _createVNode(_component_plus_outlined, {
                                          class: "opt6",
                                          style: {"color":"#fff"}
                                        }),
                                        _createElementVNode("div", _hoisted_44, _toDisplayString(_unref(langObj)['上传']), 1)
                                      ]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              }, 8, ["file-list", "custom-request"]),
                              _createVNode(_component_a_modal, {
                                visible: previewVisible.value,
                                title: previewTitle.value,
                                footer: null,
                                onCancel: handleCancel
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("img", {
                                    alt: "example",
                                    style: {"width":"100%"},
                                    src: previewImage.value
                                  }, null, 8, _hoisted_45)
                                ]),
                                _: 1
                              }, 8, ["visible", "title"])
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model"]),
              _createElementVNode("div", _hoisted_46, [
                _createElementVNode("div", {
                  onClick: submitAdd,
                  style: {"background-color":"#072499","padding":"5px 10px","width":"100px","font-size":"20px","text-align":"center","cursor":"pointer"},
                  class: "btn-submit"
                }, _toDisplayString(_unref(langObj)['提交']), 1)
              ])
            ])
          ]),
          _: 1
        }, 8, ["visible"]),
        _createVNode(_component_a_modal, {
          visible: _unref(visibledel),
          "onUpdate:visible": _cache[18] || (_cache[18] = ($event: any) => (_isRef(visibledel) ? (visibledel).value = $event : visibledel = $event)),
          closable: false,
          footer: null,
          bodyStyle: { background: '#020F46' },
          style: {"top":"30%"},
          width: "1200px",
          maskClosable: false
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_47, [
              _createElementVNode("div", _hoisted_48, [
                _cache[35] || (_cache[35] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                _createElementVNode("div", {
                  class: "cursor-pointer",
                  onClick: _cache[16] || (_cache[16] = ($event: any) => (_isRef(visibledel) //@ts-ignore
 ? visibledel.value = false : visibledel = false))
                }, [
                  _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
                ])
              ]),
              _createElementVNode("div", _hoisted_49, _toDisplayString(_unref(langObj)['关闭报警']), 1),
              _createElementVNode("div", _hoisted_50, [
                _createElementVNode("span", _hoisted_51, _toDisplayString(_unref(langObj)['故障信息（请勾选此次关闭的故障项）']), 1)
              ]),
              _createVNode(_component_a_table, {
                "row-selection": {
            selectedRowKeys: _unref(selectedRowKeysDel),
            onChange: onSelectChangeDel,
          },
                columns: _unref(columns),
                "data-source": _unref(nohandleList),
                pagination: true,
                rowKey: "_id",
                style: {"background-color":"#020f46"}
              }, {
                bodyCell: _withCtx(({ column, text }) => [
                  (column.dataIndex === 'condition')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                        _createElementVNode("div", {
                          style: _normalizeStyle([{"width":"12px","height":"12px","border-radius":"50%"}, {
                    background: _unref(conditionList).find((p) => p.value === text)
                      ?.color,
                  }])
                        }, null, 4),
                        _createElementVNode("div", _hoisted_53, _toDisplayString(_unref(langObj)[_unref(conditionList).find((p) => p.value === text)?.label]), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'type')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
                        (text === 1)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_55, _cache[36] || (_cache[36] = [
                              _createElementVNode("img", {
                                style: {"width":"20px"},
                                src: _imports_3,
                                alt: ""
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        (text === 2)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_56, _cache[37] || (_cache[37] = [
                              _createElementVNode("img", {
                                style: {"width":"20px"},
                                src: _imports_4,
                                alt: ""
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        (text === 3)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_57, _cache[38] || (_cache[38] = [
                              _createElementVNode("img", {
                                style: {"width":"20px"},
                                src: _imports_5,
                                alt: ""
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        (text === 4)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_58, _cache[39] || (_cache[39] = [
                              _createElementVNode("img", {
                                style: {"width":"20px"},
                                src: _imports_6,
                                alt: ""
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_59, _toDisplayString(_unref(langObj)[_unref(typeList).find((p) => p.value === text)?.label]), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["row-selection", "columns", "data-source"]),
              _createVNode(_component_a_form, {
                ref: "formRef",
                name: "advanced_search",
                class: "ant-advanced-search-form",
                style: {"margin-top":"20px"},
                model: _unref(formClose)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_row, { gutter: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            name: "closeCause",
                            label: _unref(langObj)['关闭原因'],
                            required: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_textarea, {
                                value: _unref(formClose).closeCause,
                                "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_unref(formClose).closeCause) = $event)),
                                placeholder: _unref(langObj)['请输入关闭原因'],
                                rows: 4
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model"]),
              _createElementVNode("div", _hoisted_60, [
                _createElementVNode("div", {
                  onClick: submitClose,
                  style: {"background-color":"#072499","padding":"5px 10px","width":"100px","font-size":"20px","text-align":"center","cursor":"pointer"},
                  class: "btn-submit"
                }, _toDisplayString(_unref(langObj)['提交']), 1)
              ])
            ])
          ]),
          _: 1
        }, 8, ["visible"]),
        (_unref(retporId))
          ? (_openBlock(), _createElementBlock("div", _hoisted_61, [
              (_unref(retportype) === 2 || _unref(retportype) === 3)
                ? (_openBlock(), _createBlock(MaintenanceReportDetailComponent, {
                    key: 0,
                    ref_key: "maint",
                    ref: maint,
                    onCancel: cancel
                  }, null, 512))
                : _createCommentVNode("", true),
              (_unref(retportype) === 1)
                ? (_openBlock(), _createBlock(DiagnosticReportDetails2Component, {
                    key: 1,
                    ref_key: "Diagn",
                    ref: Diagn,
                    onCancel: cancel
                  }, null, 512))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})