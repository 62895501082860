import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, isRef as _isRef, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex" }
const _hoisted_2 = { class: "w-full fac-box p-3 ml-4" }
const _hoisted_3 = { class: "w-full flex items-center" }
const _hoisted_4 = {
  class: "freqx_formTitle",
  style: {"width":"400px"}
}
const _hoisted_5 = { class: "w-full" }
const _hoisted_6 = { class: "code-box-meta markdown" }
const _hoisted_7 = {
  class: "code-box-title",
  style: {"background":"#000a32"}
}
const _hoisted_8 = { class: "code-box-description" }
const _hoisted_9 = { class: "flex" }
const _hoisted_10 = { class: "code-box-description" }
const _hoisted_11 = { class: "flex" }
const _hoisted_12 = {
  key: 0,
  class: "code-box-description"
}
const _hoisted_13 = { class: "code-box-description" }
const _hoisted_14 = { class: "code-box-description" }

import request from "../../common/request";
import { getFactoryName, getGroupName, transformDate2, getGroupListByFactory } from "../../common/tools";
import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
/**操作日志记录 */
import { operateLogs } from "@/common/logs";
import { langList } from "../../common/lang";
import { getLanguage } from "../../common/translate";

export default /*@__PURE__*/_defineComponent({
  __name: 'WeekReportRuleSetting',
  props: {
  visible: null,
  editRindex: null
},
  emits: ["saveWeekReportRule"],
  setup(__props, { emit: __emit }) {

let langObj: any = ref({});
let language: any = ref("Chinese");
/**操作日志记录 */
var activeKey = ref<any>(0);
let facForm = ref([]);

let factoryListAll: any = ref([]);
let visible = ref(true)
let editRindex = ref("auto");

const getLang = () => {
  language.value = localStorage.getItem("language") || "Chinese";
  langObj.value = langList[language.value];
};

operateLogs("访问基础配置");

let factory_id = "";
getLang();

let dayOfWeeks: any = ref([
  { label: getLanguage("星期日", language.value), value: 0 },
  { label: getLanguage("星期一", language.value), value: 1 },
  { label: getLanguage("星期二", language.value), value: 2 },
  { label: getLanguage("星期三", language.value), value: 3 },
  { label: getLanguage("星期四", language.value), value: 4 },
  { label: getLanguage("星期五", language.value), value: 5 },
  { label: getLanguage("星期六", language.value), value: 6 },
]);
const close =() =>{
  visible.value = false
  emits("saveWeekReportRule", false);
}
// 保存
const handleOk = () => {
  if (!factory_id) {
    message.warning("请选择工厂后提交");
    return;
  }
  formInfo.value.factoryId = factory_id;
  request.post("/api/factories/setting", formInfo.value).then((res) => {
    if (res) {
      operateLogs("修改基础配置");
      message.success(langObj.value["操作成功"]);
      search(formInfo.value.factoryId);
      visible.value = false
      emits("saveWeekReportRule", true);
    }
  });
};

const tabChange = () => {
  var selectFact = factoryList.value[activeKey.value];
  factory_id = selectFact._id;
  search(selectFact._id);
};

const handleTableChange = (val: any) => { };
// 查询
const search = (id: any) => {
  formInfo.value = {
    reportRule: {
      enabled: true,
      dayOfMonth: 0,
      dayOfWeek: [],
      dayOfHour: 0,
      isLogin: false,
    },
  };

  const config = {
    params: { id: id },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/factories/setting", config).then((res: any) => {
    if (res && res.data) {
      if (res.data.reportRule?.dayOfWeek) {
        if (typeof res.data.reportRule.dayOfWeek == "number") {
          res.data.reportRule.dayOfWeek = [res.data.reportRule.dayOfWeek];
        }
      } else {
        res.data.reportRule = {
          enabled: true,
          dayOfMonth: 0,
          dayOfWeek: [],
          dayOfHour: 0,
        };
      }

      formInfo.value = { ...res.data };
    }
  });
};

let formInfo = ref<any>({
  reportRule: {
    enabled: true,
    dayOfMonth: 0,
    dayOfWeek: [],
    dayOfHour: 0,
  },
});
let factoryList = ref<any>();
let treeData: any = ref([]);

const loadFactory = async () => {
  const config = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  await request.get("/api/factories", config).then((res: any) => {
    if (res) {
      factoryListAll.value = JSON.parse(JSON.stringify(res.data));
      let tree: any = [];

      let list1 = res.data.filter((t: any) => t.parentId);

      let list2 = res.data.filter((t: any) => !t.parentId);

      list2.map((d: any) => {
        let first = {
          title: d.factoryName,
          key: d._id,
          children: [],
        };
        let list3 = list1.filter((p: any) => p.parentId == d._id);
        if (list3 && list3.length) {
          first.children = list3.map((p: any) => ({
            title: p.factoryName,
            key: p._id,
          }));
        }
        tree.push(first);
      });

      treeData.value = tree;
    }
  });
};

const selectTree = (ev: any) => {
  if (ev && ev.length) {
    factory_id = ev[0];
    search(ev[0]);
  }
};

onMounted(async () => {
  editRindex.value = props.editRindex
  await loadFactory();
  await selectTree([treeData.value[0].key]);
});

const props: any = __props;
const emits = __emit;

watch(
  () => props.visible,
  (newValue, oldValue) => {
    visible.value = newValue
    init()
  }
);

return (_ctx: any,_cache: any) => {
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_a_tree = _resolveComponent("a-tree")!
  const _component_bank_outlined = _resolveComponent("bank-outlined")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_checkbox_group = _resolveComponent("a-checkbox-group")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_modal, {
      visible: _unref(visible),
      "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
      closable: false,
      footer: null,
      bodyStyle: { 'background': '#020F46' },
      style: {"top":"30%"},
      width: "1200px",
      maskClosable: true,
      "z-index": _unref(editRindex),
      "onUpdate:zIndex": _cache[7] || (_cache[7] = ($event: any) => (_isRef(editRindex) ? (editRindex).value = $event : editRindex = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
          _createElementVNode("div", {
            class: "cursor-pointer",
            onClick: close
          }, [
            _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
          ])
        ]),
        _createVNode(_component_a_layout_content, {
          style: {"padding":"0 20px"},
          class: "flex theme-common"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_tree, {
              style: {"height":"40vh","overflow":"auto","padding":"10px 0"},
              class: "draggable-tree",
              "block-node": "",
              "tree-data": _unref(treeData),
              onSelect: selectTree
            }, null, 8, ["tree-data"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_a_form, {
                model: _unref(formInfo),
                name: "basic",
                autocomplete: "off",
                layout: "inline",
                "label-col": { style: { width: '150px' } },
                style: {"color":"#fff"}
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(langObj)["周报配置"]), 1)
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_a_tabs, {
                      activeKey: _unref(activeKey),
                      "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => (_isRef(activeKey) ? (activeKey).value = $event : activeKey = $event)),
                      onChange: tabChange
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(factoryList), (item, key) => {
                          return (_openBlock(), _createBlock(_component_a_tab_pane, { key: key }, {
                            tab: _withCtx(() => [
                              _createElementVNode("span", null, [
                                _createVNode(_component_bank_outlined),
                                _createTextVNode(" " + _toDisplayString(item.factoryName), 1)
                              ])
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["activeKey"])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(langObj)["报告生成策略"]), 1),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_a_form_item, {
                        label: _unref(langObj)['是否生成报告'],
                        name: "alarmTactics"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_9, [
                            _createVNode(_component_a_radio_group, {
                              name: "radioGroup",
                              value: _unref(formInfo).reportRule.enabled,
                              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_unref(formInfo).reportRule.enabled) = $event))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_radio, { value: false }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_unref(langObj)["否"]), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_radio, { value: true }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_unref(langObj)["是"]), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["value"])
                          ])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_a_form_item, {
                        label: _unref(getLanguage)('是否需要登录查看'),
                        name: "alarmTactics"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_11, [
                            _createVNode(_component_a_radio_group, {
                              name: "radioGroup",
                              value: _unref(formInfo).reportRule.isLogin,
                              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(formInfo).reportRule.isLogin) = $event))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_radio, { value: false }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_unref(langObj)["否"]), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_radio, { value: true }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_unref(langObj)["是"]), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["value"])
                          ])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    false
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          _createVNode(_component_a_form_item, {
                            label: "",
                            name: "deadline",
                            style: {"color":"#fff"}
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(langObj)["每月第"]) + "  ", 1),
                              _createVNode(_component_a_input_number, {
                                min: 1,
                                max: 28,
                                disabled: !_unref(formInfo).reportRule.enabled,
                                value: _unref(formInfo).reportRule.dayOfMonth,
                                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_unref(formInfo).reportRule.dayOfMonth) = $event))
                              }, null, 8, ["disabled", "value"]),
                              _createTextVNode("  " + _toDisplayString(_unref(langObj)["天生成月报"]), 1)
                            ]),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(_component_a_form_item, {
                        label: "",
                        name: "deadline",
                        style: {"color":"#fff"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(langObj)["每周第"]) + "   ", 1),
                          _createVNode(_component_a_checkbox_group, {
                            value: _unref(formInfo).reportRule.dayOfWeek,
                            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(formInfo).reportRule.dayOfWeek) = $event)),
                            name: "checkboxgroup",
                            options: _unref(dayOfWeeks)
                          }, null, 8, ["value", "options"]),
                          _createTextVNode("   " + _toDisplayString(_unref(langObj)["天生成周报"]), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createVNode(_component_a_form_item, {
                        label: "",
                        name: "deadline",
                        style: {"color":"#fff"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(langObj)["第"]) + "  ", 1),
                          _createVNode(_component_a_input_number, {
                            min: 0,
                            max: "23",
                            disabled: !_unref(formInfo).reportRule.enabled,
                            value: _unref(formInfo).reportRule.dayOfHour,
                            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_unref(formInfo).reportRule.dayOfHour) = $event))
                          }, null, 8, ["disabled", "value"]),
                          _createTextVNode("  " + _toDisplayString(_unref(langObj)["小时生成报告"]), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["model"]),
              _createVNode(_component_a_button, {
                type: "primary",
                onClick: handleOk
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(langObj)["保存"]), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["visible", "z-index"])
  ]))
}
}

})