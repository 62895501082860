<template>
  <a-config-provider :locale="language === 'English' ? enUS : zhCN">
    <div class="content-o mt-2 w-full relative theme-common">
      <div class="flex items-center w-full justify-center" style="border-bottom: 1px solid #30406a">
        <div class="flex-1"></div>
        <div @click="selectType(1)" :class="{ 'btn1-select-o': searchModel.select === 1 }" class="py-1 cursor-pointer">
          {{ langObj['报警事件'] }}
        </div>
        <div class="flex-1"></div>
        <div @click="selectType(2)" :class="{ 'btn1-select-o': searchModel.select === 2 }" class="py-1 cursor-pointer">
          {{ langObj['检修事件'] }}
        </div>
        <div class="flex-1"></div>
      </div>

      <div class="mt-2 w-full px-2" v-if="searchModel.select === 1">
        <div class="w-full flex items-center justify-center">
          <div class="btn2-o flex items-center cursor-pointer" @click="addRepair"
            style="padding: 4px 8px;max-width: 145px;line-height: normal;">
            <plus-outlined class="mr-1" /> {{ langObj['添加检修记录'] }}
          </div>
          <div class="btn2-o ml-2 flex items-center cursor-pointer" @click="deleteWaring"
            style="padding: 4px 18px; width: 145px;line-height: normal;">
            <close-outlined class="mr-1" /> {{ langObj['批量关闭报警'] }}
          </div>
        </div>
        <div class="w-full flex items-center justify-center mt-2">
          <div class="btn2-o  items-center cursor-pointer" :class="searchModel.type == 1?'btn2-o-s':''" @click="warnFun(1)"
            style="max-width: 145px;padding: 4px 31px;line-height: normal;">
            {{ langObj['智能诊断'] }}
          </div>
          <div class="btn2-o ml-2  items-center cursor-pointer" :class="searchModel.type == 4?'btn2-o-s':''" @click="warnFun(4)"
            style="width: 145px;padding: 4px 0;text-align: center;line-height: normal;">
            {{ langObj['门限报警'] }}
          </div>
        </div>

        <div class="mt-2  w-full flex items-center justify-center">
          <div @click="changeSort" class="btn2-o flex items-center cursor-pointer mr-2"
            style="padding: 4px 8px; width: 120px;justify-content: center;line-height: normal;">
            <block v-if="theme == 'white'">

            </block>
            <block v-else>
              <img v-if="searchModel.sort === -1" style="width: 16px; height: 16px" src="@/assets/sort-up.png" alt="" />
              <img v-if="searchModel.sort === 1" style="width: 16px; height: 16px" src="@/assets/sort-down.png"
                alt="" />

            </block>

            <div class="ml-2 workColor">{{ langObj['排序'] }}</div>
          </div>
          <!-- <div class="flex-1"></div> -->
          <a-select ref="select" v-model:value="searchModel.condition" style="width: 144px;height: 28px;"
            :placeholder="langObj['严重程度']" @change="getOrderData" allowClear>
            <a-select-option v-for="(item, index) in conditionLists" :key="index" :value="item.value">
              {{ langObj[item.label] }}
            </a-select-option>
          </a-select>
          <!-- <div class="flex-1"></div> -->
          <!-- <a-select
          ref="select"
          v-model:value="searchModel.type"
          style="width: 100px"
          :placeholder="langObj['事件类型']"
          @change="getOrderData"
          allowClear
        >
          <a-select-option
            v-for="(item, index) in typeList"
            :key="index"
            :value="item.value"
          >
            {{ langObj[item.label] }}
          </a-select-option>
        </a-select> -->
        </div>
      </div>

      <!-- 工单列表 -->
      <div class="w-full px-2 mt-2">
        <div class="w-full mt-2 cursor-pointer workBg" @click="viewReport(item)" v-for="(item, index) in workFlowList"
          :key="index">
          <div v-if="item.type === 1" class="w-full flex items-center">
            <img v-if="item.expertCertification" class="img-zhuanjia" src="@/assets/zhuanjia.png" alt="" />
            <img class="img-ico" src="@/assets/zhenduan.png" alt="" />
            <div class="ml-2" :style="{
              color: conditionList.find((p) => p.value === item.condition)
                ?.color,
            }">
              {{ langObj['诊断报警'] }} :
              {{ langObj[conditionList.find((p) => p.value === item.condition)?.label] }}
            </div>
            <div class="flex-1"></div>
            <div class="box1-o" v-if="item.reportIds && item.reportIds.length">
              {{ langObj['诊断报告'] }}
            </div>
          </div>

          <div v-if="item.type === 2" class="w-full flex items-center">
            <img v-if="item.expertCertification" class="img-zhuanjia" src="@/assets/zhuanjia.png" alt="" />
            <img class="img-ico" src="@/assets/weixiu.png" alt="" />
            <div class="ml-2">{{ langObj['现场维修'] }}</div>
            <div class="flex-1"></div>
            <div class="box1-o" v-if="item.reportIds && item.reportIds.length">
              {{ langObj['检修报告'] }}
            </div>
          </div>

          <div v-if="item.type === 3" class="w-full flex items-center">
            <img v-if="item.expertCertification" class="img-zhuanjia" src="@/assets/zhuanjia.png" alt="" />
            <img class="img-ico" src="@/assets/jiancha.png" alt="" />
            <div class="ml-2">{{ langObj['现场检查'] }}</div>
            <div class="flex-1"></div>
            <div class="box1-o" v-if="item.reportIds && item.reportIds.length">
              {{ langObj['检修报告'] }}
            </div>
          </div>

          <div v-if="item.type === 4" class="w-full flex items-center">
            <img v-if="item.expertCertification" class="img-zhuanjia" src="@/assets/zhuanjia.png" alt="" />
            <img class="img-ico" src="@/assets/menxian.png" alt="" />
            <div class="ml-2">{{ langObj['门限报警'] }}</div>
          </div>

          <div class="mt-2 word-break">
            {{ item.diagnosticNotes }}
          </div>

          <div class="mt-2 opt6">
            {{ item.date }}
          </div>
        </div>
      </div>

      <div class="flex my-4 cursor-pointer">
        <div class="flex-1"></div>
        <div @click="viewMore" class="mr-4 flex items-center opt6">
          {{ langObj['查看更多'] }} <right-outlined class="ml-1" />
        </div>
      </div>

      <!-- 新增检修记录 -->
      <a-modal v-model:visible="visibleAdd" :closable="false" :footer="null" :bodyStyle="{ background: '#020F46' }"
        style="top: 15%" width="1200px" :maskClosable="false">
        <div style="color: #fff">
          <div class="w-full flex">
            <div class="flex-1"></div>
            <div class="cursor-pointer" @click="visibleAdd = false">
              <close-outlined style="font-size: 20px" />
            </div>
          </div>
          <div class="text-center w-full" style="font-size: 24px">
            {{ langObj['添加检修记录'] }}
          </div>

          <div class="w-full flex items-center">
            <span style="font-size: 20px"> {{ langObj['故障信息(请勾选此次维修的故障项)'] }}
            </span>
            <!-- <div class="btn3-o">添加故障</div> -->
          </div>

          <a-table :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }" :columns="columns" :data-source="nohandleList" :pagination="true" rowKey="_id"
            style="background-color: #020f46">
            <template #bodyCell="{ column, text }">
              <template v-if="column.dataIndex === 'condition'">
                <div class="flex items-center">
                  <div class="ml-2">
                    {{ langObj[conditionList.find((p) => p.value === text)?.label] }}
                  </div>
                </div>
              </template>
              <template v-if="column.dataIndex === 'type'">
                  <div class="flex items-center">
                    <div v-if="text === 1">
                      <img style="width: 20px" src="@/assets/zhenduan.png" alt="" />
                    </div>
                    <div v-if="text === 2">
                      <img style="width: 20px" src="@/assets/weixiu.png" alt="" />
                    </div>
                    <div v-if="text === 3">
                      <img style="width: 20px" src="@/assets/jiancha.png" alt="" />
                    </div>
                    <div v-if="text === 4">
                      <img style="width: 20px" src="@/assets/menxian.png" alt="" />
                    </div>
                    <div class="ml-2">
                      {{
                        langObj[typeList.find((p) => p.value === text)?.label]
                      }}
                    </div>
                  </div>
                </template>
            </template>
          </a-table>

          <div class="mt-4" style="font-size: 20px">{{ langObj['维修详情'] }}</div>

          <a-form ref="formRef" name="advanced_search" class="ant-advanced-search-form" :model="form"
            :label-col="{ style: { width: '180px' } }">
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-item name="begin" :label="langObj['开始时间']"
                  :rules="[{ required: true, message: langObj['请选择开始时间'] }]" :allowClear="false">
                  <a-date-picker v-model:value="form.begin" show-time :placeholder="langObj['请选择时间']" />
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item name="end" :label="langObj['结束时间']"
                  :rules="[{ required: true, message: langObj['请选择结束时间'] }]" :allowClear="false">
                  <a-date-picker v-model:value="form.end" show-time :placeholder="langObj['请选择时间']" />
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item name="value" :label="langObj['设备名称']" :rules="[{ required: true }]">
                  <a-input v-model:value="form.machineName" style="width: 210px" readonly />
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item name="value" :label="langObj['设备区域']" :rules="[{ required: true }]">
                  <a-input v-model:value="form.groupName" style="width: 210px" readonly />
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item name="maintainPerson" :label="langObj['检修人员']" :rules="[{ required: true }]">
                  <a-input v-model:value="form.maintainPerson" style="width: 210px" />
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item name="condition" :label="langObj['现场设备状态']" :rules="[{ required: true }]">
                  <a-select v-model:value="form.condition" style="width: 210px" :placeholder="langObj['请选择现场设备状态']"
                    allowClear>
                    <a-select-option v-for="(item, index) in conditionAdd" :key="index" :value="item.value">
                      {{ langObj[item.label] }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :span="24">
                <a-form-item name="faultCause" :label="langObj['故障原因']" :rules="[{ required: true }]">
                  <a-textarea v-model:value="form.faultCause" :placeholder="langObj['请输入故障原因']" :rows="4" />
                </a-form-item>
              </a-col>

              <a-col :span="24">
                <a-form-item name="maintainMessage" :label="langObj['检修内容']" :rules="[{ required: true }]">
                  <a-textarea v-model:value="form.maintainMessage" :placeholder="langObj['请输入检修内容']" :rows="4" />
                </a-form-item>
              </a-col>

              <a-col :span="24">
                <a-form-item style="margin-left: 10px" name="value" :label="langObj['检修图片']">
                  <a-upload v-model:file-list="fileList" list-type="picture-card" @preview="handlePreview"
                    :custom-request="customRequest" :auto-upload="false" accept="image" :max-count="9">
                    <div v-if="!fileList || fileList.length < 9">
                      <plus-outlined class="opt6" style="color: #fff" />
                      <div class="opt6" style="margin-top: 8px; color: #fff">
                        {{ langObj['上传'] }}
                      </div>
                    </div>
                  </a-upload>
                  <a-modal :visible="previewVisible" :title="previewTitle" :footer="null" @cancel="handleCancel">
                    <img alt="example" style="width: 100%" :src="previewImage" />
                  </a-modal>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>

          <div class="w-full flex justify-center">
            <div @click="submitAdd" style="
              background-color: #072499;
              padding: 5px 10px;
              width: 100px;
              font-size: 20px;
              text-align: center;
              cursor: pointer;
            " class="btn-submit">
              {{ langObj['提交'] }}
            </div>
          </div>
        </div>
      </a-modal>

      <!-- 批量关闭报警 -->
      <a-modal v-model:visible="visibledel" :closable="false" :footer="null" :bodyStyle="{ background: '#020F46' }"
        style="top: 30%" width="1200px" :maskClosable="false">
        <div style="color: #fff">
          <div class="w-full flex">
            <div class="flex-1"></div>
            <div class="cursor-pointer" @click="visibledel = false">
              <close-outlined style="font-size: 20px" />
            </div>
          </div>
          <div class="text-center w-full" style="font-size: 24px">{{ langObj['关闭报警'] }}</div>

          <div class="w-full flex items-center">
            <span style="font-size: 20px">{{ langObj['故障信息（请勾选此次关闭的故障项）'] }}</span>
          </div>

          <a-table :row-selection="{
            selectedRowKeys: selectedRowKeysDel,
            onChange: onSelectChangeDel,
          }" :columns="columns" :data-source="nohandleList" :pagination="true" rowKey="_id"
            style="background-color: #020f46">
            <template #bodyCell="{ column, text }">
              <template v-if="column.dataIndex === 'condition'">
                <div class="flex items-center">
                  <div style="width: 12px; height: 12px; border-radius: 50%" :style="{
                    background: conditionList.find((p:any) => p.value === text)
                      ?.color,
                  }"></div>
                  <div class="ml-2">
                    {{ langObj[conditionList.find((p) => p.value === text)?.label] }}
                  </div>
                </div>
              </template>
              <template v-if="column.dataIndex === 'type'">
                  <div class="flex items-center">
                    <div v-if="text === 1">
                      <img style="width: 20px" src="@/assets/zhenduan.png" alt="" />
                    </div>
                    <div v-if="text === 2">
                      <img style="width: 20px" src="@/assets/weixiu.png" alt="" />
                    </div>
                    <div v-if="text === 3">
                      <img style="width: 20px" src="@/assets/jiancha.png" alt="" />
                    </div>
                    <div v-if="text === 4">
                      <img style="width: 20px" src="@/assets/menxian.png" alt="" />
                    </div>
                    <div class="ml-2">
                      {{
                        langObj[typeList.find((p) => p.value === text)?.label]
                      }}
                    </div>
                  </div>
                </template>
            </template>
          </a-table>

          <a-form ref="formRef" name="advanced_search" class="ant-advanced-search-form" style="margin-top: 20px"
            :model="formClose">
            <a-row :gutter="24">
              <a-col :span="24">
                <a-form-item name="closeCause" :label="langObj['关闭原因']" required>
                  <a-textarea v-model:value="formClose.closeCause" :placeholder="langObj['请输入关闭原因']" :rows="4" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>

          <div class="w-full flex justify-center">
            <div @click="submitClose" style="
              background-color: #072499;
              padding: 5px 10px;
              width: 100px;
              font-size: 20px;
              text-align: center;
              cursor: pointer;
            " class="btn-submit">
              {{ langObj['提交'] }}
            </div>
          </div>
        </div>
      </a-modal>

      <div v-if="retporId" style="position: absolute; left: -1200px; width: 1200px; top: 0">
        <MaintenanceReportDetailComponent v-if="retportype === 2 || retportype === 3" ref="maint" @cancel="cancel">
        </MaintenanceReportDetailComponent>
        <DiagnosticReportDetails2Component v-if="retportype === 1" ref="Diagn" @cancel="cancel">
        </DiagnosticReportDetails2Component>
      </div>
    </div>
  </a-config-provider>
</template>

<script lang="ts" setup>
import {
  computed,
  defineProps,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { transformDate, getGroupName } from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import MaintenanceReportDetailComponent from "../report4/common/maintenanceReportDetails.vue";
import DiagnosticReportDetails2Component from "../report4/common/DiagnosticReportDetails2.vue";
import { message } from "ant-design-vue";
import { langList } from "../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
const router = useRouter();
let value = "";
let visibleAdd = ref(false);
let visibledel = ref(false);
let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
  theme.value = localStorage.getItem('theme') || 'blue'
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}
getLang()

let selectedRowKeys = ref([]);
// const pagination = computed(() => ({
//   total: searchModel.value.total,
//   current: searchModel.value.skip,
//   pageSize: searchModel.value.take,
// }));
let conditionList = [
  { label: "健康", value: 0, color: "#61c08f" },
  { label: "可用", value: 1, color: "#d2de49" },
  { label: "可用", value: 2, color: "#d2de49" },
  { label: "警戒", value: 3, color: "#cd5f3b" },
  { label: "警戒", value: 4, color: "#cd5f3b" },
  { label: "警戒", value: 5, color: "#cd5f3b" },
  { label: "故障", value: 6, color: "#921e37" },
  { label: "故障", value: 7, color: "#921e37" },
];

let conditionLists = [
  { label: "健康", value: 1, real: [0] },
  { label: "可用", value: 2, real: [1, 2] },
  { label: "警戒", value: 3, real: [3, 4, 5] },
  { label: "故障", value: 4, real: [6, 7] },
];
let typeList = [
  { label: "智能诊断", value: 1 },
  // {label: '维修记录', value: 2},
  // {label: '设备检查', value: 3},
  { label: "门限报警", value: 4 },
];
let searchModel: any = ref({
  sort: -1,
  condition: null,
  type: 1,
  select: 1,
  typeList: [1, 4],
});
const props: any = defineProps({
  machineInfo: null,
});
let machine_id = ref("");
let machineInfo: any = ref({});
let workFlowList: any = ref([]);
let orderId = ref();
let retporId = ref();
let retportype = ref();
let maint = ref();
let Diagn = ref();

watch(
  () => props.machineInfo,
  (newValue, oldValue) => {
    machine_id.value = props.machineInfo.id;
    machineInfo.value = props.machineInfo;
    init();
  }
);

onMounted(() => {
  machine_id.value = props.machineInfo?.id;
  machineInfo.value = props.machineInfo;
  init();
});

const init = () => {
  workFlowList.value = [];
  if (!machine_id.value) return;
  getOrderData();
};



const changeSort = () => {
  if (searchModel.value.sort == 1) {
    searchModel.value.sort = -1;
  } else {
    searchModel.value.sort = 1;
  }

  getOrderData();
};

const selectType = (ev: any) => {
  searchModel.value = {
    sort: -1,
    condition: null,
    type: null,
    select: ev,
    typeList: [],
  };
  if (ev === 1) {
    searchModel.value.typeList = [1, 4];
  }
  if (ev === 2) {
    searchModel.value.typeList = [2, 3];
  }

  getOrderData();
};
const warnFun = (type: any) => {
  searchModel.value.type = type;
  getOrderData()
}

const getOrderData = async () => {
  let config: any = {
    params: {
      skip: 1,
      take: 7,
      machineId: machine_id.value,
      type: searchModel.value.type,
      sort: searchModel.value.sort,
      typeList: searchModel.value.typeList,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  if (searchModel.value.condition) {
    config.params.conditionList = conditionLists.find(
      (p: any) => p.value === searchModel.value.condition
    )?.real;
  }

  let result = await request.get("/api/workflows", config);

  if (result) {
    workFlowList.value = result.data.list;
  }
};

const viewReport = (ev: any) => {
  console.log("ev", ev);
  orderId.value = ev.id;
  retportype.value = ev.type;
  if (ev.reportIds.length) {
    retporId.value = ev.reportIds[0];
  }

  setTimeout(() => {
    if ((ev.type === 2 || ev.type === 3) && retporId.value) {
      maint.value.show(retporId.value);
    }
  }, 500);

  setTimeout(() => {
    if (ev.type === 1 && retporId.value) {
      Diagn.value.show(retporId.value);
    }
  }, 500);
};

const cancel = () => {
  retporId.value = null;
  retportype.value = null;
};

const onSelectChange = (ev: any) => {
  selectedRowKeys.value = ev;
};

// 添加检修记录
let nohandleList: any = ref([]);
let columns = [
  {
    title: langObj.value["报警时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["报警类型"],
    dataIndex: "type",
  },
  {
    title: langObj.value["严重程度"],
    dataIndex: "condition",
  },
  {
    title: langObj.value["故障描述"],
    dataIndex: "diagnosticNotes",
  },
];
let conditionAdd = [
  { label: "健康", value: 0 },
  { label: "可用", value: 1 },
  { label: "警戒", value: 3 },
  { label: "故障", value: 6 },
];
let form: any = ref({
  value: "",
  begin: null,
  end: null,
  type: 2,
  machineName: "",
  machineId: "",
  groupId: "",
  groupName: "",
  factoryId: "",
  condition: null,
  faultCause: "",
});

const addRepair = () => {
  machineInfo.value.groupName = getGroupName(machineInfo.value.groupId);
  form.value.groupName = machineInfo.value.groupName;
  form.value.machineName = machineInfo.value.machineName;
  form.value.machineId = machineInfo.value.id;
  form.value.groupId = machineInfo.value.groupId;
  form.value.factoryId = machineInfo.value.factoryId;
  form.value.maintainPerson = JSON.parse(
    localStorage.getItem("memberInfo") || ""
  )?.nickName;
  nohandleList.value = [];
  visibleAdd.value = true;
  getNotHandleOrder();
};
const handleTableChange = (pagination: any, filters: any, sorter: any) => {
  // 处理分页数据
  // if (Object.keys(sorter).length) {
  //   var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
  //   searchModel.value.sort = sorter.field;
  //   searchModel.value.order = order;
  // }
  // // 处理分页数据
  // searchModel.value.skip = pagination.current;
  // searchModel.value.take = pagination.pageSize;
  // getData();
};


//获取未处理的诊断工单
const getNotHandleOrder = async () => {
  let config: any = {
    params: {
      machineId: machine_id.value,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/workflows/nohandle", config);
  if (result && result.data) {
    nohandleList.value = result.data;
  }
};

const submitAdd = async () => {
  let submit = JSON.parse(JSON.stringify(form.value));
  if (!submit.begin) {
    message.warning(langObj.value["请选择开始时间"]);
    return;
  } else {
    submit.begin = transformDate(submit.begin);
  }

  if (!submit.end) {
    message.warning(langObj.value["请选择结束时间"]);
    return;
  } else {
    submit.end = transformDate(submit.end);
  }

  if (!submit.maintainPerson) {
    message.warning(langObj.value["请填写检修人员"]);
    return;
  }

  if (!submit.condition && submit.condition !== 0) {
    message.warning(langObj.value["请选择现场设备状态"]);
    return;
  }

  if (!submit.faultCause) {
    message.warning(langObj.value["请填写故障原因"]);
    return;
  }

  if (!submit.maintainMessage) {
    message.warning(langObj.value["请填写检修内容"]);
    return;
  }

  let list = nohandleList.value.filter((p: any) =>
    selectedRowKeys.value.includes(p._id)
  );

  submit.list = list.map((d: any) => ({
    date: d.date,
    condition: d.condition,
    diagnosticNotes: d.diagnosticNotes,
    id: d._id,
  }));
  

  if (fileList.value && fileList.value.length) {
    submit.pictures = fileList.value.map((p: any) => ({
      url: p.url,
    }));
  } else {
    submit.pictures = [];
  }

  console.log(submit.pictures)

  let data: any = {
    repairReport: submit,
    date: submit.end,
    machineIds: [machine_id.value],
    factoryId: submit.factoryId,
    pictures: submit.pictures,
    type: 2,
    workflow: true,
  };

  let result = await request.post("/api/reports", data);

  if (result && result.status) {
    message.success(langObj.value["新增成功"]);
    visibleAdd.value = false;
    getOrderData();
  } else {
    message.warning(langObj.value["操作失败"] + "!");
  }
};

//批量关闭报警
let formClose = ref({
  closeCause: "",
});

const deleteWaring = () => {
  formClose.value.closeCause = "";
  nohandleList.value = [];
  visibledel.value = true;
  getNotHandleOrder();
};

// 关闭报警
let selectedRowKeysDel: any = ref([]);

const onSelectChangeDel = (ev: any) => {
  selectedRowKeysDel.value = ev;
};

const submitClose = async () => {
  if (!formClose.value.closeCause) {
    message.warning(langObj.value["请填写关闭原因"]);
    return;
  }
  if (!selectedRowKeysDel.value || !selectedRowKeysDel.value.length) {
    message.warning(langObj.value["请选择关闭的故障项"]);
    return;
  }

  let form = {
    closeCause: formClose.value.closeCause,
    list: [],
  };

  let list = nohandleList.value.filter((p: any) =>
    selectedRowKeysDel.value.includes(p._id)
  );
  if (list && list.length) {
    form.list = list.map((d: any) => ({
      date: d.date,
      condition: d.condition,
      diagnosticNotes: d.diagnosticNotes,
      id: d._id,
    }));
  }
  let result = await request.post("/api/workflows/close", form);
  if (result && result.status == 200) {
    message.success(langObj.value["关闭成功"] + "!");
    visibledel.value = false;
  } else {
    message.warning(langObj.value["操作失败"]);
  }
};

//**图片上传* */
let fileList = ref<any>([]);
const previewVisible = ref(false);
const previewImage = ref("");
const previewTitle = ref("");
const handleCancel = () => {
  previewVisible.value = false;
  previewTitle.value = "";
};

const handlePreview = async (file: any) => {
  previewImage.value = file.url;
  previewVisible.value = true;
  previewTitle.value = file.url;
};

let customRequest = async (options: any) => {
  const { onSuccess, onError, file } = options;
  let formData = new FormData();
  console.log('file', file)
  console.log('formData', formData)
  formData.append("file", file); // file为要上传的文件
  const config = {
    headers: {
      requestId: uuidv4(),
      "Content-Type": "multipart/form-data",
    },
  };
  let res = await request.post("/api/upload", formData, config);
  if (res) {
    file.url = res.data;
    onSuccess({ url: file.url, status: "done" });
  }
};

// 查看更多
const viewMore = () => {
  if (searchModel.value.select === 1) {
    router.push("/warning-manage/warning-list");
  }
  if (searchModel.value.select === 2) {
    localStorage.setItem("report_type", "2");
    router.push("/report/list");
  }
};

// 关闭所有弹窗
const closeAll = () => {
  cancel();
  searchModel.value = {
    sort: -1,
    condition: null,
    type: 1,
    select: 1,
    typeList: [1, 4],
  };
};

defineExpose({
  closeAll,
});
</script>

<style lang="less" scoped>
/deep/ .ant-table {
  color: #fff;
}

/deep/ .ant-table-thead .ant-table-cell {
  background-color: #020f46;
  color: #fff;
}

/deep/ table tbody tr:hover>td {
  background-color: #020f46 !important;
}

/deep/ .ant-table-tbody>tr>td.ant-table-cell-row-hover {
  background: #020f46 !important;
}

/deep/ .ant-table-tbody>tr>td {
  transition: 0s;
}

/deep/ .ant-table-tbody>tr.ant-table-row-selected>td {
  background: transparent;
  border-color: #fff;
}

/deep/ .ant-empty-normal {
  color: #fff !important
}

/deep/ .ant-form-item-label>label {
  color: #fff;
}

/deep/ .ant-picker {
  background: transparent;
  border: 1px solid #0d53b7cc;
}

/deep/ .ant-picker-suffix {
  color: #fff;
  opacity: 0.6;
}

/deep/ .ant-input {
  background: transparent;
  border: 1px solid #0d53b7cc;
  color: #fff;
}

/deep/ .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  background-color: transparent;
  color: #fff;
}

/deep/ .ant-upload.ant-upload-select-picture-card {
  background-color: transparent;
  border: 1px solid #0d53b7cc;
}

.content-o {
  border: 1px solid #0d53b7cc;
  background: #07249933;
}

/deep/ .ant-btn-primary {
  background: #0d53b7cc !important;
  color: #fff !important;
}

.ant-picker-ok {
  .ant-btn-sm {
    color: #000 !important;
  }
}

.btn1-o {}


.btn1-select-o {
  border-bottom: 1px solid #00fff4;
}

.btn2-o {
  border-radius: 2px;
  border: 1px solid #0d53b7cc;
  background: #07249966;
 
}
.btn2-o-s{
    background: #072499;
  }


.btn3-o {
  background: #072499;
  padding: 3px 10px;
  cursor: pointer;
}

.img-ico {
  width: 20px;
  height: 20px;
}

.cursor-pointer {
  position: relative;
}

.img-zhuanjia {
  position: absolute;
  right: 28%;
  top: 0px;
}

.box1-o {
  border: 1px solid #ffffff66;
  border-radius: 2px;
  padding: 1px 4px;
}

.opt6 {
  opacity: 0.6;
}

/deep/ .ant-select-clear span {
  background-color: #000;
}
/deep/ .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 28px;
}
.word-break{
  word-break: break-all;
}

</style>
