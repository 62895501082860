import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, isRef as _isRef, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/facIco.png'
import _imports_1 from '@/assets/groupIco.png'


const _hoisted_1 = {
  key: 0,
  class: "box",
  style: {"width":"250px","height":"85vh","overflow":"auto"}
}
const _hoisted_2 = { class: "w-full flex items-center" }
const _hoisted_3 = {
  class: "w-full",
  style: {"color":"#fff"}
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["title"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["title"]
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "flex-1 theme-font-black-color" }
const _hoisted_12 = {
  key: 1,
  class: "box",
  style: {"width":"250px","height":"85vh","overflow":"auto"}
}
const _hoisted_13 = { class: "w-full flex items-center" }
const _hoisted_14 = {
  class: "w-full mt-3",
  style: {"color":"#fff"}
}
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["title"]
const _hoisted_17 = { key: 0 }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = ["title"]
const _hoisted_20 = { key: 0 }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = ["title"]
const _hoisted_23 = { key: 0 }
const _hoisted_24 = ["onClick"]
const _hoisted_25 = { class: "ml-1 flex-1 ellipsis theme-font-black-color" }
const _hoisted_26 = {
  key: 2,
  class: "box",
  style: {"width":"250px","height":"85vh","overflow":"auto"}
}
const _hoisted_27 = { class: "w-full flex items-center" }
const _hoisted_28 = {
  class: "w-full",
  style: {"color":"#fff"}
}
const _hoisted_29 = ["onClick"]
const _hoisted_30 = ["title"]
const _hoisted_31 = { key: 0 }
const _hoisted_32 = ["title", "onClick"]
const _hoisted_33 = { key: 0 }
const _hoisted_34 = ["onClick"]
const _hoisted_35 = { class: "flex-1 theme-font-black-color" }

import { onMounted, ref, watch } from "vue";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import { langList } from "../../common/lang";

export default /*@__PURE__*/_defineComponent({
  __name: 'machine-list-left',
  props: {
    type: {},
    from: {},
    mode: {}
  },
  emits: ['selectMachine', 'selectSensor', 'selectGroup'],
  setup(__props: any, { emit: __emit }) {

let dataList: any = ref([])
let selectId: any = ref('')
let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
  theme.value = localStorage.getItem('theme') || 'blue'
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}
getLang()
let conditionList = [
  { label: langObj.value['健康'], value: [0], color: '#61c08f' },
  { label: langObj.value['可用'], value: [1, 2], color: '#d2de49' },
  { label: langObj.value['警戒'], value: [3, 4, 5], color: '#cd5f3b' },
  { label: langObj.value['故障'], value: [6, 7], color: '#921e37' },
]

const props = __props

let type = ref(props.type || 1);

watch(
  () => props.type,
  () => {
    type.value = props.type || 1;
    init();
  }
);

let search = ref('')

onMounted(() => {
  init()
})

//获取设备列表/测点列表
const init = () => {
  if (props.type === 1 || props.type === 3) {
    if (localStorage.getItem('machine_id')) {
      emits('selectMachine', localStorage.getItem('machine_id'))
    }
    getMachineList()
  }

  if (props.type === 2) {
  
    
    if (localStorage.getItem('sensor_id')) {
      emits('selectSensor', localStorage.getItem('sensor_id'))
    }
    getSensorList()
  }
}

const getSensorList = async () => {
  let memberInfo: any = JSON.parse(localStorage.getItem('memberInfo') || '')
  let factory_id = localStorage.getItem('factory_id')
  let factorys = memberInfo.factories.filter((p: any) => p.id === factory_id || p.parentId === factory_id)
  let factoryList: any = []
  if (factorys.length > 1) {
    factoryList = factorys.filter((p: any) => p.parentId)
  } else {
    factoryList = factorys
  }
  let factoryIds = factoryList.map((p: any) => p.id)
  let config: any = {
    params: {
      factoryIds,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  if (props.mode) {
    config.params.mode = props.mode;
  }
  let result = await request.get('/api/machinesall', config)

  let machienAll = result ? result.data : []
  let machineIds = machienAll.map((p: any) => p.id)
  factoryList.map((fac: any) => {
    let groups = memberInfo.groups.filter((p: any) => p.factoryId === fac.id)
    fac.groupList = groups
    fac.isShow = false
    if (search.value) {
      fac.isShow = true
    }
    for (const group of fac.groupList) {
      let machineList = memberInfo.machines.filter((p: any) => p.groupId === group.id)
      machineList = machineList.filter((p: any) => machineIds.includes(p.id));

      for (const machine of machineList) {
        let machineInfo = machienAll.find((p: any) => p.id === machine.id)
        if (machineInfo && machineInfo.workStatus && machineInfo.workStatus.stableCondition) {
          machine.condition = conditionList.find((p: any) => p.value.includes(machineInfo.workStatus.stableCondition))
        } else {
          machine.condition = conditionList[0]
        }
        if (machineInfo) {
          let sensorList1 = JSON.parse(JSON.stringify(machineInfo.sensorList))
          if (search.value) {
            sensorList1 = sensorList1.filter((p: any) => p.sensorPlace.includes(search.value))
          }
          sensorList1.map((s: any) => {
            let score = 100
            let conditionS = 0
            if (machineInfo?.workStatus?.stableSensors?.length > 0) {
              let sInfo = machineInfo.workStatus.stableSensors.find((p: any) => p.id === s.id)
              if (sInfo) {
                let scInfo = JSON.parse(JSON.stringify(sInfo))
                delete scInfo.id
                let scores: any = Object.values(scInfo) || []
                if (scores?.length > 0) {
                  score = Math.min(...scores);
                }
              }
            }
            if (score >= 0 && score < 30) {
              conditionS = 6
            }
            if (score >= 30 && score < 60) {
              conditionS = 3
            }
            if (score >= 60 && score < 80) {
              conditionS = 1
            }
            if (score >= 80 && score <= 100) {
              conditionS = 0
            }
            s.condition = conditionList.find((p: any) => p.value.includes(conditionS))
          })
          machine.sensorList = sensorList1

        } else {
          machine.sensorList = []
        }
        machine.isShow = false
        if (search.value) {
          group.isShow = true
          fac.isShow = true
          machine.isShow = true
        }
      }
      group.machineList = machineList
      group.isShow = false
      if (search.value) {
        group.isShow = true
        fac.isShow = true
      }
    }
    fac.groupList = fac.groupList.filter((t: any) => t.machineList.length);

  })
  dataList.value = factoryList
  if (!search.value) {
    getSensor()
  }
}

//读取缓存测点
const getSensor = () => {
  let sensor_id = localStorage.getItem('sensor_id')
  let machine_id = localStorage.getItem('machine_id')
  if (machine_id) {
    dataList.value.map((d1: any) => {
      d1.groupList && d1.groupList.map((d2: any) => {
        if (d2.machineList?.length > 0) {
          let machineInfo = d2.machineList.find((p: any) => p.id === machine_id)
          if (machineInfo?.sensorList?.length > 0) {
            let info = machineInfo.sensorList[0]
            if (info) {
              d1.isShow = true
              d2.isShow = true
              machineInfo.isShow = true
              selectId.value = info.id
              localStorage.setItem('sensor_id', selectId.value)
              emits('selectSensor', selectId.value)
            }
          }
        }
      })
    })
  } else {
    let sensorId = dataList.value[0]?.groupList[0]?.machineList[0]?.sensorList[0]?.id
    if (sensorId) {
      selectId.value = sensorId
      dataList.value[0].isShow = true
      dataList.value[0].groupList[0].isShow = true
      dataList.value[0].groupList[0].machineList[0].isShow = true
      localStorage.setItem('sensor_id', sensorId)
      emits('selectSensor', sensorId)
    }
  }




  // if(sensor_id) {
  //     selectId.value = sensor_id
  //     dataList.value.map((d1:any) => {
  //         d1.groupList && d1.groupList.map((d2:any) => {
  //             d2.machineList && d2.machineList.map((d3:any) => {
  //                 let info = d3.sensorList.find((p:any) => p.id === sensor_id)
  //                 if(info) {
  //                     d1.isShow = true
  //                     d2.isShow = true
  //                     d3.isShow = true
  //                 }
  //             })
  //         })
  //     })
  // } else {
  //     let sensorId =  dataList.value[0]?.groupList[0]?.machineList[0]?.sensorList[0]?.id
  //     if(sensorId) {
  //         selectId.value = sensorId
  //         dataList.value[0].isShow = true
  //         dataList.value[0].groupList[0].isShow = true
  //         dataList.value[0].groupList[0].machineList[0].isShow = true
  //         localStorage.setItem('sensor_id', sensorId) 
  //         emits('selectSensor', sensorId)
  //     }
  // }
}



// 选择测点
const selectSensor = (ev: any) => {
  
  selectId.value = ev.id
  localStorage.setItem('sensor_id', ev.id)
  emits('selectSensor', ev.id)
  localStorage.setItem('machine_id', ev.machineId)
}

const getMachineList = async () => {
  let memberInfo: any = JSON.parse(localStorage.getItem('memberInfo') || '')
  let factory_id = localStorage.getItem('factory_id')
  let factorys = memberInfo.factories.filter((p: any) => p.id === factory_id || p.parentId === factory_id)
  let factoryList: any = []
  if (factorys.length > 1) {
    factoryList = factorys.filter((p: any) => p.parentId)
  } else {
    factoryList = factorys
  }
  let factoryIds = factoryList.map((p: any) => p.id)
  let config: any = {
    params: {
      factoryIds,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  if (props.mode) {
    config.params.mode = props.mode;
  }
  let result = await request.get('/api/machinesall', config)
  let machienAll = result ? result.data : []
  let machineIds = machienAll.map((p: any) => p.id);
  factoryList.map((fac: any) => {
    fac.groupList = memberInfo.groups.filter((p: any) => p.factoryId === fac.id)
    fac.isShow = !!search.value;

    for (const group of fac.groupList) {
      let machineList = memberInfo.machines.filter((p: any) => p.groupId === group.id)
      if (search.value) {
        machineList = machineList.filter((p: any) => p.name.includes(search.value))
      }
      machineList = machineList.filter((p: any) => machineIds.includes(p.id))

      for (const machine of machineList) {
        let machineInfo = machienAll.find((p: any) => p.id === machine.id)
        if (machineInfo && machineInfo.workStatus && machineInfo.workStatus.stableCondition) {
          machine.condition = conditionList.find((p: any) => p.value.includes(machineInfo.workStatus.stableCondition))
        } else {
          machine.condition = conditionList[0]
        }
        if (machineInfo && machineInfo.workStatus && machineInfo.workStatus.scoreWkStatus) {
          machine.scoreWkStatus = machineInfo.workStatus.scoreWkStatus
        } else {
          machine.scoreWkStatus = 0
        }
        machine.sensorList = machineInfo.sensorList  || []
        
        
        let pictureList: any = machineInfo?.pictures?.length ? machineInfo.pictures : []

        machine.picture = pictureList.find((p: any) => p.type == 1)?.url

      }
      group.machineList = machineList
      group.isShow = !!search.value;
    }

    fac.groupList = fac.groupList.filter((t: any) => t.machineList.length);
  })
  dataList.value = factoryList

  if (!search.value) {
    getMachine()
  }

}


//读取缓存设备
const getMachine = () => {
  let machine_id = localStorage.getItem('machine_id')
  if (machine_id) {
    selectId.value = machine_id
    dataList.value.map((d1: any) => {
      d1.groupList && d1.groupList.map((d2: any) => {
        let info = d2.machineList.find((p: any) => p.id === machine_id)
        if (info) {
          d1.isShow = true
          d2.isShow = true
        }
      })
    })
  } else {
    let machineId = dataList.value[0]?.groupList[0]?.machineList[0]?.id
    if (machineId) {
      selectId.value = machineId
      dataList.value[0].isShow = true
      dataList.value[0].groupList[0].isShow = true
      localStorage.setItem('machine_id', machineId)
      emits('selectMachine', machineId)
    }
  }
}

// 选择设备
const selectMachine = (ev: any) => {
  console.log(ev)
  localStorage.setItem('machine_id', ev.id)
  selectId.value = ev.id
  emits('selectMachine', ev.id)
  if(ev.sensorList.length>0){
    emits('selectSensor', ev.sensorList[0].id)
  }
}

// 选择区域
const selectGroup = (group: any, factory: any) => {
  selectId.value = group.id
  emits('selectGroup', group)
}

const emits = __emit


return (_ctx: any,_cache: any) => {
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_caret_up_outlined = _resolveComponent("caret-up-outlined")!
  const _component_caret_down_outlined = _resolveComponent("caret-down-outlined")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(type) === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_a_input, {
              value: _unref(search),
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_isRef(search) ? (search).value = $event : search = $event)),
              placeholder: _unref(langObj)['请输入设备名称搜索']
            }, {
              suffix: _withCtx(() => [
                _createVNode(_component_search_outlined, { onClick: getMachineList })
              ]),
              _: 1
            }, 8, ["value", "placeholder"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataList), (factory, index1) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index1,
                style: {"margin-top":"30px"}
              }, [
                _createElementVNode("div", {
                  class: "w-full flex items-center cursor-pointer",
                  style: {"font-size":"18px"},
                  onClick: ($event: any) => (factory.isShow = !factory.isShow)
                }, [
                  _cache[3] || (_cache[3] = _createElementVNode("img", {
                    class: "theme-white-black-img",
                    style: {"width":"18px"},
                    src: _imports_0,
                    alt: ""
                  }, null, -1)),
                  _createElementVNode("div", {
                    class: "flex-1 ml-2 ellipsis theme-font-black-color",
                    title: factory.name
                  }, _toDisplayString(factory.name), 9, _hoisted_5),
                  (factory.isShow)
                    ? (_openBlock(), _createBlock(_component_caret_up_outlined, {
                        key: 0,
                        style: {"font-size":"18px"},
                        class: "theme-font-black-color"
                      }))
                    : _createCommentVNode("", true),
                  (!factory.isShow)
                    ? (_openBlock(), _createBlock(_component_caret_down_outlined, {
                        key: 1,
                        style: {"font-size":"18px"},
                        class: "theme-font-black-color"
                      }))
                    : _createCommentVNode("", true)
                ], 8, _hoisted_4),
                (factory.isShow)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(factory.groupList, (group, index2) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "w-full",
                          style: {"margin-top":"30px"},
                          key: index2
                        }, [
                          _createElementVNode("div", {
                            class: "w-full flex items-center cursor-pointer",
                            style: {"font-size":"16px"},
                            onClick: ($event: any) => (group.isShow = !group.isShow)
                          }, [
                            _cache[4] || (_cache[4] = _createElementVNode("img", {
                              class: "theme-white-black-img",
                              style: {"width":"16px"},
                              src: _imports_1,
                              alt: ""
                            }, null, -1)),
                            _createElementVNode("div", {
                              class: "flex-1 ml-2 ellipsis theme-font-black-color",
                              title: group.name
                            }, _toDisplayString(group.name), 9, _hoisted_8),
                            (group.isShow)
                              ? (_openBlock(), _createBlock(_component_caret_up_outlined, {
                                  key: 0,
                                  style: {"font-size":"16px"},
                                  class: "theme-font-black-color"
                                }))
                              : _createCommentVNode("", true),
                            (!group.isShow)
                              ? (_openBlock(), _createBlock(_component_caret_down_outlined, {
                                  key: 1,
                                  style: {"font-size":"16px"},
                                  class: "theme-font-black-color"
                                }))
                              : _createCommentVNode("", true)
                          ], 8, _hoisted_7),
                          (group.isShow)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.machineList, (machine, index3) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    class: "w-full",
                                    style: {"margin-top":"20px"},
                                    key: index3
                                  }, [
                                    _createElementVNode("div", {
                                      onClick: ($event: any) => (selectMachine(machine)),
                                      class: _normalizeClass([{ 'select-machine theme-select-machine': machine.id === _unref(selectId) }, "w-full flex items-center machine"]),
                                      style: {"font-size":"14px"}
                                    }, [
                                      _createElementVNode("div", _hoisted_11, _toDisplayString(machine.name), 1),
                                      _createElementVNode("div", {
                                        style: _normalizeStyle([{ 'background-color': machine.condition.color }, {"width":"10px","height":"10px","border-radius":"50%","margin-right":"5px"}])
                                      }, null, 4)
                                    ], 10, _hoisted_10)
                                  ]))
                                }), 128))
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_unref(type) === 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_a_input, {
              value: _unref(search),
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_isRef(search) ? (search).value = $event : search = $event)),
              placeholder: _unref(langObj)['请输入测点名称搜索']
            }, {
              suffix: _withCtx(() => [
                _createVNode(_component_search_outlined, { onClick: getSensorList })
              ]),
              _: 1
            }, 8, ["value", "placeholder"])
          ]),
          _createElementVNode("div", _hoisted_14, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataList), (factory, index1) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index1,
                style: {"margin-top":"30px"}
              }, [
                _createElementVNode("div", {
                  class: "w-full flex items-center cursor-pointer",
                  style: {"font-size":"18px"},
                  onClick: ($event: any) => (factory.isShow = !factory.isShow)
                }, [
                  _cache[5] || (_cache[5] = _createElementVNode("img", {
                    class: "theme-white-black-img",
                    style: {"width":"18px"},
                    src: _imports_0,
                    alt: ""
                  }, null, -1)),
                  _createElementVNode("div", {
                    class: "flex-1 ml-2 ellipsis theme-font-black-color",
                    title: factory.name
                  }, _toDisplayString(factory.name), 9, _hoisted_16),
                  (factory.isShow)
                    ? (_openBlock(), _createBlock(_component_caret_up_outlined, {
                        key: 0,
                        style: {"font-size":"18px"},
                        class: "theme-font-black-color"
                      }))
                    : _createCommentVNode("", true),
                  (!factory.isShow)
                    ? (_openBlock(), _createBlock(_component_caret_down_outlined, {
                        key: 1,
                        style: {"font-size":"18px"},
                        class: "theme-font-black-color"
                      }))
                    : _createCommentVNode("", true)
                ], 8, _hoisted_15),
                (factory.isShow)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(factory.groupList, (group, index2) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "w-full",
                          style: {"margin-top":"30px"},
                          key: index2
                        }, [
                          _createElementVNode("div", {
                            class: "w-full flex items-center cursor-pointer",
                            style: {"font-size":"16px"},
                            onClick: ($event: any) => (group.isShow = !group.isShow)
                          }, [
                            _cache[6] || (_cache[6] = _createElementVNode("img", {
                              class: "theme-white-black-img",
                              style: {"width":"16px"},
                              src: _imports_1,
                              alt: ""
                            }, null, -1)),
                            _createElementVNode("div", {
                              class: "flex-1 ml-2 ellipsis theme-font-black-color",
                              title: group.name
                            }, _toDisplayString(group.name), 9, _hoisted_19),
                            (group.isShow)
                              ? (_openBlock(), _createBlock(_component_caret_up_outlined, {
                                  key: 0,
                                  style: {"font-size":"16px"},
                                  class: "theme-font-black-color"
                                }))
                              : _createCommentVNode("", true),
                            (!group.isShow)
                              ? (_openBlock(), _createBlock(_component_caret_down_outlined, {
                                  key: 1,
                                  style: {"font-size":"16px"},
                                  class: "theme-font-black-color"
                                }))
                              : _createCommentVNode("", true)
                          ], 8, _hoisted_18),
                          (group.isShow)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.machineList, (machine, index3) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    class: "w-full",
                                    style: {"margin-top":"20px"},
                                    key: index3
                                  }, [
                                    _createElementVNode("div", {
                                      class: "w-full flex items-center machine",
                                      style: {"font-size":"14px"},
                                      onClick: ($event: any) => (machine.isShow = !machine.isShow)
                                    }, [
                                      _createElementVNode("div", {
                                        class: "flex-1 ellipsis theme-font-black-color",
                                        title: machine.name
                                      }, _toDisplayString(machine.name), 9, _hoisted_22),
                                      _createElementVNode("div", {
                                        style: _normalizeStyle([{ 'background-color': machine.condition.color }, {"width":"10px","height":"10px","border-radius":"50%","margin-right":"5px"}])
                                      }, null, 4),
                                      (machine.isShow)
                                        ? (_openBlock(), _createBlock(_component_caret_up_outlined, {
                                            key: 0,
                                            style: {"font-size":"14px"},
                                            class: "theme-font-black-color"
                                          }))
                                        : _createCommentVNode("", true),
                                      (!machine.isShow)
                                        ? (_openBlock(), _createBlock(_component_caret_down_outlined, {
                                            key: 1,
                                            style: {"font-size":"14px"},
                                            class: "theme-font-black-color"
                                          }))
                                        : _createCommentVNode("", true)
                                    ], 8, _hoisted_21),
                                    (machine.isShow)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(machine.sensorList, (sensor, index4) => {
                                            return (_openBlock(), _createElementBlock("div", {
                                              onClick: ($event: any) => (selectSensor(sensor)),
                                              style: {"margin-top":"20px"},
                                              class: _normalizeClass([{ 'select-machine theme-select-machine': sensor.id === _unref(selectId) }, "w-full mt-2 cursor-pointer p-1 flex items-center"]),
                                              key: index4
                                            }, [
                                              _createElementVNode("div", _hoisted_25, _toDisplayString(sensor.sensorPlace), 1),
                                              _createElementVNode("div", {
                                                style: _normalizeStyle([{ 'background-color': sensor.condition.color }, {"width":"10px","height":"10px","border-radius":"50%","margin-right":"5px"}])
                                              }, null, 4)
                                            ], 10, _hoisted_24))
                                          }), 128))
                                        ]))
                                      : _createCommentVNode("", true)
                                  ]))
                                }), 128))
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_unref(type) === 3)
      ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
          _createElementVNode("div", _hoisted_27, [
            _createVNode(_component_a_input, {
              value: _unref(search),
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_isRef(search) ? (search).value = $event : search = $event)),
              placeholder: _unref(langObj)['请输入设备名称搜索']
            }, {
              suffix: _withCtx(() => [
                _createVNode(_component_search_outlined, { onClick: getMachineList })
              ]),
              _: 1
            }, 8, ["value", "placeholder"])
          ]),
          _createElementVNode("div", _hoisted_28, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataList), (factory, index1) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index1,
                style: {"margin-top":"30px"}
              }, [
                _createElementVNode("div", {
                  class: "w-full flex items-center cursor-pointer",
                  style: {"font-size":"18px"},
                  onClick: ($event: any) => (factory.isShow = !factory.isShow)
                }, [
                  _cache[7] || (_cache[7] = _createElementVNode("img", {
                    class: "theme-white-black-img",
                    style: {"width":"18px"},
                    src: _imports_0,
                    alt: ""
                  }, null, -1)),
                  _createElementVNode("div", {
                    class: "flex-1 ml-2 ellipsis theme-font-black-color",
                    title: factory.name
                  }, _toDisplayString(factory.name), 9, _hoisted_30),
                  (factory.isShow)
                    ? (_openBlock(), _createBlock(_component_caret_up_outlined, {
                        key: 0,
                        style: {"font-size":"18px"},
                        class: "theme-font-black-color"
                      }))
                    : _createCommentVNode("", true),
                  (!factory.isShow)
                    ? (_openBlock(), _createBlock(_component_caret_down_outlined, {
                        key: 1,
                        style: {"font-size":"18px"},
                        class: "theme-font-black-color"
                      }))
                    : _createCommentVNode("", true)
                ], 8, _hoisted_29),
                (factory.isShow)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(factory.groupList, (group, index2) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "w-full",
                          style: {"margin-top":"30px"},
                          key: index2
                        }, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["w-full flex items-center cursor-pointer", { 'select-machine': group.id === _unref(selectId) }]),
                            style: {"font-size":"16px"}
                          }, [
                            _cache[8] || (_cache[8] = _createElementVNode("img", {
                              class: "theme-white-black-img",
                              style: {"width":"16px"},
                              src: _imports_1,
                              alt: ""
                            }, null, -1)),
                            _createElementVNode("div", {
                              class: "flex-1 ml-2 ellipsis theme-font-black-color",
                              title: group.name,
                              onClick: ($event: any) => (selectGroup(group, factory))
                            }, _toDisplayString(group.name), 9, _hoisted_32),
                            (group.isShow)
                              ? (_openBlock(), _createBlock(_component_caret_up_outlined, {
                                  key: 0,
                                  onClick: ($event: any) => (group.isShow = !group.isShow),
                                  style: {"font-size":"16px"},
                                  class: "theme-font-black-color"
                                }, null, 8, ["onClick"]))
                              : _createCommentVNode("", true),
                            (!group.isShow)
                              ? (_openBlock(), _createBlock(_component_caret_down_outlined, {
                                  key: 1,
                                  onClick: ($event: any) => (group.isShow = !group.isShow),
                                  style: {"font-size":"16px"},
                                  class: "theme-font-black-color"
                                }, null, 8, ["onClick"]))
                              : _createCommentVNode("", true)
                          ], 2),
                          (group.isShow)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.machineList, (machine, index3) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    class: "w-full",
                                    style: {"margin-top":"20px"},
                                    key: index3
                                  }, [
                                    _createElementVNode("div", {
                                      onClick: ($event: any) => (selectMachine(machine)),
                                      class: _normalizeClass([{ 'select-machine theme-select-machine': machine.id === _unref(selectId) }, "w-full flex items-center machine"]),
                                      style: {"font-size":"14px"}
                                    }, [
                                      _createElementVNode("div", _hoisted_35, _toDisplayString(machine.name), 1),
                                      _createElementVNode("div", {
                                        style: _normalizeStyle([{ 'background-color': machine.condition.color }, {"width":"10px","height":"10px","border-radius":"50%","margin-right":"5px"}])
                                      }, null, 4)
                                    ], 10, _hoisted_34)
                                  ]))
                                }), 128))
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})