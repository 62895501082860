import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  class: "w-64 px-2 overflow-y-auto scroll-box h-screen code-box",
  style: {"height":"100%"}
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { style: {"color":"#f50"} }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 1,
  class: "flex mb-3 items-center"
}
const _hoisted_6 = { class: "freqx_formTitle" }
const _hoisted_7 = {
  class: "flex",
  style: {"text-align":"right","justify-content":"flex-end"}
}
const _hoisted_8 = {
  key: 2,
  class: "whiteBg"
}
const _hoisted_9 = { key: 3 }

import request from "../../common/request";
import { computed, onMounted, ref, watch, reactive } from "vue";
import { v4 as uuidv4 } from "uuid";
import AlarmItem from "./common/AlarmItem.vue";
/**操作日志记录 */
import { operateLogs } from "@/common/logs";
import { QuotaAlarmItem } from "./common/AlarmItem.vue";

import { getLanguage } from "@/common/translate";
import { message } from "ant-design-vue";

interface Props {
  hideExpertCareOptions?: boolean // 是否隐藏专家看护选项，诊断管理中需要显示，报警管理中需要隐藏
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SmartAlarm',
  props: {
    hideExpertCareOptions: { type: Boolean, default: false }
  },
  setup(__props: any) {

operateLogs("访问单值报警规则配置");

let language: any = ref('Chinese')
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
}
getLang()

const props = __props

let showExpertCareOptions = computed<boolean>(() => {
  return !props.hideExpertCareOptions
});

const modalState = reactive({
  visible: false,
  title: "说明",
  confirmLoading: false,
  roleName: "",
  meun: [],
});
const sensorsSource = ref<any>([]);
const expertCare = ref<boolean>(null);

class PowerOffAlarmConfig {
  enable: boolean = false;
  expertCare: boolean = false;
  sensors: { sensorId: string, sensorPlace: string }[] = [];
  powerOnSensorNumber: number = 1;
  times: number = 3;
  intervalDays: number = 3;
  intervalTimes: number = 1;
  alarmLevel: number = 2;
}

const alarmLevelOptions = ref<any[]>([
  { label: '可用', value: 1 },
  { label: '警戒', value: 2 },
  { label: '故障', value: 3 }
])

let powerOffAlarmConfig = ref<PowerOffAlarmConfig>(new PowerOffAlarmConfig())
let powerOffSensorOptions = ref<any[]>([])
let powerOffSelectedSensorIds = ref<any[]>([])

// 新增
const add = () => {
  modalState.visible = true;
};
// 查询
const search = async () => {

  const sConfig = {
    params: { machineId: currentMachine.value.key },
    headers: {
      requestId: uuidv4(),
    },
  };
  var sResult = await request.get("/api/sensors", sConfig);
  sensorsSource.value = [];
  if (sResult) {
    sensorsSource.value = sResult.data;
    powerOffSensorOptions.value = sResult.data.map((t: any) => ({
      sensorPlace: t.sensorPlace,
      sensorId: t.id,
    }))
  }
  const config = {
    params: { machineId: currentMachine.value.key },
    headers: {
      requestId: uuidv4(),
    },
  };
  const DefaultRangeCount = 12;
  const DefaultLimitCount = 4;
  const DefaultIntervalDays = 7;
  const DefaultIntervalTimes = 1;
  const result = await request.get("/api/sensors/quota-alarm", config);
  if (showExpertCareOptions.value) {
    if (result.data.length > 0 && result.data[0].expertCare != null) {
      expertCare.value = result.data[0].expertCare;
    } else {
      expertCare.value = true;
    }
  } else {
    expertCare.value = null;
  }
  sensorsSource.value.forEach((item: any, index: any) => {
    item.table = []

    item.dataType && item.dataType.forEach((element: any) => {
      let data = result.data.find((t: any) => t.dataType == element && t.sensorId == item.id)
      if (data && data.sensorId) {
        item.table.push(data)
      } else {
        let configItem = {
          _id: null,
          sensorId: item.id,
          sensorPlace: item.sensorPlace,
          machineId: item.machineId,
          dataType: element,
          enable: false,
          expertCare: expertCare.value,
          level1Threshold: null,
          level1RangeCount: DefaultRangeCount,
          level1LimitCount: DefaultLimitCount,
          level1IntervalDays: DefaultIntervalDays,
          level1IntervalTimes: DefaultIntervalTimes,
          level2Threshold: null,
          level2RangeCount: DefaultRangeCount,
          level2LimitCount: DefaultLimitCount,
          level2IntervalDays: DefaultIntervalDays,
          level2IntervalTimes: DefaultIntervalTimes,
          level3Threshold: null,
          level3RangeCount: DefaultRangeCount,
          level3LimitCount: DefaultLimitCount,
          level3IntervalDays: DefaultIntervalDays,
          level3IntervalTimes: DefaultIntervalTimes,
          level4Threshold: null,
          level4IntervalDays: DefaultIntervalDays,
          level4IntervalTimes: DefaultIntervalTimes
        } as QuotaAlarmItem
        item.table.push(configItem)
      }
    });
  })

  await request.get('/api/machine-online-config/alarm', {
    params: { machineId: currentMachine.value.key }
  }).then((res: any) => {
    if (res?.data) {
      powerOffAlarmConfig.value = res.data
      powerOffSelectedSensorIds.value = res.data.sensors.map((t: any) => t.sensorId)
    } else {
      powerOffAlarmConfig.value = new PowerOffAlarmConfig()
      powerOffSelectedSensorIds.value = []
    }
  })
};

/* 左侧区域-设备 数据加载 */
// 在组件加载完成后执行一些操作
let currentMachine = ref<any>();
const selectTree = (key: any, val: any) => {
  if (val.node.type == "machine") {
    currentMachine.value = val.node;
    search();
  }
};

let filterKeyWord = ref<any>();
let treeData = ref<any>();

const onExpand = (keys: string[]) => {
  expandedKeys.value = keys;
  autoExpandParent.value = false;
};
const expandedKeys = ref<(string | number)[]>([]);
const autoExpandParent = ref<boolean>(true);

watch(filterKeyWord, () => {
  expandedKeys.value = [];
  sonKey(treeData.value);
  autoExpandParent.value = true;
});
const sonKey = (item: any) => {
  item.forEach((element: any) => {
    if (element.title.indexOf(filterKeyWord.value) > -1) {
      // expandedKeys.value.push(key);
      expandedKeys.value.push(element.key);
    }
    if (element.children && element.children.length) {
      sonKey(element.children);
    }
  });
};
const getParentKey = (
  key: string | number,
  tree: any
): string | number | undefined => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (
        node.children.some((item: { key: string | number }) => item.key === key)
      ) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};


//***角色车间***//
onMounted(() => {
  //loadMember()
  var requestId = uuidv4();
  loadFactory(requestId);
});

let factoryList = ref<any>();
let allFactory: any = ref([])
const loadFactory = (requestId: any) => {
  const config = {
    params: {},
    headers: {
      requestId: requestId,
    },
  };
  request.get("/api/factories", config).then((res: any) => {
    if (res) {
      allFactory.value = res.data;
      // 2级工厂
      var list = res.data.map((t: any) => ({
        key: t._id,
        title: t.factoryName,
        value: t._id,
        label: t.factoryName,
        parentId: t.parentId,
        id: t._id,
        type: "factory",
        children: t.groups.map((group: any) => ({
          type: "group",
          value: group.sonID,
          label: group.name,
          title: group.name,
          key: group.sonID,
        })),
      }));
      let factory: any = [];
      let parentFs = list.filter((p: any) => !p.parentId)
      parentFs.map((d: any) => {
        let sonFactory = list.filter((p: any) => p.parentId === d.id)
        if (sonFactory && sonFactory.length) {
          d.children = sonFactory
        }
        factory.push(d);
      })

      factoryList.value = factory;
      loadMachine(requestId);
    }
  });
};
const loadMachine = async (requestId: any) => {
  let factoryIds = allFactory.value.map((p: any) => p._id)
  let res = await request.post("/api/machines/all", { factoryIds: factoryIds })
  factoryList.value.forEach((element: any) => {
    if (res) {
      element.children.forEach((sonFact: any) => {
        if (sonFact.type == "factory" && sonFact.children) {
          sonFact.children.forEach((groupFact: any) => {
            if (groupFact.type == "group") {
              groupFact.children = res.data
                .filter((t: any) => t.groupId == groupFact.value)
                .map((t: any) => ({
                  type: "machine",
                  ...t,
                  value: t.id,
                  label: t.machineName,
                  title: t.machineName,
                  key: t.id,
                }));
            }
          });
        } else if (sonFact.type == "group") {
          sonFact.children = res.data
            .filter((t: any) => t.groupId == sonFact.value)
            .map((t: any) => ({
              type: "machine",
              ...t,
              value: t.id,
              label: t.machineName,
              title: t.machineName,
              key: t.id,
            }));
        }
      });
    }
  });
  setTimeout(() => {
    treeData.value = factoryList.value;
  }, 100);
};

const aiColumns = [
  {
    title: "设备类型",
    dataIndex: "type",
    align: "center",
  },
  {
    title: "采用标准",
    dataIndex: "standard",
    align: "center",
  },
];
const standardData = [
  {
    type: "电机类",
    standard: "ISO",
  },
  {
    type: "泵类",
    standard: "ISO",
  },
  {
    type: "泵类",
    standard: "API",
  },
  {
    type: "往复类",
    standard: "ISO",
  },
  {
    type: "风机类",
    standard: "API",
  },
  {
    type: "齿轮类",
    standard: "ISO",
  },
  {
    type: "螺杆式",
    standard: "API",
  },
  {
    type: "汽轮机",
    standard: "ISO",
  },
  {
    type: "通用设备",
    standard: "ISO",
  },
];

const onSavePowerOffAlarm = async () => {

  if (!powerOffSelectedSensorIds.value?.length) {
    message.error('请选择测点')
    return
  }

  if (powerOffAlarmConfig.value.powerOnSensorNumber > (powerOffSelectedSensorIds.value.length ?? 0)) {
    message.error("开机测点数量不能大于测点总数")
    return
  }

  let body = {
    machineId: currentMachine.value.key,
    alarm: powerOffAlarmConfig.value,
  };

  body.alarm.expertCare = expertCare.value;
  body.alarm.sensors = powerOffSelectedSensorIds.value.map((t: any) => ({
    sensorId: t,
    sensorPlace: powerOffSensorOptions.value.find((s: any) => s.sensorId == t)?.sensorPlace
  }))

  await request.put('/api/machine-online-config/alarm', body).then((res: any) => {
    if (res.status == 200) {
      message.success('保存成功')
    } else {
      message.error('保存失败')
    }
  })
}

return (_ctx: any,_cache: any) => {
  const _component_a_input_search = _resolveComponent("a-input-search")!
  const _component_a_tree = _resolveComponent("a-tree")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_question_circle_outlined = _resolveComponent("question-circle-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_checkbox_group = _resolveComponent("a-checkbox-group")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_unref(showExpertCareOptions) ? {} : { 'padding': '30px 0' }),
    class: "bule_list_page theme-common"
  }, [
    _createVNode(_component_a_layout_content, { style: {"padding":"0 20px","height":"100%"} }, {
      default: _withCtx(() => [
        _createVNode(_component_a_row, {
          type: "flex",
          style: {"height":"100%"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, {
              span: 5,
              order: 1,
              style: {"height":"100%"}
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_a_input_search, {
                    value: _unref(filterKeyWord),
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_isRef(filterKeyWord) ? (filterKeyWord).value = $event : filterKeyWord = $event)),
                    style: {"margin-bottom":"8px"},
                    placeholder: "输入区域或设备名称筛选"
                  }, null, 8, ["value"]),
                  _createVNode(_component_a_tree, {
                    class: "draggable-tree",
                    draggable: "",
                    "block-node": "",
                    autoExpandParent: autoExpandParent.value,
                    "expanded-keys": expandedKeys.value,
                    "tree-data": _unref(treeData),
                    onExpand: onExpand,
                    onSelect: selectTree
                  }, {
                    title: _withCtx(({ title }) => [
                      (title.indexOf(_unref(filterKeyWord)) > -1)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                            _createTextVNode(_toDisplayString(title.substr(0, title.indexOf(_unref(filterKeyWord)))) + " ", 1),
                            _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(filterKeyWord)), 1),
                            _createTextVNode(" " + _toDisplayString(title.substr(
                      title.indexOf(_unref(filterKeyWord)) + _unref(filterKeyWord).length
                    )), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(title), 1))
                    ]),
                    _: 1
                  }, 8, ["autoExpandParent", "expanded-keys", "tree-data"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, {
              span: 19,
              order: 2,
              style: {"padding-bottom":"20px"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: add,
                  style: {"margin-bottom":"15px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_question_circle_outlined),
                    _cache[10] || (_cache[10] = _createTextVNode(" 说明 "))
                  ]),
                  _: 1
                }),
                (sensorsSource.value && sensorsSource.value.length > 0 && _unref(showExpertCareOptions))
                  ? (_openBlock(), _createBlock(_component_a_form_item, {
                      key: 0,
                      class: "mt-2",
                      label: _unref(getLanguage)('是否专家看护', _unref(language)),
                      name: "alarmType"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_radio_group, {
                          name: "alarmType",
                          value: expertCare.value,
                          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((expertCare).value = $event))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_radio, { value: true }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(getLanguage)('是', _unref(language))), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_radio, { value: false }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(getLanguage)('否', _unref(language))), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _: 1
                    }, 8, ["label"]))
                  : _createCommentVNode("", true),
                (_unref(currentMachine))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(getLanguage)('关机报警', _unref(language))), 1),
                      _cache[12] || (_cache[12] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_a_button, {
                          type: "primary",
                          onClick: onSavePowerOffAlarm
                        }, {
                          default: _withCtx(() => _cache[11] || (_cache[11] = [
                            _createTextVNode("保存")
                          ])),
                          _: 1
                        })
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_unref(currentMachine))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_a_form, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            label: _unref(getLanguage)('是否启用', _unref(language)),
                            name: "enablePowerOffAlarm"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_switch, {
                                checked: _unref(powerOffAlarmConfig).enable,
                                "onUpdate:checked": _cache[2] || (_cache[2] = ($event: any) => ((_unref(powerOffAlarmConfig).enable) = $event)),
                                "checked-children": "开",
                                "un-checked-children": "关"
                              }, null, 8, ["checked"])
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_a_form_item, {
                            label: _unref(getLanguage)('联动测点', _unref(language))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox_group, {
                                value: _unref(powerOffSelectedSensorIds),
                                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (_isRef(powerOffSelectedSensorIds) ? (powerOffSelectedSensorIds).value = $event : powerOffSelectedSensorIds = $event))
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(powerOffSensorOptions), (item) => {
                                    return (_openBlock(), _createBlock(_component_a_checkbox, {
                                      key: item.sensorId,
                                      value: item.sensorId
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.sensorPlace), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["value"])
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_a_form_item, {
                            label: _unref(getLanguage)('报警判断', _unref(language))
                          }, {
                            default: _withCtx(() => [
                              _cache[13] || (_cache[13] = _createElementVNode("span", { class: "text-primary" }, "至少", -1)),
                              _createVNode(_component_a_input_number, {
                                value: _unref(powerOffAlarmConfig).powerOnSensorNumber,
                                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(powerOffAlarmConfig).powerOnSensorNumber) = $event)),
                                min: "1"
                              }, null, 8, ["value"]),
                              _cache[14] || (_cache[14] = _createElementVNode("span", { class: "text-primary" }, "个测点开机，其余测点关机，且连续出现", -1)),
                              _createVNode(_component_a_input_number, {
                                value: _unref(powerOffAlarmConfig).times,
                                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_unref(powerOffAlarmConfig).times) = $event)),
                                min: "1"
                              }, null, 8, ["value"]),
                              _cache[15] || (_cache[15] = _createElementVNode("span", { class: "text-primary" }, "笔，触发报警", -1)),
                              _createVNode(_component_a_input_number, {
                                value: _unref(powerOffAlarmConfig).intervalDays,
                                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_unref(powerOffAlarmConfig).intervalDays) = $event)),
                                min: "1"
                              }, null, 8, ["value"]),
                              _cache[16] || (_cache[16] = _createElementVNode("span", { class: "text-primary" }, "天", -1)),
                              _createVNode(_component_a_input_number, {
                                value: _unref(powerOffAlarmConfig).intervalTimes,
                                "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_unref(powerOffAlarmConfig).intervalTimes) = $event)),
                                min: "1"
                              }, null, 8, ["value"]),
                              _cache[17] || (_cache[17] = _createElementVNode("span", { class: "text-primary" }, "次", -1))
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_a_form_item, {
                            label: _unref(getLanguage)('报警级别', _unref(language))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_radio_group, {
                                name: "alarmLevel",
                                value: _unref(powerOffAlarmConfig).alarmLevel,
                                "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_unref(powerOffAlarmConfig).alarmLevel) = $event))
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(alarmLevelOptions.value, (item) => {
                                    return (_openBlock(), _createBlock(_component_a_radio, {
                                      key: item.value,
                                      value: item.value
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_unref(getLanguage)(item.label, _unref(language))), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["value"])
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sensorsSource.value, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.sensorId
                  }, [
                    (_openBlock(), _createBlock(AlarmItem, {
                      sensor: item,
                      key: item.sensorId,
                      machineId: _unref(currentMachine).key,
                      "expert-care": expertCare.value,
                      onSearch: search
                    }, null, 8, ["sensor", "machineId", "expert-care"]))
                  ]))
                }), 128)),
                (!sensorsSource.value || !sensorsSource.value.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_component_a_alert, {
                        message: "未选择设备或没有配置测点",
                        type: "warning",
                        "show-icon": ""
                      })
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_a_modal, {
      width: 850,
      maskClosable: false,
      visible: modalState.visible,
      "onUpdate:visible": _cache[9] || (_cache[9] = ($event: any) => ((modalState.visible) = $event)),
      title: modalState.title,
      "confirm-loading": modalState.confirmLoading,
      footer: null
    }, {
      default: _withCtx(() => [
        _cache[18] || (_cache[18] = _createElementVNode("div", { style: {"font-size":"16px","line-height":"2","color":"#fff"} }, [
          _createTextVNode(" 基于国际、国家或行业内既定标准，实现对设备运行状态进行监测。同时，可纵向参考设备历史运行数据，横向比较同类设备运行数据，确定各报警等级的阈值。"),
          _createElementVNode("br"),
          _createTextVNode(" 但依据标准设定的绝对合理的阈值很难获取，设定过高的报警阈值会造成设备故障"),
          _createElementVNode("span", { style: {"font-weight":"600","font-size":"18px"} }, "漏报"),
          _createTextVNode("的风险，而阈值过低又会来带大量的"),
          _createElementVNode("span", { style: {"font-weight":"600","font-size":"18px"} }, "误报警"),
          _createTextVNode("，给现场处理造成极大的压力；此外，仅按照标准规定中的几种指标设定的阈值报警所能发现的故障模式有限。设置和修改阈值也面临必需手动调整与工作量大等问题。 "),
          _createElementVNode("br"),
          _createTextVNode(" 根据各类国际、国家、行业标准，根据对目前所有设备类型，参照相应标准，做如下统计："),
          _createElementVNode("br"),
          _createElementVNode("br")
        ], -1)),
        _createVNode(_component_a_table, {
          columns: aiColumns,
          "data-source": standardData,
          pagination: false,
          size: "small",
          bordered: ""
        })
      ]),
      _: 1
    }, 8, ["visible", "title", "confirm-loading"])
  ], 4))
}
}

})