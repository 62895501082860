import axios from "axios";
import { message } from 'ant-design-vue';

const service = axios.create({
    // baseURL 需要设置为反向代理前缀，不能设置绝对路径URL
    baseURL: '',
    timeout: 30000,
    withCredentials: false,
    headers: {
        'X-Custom-Header': 'freqx',
        'Content-Type': "application/json"
    }
})

if (localStorage.getItem('token')) {
    service.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('token');
}

// 添加请求拦截器
service.interceptors.request.use(function (req) {

    if (localStorage.getItem('token')) {
        req.headers.Authorization = "Bearer " + localStorage.getItem('token');
    }

    // 当前查看工厂
    if (localStorage.getItem('currentFactory')) {
        req.headers.currentFactory = localStorage.getItem('currentFactory');
    } else {
        req.headers.currentFactory = null;
    }

    //req.headers = Object.apply(service.defaults.headers, req.headers);
 
    if (req.headers.requestId) {
        service.defaults.headers.common['requestId'] = req.headers.requestId;
    }
    return req;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

//添加响应拦截器
service.interceptors.response.use((response: any) => {
    // 对响应数据做点什么
    const res = response.data;
    if (res.status == 200 || res.code === 200) {
        return res;
    } else if (res.status == 401) {
        if (res.message) {
            message.warn(res.message);
        }
        return res
        // 跳转登录页面

    } else {
        console.log(response)
        const urlParams =new URL(response.request.responseURL).searchParams;
        console.log(urlParams)
        for (const param of urlParams) {
            console.log(param);
        }

        const myParam = urlParams.get('__ignoreError');
        if (myParam) {
            return Promise.resolve(res);
        }

        message.warn(res.message);
        // return Promise.reject(new Error(res.message || "Error"));
    }
},
    function (error) {
        if (error && error.response) {
            switch (error.response.status) {
                case 400:
                    error.message = "请求错误(400)"
                    break
                case 401:
                    error.message = "未授权,请登录(401)"
                    break
                case 403:
                    error.message = "拒绝访问(403)"
                    break
                case 404:
                    error.message = `请求地址出错: ${error.response.config.url}`
                    break
                case 405:
                    error.message = "请求方法未允许(405)"
                    break
                case 408:
                    error.message = "请求超时(408)"
                    break
                case 500:
                    error.message = "服务器内部错误(500)"
                    break
                case 501:
                    error.message = "服务未实现(501)"
                    break
                case 502:
                    error.message = "网络错误(502)"
                    break
                case 503:
                    error.message = "服务不可用(503)"
                    break
                case 504:
                    error.message = "网络超时(504)"
                    break
                case 505:
                    error.message = "HTTP版本不受支持(505)"
                    break
                default:
                    error.message = `连接错误: ${error.message}`
            }
        } else {
            if (error.message == "Network Error") error.message == "网络异常，请检查后重试！"
            error.message = "连接到服务器失败，请联系管理员"
        }
        message.warn(error.message);
        // return Promise.reject(error);
    });

export default service
